import SterlingApiClient, { DeleteRequestOptions, GetRequestOptions, PostRequestOptions, PutRequestOptions } from '../../Sterling.ApiClient';
import { AddSupportingInformationBody, SupportingInformation, SupportingInformationProposition } from '../Types';

export default class SupportingInformationApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRoute = (projectId: string, versionId: string, annotationId: string) =>
        `projects/${projectId}/versions/${versionId}/annotations/${annotationId}/supportingInformation`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    getPropositionsSupportingInformation = (
        projectId: string,
        versionId: string,
        annotationId: string,
        getRequestOptions: GetRequestOptions<Array<SupportingInformationProposition>>
    ) =>
        this._sterlingApiClient.getAsync<Array<SupportingInformationProposition>>(
            `${this._rootRoute(projectId, versionId, annotationId)}/propositions`,
            getRequestOptions
        );

    getSupportingInformation = (
        projectId: string,
        versionId: string,
        annotationId: string,
        getRequestOptions: GetRequestOptions<Array<SupportingInformation>>
    ) => this._sterlingApiClient.getAsync<Array<SupportingInformation>>(`${this._rootRoute(projectId, versionId, annotationId)}`, getRequestOptions);

    addSupportingInformation = (
        projectId: string,
        versionId: string,
        annotationId: string,
        postRequestOptions: PostRequestOptions<AddSupportingInformationBody>
    ) =>
        this._sterlingApiClient.postAsync<AddSupportingInformationBody>(`${this._rootRoute(projectId, versionId, annotationId)}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Information linked.'],
        });

    deleteSupportingInformation = (
        projectId: string,
        versionId: string,
        annotationId: string,
        supportingInformationId: string,
        deleteRequestOptions: DeleteRequestOptions
    ) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRoute(projectId, versionId, annotationId)}/${supportingInformationId}`, {
            ...deleteRequestOptions,
            toastSuccessMessages: ['Information unlinked.'],
        });

    linkPropositionToAnnotation = (projectId: string, versionId: string, annotationId: string, propositionId: string, postRequestOptions: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRoute(projectId, versionId, annotationId)}/propositions/${propositionId}`, postRequestOptions);

    rejectProposition = (projectId: string, versionId: string, annotationId: string, propositionId: string, putRequestOptions: PutRequestOptions) =>
        this._sterlingApiClient.putAsync(`${this._rootRoute(projectId, versionId, annotationId)}/propositions/${propositionId}/reject`, putRequestOptions);

    restoreProposition = (projectId: string, versionId: string, annotationId: string, propositionId: string, putRequestOptions: PutRequestOptions) =>
        this._sterlingApiClient.putAsync(`${this._rootRoute(projectId, versionId, annotationId)}/propositions/${propositionId}/restore`, putRequestOptions);
}
