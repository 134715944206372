import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import ProjectDocumentsApiClient from 'ApiClients/SterlingApiClients/ProjectDocumentsApiClient/ProjectDocuments.ApiClient';
import {
    AutoAnnotationsProcessStatus,
    AutoAnnotationsSectionProcessStatus,
    DocumentInfo,
    Documents,
    DocumentsContainer,
    ProjectDocumentProcessingStatus,
    VerificationDocument,
} from 'ApiClients/SterlingApiClients/Types';
import { DocumentsContainerExtended } from 'Views/Project/ProjectWorkspace/Sidebar/Tabs/Documents/DocumentsList/Types';
import { useCallback, useMemo } from 'react';
import useDocumentsState from './useDocumentsState';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import {
    AutoAnnotationGeneratedMessageData,
    AutoAnnotationsSectionProcessStatusChangedMessageData,
} from 'App/Notifications/ProjectNotificationsSubscriber/ProjectNotificationsSubscriber.Types';

const useDocuments = (projectId: string, projectVersionId: string, docsApi: ProjectDocumentsApiClient) => {
    const {
        data: documents,
        wrappedMethod: _refetchDocuments,
        setData: setDocuments,
    } = usePromiseWithFlowMethods<{ projectId: string; projectVersionId: string }, Documents | null>({
        method: (input, flowMethods) => docsApi.getProjectDocumentsList(input.projectId, input.projectVersionId, flowMethods),
        initialData: null,
        initFetch: { input: { projectId, projectVersionId } },
    });
    const refetchDocuments = useCallback(() => _refetchDocuments({ projectId, projectVersionId }), [_refetchDocuments, projectId, projectVersionId]);
    const _setVerificationDocumentProperties = useCallback((values: Array<{ field: keyof VerificationDocument; value: number | boolean }>) => {
        setDocuments((prevState) => {
            if (prevState && prevState.verificationDocument) {
                let newState = { ...prevState };
                values.forEach(({ field, value }) => {
                    newState.verificationDocument = {
                        ...newState.verificationDocument!,
                        [field]: value,
                    };
                });

                return newState;
            } else return prevState;
        });
        // eslint-disable-next-line
    }, []);
    const setVerificationDocumentProcessingStatus = useCallback(
        (status: ProjectDocumentProcessingStatus) => _setVerificationDocumentProperties([{ field: 'processingStatus', value: status }]),
        [_setVerificationDocumentProperties]
    );

    const handleAutoAnnotationGenerated = useCallback(
        (msg: AutoAnnotationGeneratedMessageData) =>
            setDocuments((prevState) => {
                if (prevState && prevState.verificationDocument) {
                    let newState = { ...prevState };

                    newState.verificationDocument!.autoAnnotationsProcess = {
                        ...newState.verificationDocument!.autoAnnotationsProcess!,
                        status: msg.processStatus,
                        anySectionProcessFailed: msg.processStatus !== AutoAnnotationsProcessStatus.Processed,
                        progress: msg.processStatus === AutoAnnotationsProcessStatus.Failed ? 0 : 100,
                    };

                    newState.verificationDocument!.isAnnotationModificationAllowed = true;

                    return newState;
                } else return prevState;
            }),
        [setDocuments]
    );

    const setAutoAnnotationsProcessProgress = useCallback(
        (progress: number) =>
            setDocuments((prevState) => {
                if (prevState && prevState.verificationDocument) {
                    let newState = { ...prevState };
                    newState.verificationDocument!.autoAnnotationsProcess = {
                        ...newState.verificationDocument!.autoAnnotationsProcess!,
                        progress,
                    };

                    return newState;
                } else return prevState;
            }),
        [setDocuments]
    );

    const setAutoAnnotationsProcessIsErrorReceived = useCallback(
        (isErrorReceived: boolean) =>
            setDocuments((prevState) => {
                if (prevState && prevState.verificationDocument) {
                    let newState = { ...prevState };
                    newState.verificationDocument!.autoAnnotationsProcess = {
                        ...newState.verificationDocument!.autoAnnotationsProcess!,
                        isErrorReceived,
                    };

                    return newState;
                } else return prevState;
            }),
        [setDocuments]
    );

    const handleAutoAnnotationsSectionProcessStatusChanged = useCallback(
        (msg: AutoAnnotationsSectionProcessStatusChangedMessageData) =>
            setDocuments((prevState) => {
                if (prevState && prevState.verificationDocument) {
                    let newState = { ...prevState };

                    newState.verificationDocument!.autoAnnotationsProcess = {
                        ...newState.verificationDocument!.autoAnnotationsProcess!,
                        status:
                            msg.processStatus === AutoAnnotationsSectionProcessStatus.Processing
                                ? AutoAnnotationsProcessStatus.Processing
                                : newState.verificationDocument!.autoAnnotationsProcess!.status,
                        anySectionProcessFailed:
                            msg.processStatus === AutoAnnotationsSectionProcessStatus.Failed
                                ? true
                                : newState.verificationDocument!.autoAnnotationsProcess!.anySectionProcessFailed,
                        progress: msg.progress ? msg.progress : newState.verificationDocument!.autoAnnotationsProcess!.progress,
                    };

                    if (msg.processStatus === AutoAnnotationsSectionProcessStatus.Failed) {
                        newState.verificationDocument!.headings = newState.verificationDocument!.headings.map((heading) => {
                            if (heading.id === msg.headingId) {
                                return {
                                    ...heading,
                                    areAnyAutoAnnotatingSectionsErrors: true,
                                };
                            } else return heading;
                        });
                    }

                    return newState;
                } else return prevState;
            }),
        [setDocuments]
    );

    const setSupportingDocumentProcessingStatus = useCallback((supportingDocumentId: string, processingStatus: ProjectDocumentProcessingStatus) => {
        setDocuments((prevState) => {
            if (prevState) {
                let newState = { ...prevState };
                ModifySupportingDocumentById(newState.supportingDocuments!, supportingDocumentId, (doc) => ({ ...doc, processingStatus }));

                if (processingStatus === ProjectDocumentProcessingStatus.Failed) {
                    newState.anySupportingDocumentsProcessingErrors = true;
                }

                return newState;
            } else return prevState;
        });
        // eslint-disable-next-line
    }, []);

    const uploadSupportingDocument = (name: string, file: File, flowMethods: FlowMethods) =>
        docsApi.uploadSupportingDocument(projectId!, projectVersionId, {
            body: { name, document: file },
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods.onSuccess && flowMethods.onSuccess(data);
                refetchDocuments();
            },
        });

    // verDoc -> VerificationDocument
    const verificationDocumentInfo: VerificationDocument | null = useMemo(() => {
        if (documents) {
            if (documents.verificationDocument) {
                return {
                    ...documents.verificationDocument,
                    headings: documents.verificationDocument.headings.sort((a, b) => {
                        if (a.content.boundingBoxSections[0].pageNumber !== b.content.boundingBoxSections[0].pageNumber)
                            return a.content.boundingBoxSections[0].pageNumber - b.content.boundingBoxSections[0].pageNumber;
                        else return a.content.boundingBoxSections[0].boundingBoxes[0].topLeft.y - b.content.boundingBoxSections[0].boundingBoxes[0].topLeft.y;
                    }),
                };
            }
        }

        return null;
    }, [documents]);
    // supDoc -> SupportingDocument
    const getDocument = useCallback(
        (docId: string, docName: string) => docsApi.getDocument(projectId!, projectVersionId, docId, docName),
        [projectId, projectVersionId, docsApi]
    );
    const suppDocsById: { [key: string]: DocumentInfo } = useMemo(() => {
        if (documents?.supportingDocuments) {
            let suppDocsByIdTmp: { [key: string]: DocumentInfo } = {};
            const getDocsFromDirectory = (container: DocumentsContainer) => {
                container.documents.forEach((d) => (suppDocsByIdTmp[d.id] = d));
                container.directories.forEach((d) => getDocsFromDirectory(d));
            };
            getDocsFromDirectory(documents.supportingDocuments);

            return suppDocsByIdTmp;
        } else return {};
    }, [documents]);

    const mapDirectories = useCallback((directories: Array<DocumentsContainer>): DocumentsContainerExtended[] => {
        return directories.map((d) => {
            return {
                documents: d.documents,
                name: d.name,
                path: d.path,
                directories: mapDirectories(d.directories),
                isOpened: false,
            };
        });
    }, []);

    const supportingDocumentsTreeExtended: DocumentsContainerExtended | null = useMemo(() => {
        const suppDocs = documents?.supportingDocuments;
        if (suppDocs) {
            return {
                documents: suppDocs.documents,
                name: suppDocs.name,
                path: suppDocs.path,
                directories: mapDirectories(suppDocs.directories),
                isOpened: false,
            };
        } else return null;
    }, [documents, mapDirectories]);

    const {
        documentInfo: supDocInfo,
        setDocumentIdToDisplay: switchSupportingDocument,
        documentsCache: supDocsCache,
    } = useDocumentsState(suppDocsById, getDocument);
    const supDocFileInfo = useMemo(() => supDocsCache[supDocInfo?.id || '']?.fileInfo || null, [supDocInfo, supDocsCache]);

    const anySupportingDocumentsProcessingErrors = useMemo(() => documents?.anySupportingDocumentsProcessingErrors || false, [documents]);
    return {
        verificationDocumentInfo,
        supDocInfo,
        supDocFileInfo,
        suppDocsById,
        supportingDocumentsTreeExtended,
        uploadSupportingDocument,
        switchSupportingDocument,
        getDocument,
        setVerificationDocumentProcessingStatus,
        handleAutoAnnotationGenerated,
        setAutoAnnotationsProcessProgress,
        setAutoAnnotationsProcessIsErrorReceived,
        handleAutoAnnotationsSectionProcessStatusChanged,
        setSupportingDocumentProcessingStatus,
        refetchDocuments,
        anySupportingDocumentsProcessingErrors,
    };
};

export default useDocuments;

const ModifySupportingDocumentById = (rootDirectory: DocumentsContainer, supportingDocumentId: string, newDoc: (doc: DocumentInfo) => DocumentInfo) => {
    const modifyDocumentInDirectory = (directory: DocumentsContainer, supportingDocumentId: string) => {
        let documentIndex = directory.documents.findIndex((document) => document.id === supportingDocumentId);

        if (documentIndex > -1) {
            directory.documents[documentIndex] = newDoc(directory.documents[documentIndex]);
            return;
        } else {
            for (let i = 0; i < directory.directories.length; i++) {
                modifyDocumentInDirectory(directory.directories[i], supportingDocumentId);
                return;
            }
        }
    };

    modifyDocumentInDirectory(rootDirectory, supportingDocumentId);
};
