import SupportingInformationApiClient from 'ApiClients/SterlingApiClients/SupportingInformationApiClient/SupportingInformation.ApiClient';
import { BoundingBoxSection, SupportingInformationProposition } from 'ApiClients/SterlingApiClients/Types';
import useStateById from 'Hooks/useStateById';
import useToasts, { ToastEnum } from 'Hooks/useToasts';
import { ContentType } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { SupportingInformationContext, SupportingInformationPropositionContext, getSelectionType } from 'Views/Project/ProjectWorkspace/Types';
import { useCallback } from 'react';

const useSupportingInformation = (
    projectId: string,
    projectVersionId: string,
    suppInfoApiClient: SupportingInformationApiClient,
    refetchAnnotations: () => void
) => {
    const { addToast } = useToasts();
    // >> SUPPORTING INFORMATION
    const [supportingInformationByAnnotationId, setSupportingInformationByAnnotationId] = useStateById<SupportingInformationContext>();
    const getSupportingInformation = (annotationId: string) => {
        setSupportingInformationByAnnotationId.Function(annotationId, (prev) => ({
            isFetching: true,
            supportingInformation: prev?.supportingInformation ? prev.supportingInformation : [],
        }));
        suppInfoApiClient.getSupportingInformation(projectId!, projectVersionId, annotationId, {
            onSuccess: (data) =>
                setSupportingInformationByAnnotationId.Value(annotationId, {
                    isFetching: false,
                    supportingInformation: data,
                }),
        });
    };

    const addSupportingInformation = (
        annotationId: string,
        supportingDocumentId: string,
        contentType: ContentType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string
    ) =>
        suppInfoApiClient.addSupportingInformation(projectId!, projectVersionId, annotationId, {
            body: { supportingDocumentId, selectionType: getSelectionType(contentType), boundingBoxSections, statement },
            onSuccess: () => {
                refetchAnnotations();
                getSupportingInformation(annotationId);
            },
        });

    const deleteSupportingInformation = (annotationId: string, supportingInformationId: string, setFetching: (isFetching: boolean) => void) =>
        suppInfoApiClient.deleteSupportingInformation(projectId!, projectVersionId, annotationId, supportingInformationId, {
            onInit: () => setFetching(true),
            onSuccess: () => {
                setFetching(false);
                getSupportingInformation(annotationId);
                if (supportingInformationPropositionByAnnotationId[annotationId]) {
                    getSupportingInformationPropositions(annotationId, {
                        keepPrevData: true,
                    });
                }
                refetchAnnotations();
            },
            onError: () => setFetching(false),
        });

    // >> SUPPORTING INFORMATION PROPOSITIONS
    const [supportingInformationPropositionByAnnotationId, setSupportingInformationPropositionByAnnotationId] =
        useStateById<SupportingInformationPropositionContext>();
    const getSupportingInformationPropositions = (annotationId: string, options?: { keepPrevData?: boolean; onInit?: () => void; onSuccess?: () => void }) => {
        const _setState = (data: Array<SupportingInformationProposition>, isFetching: boolean) =>
            setSupportingInformationPropositionByAnnotationId.Function(annotationId, (prev) => ({
                isFetching,
                supportingInformationPropositions: isFetching && options?.keepPrevData ? prev.supportingInformationPropositions : data,
                countToDisplay: prev?.countToDisplay ? prev.countToDisplay : 5,
            }));

        suppInfoApiClient.getPropositionsSupportingInformation(projectId!, projectVersionId, annotationId, {
            onInit: () => {
                _setState([], true);
                options?.onInit && options.onInit();
            },
            onSuccess: (data) => {
                _setState(data, false);
                options?.onSuccess && options.onSuccess();
            },
            onError: () => _setState([], false),
        });
    };
    const increaseCountPropositionsToDisplay = useCallback(
        (annotationId: string) => {
            setSupportingInformationPropositionByAnnotationId.Function(annotationId, (prev) => ({
                ...prev,
                countToDisplay: prev.countToDisplay + 5,
            }));
        },
        [setSupportingInformationPropositionByAnnotationId]
    );

    const linkPropositionToAnnotation = (annotationId: string, propositionId: string, setFetching: (isFetching: boolean) => void) => {
        suppInfoApiClient.linkPropositionToAnnotation(projectId!, projectVersionId, annotationId, propositionId, {
            onInit: () => setFetching(true),
            onSuccess: () => {
                setFetching(false);
                getSupportingInformationPropositions(annotationId, {
                    keepPrevData: true,
                    onSuccess: () => getSupportingInformation(annotationId),
                });
                refetchAnnotations();
            },
            onError: () => setFetching(false),
        });
    };

    const rejectProposition = (annotationId: string, propositionId: string) => {
        suppInfoApiClient.rejectProposition(projectId!, projectVersionId, annotationId, propositionId, {
            onSuccess: () => {
                setSupportingInformationPropositionByAnnotationId.Function(annotationId, (prev) => {
                    const newState = { ...prev };
                    const item = newState.supportingInformationPropositions?.find((s) => s.id === propositionId);
                    if (item) item.isRejected = true;

                    return newState;
                });

                addToast({ type: ToastEnum.SUCCESS, content: ['Proposal rejected.'] });
            },
        });
    };

    const restoreProposition = (annotationId: string, propositionId: string, setFetching: (isFetching: boolean) => void) => {
        suppInfoApiClient.restoreProposition(projectId!, projectVersionId, annotationId, propositionId, {
            onSuccess: () => {
                setSupportingInformationPropositionByAnnotationId.Function(annotationId, (prev) => {
                    const newState = { ...prev };
                    const item = newState.supportingInformationPropositions?.find((s) => s.id === propositionId);
                    if (item) item.isRejected = false;

                    return newState;
                });
                addToast({ type: ToastEnum.SUCCESS, content: ['Proposal restored.'] });
            },
            setFetching,
        });
    };

    return {
        suppInfo: {
            supportingInformationByAnnotationId,
            getSupportingInformation,
            addSupportingInformation,
            deleteSupportingInformation,
        },
        suppInfoPropositions: {
            supportingInformationPropositionByAnnotationId,
            increaseCountPropositionsToDisplay,
            getSupportingInformationPropositions,
            linkPropositionToAnnotation,
            rejectProposition,
            restoreProposition,
        },
    };
};

export default useSupportingInformation;
