import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useCallback, useEffect, useState } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { ContentType, HighlightToolbars, ModifiedHighlight, NewHighlight } from '../PdfViewerWithToolbar.Types';
import { BoundingBox, BoundingBoxSection } from 'ApiClients/SterlingApiClients/Types';
import {
    AnnotationAttributeType,
    AnnotationAttributeTypeValues,
    getHighlightId,
    getOriginalHighlightId,
    getParentHighlightId,
    isModifiedHighlight,
    isNewHighlight,
} from './Annotations/Drawing/ViewerAnnotations/Attributes';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { AnnotationModifyingProp } from '../PdfViewerWithToolbar.Component';
import Log from '../Logger';
import { ThemeProvider } from 'UI';
import { PartNumberContainerClassName } from './Annotations/Drawing/HtmlElements/HighlightPartNumber';

const useToolbars = (
    webViewerInstance: WebViewerInstance | null,
    highlightToolbars: HighlightToolbars,
    newAnnotationId: string | null,
    selectedHighlightId: string | null,
    emotionCache: EmotionCache,
    annotationModifyingProp: AnnotationModifyingProp,
    viewerAnnotationsUpdated: number
) => {
    const [highlightToolbarRef, setHighlightToolbarRef] = useState<React.RefObject<HTMLDivElement>>();
    const { newHighlightToolbar, highlightToolbar, modifiedHighlightToolbar, deps: highlightToolbarsDeps } = highlightToolbars;
    const showHideAnnotationElements = useCallback(
        (id: string, show: boolean) => {
            if (webViewerInstance) {
                const annotationManager = webViewerInstance.Core.annotationManager;
                const elemenetsToShowHide: Array<AnnotationAttributeTypeValues> = [
                    'additionalHtmlElement',
                    'highlightLeftTopInformation',
                    'highlightLeftTopInformationBackground',
                ];
                const htmlElemenetsToShowHide: Array<HTMLDivElement> = webViewerInstance.UI.iframeWindow.document.getElementsByClassName(
                    PartNumberContainerClassName
                ) as unknown as Array<HTMLDivElement>;
                const annots = annotationManager
                    .getAnnotationsList()
                    .filter((a) => getParentHighlightId(a) === id && elemenetsToShowHide.includes(AnnotationAttributeType.get(a)));
                if (show) {
                    annots.forEach((a) => (a.Hidden = false));
                    annotationManager.drawAnnotationsFromList(annots);
                    for (const e of htmlElemenetsToShowHide) {
                        e.style.display = 'unset';
                    }
                } else {
                    annots.forEach((a) => (a.Hidden = true));
                    annotationManager.drawAnnotationsFromList(annots);
                    for (const e of htmlElemenetsToShowHide) {
                        e.style.display = 'none';
                    }
                }
            }
        },
        [webViewerInstance]
    );
    const rootRenderWrapper = useCallback(
        (root: Root, children: React.ReactNode) => {
            root.render(
                <ThemeProvider>
                    <CacheProvider value={emotionCache}>{children}</CacheProvider>
                </ThemeProvider>
            );
        },
        [emotionCache]
    );

    useEffect(() => {
        if (webViewerInstance) {
            // Rendering fake toolbar for getting width for right annotationPopup.update below. Without that PdfViewer will display toolbar in incorrect place.
            if (highlightToolbarRef?.current) {
                const toRemove = webViewerInstance.UI.iframeWindow.document.getElementsByClassName('to-remove');
                toRemove[0].remove();
            }
            const doc = webViewerInstance.UI.iframeWindow.document;
            const container = doc.querySelector(`.content`);
            const newDiv = doc.createElement('div');
            newDiv.style.position = 'absolute';
            newDiv.style.visibility = 'hidden';
            newDiv.classList.add('to-remove');
            container!.appendChild(newDiv);
            const root = createRoot(newDiv);

            const annot = webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0];
            if (annot) {
                if (isModifiedHighlight(annot)) {
                    rootRenderWrapper(
                        root,
                        modifiedHighlightToolbar!.render({
                            clearSelection: () => {},
                            getModifiedHighlight: async () => ({
                                ...(await getModifiedHighlight(webViewerInstance)),
                                originalHighlightId: getOriginalHighlightId(annot),
                            }),
                            modifyHighlight: () => {},
                            setHighlightToolbarRef,
                            showHideAnnotationElements,
                        })
                    );
                } else if (isNewHighlight(annot)) {
                    rootRenderWrapper(
                        root,
                        newHighlightToolbar.render({
                            clearSelection: () => {},
                            getNewHighlight: async () => await getNewHighlight(webViewerInstance),
                            setHighlightToolbarRef,
                        })
                    );
                } else {
                    rootRenderWrapper(
                        root,
                        highlightToolbar.render({
                            getSelectedHighlightId: () => '',
                            setHighlightToolbarRef,
                            modifyHighlight: () => {},
                            showHideAnnotationElements,
                        })
                    );
                }
            }
        }
        // eslint-disable-next-line
    }, [
        webViewerInstance,
        highlightToolbarsDeps,
        newAnnotationId,
        selectedHighlightId,
        annotationModifyingProp.annotationModifyingState,
        viewerAnnotationsUpdated,
    ]);

    useEffect(() => {
        if (webViewerInstance) {
            const clearSelection = () => {
                const annots = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => isNewHighlight(a));
                Log('ACTION', ['clearSelection', 'deleteAnnotations'], [annots]);
                webViewerInstance.Core.annotationManager.deleteAnnotations(annots);
            };

            webViewerInstance.UI.annotationPopup.update([
                {
                    type: 'customElement',
                    // It isn't imposible to pass ReactComponent because WebViewer doesn't support hooks in 'customElement'
                    render: () => {
                        const doc = webViewerInstance.UI.iframeWindow.document;
                        const newDiv = doc.createElement('div');
                        const width = highlightToolbarRef?.current?.offsetWidth;
                        if (!width || width === 0) return newDiv;
                        newDiv.style.width = `${width}px`;
                        const root = createRoot(newDiv);
                        const annot = webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0];
                        if (annot) {
                            if (isModifiedHighlight(annot)) {
                                rootRenderWrapper(
                                    root,
                                    modifiedHighlightToolbar!.render({
                                        clearSelection,
                                        getModifiedHighlight: async () => ({
                                            ...(await getModifiedHighlight(webViewerInstance)),
                                            originalHighlightId: getOriginalHighlightId(annot),
                                        }),
                                        modifyHighlight: () => {
                                            const annot =
                                                webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0] as Core.Annotations.TextHighlightAnnotation;
                                            const originalHighlightId = getOriginalHighlightId(annot);

                                            annotationModifyingProp.setAnnotationModifyingState({
                                                active: true,
                                                originalHighlightId: originalHighlightId,
                                                originalHighlightColor: annot.StrokeColor,
                                                originalHighlightBorderColor:
                                                    (
                                                        webViewerInstance.Core.annotationManager
                                                            .getAnnotationsList()
                                                            .filter(
                                                                (a) =>
                                                                    getParentHighlightId(a) === originalHighlightId &&
                                                                    AnnotationAttributeType.get(a) === 'border'
                                                            )[0] as Core.Annotations.LineAnnotation
                                                    )?.StrokeColor || null,
                                            });
                                        },
                                        setHighlightToolbarRef: () => {},
                                        showHideAnnotationElements,
                                    })
                                );
                            } else if (isNewHighlight(annot)) {
                                rootRenderWrapper(
                                    root,
                                    newHighlightToolbar.render({
                                        clearSelection,
                                        getNewHighlight: async () => await getNewHighlight(webViewerInstance),
                                        setHighlightToolbarRef: () => {},
                                    })
                                );
                            } else {
                                rootRenderWrapper(
                                    root,
                                    highlightToolbar.render({
                                        getSelectedHighlightId: () => getHighlightId(webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0]),
                                        setHighlightToolbarRef: () => {},
                                        modifyHighlight: () => {
                                            const annot =
                                                webViewerInstance.Core.annotationManager.getSelectedAnnotations()[0] as Core.Annotations.TextHighlightAnnotation;
                                            const originalHighlightId = getHighlightId(annot);

                                            annotationModifyingProp.setAnnotationModifyingState({
                                                active: true,
                                                originalHighlightId: originalHighlightId,
                                                originalHighlightColor: annot.StrokeColor,
                                                originalHighlightBorderColor:
                                                    (
                                                        webViewerInstance.Core.annotationManager
                                                            .getAnnotationsList()
                                                            .filter(
                                                                (a) =>
                                                                    getParentHighlightId(a) === originalHighlightId &&
                                                                    AnnotationAttributeType.get(a) === 'border'
                                                            )[0] as Core.Annotations.LineAnnotation
                                                    )?.StrokeColor || null,
                                            });
                                        },
                                        showHideAnnotationElements,
                                    })
                                );
                            }
                        }

                        return newDiv;
                    },
                },
            ]);
        }
        // eslint-disable-next-line
    }, [webViewerInstance, highlightToolbarRef]);
};

const getModifiedHighlight = async (instance: WebViewerInstance): Promise<Omit<ModifiedHighlight, 'originalHighlightId'>> => {
    const annots = instance.Core.annotationManager.getAnnotationsList().filter((a) => isModifiedHighlight(a));
    return await getHighlightFromAnnots(instance, annots);
};

const getNewHighlight = async (instance: WebViewerInstance): Promise<NewHighlight> => {
    const annots = instance.Core.annotationManager.getAnnotationsList().filter((a) => isNewHighlight(a));
    return getHighlightFromAnnots(instance, annots);
};

const getHighlightFromAnnots = async (instance: WebViewerInstance, annots: Array<Core.Annotations.Annotation>): Promise<NewHighlight> => {
    const { documentViewer } = instance.Core;
    let contentType: ContentType | null = null;
    let boundingBoxSections: Array<BoundingBoxSection> = [];
    let statement = '';

    if (annots.length > 0) {
        const annotType = AnnotationAttributeType.get(annots[0]);

        switch (annotType) {
            case 'textAnnotation':
                contentType = 'text';
                break;
            case 'boxAnnotation':
                contentType = 'shape';
                break;
        }

        for (const annot of annots) {
            const pageNumber = annot.getPageNumber();
            const pageWidth = documentViewer.getPageWidth(pageNumber);
            const pageHeight = documentViewer.getPageHeight(pageNumber);
            statement += (await getStatement(instance, pageNumber, annotType, annot)) + ' ';
            boundingBoxSections.push({
                pageNumber,
                pageSize: {
                    height: pageHeight,
                    width: pageWidth,
                },
                boundingBoxes: getBoundingBoxes(annotType, annot),
            });
        }
    }

    return {
        contentType: contentType!,
        boundingBoxSections,
        statement,
    };
};

const getSelectedTextFromRectangle = async (instance: WebViewerInstance, pageNumber: number, rect: Core.Math.Rect) => {
    const txt = await instance.Core.PDFNet.TextExtractor.create();
    const doc = await instance.Core.documentViewer.getDocument().getPDFDoc();
    const page = await doc.getPage(pageNumber);
    const pB = await page.getCropBox();
    const rectangle = new instance.Core.PDFNet.Rect(rect.x1, pB.y2 - rect.y1, rect.x2, pB.y2 - rect.y2);

    txt.begin(page, rectangle);
    const text = await txt.getAsText();

    return text;
};

const getBoundingBoxes = (annotType: AnnotationAttributeTypeValues, annot: Core.Annotations.Annotation) => {
    let boundingBoxes: Array<BoundingBox> = [];
    switch (annotType) {
        case 'textAnnotation':
            const quads: Array<Core.Math.Quad> = (annot as Core.Annotations.TextHighlightAnnotation).Quads;
            for (let i = 0; i < quads.length; i++) {
                const quad = quads[i];
                boundingBoxes.push({
                    topLeft: {
                        x: quad.x4,
                        y: quad.y4,
                    },
                    downRight: {
                        x: quad.x2,
                        y: quad.y2,
                    },
                });
            }
            break;
        case 'boxAnnotation':
            const rect = annot.getRect();
            boundingBoxes.push({
                topLeft: {
                    x: rect.x1,
                    y: rect.y1,
                },
                downRight: {
                    x: rect.x2,
                    y: rect.y2,
                },
            });
            break;
    }

    return boundingBoxes;
};

const getStatement = async (
    webViewerInstance: WebViewerInstance,
    pageNumber: number,
    annotType: AnnotationAttributeTypeValues,
    annot: Core.Annotations.Annotation
) => {
    let statement: string = '';
    switch (annotType) {
        case 'textAnnotation':
            const quads: Array<Core.Math.Quad> = (annot as Core.Annotations.TextHighlightAnnotation).Quads;
            for (let i = 0; i < quads.length; i++) {
                const rect = new webViewerInstance.Core.Math.Rect(quads[i].x4, quads[i].y4, quads[i].x2, quads[i].y2);
                statement += (await getSelectedTextFromRectangle(webViewerInstance, pageNumber, rect)) + ' ';
            }
            break;
        case 'boxAnnotation':
            const rect = annot.getRect();
            statement += (await getSelectedTextFromRectangle(webViewerInstance, pageNumber, rect)) + ' ';
            break;
    }

    return statement;
};

export default useToolbars;
