import { Box } from '@mui/material';
import { Icon } from 'UI';
import {
    AnnotationAttributeType,
    getOriginalHighlightId,
    getParentHighlightId,
    isModifiedHighlight,
} from '../../Hooks/Annotations/Drawing/ViewerAnnotations/Attributes';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { AnnotationModifyingProp, AvailableToolMode, MultiSelectProp } from '../../PdfViewerWithToolbar.Component';
import { ToolbarUtilComponent } from '../Toolbar.Component';

export type HighlightUtilsComponentProps = {
    webViewerInstance: WebViewerInstance;
    currentToolMode: AvailableToolMode;
    setToolMode: (viewerMode: Core.Tools.ToolNames, mode: AvailableToolMode) => void;
    isCreatingHighlightEnabled: boolean;
    multiSelectProp: MultiSelectProp;
    annotationModifyingProp: AnnotationModifyingProp;
};

function HighlightUtilsComponent(props: HighlightUtilsComponentProps) {
    const { webViewerInstance, currentToolMode, setToolMode, isCreatingHighlightEnabled, multiSelectProp, annotationModifyingProp } = props;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ToolbarUtilComponent
                title='Create annotation (text)'
                icon={Icon.TextAreaT}
                active={currentToolMode === 'AnnotationCreateTextHighlight'}
                disabled={!isCreatingHighlightEnabled}
                onClick={() => {
                    if (isCreatingHighlightEnabled) {
                        if (currentToolMode === 'AnnotationCreateTextHighlight') setToolMode(webViewerInstance.Core.Tools.ToolNames.TEXT_SELECT, 'TextSelect');
                        else setToolMode(webViewerInstance.Core.Tools.ToolNames.HIGHLIGHT, 'AnnotationCreateTextHighlight');
                    }
                }}
            />
            {/* POCSV-673: Temporarily hidden
            <ToolbarUtilComponent
                title='Create annotation (box)'
                icon={Icon.PlusSquareDotted}
                active={currentToolMode === 'AnnotationCreateRectangle'}
                disabled={!isCreatingHighlightEnabled}
                onClick={() => {
                    if (isCreatingHighlightEnabled) {
                        if (currentToolMode === 'AnnotationCreateRectangle') setToolMode(webViewerInstance.Core.Tools.ToolNames.TEXT_SELECT, 'TextSelect');
                        else setToolMode(webViewerInstance.Core.Tools.ToolNames.RECTANGLE, 'AnnotationCreateRectangle');
                    }
                }}
            />*/}
            <ToolbarUtilComponent
                title='Multi select'
                icon={Icon.ArrowsCollapse}
                active={multiSelectProp.next}
                disabled={!multiSelectProp.enabled}
                onClick={() => {
                    if (multiSelectProp.enabled) {
                        multiSelectProp.setNext(!multiSelectProp.next);
                        const annot = webViewerInstance.Core.annotationManager.getSelectedAnnotations().filter((a) => isModifiedHighlight(a))[0];
                        if (annot) {
                            const originalHighlightId = getOriginalHighlightId(annot);
                            annotationModifyingProp.setAnnotationModifyingState({
                                active: true,
                                originalHighlightId: originalHighlightId,
                                originalHighlightColor: (annot as Core.Annotations.TextHighlightAnnotation).StrokeColor,
                                originalHighlightBorderColor:
                                    (
                                        webViewerInstance.Core.annotationManager
                                            .getAnnotationsList()
                                            .filter(
                                                (a) => getParentHighlightId(a) === originalHighlightId && AnnotationAttributeType.get(a) === 'border'
                                            )[0] as Core.Annotations.LineAnnotation
                                    )?.StrokeColor || null,
                            });
                        }
                    }
                }}
                iconBoxSx={{ transform: 'rotate(90deg)' }}
            />
        </Box>
    );
}

export default HighlightUtilsComponent;
