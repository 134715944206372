import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useEffect, useRef, useState } from 'react';
import {
    AnnotationAttributeIsHtmlAnnotation,
    AnnotationAttributeIsModifiedHighlight,
    AnnotationAttributeIsNewHighlight,
    AnnotationAttributeOriginalHighlightId,
    AnnotationAttributeType,
    AnnotationAttributeTypeValues,
    getHighlightId,
    getParentHighlightId,
    isFirstAnnotationOfHighlight,
    isLastAnnotationOfHighlight,
    isModifiedHighlight,
    isNewHighlight,
    isStandardAnnotation,
} from './Drawing/ViewerAnnotations/Attributes';
import { AnnotationModifyingProp, AnnotationModifyingState, MultiSelectProp } from '../../PdfViewerWithToolbar.Component';
import { HighlightColors } from '../../PdfViewerWithToolbar.Types';
import { HtmlElementsResizing, getZoomRounded } from './useDrawing';
import Log from '../../Logger';
import { generateBorder } from './Drawing/ViewerAnnotations/Borders';

const useInit = (
    webViewerInstance: WebViewerInstance | null,
    selectedHighlightId: string | null,
    setSelectedHighlightId: (id: string | null) => void,
    clearSelectedHighlight: () => void,
    setNewAnnotationId: (id: string | null) => void,
    multiSelectProp: MultiSelectProp,
    newAnnotationId: string | null,
    annotationModifyingProp: AnnotationModifyingProp,
    selectedHighlightChangedEventName?: string,
    keepSelectedHighlightInCentre?: boolean
) => {
    const [viewerAnnotationsUpdated, setViewerAnnotationsUpdated] = useState<number>(0);

    const selectedHighlightIdRef = useRef(selectedHighlightId);
    selectedHighlightIdRef.current = selectedHighlightId;

    const newAnnotationIdRef = useRef(newAnnotationId);
    newAnnotationIdRef.current = newAnnotationId;

    const multiSelectNextRef = useRef(multiSelectProp.next);
    multiSelectNextRef.current = multiSelectProp.next;

    const annotationModifyingPropStateRef = useRef(annotationModifyingProp.annotationModifyingState);
    annotationModifyingPropStateRef.current = annotationModifyingProp.annotationModifyingState;

    const keepSelectedHighlightInCentreRef = useRef(keepSelectedHighlightInCentre);
    keepSelectedHighlightInCentreRef.current = keepSelectedHighlightInCentre;

    const disactiveAnnotationModifying = () =>
        annotationModifyingProp.setAnnotationModifyingState({
            active: false,
            originalHighlightId: null,
            originalHighlightBorderColor: null,
            originalHighlightColor: null,
        });

    const [zoom, setZoom] = useState<number>(0);

    const zoomRef = useRef<number>();
    zoomRef.current = zoom;

    useEffect(() => {
        if (webViewerInstance) {
            SetToolsConfiguration(webViewerInstance);

            RegisterCreatingAnnotations(webViewerInstance, setNewAnnotationId, multiSelectProp, multiSelectNextRef, annotationModifyingPropStateRef);
            if (selectedHighlightChangedEventName) RegisterAnnotationsJumping(webViewerInstance, selectedHighlightChangedEventName);
            RegisterAnnotationSelectedListener(
                webViewerInstance,
                selectedHighlightIdRef,
                setSelectedHighlightId,
                clearSelectedHighlight,
                newAnnotationIdRef,
                setNewAnnotationId,
                multiSelectProp,
                multiSelectNextRef,
                annotationModifyingPropStateRef,
                disactiveAnnotationModifying,
                () => setViewerAnnotationsUpdated((prev) => prev + 1)
            );
            setZoom(webViewerInstance.Core.documentViewer.getZoomLevel());
            AddZoomUpdatedEventListener(webViewerInstance, zoomRef, setZoom, keepSelectedHighlightInCentreRef);
            AddScrollEventListener(webViewerInstance);
        }
        // eslint-disable-next-line
    }, [webViewerInstance]);

    return {
        viewerAnnotationsUpdated,
    };
};

const SetToolsConfiguration = (webViewerInstance: WebViewerInstance) => {
    const defaultColor = HighlightColors.default;
    const documentViewer = webViewerInstance.Core.documentViewer;
    documentViewer.getTool(webViewerInstance.Core.Tools.ToolNames.RECTANGLE).setStyles({ FillColor: defaultColor, StrokeColor: defaultColor });
    documentViewer.getTool(webViewerInstance.Core.Tools.ToolNames.HIGHLIGHT).setStyles({ FillColor: defaultColor, StrokeColor: defaultColor });
};

const RegisterScrollJumping = (
    documentViewer: Core.DocumentViewer,
    zoomRef: React.MutableRefObject<number | undefined>,
    setZoom: (value: number) => void,
    zoom: number,
    keepSelectedHighlightInCentreRef: React.MutableRefObject<boolean | undefined>
) => {
    //Update scroll location whenever zoom has been updated
    //to prevent scrolling to different page location
    const keepSelectedHighlightInCentre = keepSelectedHighlightInCentreRef.current;
    if (!keepSelectedHighlightInCentre) {
        const scroll = documentViewer.getScrollViewElement();
        const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
        const previousZoom = zoomRef.current || zoom;
        const previousTopLocation = displayMode.state.offset;
        const topLocationForZoom1 = previousTopLocation / previousZoom;
        const topLocationForCurrentZoomLevel = topLocationForZoom1 * zoom;

        const options = { behavior: 'auto' as ScrollBehavior, left: 0, top: topLocationForCurrentZoomLevel };

        scroll.scrollTo(options);
        documentViewer.scrollViewUpdated();
    }

    setZoom(zoom);
};

const RegisterKeepingSelectedHighlightInCentreRef = (
    annotationManager: Core.AnnotationManager,
    keepSelectedHighlightInCentreRef: React.MutableRefObject<boolean | undefined>
) => {
    const keepSelectedHighlightInCentre = keepSelectedHighlightInCentreRef.current;
    if (keepSelectedHighlightInCentre) {
        const selectedAnnot = annotationManager.getSelectedAnnotations()[0];
        if (selectedAnnot) {
            annotationManager.jumpToAnnotation(selectedAnnot);
        }
    }
};

const RegisterCreatingAnnotations = (
    webViewerInstance: WebViewerInstance,
    setNewAnnotationId: (id: string) => void,
    multiSelectProp: MultiSelectProp,
    multiSelectNextRef: React.MutableRefObject<boolean | null>,
    annotationModifyingPropStateRef: React.MutableRefObject<AnnotationModifyingState>
) => {
    const { documentViewer, annotationManager } = webViewerInstance.Core;

    const onAnnotationAdded = (annot: Core.Annotations.TextHighlightAnnotation | Core.Annotations.RectangleAnnotation, type: AnnotationAttributeTypeValues) => {
        if (annot.getWidth() > 0 && annot.getHeight() > 0) {
            annot.disableRotationControl();
            if (annotationModifyingPropStateRef.current.active) {
                const isNextHighlight = multiSelectNextRef.current;

                const allAnnotations = annotationManager.getAnnotationsList();
                AnnotationAttributeIsModifiedHighlight.set(annot, 'true');
                const originalHighlightId = annotationModifyingPropStateRef.current.originalHighlightId!;
                AnnotationAttributeOriginalHighlightId.set(annot, originalHighlightId);
                annot.FillColor = annotationModifyingPropStateRef.current.originalHighlightColor!;
                annot.StrokeColor = annotationModifyingPropStateRef.current.originalHighlightColor!;
                const borderColor = annotationModifyingPropStateRef.current.originalHighlightBorderColor;
                const rect = annot.getRect();
                if (borderColor) {
                    if (!isNextHighlight) {
                        const prevBorder = allAnnotations.filter(
                            (a) => getParentHighlightId(a) === originalHighlightId && AnnotationAttributeType.get(a) === 'border'
                        );
                        Log('EVENT', ['onAnnotationAdded', 'deleteAnnotations'], [prevBorder]);
                        annotationManager.deleteAnnotations(prevBorder);
                    }
                    const border = generateBorder(webViewerInstance, originalHighlightId, 0, annot.PageNumber, rect, borderColor);
                    border.forEach((b) => AnnotationAttributeIsNewHighlight.set(b, 'true'));
                    annotationManager.addAnnotations(border);
                    annotationManager.drawAnnotationsFromList(border);
                }
                annot.NoResize = true;
                if (!isNextHighlight) {
                    const rectTopLeft = rect.getTopLeft();
                    const number = allAnnotations.filter(
                        (a) => AnnotationAttributeType.get(a) === 'highlightLeftTopInformation' && getParentHighlightId(a) === originalHighlightId
                    )[0];
                    const newX = rectTopLeft.x - 24;
                    const newY = rectTopLeft.y - 16;
                    const xTranslation = newX - number.X;
                    const yTranslation = newY - number.Y;
                    number.X = newX;
                    number.Y = newY;

                    const additionalHtmlElements = allAnnotations.filter(
                        (a) => AnnotationAttributeType.get(a) === 'additionalHtmlElement' && getParentHighlightId(a) === originalHighlightId
                    );
                    additionalHtmlElements.forEach((a) => {
                        a.X = a.X + xTranslation;
                        a.Y = a.Y + yTranslation;
                    });
                }
            }
            setNewAnnotationId(annot.Id);
            AnnotationAttributeIsNewHighlight.set(annot, 'true');
            AnnotationAttributeType.set(annot, type);
            multiSelectProp.setNext(false);
        }
    };

    const textHightlightTool = documentViewer.getTool(webViewerInstance.Core.Tools.ToolNames.HIGHLIGHT);
    textHightlightTool.addEventListener('annotationAdded', (annot: Core.Annotations.TextHighlightAnnotation) => onAnnotationAdded(annot, 'textAnnotation'));

    const boxHightlightTool = documentViewer.getTool(webViewerInstance.Core.Tools.ToolNames.RECTANGLE);
    boxHightlightTool.addEventListener('annotationAdded', (annot: Core.Annotations.RectangleAnnotation) => onAnnotationAdded(annot, 'boxAnnotation'));
};

const RegisterAnnotationsJumping = (webViewerInstance: WebViewerInstance, selectedHighlightChangedEventName: string) => {
    const annotationManager = webViewerInstance.Core.annotationManager;
    const jumpToHighlight = ((e: CustomEvent<{ id: string }>) => {
        const annot = annotationManager.getAnnotationsList().filter((a) => getHighlightId(a) === e.detail.id && isFirstAnnotationOfHighlight(a))[0];
        if (annot) annotationManager.jumpToAnnotation(annot);
    }) as EventListener;
    document.addEventListener(selectedHighlightChangedEventName, jumpToHighlight);
};

const RegisterHtmlAnnotationsResizing = (annotationManager: Core.AnnotationManager, zoom: number) => {
    const annots = annotationManager
        .getAnnotationsList()
        .filter((a) => AnnotationAttributeIsHtmlAnnotation.get(a) === 'true') as Array<Core.Annotations.HTMLAnnotation>;
    const zoomRounded = getZoomRounded(zoom);
    if (annots.length > 0) {
        annots.forEach((annot) => {
            if (annot.innerElement) {
                const component = annot.innerElement.children[0] as HTMLElement;
                if (component) {
                    component.style.transform = `scale(${zoomRounded})`;
                }
            }
        });
    }
};

const RegisterHtmlElementsResizing = (webViewerInstance: WebViewerInstance) => HtmlElementsResizing(webViewerInstance);

const AddZoomUpdatedEventListener = (
    webViewerInstance: WebViewerInstance,
    zoomRef: React.MutableRefObject<number | undefined>,
    setZoom: (value: number) => void,
    keepSelectedHighlightInCentreRef: React.MutableRefObject<boolean | undefined>
) => {
    const { documentViewer, annotationManager } = webViewerInstance.Core;
    documentViewer.addEventListener('zoomUpdated', (zoom: number) => {
        RegisterHtmlAnnotationsResizing(annotationManager, zoom);
        RegisterHtmlElementsResizing(webViewerInstance);
        RegisterScrollJumping(documentViewer, zoomRef, setZoom, zoom, keepSelectedHighlightInCentreRef);
        RegisterKeepingSelectedHighlightInCentreRef(annotationManager, keepSelectedHighlightInCentreRef);
    });
};

const AddScrollEventListener = (webViewerInstance: WebViewerInstance) => {
    webViewerInstance.UI.iframeWindow.document.getElementsByClassName('DocumentContainer')[0].addEventListener('scroll', () => {
        RegisterHtmlElementsResizing(webViewerInstance);
    });
};

const RegisterAnnotationSelectedListener = (
    webViewerInstance: WebViewerInstance,
    selectedHighlightIdRef: React.MutableRefObject<string | null>,
    setSelectedHighlightId: (id: string | null) => void,
    clearSelectedHighlight: () => void,
    newAnnotationIdRef: React.MutableRefObject<string | null>,
    setNewAnnotationId: (id: string | null) => void,
    multiSelectProp: MultiSelectProp,
    multiSelectNextRef: React.MutableRefObject<boolean | null>,
    annotationModifyingPropStateRef: React.MutableRefObject<AnnotationModifyingState>,
    disactiveAnnotationModifying: () => void,
    setViewerAnnotationsUpdated: () => void
) => {
    const annotationManager = webViewerInstance.Core.annotationManager;
    annotationManager.addEventListener('annotationSelected', (_annotations: Array<Core.Annotations.Annotation>, _action: string) => {
        Log('EVENT', ['RegisterAnnotationSelectedListener', 'annotationSelected'], [_action, _annotations]);
        const oneOrNoneAnnotationIsSelected = CheckOneOrNoneAnnotationIsSelected(
            annotationManager,
            newAnnotationIdRef,
            setNewAnnotationId,
            multiSelectProp,
            multiSelectNextRef,
            annotationModifyingPropStateRef
        );

        if (oneOrNoneAnnotationIsSelected) {
            const isLastAnnotationOfHighlightIsSelected = CheckLastAnnotationOfHighlightIsSelected(annotationManager);

            if (isLastAnnotationOfHighlightIsSelected)
                CheckApplicationSelectedHighlight(
                    annotationManager,
                    selectedHighlightIdRef,
                    setSelectedHighlightId,
                    clearSelectedHighlight,
                    multiSelectNextRef,
                    annotationModifyingPropStateRef,
                    disactiveAnnotationModifying
                );
        }

        setViewerAnnotationsUpdated();
    });
};

const CheckOneOrNoneAnnotationIsSelected = (
    annotationManager: Core.AnnotationManager,
    newAnnotationIdRef: React.MutableRefObject<string | null>,
    setNewAnnotationId: (id: string | null) => void,
    multiSelectProp: MultiSelectProp,
    multiSelectNextRef: React.MutableRefObject<boolean | null>,
    annotationModifyingPropStateRef: React.MutableRefObject<AnnotationModifyingState>
) => {
    Log('EVENT', ['RegisterAnnotationSelectedListener', 'annotationSelected', 'CheckOneOrNoneAnnotationIsSelected'], []);
    const newAnnotationId = newAnnotationIdRef.current;
    const selectedAnnotations = annotationManager.getSelectedAnnotations();
    if (selectedAnnotations.length > 0) {
        if (selectedAnnotations.length === 1) {
            const selectedAnnotation = selectedAnnotations[0];
            if (AnnotationAttributeType.get(selectedAnnotation) === 'highlightLeftTopInformationBackground') {
                const highlightId = getParentHighlightId(selectedAnnotation);
                const annot = annotationManager.getAnnotationsList().filter((a) => getHighlightId(a) === highlightId && isLastAnnotationOfHighlight(a))[0];
                annotationManager.deselectAllAnnotations();
                setTimeout(() => {
                    // For Highlight Toolbar -> in another way it will be display under first annotation before deselect
                    annotationManager.selectAnnotation(annot);
                }, 10);

                return false;
            }

            if (!isStandardAnnotation(selectedAnnotation) && selectedAnnotation.Id !== newAnnotationId) {
                annotationManager.deselectAllAnnotations();
            } else return true;
        } else {
            const annot = selectedAnnotations.filter((a) => isStandardAnnotation(a) && isLastAnnotationOfHighlight(a)).reverse()[0]; // reverse for get first from top annotations
            if (annot) {
                const index = selectedAnnotations.indexOf(annot);
                const annotationsToDeselect = [...selectedAnnotations.slice(0, index), ...selectedAnnotations.slice(index + 1)];
                annotationManager.deselectAnnotations(annotationsToDeselect);

                setTimeout(() => {
                    // For Highlight Toolbar -> in another way it will be display under first annotation before deselect
                    annotationManager.selectAnnotation(annot);
                }, 10);
            } else {
                annotationManager.deselectAllAnnotations();
            }
        }
    } else {
        // if no annotations are selected then remove annotation prototype
        if (newAnnotationId && !multiSelectNextRef.current) {
            multiSelectProp.setActive(false);
            if (!annotationModifyingPropStateRef.current.active) setNewAnnotationId(null);
        }
        return true;
    }
    return false;
};

const CheckApplicationSelectedHighlight = (
    annotationManager: Core.AnnotationManager,
    selectedHighlightIdRef: React.MutableRefObject<string | null>,
    setSelectedHighlightId: (id: string | null) => void,
    clearSelectedHighlight: () => void,
    multiSelectNextRef: React.MutableRefObject<boolean | null>,
    annotationModifyingPropStateRef: React.MutableRefObject<AnnotationModifyingState>,
    disactiveAnnotationModifying: () => void
) => {
    Log('EVENT', ['RegisterAnnotationSelectedListener', 'annotationSelected', 'CheckApplicationSelectedHighlight'], []);
    const allSelectedAnnots = annotationManager.getSelectedAnnotations();
    const annots = allSelectedAnnots.filter((a) => isStandardAnnotation(a) && !isNewHighlight(a) && !isModifiedHighlight(a));
    const modifiedAnnots = allSelectedAnnots.filter((a) => isModifiedHighlight(a));
    if (modifiedAnnots.length > 0 && !multiSelectNextRef.current) disactiveAnnotationModifying();

    // Set Selected Highligh only if we have one or none annotations selected
    if (annots.length <= 1) {
        const selectedHighlightId = selectedHighlightIdRef.current;
        const annot = annots[0] || null;
        if (annot) {
            const highlightId = getHighlightId(annot);
            if (highlightId !== selectedHighlightId) {
                setSelectedHighlightId(getHighlightId(annot));
            }
            if (annotationModifyingPropStateRef.current.active) disactiveAnnotationModifying();
        } else if (selectedHighlightId !== null && !annotationModifyingPropStateRef.current.active) clearSelectedHighlight();
    }
};

const CheckLastAnnotationOfHighlightIsSelected = (annotationManager: Core.AnnotationManager) => {
    Log('EVENT', ['RegisterAnnotationSelectedListener', 'annotationSelected', 'CheckLastAnnotationOfHighlightIsSelected'], []);
    const annots = annotationManager.getSelectedAnnotations().filter((a) => isStandardAnnotation(a) && !isNewHighlight(a));
    if (annots.length === 1) {
        const annot = annots[0];
        const highlightId = getHighlightId(annot);
        if (!isLastAnnotationOfHighlight(annot)) {
            const lastAnnotById = annotationManager.getAnnotationsList().filter((a) => getHighlightId(a) === highlightId && isLastAnnotationOfHighlight(a))[0];
            annotationManager.selectAnnotation(lastAnnotById);
            return false;
        } else return true;
    } else if (annots.length === 0) return true;
    else return false;
};

export default useInit;
