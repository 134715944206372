import ProjectWorkspaceModule, { ProjectWorkspaceModuleProps } from './ProjectWorkspace.Module';
import ProjectVersionWrapper from '../Common/ProjectVersion.Wrapper';

export type ProjectWorkspaceVersionWrapperProps = Omit<ProjectWorkspaceModuleProps, 'projectWrapperProps'>;

function ProjectWorkspaceVersionWrapper(props: ProjectWorkspaceVersionWrapperProps) {
    return (
        <ProjectVersionWrapper
            projectsApiClient={props.projectsApiClient}
            renderModule={(projectWrapperProps) => <ProjectWorkspaceModule projectWrapperProps={projectWrapperProps} {...props} />}
        />
    );
}

export default ProjectWorkspaceVersionWrapper;
