import { useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Icon } from 'UI';
import { AnnotationStatus } from 'ApiClients/SterlingApiClients/Types';
import HighlightButtonComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightButton/HighlightButton.Component';
import HighlightToolbarComponent, { Util } from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightToolbar/HighlightToolbar.Component';

export type EditPanelComponentProps = {
    openDeleteModal: () => void;
    openAssignmentModal: () => void;
    openTagAssignmentModal: () => void;
    openNeedsReviewModal: () => void;
    supportingInformationPropositionsOpen: boolean;
    verifyAnnotation: () => void;
    openAssertionModal: () => void;
    isEditor: boolean;
    isVerificationDocumentProcessed: boolean;
    showHideAnnotationElements: (show: boolean) => void;
    isAnnotateStatus: boolean;
    openSuppInfoSummaryModal: () => void;
    acceptAnnotationStage: () => void;
    nextAnnotationStatus: AnnotationStatus | null;
    needsReview: boolean;
};

function EditPanelComponent(props: EditPanelComponentProps) {
    const {
        openDeleteModal,
        openAssignmentModal,
        openTagAssignmentModal,
        openAssertionModal,
        openNeedsReviewModal,
        supportingInformationPropositionsOpen,
        verifyAnnotation,
        isEditor,
        isVerificationDocumentProcessed,
        showHideAnnotationElements,
        isAnnotateStatus,
        openSuppInfoSummaryModal,
        acceptAnnotationStage,
        nextAnnotationStatus,
        needsReview,
    } = props;

    const [expanded, setExpanded] = useState<boolean>(true);
    const showHideAnnotationElementsHandle = () => {
        showHideAnnotationElements(!expanded);
        setExpanded(!expanded);
    };

    let utils: Array<Util> = [];

    if (isEditor || !isAnnotateStatus) {
        utils.push({ icon: Icon.FileEarmarkBinary, onClick: showHideAnnotationElementsHandle, tooltip: expanded ? 'Hide number' : 'Show number' });

        if (isEditor) {
            utils.push({ icon: Icon.PersonsAdd, onClick: () => openAssignmentModal(), tooltip: 'Assign Member' });
        }

        utils.push({ icon: Icon.Quote, onClick: () => openAssertionModal(), tooltip: 'Add Assertion' });

        if (isEditor) {
            utils.push({ icon: Icon.Tag, onClick: () => openTagAssignmentModal(), tooltip: 'Add Tag' });
        }

        utils.push({ icon: Icon.Link45deg, onClick: () => openSuppInfoSummaryModal(), tooltip: 'Linked Information' });

        if (isEditor) {
            utils.push({
                icon: Icon.FlagFill,
                onClick: () => openNeedsReviewModal(),
                tooltip: 'Needs Review',
                utilIconBoxSx: { color: (theme) => theme.palette.attention.high },
                disabled: needsReview,
            });
            utils.push({ icon: Icon.TrashBin, onClick: () => openDeleteModal(), tooltip: 'Delete Annotation' });
        }
    }

    let nextStatusTooltip: string = '';
    if (nextAnnotationStatus) {
        switch (nextAnnotationStatus) {
            case AnnotationStatus.Verify:
                nextStatusTooltip = 'Approve annotation';
                break;
            case AnnotationStatus.Approve:
                nextStatusTooltip = 'Submit for approval';
                break;
            case AnnotationStatus.Verified:
                nextStatusTooltip = 'Approve verification';
                break;
            default:
                break;
        }
    }

    const rootRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={rootRef} style={{ display: 'flex' }}>
            {!supportingInformationPropositionsOpen && !isAnnotateStatus && (
                <HighlightButtonComponent
                    rootRef={rootRef}
                    content='FIND SUPPORTING INFORMATION'
                    {...(!isVerificationDocumentProcessed && { tooltip: 'Indexing is currently been done' })}
                    disabled={!isVerificationDocumentProcessed}
                    onClick={() => verifyAnnotation()}
                />
            )}
            <Box sx={{ width: '0.25rem' }} />
            {utils.length > 0 && <HighlightToolbarComponent utils={utils} />}
            {nextAnnotationStatus && (
                <>
                    <Box sx={{ width: '0.25rem' }} />
                    <HighlightToolbarComponent
                        utils={[
                            {
                                icon: Icon.Approve,
                                onClick: () => acceptAnnotationStage(),
                                tooltip: nextStatusTooltip,
                                utilIconBoxSx: { color: (theme) => theme.palette.success.main },
                                disabled: needsReview,
                            },
                        ]}
                    />
                </>
            )}
        </div>
    );
}

export default EditPanelComponent;
