import { BoundingBoxSection, FileInfo } from 'ApiClients/SterlingApiClients/Types';
import { HighlightLeftTopInformation } from './HighlightLeftTopInformation/HighlightLeftTopInformation.Component';
import { InitAnnotationModifyingProp } from './PdfViewerWithToolbar.Component';
import { RgbaColor } from 'UI/functions/hexToRgba';
import { TextSelectionColor } from './CustomWebViewerInstance';

export type DocumentProps = {
    documentInfo: DocumentInfo;
    headings?: Array<Heading>;
    fileSource: FileSource;
    getDocument?: (docId: string, docName: string) => Promise<FileInfo | null>;
    docFileInfo?: FileInfo | null;
    onDocumentLoad?: () => void;
    onFinishedRendering?: (documentInfo: DocumentInfo) => void;
};

export type DocumentInfo = {
    id: string;
    name: string;
    fileId: string;
};

export type FileSource = 'getDocument' | 'docFile';

export type HighlightsProps<THighlightCustomData> = {
    highlights: Array<Highlight<THighlightCustomData>>;
    selectedHighlightId: string | null;
    setSelectedHighlightId: (id: string | null) => void;
    selectedHighlightChangedEventName?: string;
    highlightLeftTopInformation: HighlightLeftTopInformation<THighlightCustomData>;
    highlightToolbars: HighlightToolbars;
    clearSelectedHighlight: () => void;
    initialChosenHighlight?: InitialChosenHighlight;
    initModifyAnnotation?: InitAnnotationModifyingProp;
    additionalHtmlElements?: Array<AdditionalHtmlElement<THighlightCustomData>>;
    focusOnSelectedHighlightAfterDraw?: boolean;
    keepSelectedHighlightInCentre?: boolean;
};

export type HighlightToolbars = {
    newHighlightToolbar: HighlightToolbar<NewHighlightToolbarOptions>;
    highlightToolbar: HighlightToolbar<HighlightToolbarOptions>;
    modifiedHighlightToolbar?: HighlightToolbar<ModifiedHighlightToolbarOptions>;
    deps?: React.DependencyList;
};

export type HighlightToolbar<TOptions> = {
    render: (options: TOptions) => JSX.Element;
};

type BaseHighlightToolbarOptions = {
    setHighlightToolbarRef: (ref: React.RefObject<HTMLDivElement>) => void;
};

export type NewHighlightToolbarOptions = BaseHighlightToolbarOptions & {
    getNewHighlight: () => Promise<NewHighlight>;
    clearSelection: () => void;
};

export type HighlightToolbarOptions = BaseHighlightToolbarOptions & {
    getSelectedHighlightId: () => string;
    modifyHighlight: () => void;
    showHideAnnotationElements: (id: string, show: boolean) => void;
};

export type ModifiedHighlightToolbarOptions = BaseHighlightToolbarOptions & {
    getModifiedHighlight: () => Promise<ModifiedHighlight>;
    clearSelection: () => void;
    modifyHighlight: () => void;
    showHideAnnotationElements: (id: string, show: boolean) => void;
};

export type Highlight<TCustomData = {}> = TCustomData & {
    id: string;
    orderNumber: number;
    contentType: ContentType;
    boundingBoxSections: Array<BoundingBoxSection>;
    color?: HighlightColor;
    borderColor?: HighlightColor;
    siblingsId?: string;
};

export type HighlightColor = RgbaColor;

type HighlightColorsType = {
    default: HighlightColor;
    selected: HighlightColor;
    simpleText: HighlightColor;
};

export const HighlightColors: HighlightColorsType = {
    default: { R: 255, G: 184, B: 0, A: 0.2 },
    selected: { R: 255, G: 184, B: 0, A: 0.5 },
    simpleText: TextSelectionColor,
};

export type ContentType = 'text' | 'shape';

export type NewHighlight = {
    contentType: ContentType;
    boundingBoxSections: Array<BoundingBoxSection>;
    statement: string;
};

export type ModifiedHighlight = {
    originalHighlightId: string;
    contentType: ContentType;
    boundingBoxSections: Array<BoundingBoxSection>;
    statement: string;
};

export type InitialChosenHighlight = {
    id: string | null;
    callback: () => void;
};

export type AdditionalHtmlElementOptions<THighlightCustomData> = {
    highlight: Highlight<THighlightCustomData>;
    selectedHighlightId: string | null;
};

export type AdditionalHtmlElement<THighlightCustomData> = {
    display: (options: AdditionalHtmlElementOptions<THighlightCustomData>) => boolean;
    info: (options: AdditionalHtmlElementOptions<THighlightCustomData>) => AdditionalHtmlElementInfo;
    component: (options: AdditionalHtmlElementOptions<THighlightCustomData>) => JSX.Element;
};

export type AdditionalHtmlElementInfo = {
    pageNumber: number;
    x: number;
    y: number;
    height: number;
    width: number;
    position?: 'top left' | 'top right' | 'bottom left' | 'bottom right';
};

export type Heading = {
    id: string;
    title: string;
    boundingBoxSections: Array<BoundingBoxSection>;
};

export type HighlightNavigationProps = {
    disabled: boolean;
    previousHighlight: () => void;
    nextHighlight: () => void;
};
