import { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Box, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Theme, useTheme } from '@mui/material';
import { ChevronDown, ChevronUp, FileTypePdf, Folder } from 'UI/Icon';
import { palette } from 'UI/Provider/VerifiTheme';
import { Icon, Tooltip, Typography, VerifiSpinner } from 'UI';
import { DocumentInfo, ProjectDocumentProcessingStatus } from 'ApiClients/SterlingApiClients/Types';
import { DocumentsContainerExtended } from './Types';

export type DocumentsListComponentProps = {
    documents: DocumentsContainerExtended | null;
    selectedDocumentId: string | null;
    swichDocument: (id: string) => void;
};

const getListItemStyle = (isParentOpened: boolean, level: number, theme: Theme) => ({
    pl: level * 4 + 3,

    bgcolor: isParentOpened ? theme.palette.blue.light : theme.palette.white.main,
    '&:hover': {
        backgroundColor: theme.palette.white.main,
    },
    '&.Mui-selected, &.Mui-selected:hover': {
        bgcolor: theme.palette.body.main,
        '&::before': {
            content: '""',
            position: 'absolute',
            left: 10,
            top: '50%',
            width: '6px',
            height: '6px',
            bgcolor: theme.palette.stroke.main,
            borderRadius: '50%',
            transform: 'translateY(-50%)',
        },
    },
});

function DocumentsListComponent(props: DocumentsListComponentProps) {
    const theme = useTheme();

    const { documents, selectedDocumentId, swichDocument } = props;
    const [directories, setDirectories] = useState<DocumentsContainerExtended | null>(documents);

    const copyIsOpened = useCallback((newDirectories: DocumentsContainerExtended, directoriesOld: DocumentsContainerExtended) => {
        newDirectories.isOpened = directoriesOld.isOpened;
        newDirectories.directories.forEach((newD) => {
            const oldD = directoriesOld.directories.find((d) => d.path === newD.path);
            if (oldD) copyIsOpened(newD, oldD);
        });
    }, []);

    const isFirstRun = useRef(true);
    useEffect(() => {
        if (isFirstRun.current) isFirstRun.current = false;
        else if (documents && directories) {
            const newDirectories = { ...documents };
            copyIsOpened(newDirectories, directories);
            setDirectories(newDirectories);
        }
        // eslint-disable-next-line
    }, [documents, setDirectories, copyIsOpened]);

    const closeOtherDirectories = (mainDirectory: DocumentsContainerExtended, openedPath: string) => {
        mainDirectory.directories.forEach((directory) => {
            if (!openedPath.startsWith(directory.path)) directory.isOpened = false;

            closeOtherDirectories(directory, openedPath);
        });
    };

    const openOrCloseDirectory = (directory: DocumentsContainerExtended, level: number) => {
        let copy = _.cloneDeep(directories);

        const findDirectory = (
            name: string,
            parentDirectory: DocumentsContainerExtended,
            currentLevel: number,
            level: number
        ): DocumentsContainerExtended | null => {
            const dir = parentDirectory?.directories.find((item) => item.name === name);

            if (dir && level === currentLevel) return dir;

            for (let i = 0; i < parentDirectory.directories.length; i++) {
                const dir = findDirectory(name, parentDirectory.directories[i], currentLevel + 1, level);

                if (dir) return dir;
            }

            return null;
        };

        const foundDirectory = findDirectory(directory.name, copy!, 0, level)!;

        foundDirectory.isOpened = !foundDirectory.isOpened;

        if (foundDirectory.isOpened) {
            closeOtherDirectories(copy!, foundDirectory.path);
        }

        setDirectories(copy);
    };

    const renderDirectories = (directories: DocumentsContainerExtended[], level: number, isParentOpened: boolean) => {
        return directories.map((directory: DocumentsContainerExtended, index: number) => {
            const isOpened = directory.isOpened || isParentOpened;
            const hasItems = directory.directories.length > 0 || directory.documents.length > 0;
            return (
                <Box key={'dir-' + level + '-' + index} data-testid={'directory-' + directory.name}>
                    <ListItem
                        sx={(theme) => getListItemStyle(isOpened, level, theme)}
                        onClick={() => openOrCloseDirectory(directory, level)}
                        secondaryAction={hasItems && (directory.isOpened ? <ChevronDown color={palette.text.main} /> : <ChevronUp color={palette.text.main} />)}
                    >
                        <ListItemIcon sx={{ minWidth: 'auto', mr: 0.5 }}>
                            <Folder color={palette.text.main} />
                        </ListItemIcon>
                        <ListItemText sx={{ paddingLeft: '1rem' }}>
                            <Typography variant='title' sx={{ fontSize: 12 }}>
                                {directory.name}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <Collapse in={directory.isOpened} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                            {renderDirectory(directory, level + 1, isOpened)}
                        </List>
                    </Collapse>
                </Box>
            );
        });
    };

    const renderDirectory = (directory: DocumentsContainerExtended, level: number, isParentOpened: boolean): JSX.Element[] => {
        const directories = renderDirectories(directory.directories, level, isParentOpened);

        const files = directory.documents
            .sort((a: DocumentInfo, b: DocumentInfo) => (a.name > b.name ? 1 : -1))
            .map((doc: DocumentInfo, idx: number) => {
                const name: string = doc.originalName || doc.name;
                const docName: string = name.length > 40 ? `${name.substring(0, 40)}...` : name;

                let tooltip: string | null = null;
                switch (doc.processingStatus) {
                    case ProjectDocumentProcessingStatus.ToBeProcessed:
                        tooltip = 'Supporting document is waiting in the queue for processing.';
                        break;
                    case ProjectDocumentProcessingStatus.Processing:
                        tooltip = 'Processing supporting document...';
                        break;
                    case ProjectDocumentProcessingStatus.Processed:
                    case ProjectDocumentProcessingStatus.Failed:
                        tooltip = name;
                        break;
                }

                return (
                    <ListItemButton
                        key={'file-' + level + '-' + idx}
                        data-testid={'file-' + doc.name}
                        sx={(theme) => getListItemStyle(directory.isOpened, level, theme)}
                        onClick={() => {
                            swichDocument(doc.id);
                            closeOtherDirectories(directory, directory.path);
                        }}
                        selected={doc.id === selectedDocumentId}
                    >
                        <ListItemIcon sx={{ minWidth: 'auto', mr: 0.5 }}>
                            <FileTypePdf height={16} width={16} />
                        </ListItemIcon>
                        <ListItem
                            sx={{
                                paddingLeft: '1rem',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                                overflowWrap: 'break-word',
                            }}
                        >
                            <Typography
                                noWrap
                                sx={{
                                    color: (theme) => theme.palette.blue.dark,
                                    fontSize: 12,
                                    ...(doc.processingStatus !== ProjectDocumentProcessingStatus.Processed && {
                                        color: (theme) => theme.palette.text.disabled,
                                    }),
                                }}
                            >
                                <Tooltip title={tooltip}>
                                    <span>{docName}</span>
                                </Tooltip>
                            </Typography>
                            {doc.processingStatus === ProjectDocumentProcessingStatus.Failed && (
                                <Box sx={{ color: (theme) => theme.palette.attention.medium, marginLeft: '0.5rem', display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title={'Processing supporting document failed.'} tooltipsx={{ color: theme.palette.attention.medium }}>
                                        <Icon.ExclamationCircle />
                                    </Tooltip>
                                </Box>
                            )}
                            {doc.processingStatus === ProjectDocumentProcessingStatus.Processing && (
                                <Box sx={{ marginLeft: '0.5rem', height: '1rem' }}>
                                    <VerifiSpinner height='16' width='16' />
                                </Box>
                            )}
                        </ListItem>
                    </ListItemButton>
                );
            });

        const combinedElements = [...directories, ...files].reduce<JSX.Element[]>((acc, element, index, array) => {
            acc.push(element);
            if (index < array.length - 1) {
                acc.push(
                    <Divider
                        key={`divider-${index}`}
                        sx={{
                            borderColor: (theme) => theme.palette.stroke.main,
                        }}
                    />
                );
            }
            return acc;
        }, []);

        return combinedElements;
    };

    return (
        <List
            sx={{
                height: 'calc(100% - 5rem - 1px)', // button panel height + border
                overflowY: 'auto',
            }}
            disablePadding
        >
            {directories && renderDirectory(directories, 0, false)}
        </List>
    );
}

export default DocumentsListComponent;
