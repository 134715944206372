import { NodeRoutePath, RouteParams } from "App/RoutesPaths/RoutePath";
import Workspace from "./Routes/Workspace";
import Dashboard from "./Routes/Dashboard";

type ProjectInstanceParams<TParentParams> = TParentParams & { versionId: string };

export default class ProjectVersionInstance<TParentParams extends RouteParams> extends NodeRoutePath<TParentParams, ProjectInstanceParams<TParentParams>> {
    public dashboard = new Dashboard(this.specificPath.bind(this));
    public workspace = new Workspace(this.specificPath.bind(this));

    constructor(parentSpecificPath: (params: TParentParams) => string) {
        super('version/:versionId', parentSpecificPath);
    }
}

export const ActiveProjectVersionId = 'active'