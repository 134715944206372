import * as signalR from '@microsoft/signalr';
import AuthApiClient from 'ApiClients/Auth.ApiClient';
const NotificationsHubUrl = process.env.REACT_APP_INTERNAL_NOTIFICATIONS_URL!;

export default class NotificationsConnector {
    _authApiClient: AuthApiClient;
    public connection: signalR.HubConnection | null;
    public onReconnect: () => void;

    constructor(authApiClient: AuthApiClient, isAuthenticated: boolean) {
        this._authApiClient = authApiClient;
        this.connection = null;
        this.onReconnect = () => {};

        if (isAuthenticated) {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(NotificationsHubUrl, {
                    accessTokenFactory: async () => {
                        return authApiClient.getToken();
                    },
                    withCredentials: true,
                })
                .withAutomaticReconnect()
                .build();

            this.connection.start();

            this.connection.onreconnected((_) => {
                if (this.onReconnect) this.onReconnect();
            });
        }
    }

    public isConnected = (): boolean => {
        return this.connection?.state === signalR.HubConnectionState.Connected;
    };
}
