import { Box } from '@mui/material';
import { Annotation, Keyword } from 'ApiClients/SterlingApiClients/Types';
import { Button, Chip, Icon, Tooltip, Typography } from 'UI';

type HeadComponentProps = {
    isFetching: boolean;
    selectedAnnotation: Annotation;
    selectPreviousAnnotation: () => void;
    selectNextAnnotation: () => void;
    suppInfoPropLength: number;
    countSuppInfoPropLengthToDisplay: number;
    openKeywordsModal: () => void;
    keywords: Array<Keyword>;
};

export const HeadHeight = '5.5rem';

function HeadComponent(props: HeadComponentProps) {
    const {
        isFetching,
        selectedAnnotation,
        selectPreviousAnnotation,
        selectNextAnnotation,
        suppInfoPropLength,
        countSuppInfoPropLengthToDisplay,
        openKeywordsModal,
        keywords,
    } = props;

    return (
        <Box sx={{ display: 'flex', paddingLeft: '1rem', paddingRight: '1rem', height: HeadHeight }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        minWidth: '1.5rem',
                        height: '1.5rem',
                        fontWeight: 700,
                        fontSize: '0.75rem',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyContent: 'center',
                        borderRadius: '0.25rem',
                        paddingLeft: '0.25rem',
                        paddingRight: '0.25rem',
                        backgroundColor: (theme) => theme.palette.blue.light,
                        border: (theme) => `0.125rem solid ${theme.palette.blue.dark}`,
                        marginRight: '0.5rem',
                    }}
                >
                    {selectedAnnotation.orderNumber}
                </Box>
                <Typography>Annotation</Typography>
            </Box>
            <Box sx={{ display: 'flex', marginRight: '0rem', marginLeft: 'auto', alignItems: 'center' }}>
                {!isFetching && (
                    <Box sx={{ width: '27rem' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button color='secondary' sx={{ height: '2rem', marginRight: '1.5rem' }} onClick={openKeywordsModal}>
                                <Icon.Search />
                                <Typography sx={{ marginLeft: '0.5rem', fontSize: '0.875rem' }}>KEYWORD SEARCH</Typography>
                            </Button>
                            <Typography sx={{ fontSize: '0.875rem' }}>
                                Number of results: {Math.min(suppInfoPropLength, countSuppInfoPropLengthToDisplay)} of {suppInfoPropLength}{' '}
                            </Typography>
                        </Box>
                        {keywords.length > 0 && (
                            <Box sx={{ display: 'flex', marginTop: '0.5rem' }}>
                                <Typography sx={{ marginRight: '0.5rem', fontSize: '0.75rem' }}>Keywords:</Typography>
                                {[...keywords].splice(0, 2).map((keyword) => (
                                    <Chip
                                        key={`keyword-chip-${keyword.id}`}
                                        color='info'
                                        label={keyword.content.length > 20 ? `${keyword.content.substring(0, 20)}...` : keyword.content}
                                        size='small'
                                        sx={{ marginRight: '0.5rem', minWidth: 'unset' }}
                                    />
                                ))}
                                {keywords.length > 2 && (
                                    <Typography sx={{ fontSize: '0.75rem', fontWeight: 600, color: (theme) => theme.palette.info.main, marginRight: '0.5rem' }}>
                                        +{keywords.length - 2} more
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </Box>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Tooltip title='Previous Annotation'>
                        <Box sx={{ marginLeft: '2rem', cursor: 'pointer' }} onClick={() => selectPreviousAnnotation()}>
                            <Icon.ArrowLeftCircle height={24} width={24} />
                        </Box>
                    </Tooltip>
                    <Tooltip title='Next Annotation'>
                        <Box sx={{ marginLeft: '1rem', cursor: 'pointer' }} onClick={() => selectNextAnnotation()}>
                            <Icon.ArrowRightCircle height={24} width={24} />
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
}

export default HeadComponent;
