import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useState } from 'react';
import useMultiSelect from './Annotations/useMultiSelect';
import useInit from './Annotations/useInit';
import { AdditionalHtmlElement, Highlight, InitialChosenHighlight } from '../PdfViewerWithToolbar.Types';
import { HighlightLeftTopInformation } from '../HighlightLeftTopInformation/HighlightLeftTopInformation.Component';
import useOnEndCreatingAnnotation from './Annotations/useOnEndCreatingAnnotation';
import useOnAppAnnotationChanged from './Annotations/useOnAppAnnotationChanged';
import useAnnotationModifying from './Annotations/useAnnotationModifying';
import { AvailableToolMode, InitAnnotationModifyingProp } from '../PdfViewerWithToolbar.Component';
import { EmotionCache } from '@emotion/react';
import useDrawing from './Annotations/useDrawing';

const useAnnotations = <THighlightCustomData,>(
    webViewerInstance: WebViewerInstance | null,
    documentLoading: boolean,
    highlights: Array<Highlight<THighlightCustomData>>,
    selectedHighlightId: string | null,
    setSelectedHighlightId: (id: string | null) => void,
    highlightLeftTopInformation: HighlightLeftTopInformation<THighlightCustomData>,
    clearSelectedHighlight: () => void,
    setToolMode: (viewerMode: Core.Tools.ToolNames, mode: AvailableToolMode) => void,
    emotionCache: EmotionCache,
    selectedHighlightChangedEventName?: string,
    initialChosenHighlight?: InitialChosenHighlight,
    initModifyAnnotation?: InitAnnotationModifyingProp,
    additionalHtmlElements?: Array<AdditionalHtmlElement<THighlightCustomData>>,
    focusOnSelectedHighlightAfterDraw?: boolean,
    keepSelectedHighlightInCentre?: boolean
) => {
    const [newAnnotationId, setNewAnnotationId] = useState<string | null>(null);

    const { multiSelectProp } = useMultiSelect();
    const annotationModifyingProp = useAnnotationModifying(webViewerInstance, setToolMode, selectedHighlightId, multiSelectProp, initModifyAnnotation);
    const { viewerAnnotationsUpdated } = useInit(
        webViewerInstance,
        selectedHighlightId,
        setSelectedHighlightId,
        clearSelectedHighlight,
        setNewAnnotationId,
        multiSelectProp,
        newAnnotationId,
        annotationModifyingProp,
        selectedHighlightChangedEventName,
        keepSelectedHighlightInCentre
    );
    useOnEndCreatingAnnotation(webViewerInstance, newAnnotationId, multiSelectProp);
    useDrawing(
        webViewerInstance,
        documentLoading,
        highlights,
        selectedHighlightId,
        highlightLeftTopInformation,
        emotionCache,
        initialChosenHighlight,
        additionalHtmlElements,
        focusOnSelectedHighlightAfterDraw
    );
    useOnAppAnnotationChanged(webViewerInstance, documentLoading, highlights, selectedHighlightId);

    return {
        newAnnotationId,
        multiSelectProp,
        annotationModifyingProp,
        viewerAnnotationsUpdated,
    };
};

export default useAnnotations;
