import { WebViewerInstance } from '@pdftron/webviewer';
import { useEffect } from 'react';
import { Highlight } from '../../PdfViewerWithToolbar.Types';
import { getHighlightId, isLastAnnotationOfHighlight } from './Drawing/ViewerAnnotations/Attributes';

const useOnAppAnnotationChanged = <THighlightCustomData,>(
    webViewerInstance: WebViewerInstance | null,
    documentLoading: boolean,
    highlights: Array<Highlight<THighlightCustomData>>,
    selectedHighlightId: string | null
) => {
    useEffect(() => {
        if (webViewerInstance && webViewerInstance.Core.documentViewer.getDocument() && !documentLoading) {
            const annotationManager = webViewerInstance.Core.annotationManager;
            const selectedAnnotation = annotationManager.getSelectedAnnotations()[0];
            if (selectedHighlightId && (!selectedAnnotation || (selectedAnnotation && getHighlightId(selectedAnnotation) !== selectedHighlightId))) {
                const annot = annotationManager
                    .getAnnotationsList()
                    .filter((a) => getHighlightId(a) === selectedHighlightId && isLastAnnotationOfHighlight(a))[0];
                if (annot) annotationManager.selectAnnotation(annot);
                else annotationManager.deselectAllAnnotations();
            } else if (!selectedHighlightId) {
                annotationManager.deselectAllAnnotations();
            }
        }
    }, [webViewerInstance, documentLoading, highlights, selectedHighlightId]);
};

export default useOnAppAnnotationChanged;
