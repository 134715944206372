import { useCallback, useEffect, useRef, useState } from 'react';
import { AnnotationTransferData } from '../useAnnotationsTransferState';
import { AnnotationTransferPropositionStatus } from 'ApiClients/SterlingApiClients/Types';

const useSelectingAnnotation = (annotationsTransferData: Array<AnnotationTransferData> | null, filteredData: Array<AnnotationTransferData> | null) => {
    const [selectedAnnotationIdActiveVersion, _setSelectedAnnotationIdActiveVersion] = useState<string | null>(null);
    const [selectedAnnotationIdDraftVersion, _setSelectedAnnotationIdDraftVersion] = useState<string | null>(null);

    const annotationsTransferDataRef = useRef(annotationsTransferData);
    annotationsTransferDataRef.current = annotationsTransferData;

    const setSelectedAnnotationIdBothVersions = useCallback(
        (id: string | null) => {
            _setSelectedAnnotationIdActiveVersion(id);
            const data = annotationsTransferDataRef.current;
            if (data) {
                const transfer = data.find((a) => a.annotationActive.id === id);
                if (transfer && transfer.status !== AnnotationTransferPropositionStatus.Removed) {
                    _setSelectedAnnotationIdDraftVersion(id);
                } else {
                    _setSelectedAnnotationIdDraftVersion(null);
                }
            }
        },
        [_setSelectedAnnotationIdActiveVersion, _setSelectedAnnotationIdDraftVersion]
    );

    const setSelectedAnnotationIdActiveVersion = useCallback(
        (id: string | null) => setSelectedAnnotationIdBothVersions(id),
        [setSelectedAnnotationIdBothVersions]
    );

    const setSelectedAnnotationIdDraftVersion = useCallback(
        (id: string | null) => {
            _setSelectedAnnotationIdDraftVersion(id);
            if (id) {
                setSelectedAnnotationIdActiveVersion(id);
            }
        },
        [_setSelectedAnnotationIdDraftVersion, setSelectedAnnotationIdActiveVersion]
    );

    const [initSelectedAnnotationId, setInitSelectedAnnotationId] = useState<string | null>(null);

    useEffect(() => {
        if (annotationsTransferData && annotationsTransferData.length > 0 && !initSelectedAnnotationId) {
            const annotation = annotationsTransferData[0].annotationActive;
            setInitSelectedAnnotationId(annotation.id);
        }
        // eslint-disable-next-line
    }, [annotationsTransferData]);

    useEffect(() => {
        if (selectedAnnotationIdActiveVersion && filteredData) {
            const transfer = filteredData.find((a) => a.annotationActive.id === selectedAnnotationIdActiveVersion);
            if (!transfer) {
                setSelectedAnnotationIdBothVersions(null);
            }
        }
    }, [filteredData, selectedAnnotationIdActiveVersion, setSelectedAnnotationIdBothVersions]);

    useEffect(() => {
        if (initSelectedAnnotationId) {
            setSelectedAnnotationIdBothVersions(initSelectedAnnotationId);
        }
    }, [initSelectedAnnotationId, setSelectedAnnotationIdBothVersions]);

    return {
        selectedAnnotationIdActiveVersion,
        setSelectedAnnotationIdActiveVersion,
        selectedAnnotationIdDraftVersion,
        setSelectedAnnotationIdDraftVersion,
        setSelectedAnnotationIdBothVersions,
    };
};

export default useSelectingAnnotation;
