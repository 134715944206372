import { Box, CircularProgress, Divider } from '@mui/material';
import { Annotation, DocumentInfo, SupportingInformationProposition } from 'ApiClients/SterlingApiClients/Types';
import useStateById from 'Hooks/useStateById';
import { HeadHeight } from '../Head/Head.Component';
import { Button, Icon, Typography } from 'UI';

type PropositionsListComponentProps = {
    isFetching: boolean;
    selectedAnnotation: Annotation;
    suppInfoProp: Array<SupportingInformationProposition>;
    documentsById: { [key: string]: DocumentInfo };
    showSupportingInformationPropositionOnDocument: (id: string, documentId: string) => void;
    linkPropositionToAnnotation: (annotationId: string, propositionId: string, setFetching: (isFetching: boolean) => void) => void;
    unlinkPropositionFromAnnotation: (annotationId: string, supportingInformationId: string, setFetching: (isFetching: boolean) => void) => void;
    rejectProposition: (annotationId: string, propositionId: string) => void;
    restoreProposition: (annotationId: string, propositionId: string, setFetching: (isFetching: boolean) => void) => void;
    countPropositionsToDisplay: number;
    increaseCountPropositionsToDisplay: (annotationId: string) => void;
};

const ActionButtonStyle = {
    height: '1.5rem',
    width: '10rem',
};

function PropositionsListComponent(props: PropositionsListComponentProps) {
    const {
        isFetching,
        selectedAnnotation,
        suppInfoProp,
        documentsById,
        showSupportingInformationPropositionOnDocument,
        linkPropositionToAnnotation,
        unlinkPropositionFromAnnotation,
        rejectProposition,
        restoreProposition,
        countPropositionsToDisplay,
        increaseCountPropositionsToDisplay,
    } = props;

    const [linkingInformationInProgressById, _setLinkingInformationInProgressById] = useStateById<boolean>();
    const setLinkingInformationInProgressById = _setLinkingInformationInProgressById.Value;

    return (
        <Box
            sx={{
                position: 'relative',
                height: `calc(100% - ${HeadHeight})`,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {isFetching && (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        textAlign: 'center',
                        zIndex: 20,
                        backgroundColor: '#dfdfdfcc',
                    }}
                >
                    <CircularProgress sx={{ marginTop: '4rem', position: 'fixed' }} />
                </Box>
            )}
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '61.5rem',
                    }}
                >
                    {[...suppInfoProp].slice(0, countPropositionsToDisplay).map((ver) => {
                        const document = documentsById[ver.documentId];
                        const documentName = document ? document.originalName || document.name : 'Document not found';
                        const isRejected = ver.isRejected;
                        const relevancyScore = Math.round(ver.relevancyScore * 100);
                        return (
                            <Box key={`Propositions-${ver.id}`}>
                                <Box sx={{ position: 'relative', height: '13.5rem', paddingTop: '1rem', display: 'flex' }}>
                                    {isRejected && (
                                        <Box
                                            sx={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: '#d9d9d999', zIndex: 5, top: '0rem' }}
                                        />
                                    )}
                                    <Box sx={{ display: 'flex', height: '1rem', alignItems: 'center', marginRight: '0.5rem' }}>
                                        <Typography sx={{ marginRight: '2rem' }}>#{ver.propositionNumber}</Typography>
                                        <Box sx={{ color: (theme) => theme.palette.success.main, height: '1rem', width: '1rem' }}>
                                            <Icon.Approve height={16} width={16} />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ display: 'flex', height: '1rem', alignItems: 'center' }}>
                                            <Typography sx={{ fontWeight: 700 }}>{documentName}</Typography>
                                            <Divider orientation='vertical' sx={{ height: '100%', marginLeft: '2rem', marginRight: '2rem' }} />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    color: (theme) => theme.palette.attention.high,
                                                    ...(relevancyScore > 60 && { color: (theme) => theme.palette.attention.medium }),
                                                    ...(relevancyScore > 80 && { color: (theme) => theme.palette.success.main }),
                                                    width: '20rem',
                                                }}
                                            >
                                                <Icon.Star />
                                                {relevancyScore > 60 && <Icon.Star />}
                                                {relevancyScore > 80 && <Icon.Star />}

                                                <Typography sx={{ marginLeft: '0.5rem', fontWeight: 600, color: (theme) => theme.palette.text.primary }}>
                                                    Relevance score: {relevancyScore}%
                                                </Typography>
                                            </Box>
                                            {ver.propositionUsed && (
                                                <Box
                                                    sx={{
                                                        marginRight: '0rem',
                                                        marginLeft: 'auto',
                                                        display: 'flex',
                                                        color: (theme) => theme.palette.success.main,
                                                        height: '1rem',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Icon.Link45deg height={24} width={24} />
                                                    <Typography sx={{ marginLeft: '0.5rem', fontWeight: 500, whiteSpace: 'nowrap' }}>
                                                        Information Linked
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                        <Typography sx={{ marginTop: '1rem', marginBottom: '1rem', height: '3rem', fontSize: '1rem', fontWeight: 400 }}>
                                            {ver.text.length > 240 ? `${ver.text.substring(0, 240)}...` : ver.text}
                                        </Typography>
                                        <Typography
                                            variant='link11'
                                            sx={{ height: '2rem', fontSize: '0.875rem', fontWeight: 600 }}
                                            onClick={() => showSupportingInformationPropositionOnDocument(ver.id, ver.documentId)}
                                        >
                                            {documentName}
                                        </Typography>
                                        <Box sx={{ marginTop: '1.5rem' }}>
                                            {!ver.propositionUsed && (
                                                <Button
                                                    color='secondary'
                                                    onClick={() =>
                                                        isRejected
                                                            ? restoreProposition(selectedAnnotation.id, ver.id, () => {})
                                                            : rejectProposition(selectedAnnotation.id, ver.id)
                                                    }
                                                    sx={{
                                                        ...ActionButtonStyle,
                                                        zIndex: 10,
                                                        marginRight: '2rem',
                                                        backgroundColor: (theme) => theme.palette.white.main,
                                                    }}
                                                >
                                                    {isRejected ? 'RESTORE' : 'REJECT'}
                                                </Button>
                                            )}
                                            {!isRejected && (
                                                <>
                                                    {ver.propositionUsed ? (
                                                        <Button
                                                            isLoading={linkingInformationInProgressById[ver.id]}
                                                            onClick={() =>
                                                                ver.supportingInformationId &&
                                                                unlinkPropositionFromAnnotation(
                                                                    selectedAnnotation.id,
                                                                    ver.supportingInformationId,
                                                                    (linkingInformation) => setLinkingInformationInProgressById(ver.id, linkingInformation)
                                                                )
                                                            }
                                                            sx={{
                                                                ...ActionButtonStyle,
                                                            }}
                                                        >
                                                            UNLINK
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            isLoading={linkingInformationInProgressById[ver.id]}
                                                            onClick={() =>
                                                                linkPropositionToAnnotation(selectedAnnotation.id, ver.id, (linkingInformation) =>
                                                                    setLinkingInformationInProgressById(ver.id, linkingInformation)
                                                                )
                                                            }
                                                            sx={{
                                                                ...ActionButtonStyle,
                                                            }}
                                                        >
                                                            LINK
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                                <Divider />
                            </Box>
                        );
                    })}
                    {suppInfoProp.length > 0 && suppInfoProp.length > countPropositionsToDisplay && (
                        <Box sx={{ height: '5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button color='secondary' onClick={() => increaseCountPropositionsToDisplay(selectedAnnotation.id)}>
                                Show More
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default PropositionsListComponent;
