import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { Keyword } from 'ApiClients/SterlingApiClients/Types';

const useAnnotationsKeywords = (projectId: string, annotationApi: AnnotationsApiClient) => {
    const getAnnotationKeywords = (annotationId: string, flowMethods?: FlowMethods<Array<Keyword>>) =>
        annotationApi.getKeywords(projectId!, annotationId, flowMethods);

    const addAnnotationKeyword = (annotationId: string, keyword: string, flowMethods?: FlowMethods) =>
        annotationApi.addKeyword(projectId!, annotationId, {
            ...flowMethods,
            body: { keyword },
        });

    const removeAnnotationKeyword = (annotationId: string, keywordId: string, flowMethods?: FlowMethods) =>
        annotationApi.removeKeyword(projectId!, annotationId, keywordId, flowMethods);

    const removeAllAnnotationKeywords = (annotationId: string, flowMethods?: FlowMethods) =>
        annotationApi.removeAllKeywords(projectId!, annotationId, flowMethods);

    return {
        getAnnotationKeywords,
        addAnnotationKeyword,
        removeAnnotationKeyword,
        removeAllAnnotationKeywords,
    };
};

export default useAnnotationsKeywords;
