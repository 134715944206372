import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { highlightColorToAnnotationsColor } from '../../useDrawing';
import { generateBorder } from './Borders';
import {
    isStandardAnnotation,
    isNewHighlight,
    getHighlightId,
    getHighlightPartIndex,
    AnnotationAttributeTypeValues,
    AnnotationAttributeHighlightId,
    AnnotationAttributeType,
    AnnotationAttributeIsFirst,
    AnnotationAttributeIsLast,
    AnnotationAttributeHighlightPartIndex,
    AnnotationAttributeSiblingsId,
} from './Attributes';
import { Highlight, HighlightColors } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

export const getAnnotationsToRemove = (instance: WebViewerInstance, highlights: Array<Highlight>) => {
    const { annotationManager } = instance.Core;
    const allAnnotations = annotationManager.getAnnotationsList();
    const annotations = allAnnotations.filter((a) => isStandardAnnotation(a) && !isNewHighlight(a));
    let annotationsToRemove: Array<Core.Annotations.Annotation> = [];
    annotations.forEach((a) => {
        const highlightId = getHighlightId(a);
        const highlightIndex = highlights.findIndex((h) => h.id === highlightId);
        if (highlightIndex < 0) annotationsToRemove.push(a);
        else {
            const highlightPartIndex = getHighlightPartIndex(a);
            if (highlightPartIndex + 1 > highlights[highlightIndex].boundingBoxSections.length) annotationsToRemove.push(a);
        }
    });

    return annotationsToRemove;
};

export const getAnnotations = (instance: WebViewerInstance, highlights: Array<Highlight>, selectedHighlightId: string | null) => {
    const { Annotations, annotationManager } = instance.Core;
    const allAnnotations = annotationManager.getAnnotationsList();

    const selectedColor = highlightColorToAnnotationsColor(Annotations, HighlightColors.selected);
    const defaultColor = highlightColorToAnnotationsColor(Annotations, HighlightColors.default);

    const getColor = (h: Highlight, selectedHighlightId: string | null) => {
        if (h.color) return highlightColorToAnnotationsColor(Annotations, h.color);
        else {
            if (h.id === selectedHighlightId) return selectedColor;
            else return defaultColor;
        }
    };

    const setupAnnotation = (
        annot: Core.Annotations.TextHighlightAnnotation | Core.Annotations.RectangleAnnotation,
        highlight: Highlight,
        type: AnnotationAttributeTypeValues,
        idx: number,
        pageNumber: number
    ) => {
        AnnotationAttributeHighlightId.set(annot, highlight.id);
        AnnotationAttributeType.set(annot, type);
        if (idx === 0) AnnotationAttributeIsFirst.set(annot, 'true');
        if (idx === highlight.boundingBoxSections.length - 1) AnnotationAttributeIsLast.set(annot, 'true');
        AnnotationAttributeHighlightPartIndex.set(annot, idx);
        if (highlight.siblingsId) AnnotationAttributeSiblingsId.set(annot, highlight.siblingsId);

        annot.PageNumber = pageNumber;
        annot.StrokeColor = getColor(highlight, selectedHighlightId);
        annot.FillColor = getColor(highlight, selectedHighlightId);
        annot.NoResize = true;
    };

    let annotations: Array<Core.Annotations.Annotation> = [];
    highlights.forEach((h) => {
        const highlightAnnots = allAnnotations.filter((a) => getHighlightId(a) === h.id && isStandardAnnotation(a)) as Array<Core.Annotations.Annotation>;
        h.boundingBoxSections.forEach((section, idx) => {
            // TODO: Change on h.ContentType. Fix the problem with rectangle covering text.
            const tmp: string = 'text';
            switch (tmp) {
                case 'text':
                    let textAnnot = highlightAnnots.filter((a) => getHighlightPartIndex(a) === idx)[0] as Core.Annotations.TextHighlightAnnotation;
                    if (!textAnnot) {
                        textAnnot = new Annotations.TextHighlightAnnotation();
                        annotations.push(textAnnot);
                    }

                    let quads: Array<Core.Math.Quad> = [];
                    section.boundingBoxes.forEach((b) => {
                        const tL = b.topLeft;
                        const dR = b.downRight;

                        quads.push(new instance.Core.Math.Quad(tL.x, dR.y, dR.x, dR.y, dR.x, tL.y, tL.x, tL.y));
                    });
                    textAnnot.Quads = quads;
                    setupAnnotation(textAnnot, h, 'textAnnotation', idx, section.pageNumber);
                    if (h.borderColor && h.id === selectedHighlightId) {
                        annotations.push(
                            ...generateBorder(
                                instance,
                                h.id,
                                idx,
                                textAnnot.PageNumber,
                                textAnnot.getRect(),
                                highlightColorToAnnotationsColor(Annotations, h.borderColor)
                            )
                        );
                    }
                    break;
                /* CODE BELOW NEED TO BE ADJUSTED
                case 'shape': 
                    const boxAnnot = new Annotations.RectangleAnnotation();
                    setupAnnotation(boxAnnot, h, 'boxAnnotation', idx, section.pageNumber);
                    const boundingBox = section.boundingBoxes[0];
                    boxAnnot.setRect(
                        new instance.Core.Math.Rect(boundingBox.topLeft.x, boundingBox.topLeft.y, boundingBox.downRight.x, boundingBox.downRight.y)
                    );
                    boxAnnot.NoMove = true;
                    boxAnnot.disableRotationControl();
                    annotations.push(boxAnnot);
                    break;
                */
            }
        });
    });
    return annotations;
};
