import { Box } from '@mui/material';
import { AutoAnnotationsProcess, AutoAnnotationsProcessStatus, ProjectDocumentProcessingStatus } from 'ApiClients/SterlingApiClients/Types';
import { ErrorsDescription } from 'App/Consts';
import { Icon, Typography, VerifiSpinner } from 'UI';
import ProgressBarComponent from 'UI/ProgressBar';

export type ProcessingInfoComponentProps = {
    documentProcessingStatus: ProjectDocumentProcessingStatus;
    autoAnnotationsProcess: AutoAnnotationsProcess | null;
};

function ProcessingInfoComponent(props: ProcessingInfoComponentProps) {
    const { documentProcessingStatus, autoAnnotationsProcess } = props;

    let status: 'working' | 'failed' | null = null;
    let processing: { stateType: 'Processing' | 'Waiting'; description: string; progress?: number; error?: string } | null = null;
    let failed: { description: string } | null = null;
    if (documentProcessingStatus !== ProjectDocumentProcessingStatus.Processed) {
        switch (documentProcessingStatus) {
            case ProjectDocumentProcessingStatus.ToBeProcessed:
                status = 'working';
                processing = { stateType: 'Waiting', description: 'Verification document is waiting in the queue for processing.' };
                break;
            case ProjectDocumentProcessingStatus.Processing:
                status = 'working';
                processing = { stateType: 'Processing', description: 'Processing verification document.' };
                break;
            case ProjectDocumentProcessingStatus.Failed:
                status = 'failed';
                failed = { description: ErrorsDescription.verificationDocumentProcessingStatusFailed };
                break;
        }
    } else if (autoAnnotationsProcess) {
        const processStatus = autoAnnotationsProcess.status;

        switch (processStatus) {
            case AutoAnnotationsProcessStatus.ToBeProcessed:
                status = 'working';
                processing = { stateType: 'Waiting', description: 'Auto Annotations process is waiting in the queue for processing.' };
                break;
            case AutoAnnotationsProcessStatus.Processing:
                status = 'working';
                processing = {
                    stateType: 'Processing',
                    description: 'Generating Auto Annotations.',
                    progress: autoAnnotationsProcess.progress,
                    error: (autoAnnotationsProcess.anySectionProcessFailed && 'Auto Annotations process for some sections failed.') || undefined,
                };
                break;
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
                backgroundColor: (theme) => theme.palette.blue.light,
                zIndex: 10,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {status === 'working' && processing && (
                <Box sx={{ width: '75%', textAlign: 'center', display: 'grid', gap: 1.5 }}>
                    <VerifiSpinner height='4rem' />
                    <Typography variant='body1' sx={{ fontWeight: 600 }}>
                        {processing.stateType}...
                    </Typography>
                    <Typography variant='body2'>{processing.description}</Typography>
                    {processing.progress !== undefined && <ProgressBarComponent currentValue={processing.progress} />}
                    {processing.error !== undefined && (
                        <>
                            <Box
                                sx={{
                                    color: (theme) => theme.palette.attention.high,
                                    width: '100%',
                                    justifyContent: 'center',
                                    gap: 0.5,
                                }}
                            >
                                <Icon.ExclamationCircle />
                                <Typography variant='body2'>{processing.error}</Typography>
                            </Box>
                        </>
                    )}
                </Box>
            )}
            {status === 'failed' && failed && (
                <Box sx={{ width: '75%', textAlign: 'center', display: 'grid', gap: 1.5, color: (theme) => theme.palette.attention.high }}>
                    <Box>
                        <Icon.ExclamationCircle height={32} width={32} />
                    </Box>
                    <Typography variant='body1' sx={{ fontWeight: 600 }}>
                        Error
                    </Typography>
                    <Typography variant='body2'>{failed.description}</Typography>
                </Box>
            )}
        </Box>
    );
}

export default ProcessingInfoComponent;
