import { useState } from 'react';
import { Box } from '@mui/material';
import Menu from 'UI/Menu';
import { Gear, IconType, ProjectProgress, Tags, PersonsAdd } from 'UI/Icon';

export type SettingsComponentProps = {
    openProjectNameModal: () => void;
    openProjectMembersModal: () => void;
    openTagManagementModal: () => void;
    goToProjectDashboard: () => void;
};

function SettingsComponent(props: SettingsComponentProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const menuGroups: Array<MyMenuGroupHeadProps & { items: Array<Omit<MyMenuItemProps, 'handleClose'>> }> = [
        {
            text: 'SETTINGS',
            items: [
                {
                    icon: Tags,
                    text: 'Tag management',
                    onClick: () => props.openTagManagementModal(),
                    dataTestId: 'tag-management-menu-item',
                },
                {
                    icon: PersonsAdd,
                    text: 'Project Members',
                    onClick: () => props.openProjectMembersModal(),
                    dataTestId: 'project-members-menu-item',
                },
            ],
        },
        {
            text: 'REPORTS',
            items: [
                {
                    icon: ProjectProgress,
                    text: 'Project dashboard',
                    onClick: () => props.goToProjectDashboard(),
                    dataTestId: 'project-progress-menu-item',
                },
            ],
        },
    ];

    return (
        <Menu open={isOpen}>
            <Menu.Trigger color='secondary' startIcon={<Gear />} onClick={handleClick} data-testid='settings-menu-trigger'>
                Settings
            </Menu.Trigger>

            <Menu.List hasContainerWidth={false} data-testid='menu-list'>
                {getList(menuGroups).map((i) => i)}
            </Menu.List>
        </Menu>
    );
}

const getList = (menuGroups: Array<MyMenuGroupHeadProps & { items: Array<Omit<MyMenuItemProps, 'handleClose'>> }>) => {
    let list: Array<JSX.Element> = [];
    menuGroups.forEach((g, idxG) => {
        list.push(<Menu.GroupItem key={`settings-OldMenu-list-group-${idxG}`}>{g.text} </Menu.GroupItem>);
        g.items.forEach((i, idxI) => {
            list.push(
                <Menu.Item key={`settings-OldMenu-list-item-${idxG}-${idxI}`} onClick={i.onClick} data-testid={i.dataTestId}>
                    <Box display='flex' gap={1} alignItems='center'>
                        <i.icon />
                        {i.text}
                    </Box>
                </Menu.Item>
            );
        });
    });
    return list;
};

type MyMenuGroupHeadProps = {
    text: string;
};

type MyMenuItemProps = { icon: IconType; text: string; handleClose: () => void; onClick: () => void; dataTestId?: string };

export default SettingsComponent;
