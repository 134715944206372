import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { AnnotationAttributeHighlightPartIndex, AnnotationAttributeParentHighlightId, AnnotationAttributeType } from './Attributes';

export const generateBorder = (
    instance: WebViewerInstance,
    highlightId: string,
    highlightPartIndex: number,
    pageNumber: number,
    rect: Core.Math.Rect,
    borderColor: Core.Annotations.Color
) => {
    const { Annotations } = instance.Core;
    let annots: Array<Core.Annotations.LineAnnotation> = [];
    const l = rect.getLeft();
    const t = rect.getTop();
    const r = rect.getRight();
    const b = rect.getBottom();

    const setupLine = (annot: Core.Annotations.LineAnnotation) => {
        annot.PageNumber = pageNumber;
        annot.Color = borderColor;
        annot.Style = 'dash';
        annot.Dashes = '3,3';
        annot.StrokeThickness = 0.5;
        annot.NoMove = true;
        annot.NoResize = true;
        AnnotationAttributeType.set(annot, 'border');
        AnnotationAttributeParentHighlightId.set(annot, highlightId);
        AnnotationAttributeHighlightPartIndex.set(annot, highlightPartIndex);
        annots.push(annot);
    };

    const leftAnnot = new Annotations.LineAnnotation();
    leftAnnot.Start = new instance.Core.Math.Point(l, b);
    leftAnnot.End = new instance.Core.Math.Point(l, t);
    setupLine(leftAnnot);
    const topAnnot = new Annotations.LineAnnotation();
    topAnnot.Start = new instance.Core.Math.Point(l, t);
    topAnnot.End = new instance.Core.Math.Point(r, t);
    setupLine(topAnnot);
    const rightAnnot = new Annotations.LineAnnotation();
    rightAnnot.Start = new instance.Core.Math.Point(r, t);
    rightAnnot.End = new instance.Core.Math.Point(r, b);
    setupLine(rightAnnot);
    const bottomAnnot = new Annotations.LineAnnotation();
    bottomAnnot.Start = new instance.Core.Math.Point(r, b);
    bottomAnnot.End = new instance.Core.Math.Point(l, b);
    setupLine(bottomAnnot);

    return annots;
};
