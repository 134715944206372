import { Icon } from 'UI';
import { Navigation } from '../../Hooks/useNavigation';
import HighlightToolbarComponent, { Util } from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightToolbar/HighlightToolbar.Component';

type SupportingInformationPanelComponentProps = {
    navigation: Navigation;
    deleteSupportingInformation: () => void;
};

function SupportingInformationPanelComponent(props: SupportingInformationPanelComponentProps) {
    const { navigation, deleteSupportingInformation } = props;

    const utils: Array<Util> = [
        { icon: Icon.ArrowLeftCircle, onClick: () => navigation.previous(), tooltip: 'Previous Linked Information', disabled: !navigation.available },
        { icon: Icon.ArrowRightCircle, onClick: () => navigation.next(), tooltip: 'Next Linked Information', disabled: !navigation.available },
        { icon: Icon.TrashBin, onClick: () => deleteSupportingInformation(), tooltip: 'Delete' },
    ];

    return (
        <div style={{ display: 'flex' }}>
            <HighlightToolbarComponent utils={utils} />
        </div>
    );
}

export default SupportingInformationPanelComponent;
