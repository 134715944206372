import React, { memo, useCallback, useEffect, useState } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { Button, TextField } from 'UI';

type RespondProps = {
    handleCancelClick: () => void;
    handleSubmitClick: (comment: string) => void;
    author: string;
    onMount: () => void;
};

function Respond({ handleCancelClick, handleSubmitClick, author, onMount }: RespondProps) {
    const [comment, setComment] = useState('');

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            handleSubmitClick(comment);
        },
        [comment, handleSubmitClick]
    );

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    };

    const Cancel = memo(({ onClick }: { onClick: React.MouseEventHandler<HTMLButtonElement> }) => (
        <Button type='button' variant='text' size='small' onClick={onClick} sx={{ textTransform: 'none' }}>
            Cancel
        </Button>
    ));

    const SubmitComment = memo(() => (
        <Button type='submit' variant='contained' size='small' sx={{ textTransform: 'none' }} disabled={!comment.trim()}>
            Comment
        </Button>
    ));

    useEffect(() => {
        onMount();
        // eslint-disable-next-line
    }, []);

    return (
        <Paper
            sx={{
                width: '25rem',
                borderRadius: '0.75rem',
                boxShadow: '0px 2px 10px 0px rgba(63, 68, 68, 0.4)',
                backgroundColor: (theme) => theme.palette.white.main,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                padding: '1rem',
                marginTop: '0.5rem',
            }}
        >
            <form onSubmit={handleSubmit}>
                <Box display='flex' flexDirection='column' gap={1}>
                    <Typography variant='subtitle2'>{author}</Typography>
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                        <TextField
                            placeholder='Add comment'
                            multiline
                            rows={3}
                            fullWidth
                            value={comment}
                            onChange={handleCommentChange}
                            sx={{
                                '.MuiInputBase-input': {
                                    fontSize: '0.75rem',
                                },
                            }}
                            inputProps={{ maxLength: 1000 }}
                        />
                        <Box display='flex' justifyContent='space-between'>
                            <Cancel onClick={handleCancelClick} />
                            <SubmitComment />
                        </Box>
                    </Box>
                </Box>
            </form>
        </Paper>
    );
}

export default Respond;
