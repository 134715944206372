import { Box } from '@mui/material';
import { Icon, Tooltip, Typography } from 'UI';

export type ToolbarComponentProps = {
    closeSidebar: () => void;
};

export const ToolbarHeight = '2.5rem';

function ToolbarComponent(props: ToolbarComponentProps) {
    const { closeSidebar } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                height: ToolbarHeight,
                borderRadius: '0.25rem 0.25rem 0rem 0rem',
                alignItems: 'center',
                backgroundColor: (theme) => theme.palette.blue.light,
            }}
        >
            <Box>
                <Typography variant='subtitle1' sx={{ marginLeft: '1rem' }}>
                    Search results
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '1.5rem', marginLeft: 'auto' }}>
                <Box>
                    <Tooltip title={'Close'} placement='bottom'>
                        <Box
                            onClick={closeSidebar}
                            sx={{
                                height: '1.5rem',
                                width: '1.5rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: '0.75rem',
                                cursor: 'pointer',
                                borderRadius: '0.25rem',
                            }}
                        >
                            <Icon.CloseCircle height={16} width={16} />
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
}

export default ToolbarComponent;
