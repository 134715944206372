import { WebViewerInstance } from '@pdftron/webviewer';
import { drawHighlightPartNumber, eraseHighlightPartNumber } from './HighlightPartNumber';
import { EmotionCache } from '@emotion/react';

const drawHtmlElements = (instance: WebViewerInstance, selectedHighlightId: string | null, emotionCache: EmotionCache) => {
    eraseHighlightPartNumber(instance);
    drawHighlightPartNumber(instance, selectedHighlightId, emotionCache);
};

export default drawHtmlElements;
