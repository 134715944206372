import { useEffect, useState } from 'react';
import { MultiSelectProp } from '../../PdfViewerWithToolbar.Component';

const useMultiSelect = (): { multiSelectProp: MultiSelectProp } => {
    const [multiSelectActive, setMultiSelectActive] = useState<boolean>(false);
    const [multiSelectEnabled, setMultiSelectEnabled] = useState<boolean>(false);
    const [multiSelectNext, setMultiSelectNext] = useState<boolean>(false);
    useEffect(() => {
        if (multiSelectNext && !multiSelectActive) setMultiSelectActive(true);
    }, [multiSelectNext, multiSelectActive, setMultiSelectActive]);

    return {
        multiSelectProp: {
            active: multiSelectActive,
            setActive: setMultiSelectActive,
            enabled: multiSelectEnabled,
            setEnabled: setMultiSelectEnabled,
            next: multiSelectNext,
            setNext: setMultiSelectNext,
        },
    };
};

export default useMultiSelect;
