import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import {
    Annotation,
    AnnotationStatus,
    AutoAnnotation,
    AutoAnnotationsProcessStatus,
    BoundingBoxSection,
    Content,
    VerificationDocument,
} from 'ApiClients/SterlingApiClients/Types';
import { getSelectionType } from 'Views/Project/ProjectWorkspace/Types';
import { useMemo } from 'react';
import useAnnotationsFilters from '../../useAnnotationsFilters';
import useFilteredAnnotations from './useFilteredAnnotations';
import NotificationsApiClient from 'ApiClients/SterlingApiClients/NotificationsApiClient/Notifications.ApiClient';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { ContentType } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import OrderByHighlightPosition from 'Views/Common/PdfViewerWithToolbar/Functions/OrderByHighlightPosition';

const getPageAndCoordinates = (a: { content: Content }): { page: number; coordinateY: number; coordinateX: number } => ({
    page: a.content.boundingBoxSections[0].pageNumber,
    coordinateY: a.content.boundingBoxSections[0].boundingBoxes[0].topLeft.y,
    coordinateX: a.content.boundingBoxSections[0].boundingBoxes[0].topLeft.x,
});

const useAnnotations = (
    projectId: string,
    projectVersionId: string,
    annotationApi: AnnotationsApiClient,
    notifiApi: NotificationsApiClient,
    verificationDocumentInfo: VerificationDocument | null,
    createAuditLog: (actionType: string, content: string) => void,
    setAutoAnnotationsProcessIsErrorReceived: (isErrorReceived: boolean) => void
) => {
    const { data: __annotations, refetch: refetchAnnotations } = usePromiseWithFlowMethods<{ projectId: string; projectVersionId: string }, Array<Annotation>>({
        method: (input, flowMethods) => annotationApi.getAnnotations(input.projectId, input.projectVersionId, flowMethods),
        initialData: [],
        initFetch: { input: { projectId, projectVersionId } },
    });

    const autoAnnotationsEnabled = useMemo(() => {
        if (verificationDocumentInfo?.autoAnnotationsProcess) {
            const status = verificationDocumentInfo.autoAnnotationsProcess.status;

            return status === AutoAnnotationsProcessStatus.Processed || status === AutoAnnotationsProcessStatus.ProcessedWithErrors;
        }

        return false;
    }, [verificationDocumentInfo?.autoAnnotationsProcess]);
    const { data: _autoAnnotations, refetch: refetchAutoAnnotations } = usePromiseWithFlowMethods<
        { projectId: string; projectVersionId: string },
        Array<AutoAnnotation>
    >({
        method: (input, flowMethods) => annotationApi.getAutoAnnotations(input.projectId, input.projectVersionId, flowMethods),
        initialData: [],
        initFetch: { input: { projectId, projectVersionId } },
        enabled: autoAnnotationsEnabled,
    });
    const autoAnnotations = useMemo(() => {
        return _autoAnnotations.sort((a, b) => OrderByHighlightPosition(getPageAndCoordinates(a), getPageAndCoordinates(b)));
    }, [_autoAnnotations]);

    const annotationFilters = useAnnotationsFilters();
    const _annotations = useFilteredAnnotations(__annotations, annotationFilters, (filters) => createAuditLog('FilteringAnnotation', filters));
    const annotations = useMemo(() => {
        return _annotations.sort((a, b) => OrderByHighlightPosition(getPageAndCoordinates(a), getPageAndCoordinates(b)));
    }, [_annotations]);

    const addAnnotation = (contentType: ContentType, boundingBoxSections: Array<BoundingBoxSection>, statement: string) =>
        annotationApi.addAnnotation(projectId, projectVersionId, {
            body: { selectionType: getSelectionType(contentType), boundingBoxSections, statement },
            onSuccess: refetchAnnotations,
        });
    const deleteAnnotation = (id: string, flowMethods: FlowMethods) =>
        annotationApi.deleteAnnotation(projectId, projectVersionId, id, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods?.onSuccess?.(data);
                refetchAnnotations();
            },
        });
    const assignMemberToAnnotation = (annotationId: string, memberId: string, flowMethods?: FlowMethods) => {
        annotationApi.assignMember(projectId, annotationId, memberId, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods?.onSuccess?.(data);
                refetchAnnotations();
            },
        });
    };
    const markAnnotationNotificationAsRead = (annotationId: string) => notifiApi.markNotificationAsRead(annotationId, refetchAnnotations);

    const createAnnotationFromAutoAnnotation = (autoAnnotationId: string, flowMethods: FlowMethods) =>
        annotationApi.createAnnotationFromAutoAnnotation(projectId, projectVersionId, autoAnnotationId, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods.onSuccess && flowMethods.onSuccess(data);
                refetchAnnotations();
                refetchAutoAnnotations();
            },
        });

    const createAnnotationFromModifiedAutoAnnotation = (
        autoAnnotationId: string,
        contentType: ContentType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string,
        flowMethods: FlowMethods
    ) =>
        annotationApi.createAnnotationFromModifiedAutoAnnotation(projectId, projectVersionId, autoAnnotationId, {
            ...flowMethods,
            body: {
                boundingBoxSections,
                selectionType: getSelectionType(contentType),
                statement,
            },
            onSuccess: (data) => {
                flowMethods.onSuccess && flowMethods.onSuccess(data);
                refetchAnnotations();
                refetchAutoAnnotations();
            },
        });
    const rejectAutoAnnotation = (autoAnnotationId: string, flowMethods: FlowMethods) =>
        annotationApi.rejectAutoAnnotation(projectId, projectVersionId, autoAnnotationId, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods.onSuccess && flowMethods.onSuccess(data);
                refetchAutoAnnotations();
            },
        });

    const getAvailablePreviousStatuses = (annotationId: string, flowMethods: FlowMethods<Array<AnnotationStatus>>) =>
        annotationApi.getAvailablePreviousStatuses(projectId, projectVersionId, annotationId, flowMethods);

    const reorderAnnotationsNumbers = (flowMethods: FlowMethods) =>
        annotationApi.reorderAnnotationsNumbers(projectId, projectVersionId, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods.onSuccess && flowMethods.onSuccess(data);
                refetchAnnotations();
            },
        });

    const markAutoAnnotationsProcessErrorAsReceived = () => {
        annotationApi.markAutoAnnotationsProcessErrorAsReceived(projectId, projectVersionId, {
            onSuccess: () => setAutoAnnotationsProcessIsErrorReceived(true),
        });
    };

    return {
        annots: {
            annotations,
            annotationFilters,
            refetchAnnotations,
            addAnnotation,
            deleteAnnotation,
            assignMemberToAnnotation,
            markAnnotationNotificationAsRead,
            getAvailablePreviousStatuses,
            reorderAnnotationsNumbers,
        },
        autoAnnots: {
            autoAnnotations,
            refetchAutoAnnotations,
            createAnnotationFromAutoAnnotation,
            createAnnotationFromModifiedAutoAnnotation,
            rejectAutoAnnotation,
            markAutoAnnotationsProcessErrorAsReceived,
        },
    };
};

export default useAnnotations;
