import { useEffect, useRef } from 'react';
import AutoAnnotationPanelComponent from '../Common/AutoAnnotationPanel/AutoAnnotationPanel.Component';
import { BoundingBoxSection } from 'ApiClients/SterlingApiClients/Types';
import { ModifiedHighlightToolbarOptions, ContentType } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

export type AnnotationModifyingPanelWrapperComponentProps = {
    newHighlightToolbarOptions: ModifiedHighlightToolbarOptions;
    autoAnnotationProps: AutoAnnotationProps;
    showHideAnnotationElements: (id: string, show: boolean) => void;
};

type AutoAnnotationProps = {
    createAnnotation: (originalHighlightId: string, contentType: ContentType, boundingBoxSections: BoundingBoxSection[], statement: string) => void;
    rejectAnnotation: (id: string) => void;
};

function AnnotationModifyingPanelWrapperComponent(props: AnnotationModifyingPanelWrapperComponentProps) {
    const { newHighlightToolbarOptions, autoAnnotationProps, showHideAnnotationElements } = props;
    const { setHighlightToolbarRef: setRef, getModifiedHighlight, clearSelection, modifyHighlight } = newHighlightToolbarOptions;
    const { createAnnotation, rejectAnnotation } = autoAnnotationProps;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setRef(ref);
    }, [setRef]);

    return (
        <div ref={ref}>
            <AutoAnnotationPanelComponent
                createAnnotation={async () => {
                    const highlight = await getModifiedHighlight();
                    createAnnotation(highlight.originalHighlightId, highlight.contentType, highlight.boundingBoxSections, highlight.statement);
                    clearSelection();
                }}
                modifyAnnotation={() => modifyHighlight()}
                rejectAnnotation={async () => {
                    const highlight = await getModifiedHighlight();
                    rejectAnnotation(highlight.originalHighlightId);
                }}
                showHideAnnotationElements={async (show) => {
                    const highlight = await getModifiedHighlight();
                    showHideAnnotationElements(highlight.originalHighlightId, show);
                }}
            />
        </div>
    );
}

export default AnnotationModifyingPanelWrapperComponent;
