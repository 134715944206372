import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import AuthApiClient from './Auth.ApiClient';
import { Toast, ToastEnum } from 'Hooks/useToasts';
import { DocumentFileConfiguration } from 'App/Consts';

export type GetRequestOptions<TData> = FlowMethods<TData> & RequestOptions;

export type PostRequestOptions<TBody = {}, TData = {}> = PutPostRequestOptions<TBody, TData>;

export type PutRequestOptions<TBody = {}, TData = {}> = PutPostRequestOptions<TBody, TData>;

export type PutPostRequestOptions<TBody = {}, TData = {}> = FlowMethods<TData> &
    RequestOptions & {
        body?: TBody;
        bodyAsDataForm?: boolean;
    };

export type DeleteRequestOptions<TData = {}> = FlowMethods<TData> & RequestOptions;

export type RequestOptions = {
    toastSuccessMessages?: Array<string>;
};

export type FlowMethods<TData = {}> = {
    onInit?: () => void;
    onSuccess?: (data: TData) => void;
    onError?: () => void;
    setFetching?: (isFetching: boolean) => void;
};

export type CustomErrorHandler = (error: AxiosError<ErrorResponseData>, baseErrorHandler: (error: AxiosError<ErrorResponseData>) => void) => void;

export type ErrorHandlerRegistration = {
    register: (customErrorHandler: CustomErrorHandler) => void;
    unregister: () => void;
};

export type ErrorResponseData = {
    errorId: string;
    message: string;
    displayMessages: Array<string>;
};

const AllowedFileTypesConfig = DocumentFileConfiguration.allowedFileTypes;

// TODO: Refactor this to remove repetitive code and make it more readable
export default class SterlingApiClient {
    _apiUrl: string;
    _authApiClient: AuthApiClient;
    _addToast: (toast: Toast) => void;
    _customErrorHandler: CustomErrorHandler | null = null;

    constructor(authApiClient: AuthApiClient, addToast: (toast: Toast) => void) {
        this._authApiClient = authApiClient;
        this._apiUrl = process.env.REACT_APP_INTERNAL_WS_URL;
        this._addToast = addToast;
    }

    getUseQuery = <TData,>(queryKey: QueryKey, url: string, useQueryOptions?: UseQueryOptions<TData>) =>
        useQuery<TData>(queryKey, () => this._getUseQueryAsync(url), { retry: false, ...useQueryOptions });

    _getUseQueryAsync = async <TData,>(url: string): Promise<TData> => {
        try {
            const headers: RawAxiosRequestHeaders = await this._getAuthenticationHeaderAsync();
            const result = await axios<TData>({
                method: 'get',
                url: `${this._apiUrl}${url}`,
                headers,
            }).then(
                (response) => response.data,
                (error) => {
                    this._errorHandler(error);
                    return null as TData;
                }
            );
            return result;
        } catch (error) {
            throw error;
        }
    };

    getAsync = async <TData,>(url: string, requestOptions?: GetRequestOptions<TData>) => {
        try {
            const headers: RawAxiosRequestHeaders = await this._getAuthenticationHeaderAsync();
            requestOptions?.onInit && requestOptions?.onInit();
            requestOptions?.setFetching && requestOptions?.setFetching(true);
            const result = await axios<TData>({
                method: 'get',
                url: `${this._apiUrl}${url}`,
                headers,
            }).then(
                (response) => {
                    requestOptions?.onSuccess && requestOptions?.onSuccess(response.data);
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    return response.data;
                },
                (error) => {
                    requestOptions?.onError && requestOptions?.onError();
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    this._errorHandler(error);
                    return null as TData;
                }
            );
            return result;
        } catch (error) {
            throw error;
        } finally {
            requestOptions?.setFetching && requestOptions?.setFetching(false);
        }
    };

    getFileAsync = async (url: string, fileId: string, fileName: string, requestOptions?: GetRequestOptions<File>) => {
        try {
            const headers: RawAxiosRequestHeaders = await this._getAuthenticationHeaderAsync();
            requestOptions?.onInit && requestOptions?.onInit();
            requestOptions?.setFetching && requestOptions?.setFetching(true);
            const result = await axios<Blob>({
                method: 'get',
                url: `${this._apiUrl}${url}`,
                headers,
                responseType: 'blob',
            }).then(
                (response) => {
                    const file = new File([response.data], fileName, {
                        type: response.data.type === 'application/octet-stream' ? this._getMimeTypeFromFileName(fileName) : response.data.type,
                    });
                    requestOptions?.onSuccess && requestOptions?.onSuccess(file);
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    return { id: fileId, file };
                },
                (error) => {
                    requestOptions?.onError && requestOptions?.onError();
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    this._errorHandler(error);
                    return null;
                }
            );
            return result;
        } catch (error) {
            throw error;
        } finally {
            requestOptions?.setFetching && requestOptions?.setFetching(false);
        }
    };

    getFileAsyncWithPost = async <TBody = {},>(url: string, fileName: string, requestOptions?: PostRequestOptions<TBody, File>) => {
        try {
            const headers: RawAxiosRequestHeaders = await this._getAuthenticationHeaderAsync();
            requestOptions?.onInit && requestOptions?.onInit();
            requestOptions?.setFetching && requestOptions?.setFetching(true);
            const result = await axios<Blob>({
                method: 'post',
                url: `${this._apiUrl}${url}`,
                headers: { ...headers, ...(requestOptions?.bodyAsDataForm ? { 'Content-Type': 'multipart/form-data' } : {}) },
                responseType: 'blob',
                data: requestOptions?.body,
            }).then(
                (response) => {
                    const file = new File([response.data], fileName, {
                        type: response.data.type === 'application/octet-stream' ? this._getMimeTypeFromFileName(fileName) : response.data.type,
                    });
                    requestOptions?.onSuccess && requestOptions?.onSuccess(file);
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    return file;
                },
                (error) => {
                    requestOptions?.onError && requestOptions?.onError();
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    this._errorHandler(error);
                    return null;
                }
            );
            return result;
        } catch (error) {
            throw error;
        } finally {
            requestOptions?.setFetching && requestOptions?.setFetching(false);
        }
    };

    _getMimeTypeFromFileName = (fileName: string) => {
        const mimeType = fileName.split('.').pop();
        switch (mimeType) {
            case 'pdf':
                return AllowedFileTypesConfig.pdf.key;
            case 'docx':
                return AllowedFileTypesConfig.word.key;
            default:
                return 'application/octet-stream';
        }
    };

    postAsync = async <TBody = {}, TData = {}>(url: string, requestOptions?: PostRequestOptions<TBody, TData>): Promise<any> =>
        this._putPostAsync(url, 'post', requestOptions);

    putAsync = async <TBody = {}, TData = {}>(url: string, requestOptions?: PutRequestOptions<TBody, TData>): Promise<any> =>
        this._putPostAsync(url, 'put', requestOptions);

    _putPostAsync = async <TBody = {}, TData = {}>(url: string, method: 'put' | 'post', requestOptions?: PutPostRequestOptions<TBody, TData>): Promise<any> => {
        try {
            const headers: RawAxiosRequestHeaders = await this._getAuthenticationHeaderAsync();
            requestOptions?.onInit && requestOptions?.onInit();
            requestOptions?.setFetching && requestOptions?.setFetching(true);
            await axios<TData>({
                method: method,
                url: `${this._apiUrl}${url}`,
                headers: { ...headers, ...(requestOptions?.bodyAsDataForm ? { 'Content-Type': 'multipart/form-data' } : {}) },
                data: requestOptions?.body,
            }).then(
                (response) => {
                    requestOptions?.toastSuccessMessages && this._addToast({ type: ToastEnum.SUCCESS, content: requestOptions.toastSuccessMessages });
                    requestOptions?.onSuccess && requestOptions.onSuccess(response.data);
                    requestOptions?.setFetching && requestOptions.setFetching(false);
                },
                (error) => {
                    requestOptions?.onError && requestOptions.onError();
                    requestOptions?.setFetching && requestOptions.setFetching(false);
                    this._errorHandler(error);
                }
            );
        } catch (error) {
            throw error;
        } finally {
            requestOptions?.setFetching && requestOptions?.setFetching(false);
        }
    };

    deleteAsync = async <TData = {},>(url: string, requestOptions?: DeleteRequestOptions<TData>): Promise<any> => {
        try {
            const headers: RawAxiosRequestHeaders = await this._getAuthenticationHeaderAsync();
            requestOptions?.onInit && requestOptions?.onInit();
            requestOptions?.setFetching && requestOptions?.setFetching(true);
            await axios<TData>({
                method: 'delete',
                url: `${this._apiUrl}${url}`,
                headers,
            }).then(
                (response) => {
                    requestOptions?.toastSuccessMessages && this._addToast({ type: ToastEnum.SUCCESS, content: requestOptions.toastSuccessMessages });
                    requestOptions?.onSuccess && requestOptions?.onSuccess(response.data);
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                },
                (error) => {
                    requestOptions?.onError && requestOptions?.onError();
                    requestOptions?.setFetching && requestOptions?.setFetching(false);
                    this._errorHandler(error);
                }
            );
        } catch (error) {
            throw error;
        } finally {
            requestOptions?.setFetching && requestOptions?.setFetching(false);
        }
    };

    _getAuthenticationHeaderAsync = async () => {
        const token = await this._authApiClient.getToken();

        const headers: RawAxiosRequestHeaders = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        return headers;
    };

    _errorHandler = (error: AxiosError<ErrorResponseData>) => {
        if (this._customErrorHandler) this._customErrorHandler(error, this._defaultErrorHandler);
        else this._defaultErrorHandler(error);
    };

    _defaultErrorHandler = (error: AxiosError<ErrorResponseData>) => {
        if (error.response) {
            const response = error.response;
            this._addToast({
                type: response.status >= 500 || [404, 405].includes(response.status) ? ToastEnum.ERROR : ToastEnum.WARNING,
                content: response.data.displayMessages || ['Undefined message.'],
                errorId: response.data.errorId || 'Undefined Error Id.',
            });
        } else this._addToast({ type: ToastEnum.ERROR, content: [error.message], errorId: error.code });
    };

    _registerCustomErrorHandler = (
        customErrorHandler: (error: AxiosError<ErrorResponseData>, baseErrorHandler: (error: AxiosError<ErrorResponseData>) => void) => void
    ) => (this._customErrorHandler = customErrorHandler);

    _unregisterCustomErrorHandler = () => (this._customErrorHandler = null);

    errorHandlerRegistration: ErrorHandlerRegistration = {
        register: this._registerCustomErrorHandler,
        unregister: this._unregisterCustomErrorHandler,
    };
}
