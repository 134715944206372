import TabsComponent from './Tabs/Tabs.Component';
import { AnnotationsScope } from '../Types';
import { DocumentsComponentProps } from './Tabs/Documents/Documents.Component';
import { StylesConsts } from '../ProjectWorkspace.Component';
import { Box } from '@mui/material';
import { useState } from 'react';
import { Icon } from 'UI';
import { DocumentHeading } from 'ApiClients/SterlingApiClients/Types';

type SidebarComponentProps = {
    annotationsScope: AnnotationsScope;
    supportingDocuments: DocumentsComponentProps;
    verificationDocumentHeadings: Array<DocumentHeading>;
    closeSupportingInformationPropositions: () => void;
};

function SidebarComponent(props: SidebarComponentProps) {
    const { supportingDocuments, verificationDocumentHeadings } = props;

    const [collapsed, setCollapsed] = useState<boolean>(false);

    return (
        <Box
            sx={{
                display: 'inline-block',
                position: 'relative',
                minWidth: '21.25rem',
                width: '20%',
                minHeight: `${StylesConsts.ContentMinHeight}rem`,
                height: '100%',
                backgroundColor: (theme) => theme.palette.white.main,
                borderRight: (theme) => `0.125rem solid ${theme.palette.stroke.main}`,
                borderRadius: '0rem 0.25rem 0rem 0rem',
                ...(collapsed && { marginLeft: 'min(calc(-20% + 0.5rem), -21.25rem)' }),
                transition: 'margin-left 300ms',
            }}
        >
            <TabsComponent
                supportingDocuments={supportingDocuments}
                annotationsScope={props.annotationsScope}
                verificationDocumentHeadings={verificationDocumentHeadings}
            />
            <Box
                onClick={() => setCollapsed((prev) => !prev)}
                sx={{
                    cursor: 'pointer',
                    width: '1rem',
                    height: '3.5rem',
                    position: 'absolute',
                    top: 'calc(50% - 1.75rem)',
                    right: '-1rem',
                    backgroundColor: (theme) => theme.palette.white.main,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '0 0.25rem 0.25rem 0',
                    border: (theme) => `0.125rem solid ${theme.palette.stroke.main}`,
                    borderLeft: 'none',
                }}
            >
                {collapsed ? <Icon.ArrowBarRight /> : <Icon.ArrowBarLeft />}
            </Box>
        </Box>
    );
}

export default SidebarComponent;
