import { useMemo } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { IndexReportElement, TagAnnotation } from 'ApiClients/SterlingApiClients/Types';
import { AppRoutes, SearchRoutes } from 'App/RoutesPaths';
import { InitialsAvatar, Tag, Tooltip } from 'UI';
import { FlagFill, NoData } from 'UI/Icon';
import AnnotationStatusComponent from 'Views/Common/AnnotationStatus/AnnotationStatus.Component';
import useStateById from 'Hooks/useStateById';
import DashboardTableComponent, { DashboardTableColumn } from './DashboardTable/DashboardTable.Component';

type IndexTableComponentProps = {
    report: Array<IndexReportElement>;
    projectId: string;
    projectVersionIdUrlParam: string;
};

function IndexTableComponent(props: IndexTableComponentProps) {
    const { report, projectId, projectVersionIdUrlParam } = props;

    const [expandedSupportingDocuments, setExpandedSupportingDocuments] = useStateById<boolean>();
    const theme = useTheme();

    const columns: Array<DashboardTableColumn<IndexReportElement>> = useMemo(
        () => [
            {
                displayName: 'Id',
                width: 10,
                customContent: (value: IndexReportElement) => {
                    return (
                        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    border: (theme: Theme) => `1px solid ${theme.palette.stroke.main}`,
                                    borderRadius: '0.25rem',
                                    color: (theme: Theme) => theme.palette.text.primary,
                                    padding: '0.25rem 0.5rem',
                                }}
                                data-testid='column-id'
                            >
                                <Typography variant='subtitle2'>{value.annotation.orderNumber}</Typography>
                            </Box>
                        </Box>
                    );
                },
            },
            {
                displayName: 'Status',
                width: 15,
                customContent: (value: IndexReportElement) => {
                    const { needsReview } = value.annotation;
                    return (
                        <Box sx={{ position: 'relative', overflow: 'visible !important' }}>
                            {needsReview && (
                                <FlagFill style={{ position: 'absolute', top: '5%', left: '-20%', zIndex: 1, color: theme.palette.attention.high }} />
                            )}
                            <AnnotationStatusComponent status={value.annotation.status} />
                        </Box>
                    );
                },
            },
            {
                displayName: 'Tag',
                width: 15,
                customContent: (value: IndexReportElement) => {
                    const tags = value.annotation.tags;

                    return (
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {tags.map((tag: TagAnnotation) => (
                                <Tag key={tag.id} textColor={theme.palette.white.main} color={theme.palette.blue.medium}>
                                    {tag.name}
                                </Tag>
                            ))}
                        </Box>
                    );
                },
            },

            {
                displayName: 'Assigned to',
                width: 15,
                customContent: (value: IndexReportElement) =>
                    value.assignedMember ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <InitialsAvatar name={value.assignedMember?.fullName} /> {value.assignedMember?.fullName}
                        </Box>
                    ) : (
                        'Unassigned'
                    ),
            },
            {
                displayName: 'Approved by',
                width: 10,
                customContent: (value: IndexReportElement) => value.verifiedBy?.fullName || 'Unverified',
            },
            {
                displayName: 'Page',
                width: 5,
                customContent: (value: IndexReportElement) => {
                    return (
                        <Link
                            to={{
                                pathname: `${AppRoutes.projectInstance.projectVersionInstance.workspace.specificPath({
                                    projectId,
                                    versionId: projectVersionIdUrlParam,
                                })}`,
                                hash: `${SearchRoutes.goToAnnotation.hash}`,
                                search: `${SearchRoutes.goToAnnotation.getPath(value.annotation.id)}`,
                            }}
                        >
                            <Box
                                sx={{
                                    color: (theme: Theme) => theme.palette.blue.dark,
                                    textDecoration: 'underline',
                                    fontWeight: 400,
                                    fontSize: '0.75rem',
                                    cursor: 'pointer',
                                }}
                            >
                                {value.annotation.pageNumber}
                            </Box>
                        </Link>
                    );
                },
            },
            {
                displayName: 'Supporting information',
                width: 15,
                customContent: (value: IndexReportElement) => {
                    const maxDocNameLength: number = 25;
                    const maxCountDocs: number = 3;
                    const annotId: string = value.annotation.id;
                    let supportingDocuments = value.supportingDocuments;
                    const expanded: boolean = expandedSupportingDocuments[annotId];

                    if (!expanded) supportingDocuments = supportingDocuments.slice(0, maxCountDocs);

                    return (
                        <Box sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                            {supportingDocuments.map((doc, idx) => {
                                const docName = doc.originalName || doc.name;

                                return (
                                    <Tooltip key={`it-cols-sd-${idx}`} title={docName}>
                                        <Box sx={{ marginTop: '0.5rem' }}>
                                            <Typography variant='link12'>
                                                {`Page ${doc.pageNumber} in: ${
                                                    docName.length > maxDocNameLength ? `${docName.substring(0, maxDocNameLength)}...` : docName
                                                }`}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                );
                            })}
                            {value.supportingDocuments.length === 0 && <span>Unlinked</span>}
                            {value.supportingDocuments.length > maxCountDocs && (
                                <Box
                                    sx={{
                                        marginTop: '0.5rem',
                                        color: (theme: Theme) => theme.palette.overlay.main,
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setExpandedSupportingDocuments.Value(annotId, !expanded)}
                                >
                                    {expanded ? 'See less' : 'See more'}
                                </Box>
                            )}
                        </Box>
                    );
                },
            },
            {
                displayName: 'Last modified',
                width: 10,
                customContent: (value: IndexReportElement) => {
                    const date = new Date(value.annotation.lastModifiedDate);
                    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                },
            },
        ],
        [expandedSupportingDocuments, projectId, projectVersionIdUrlParam, setExpandedSupportingDocuments, theme]
    );

    const emptyContentInfo = useMemo(
        () => (
            <Box
                sx={{
                    marginTop: '5rem',
                    marginBottom: '5rem',
                }}
            >
                <NoData />
                <Typography
                    sx={{
                        color: (theme: Theme) => theme.palette.blue.medium,
                    }}
                    variant='h5'
                >
                    No Data
                </Typography>
            </Box>
        ),
        []
    );
    return <DashboardTableComponent<IndexReportElement> data={report} columns={columns} emptyContentInfo={emptyContentInfo} />;
}

export default IndexTableComponent;
