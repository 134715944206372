import SterlingApiClient, { DeleteRequestOptions, GetRequestOptions, PostRequestOptions } from '../../Sterling.ApiClient';
import {
    AddAnnotationBody,
    AddAnnotationKeywordBody,
    AddAnnotationTagBody,
    Annotation,
    Assertion,
    CreateAnnotationFromModifiedAutoAnnotationBody,
    Keyword,
    SetNeedsReviewBody,
    AcceptAnnotationStageBody,
    Tag,
    AddCommentBody,
    AnnotationStatus,
    AnnotationComment,
    AutoAnnotation,
} from '../Types';

export default class AnnotationsApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRouteAnnotations = (projectId: string, versionId: string) => `projects/${projectId}/versions/${versionId}/annotations`;
    _rootRouteTags = (projectId: string, versionId: string, annotationId: string) => `${this._rootRouteAnnotations(projectId, versionId)}/${annotationId}/tags`;
    _rootRouteAutoAnnotationsProcess = (projectId: string, versionId: string) => `${this._rootRouteAnnotations(projectId, versionId)}/autoAnnotationsProcess`;

    _rootRouteAnnotationWithoutVersion = (projectId: string, annotationId: string) => `projects/${projectId}/annotations/${annotationId}`;
    _rootRouteAssertions = (projectId: string, annotationId: string) => `${this._rootRouteAnnotationWithoutVersion(projectId, annotationId)}/assertions`;
    _rootRouteAssignments = (projectId: string, annotationId: string) => `${this._rootRouteAnnotationWithoutVersion(projectId, annotationId)}/assignments`;
    _rootRouteComments = (projectId: string, annotationId: string) => `${this._rootRouteAnnotationWithoutVersion(projectId, annotationId)}/comments`;
    _rootRouteKeywords = (projectId: string, annotationId: string) => `${this._rootRouteAnnotationWithoutVersion(projectId, annotationId)}/keywords`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    // _rootRouteAnnotations
    getAnnotations = (projectId: string, versionId: string, getRequestOptions?: GetRequestOptions<Array<Annotation>>) =>
        this._sterlingApiClient.getAsync<Array<Annotation>>(`${this._rootRouteAnnotations(projectId, versionId)}`, getRequestOptions);

    addAnnotation = (projectId: string, versionId: string, postRequestOptions: PostRequestOptions<AddAnnotationBody>) =>
        this._sterlingApiClient.postAsync<AddAnnotationBody>(`${this._rootRouteAnnotations(projectId, versionId)}`, postRequestOptions);

    deleteAnnotation = (projectId: string, versionId: string, annotationId: string, deleteRequestOptions: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteAnnotations(projectId, versionId)}/${annotationId}`, deleteRequestOptions);

    acceptAnnotationStage = (projectId: string, versionId: string, annotationId: string, postRequestOptions: PostRequestOptions<AcceptAnnotationStageBody>) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteAnnotations(projectId, versionId)}/${annotationId}/acceptStage`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Annotation state updated.'],
        });

    setNeedsReview = (projectId: string, versionId: string, annotationId: string, postRequestOptions?: PostRequestOptions<SetNeedsReviewBody>) =>
        this._sterlingApiClient.postAsync<SetNeedsReviewBody>(`${this._rootRouteAnnotations(projectId, versionId)}/${annotationId}/needsReview`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Needs review tag updated.'],
        });

    getAvailablePreviousStatuses = (
        projectId: string,
        versionId: string,
        annotationId: string,
        getRequestOptions?: GetRequestOptions<Array<AnnotationStatus>>
    ) =>
        this._sterlingApiClient.getAsync<Array<AnnotationStatus>>(
            `${this._rootRouteAnnotations(projectId, versionId)}/${annotationId}/availablePreviousStatuses`,
            getRequestOptions
        );

    getAutoAnnotations = (projectId: string, versionId: string, getRequestOptions?: GetRequestOptions<Array<AutoAnnotation>>) =>
        this._sterlingApiClient.getAsync<Array<AutoAnnotation>>(`${this._rootRouteAnnotations(projectId, versionId)}/autoAnnotations`, getRequestOptions);

    createAnnotationFromAutoAnnotation = (projectId: string, versionId: string, autoAnnotationId: string, postRequestOptions: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteAnnotations(projectId, versionId)}/autoAnnotations/${autoAnnotationId}`, postRequestOptions);

    createAnnotationFromModifiedAutoAnnotation = (
        projectId: string,
        versionId: string,
        autoAnnotationId: string,
        postRequestOptions: PostRequestOptions<CreateAnnotationFromModifiedAutoAnnotationBody>
    ) =>
        this._sterlingApiClient.postAsync<CreateAnnotationFromModifiedAutoAnnotationBody>(
            `${this._rootRouteAnnotations(projectId, versionId)}/autoAnnotations/${autoAnnotationId}/modified`,
            postRequestOptions
        );

    rejectAutoAnnotation = (projectId: string, versionId: string, autoAnnotationId: string, deleteRequestOptions: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteAnnotations(projectId, versionId)}/autoAnnotations/${autoAnnotationId}`, {
            ...deleteRequestOptions,
            toastSuccessMessages: ['Proposal removed.'],
        });

    reorderAnnotationsNumbers = (projectId: string, versionId: string, postRequestOptions: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteAnnotations(projectId, versionId)}/reorder`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Annotations numbers reordered.'],
        });

    // _rootRouteAssignments
    assignMember = (projectId: string, annotationId: string, memberId: string, postRequestOptions: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteAssignments(projectId, annotationId)}/members/${memberId}`, postRequestOptions);

    // _rootRouteAssertions
    getAnnotationAssertion = (projectId: string, annotationId: string, getRequestOptions: GetRequestOptions<Assertion>) =>
        this._sterlingApiClient.getAsync<Assertion>(`${this._rootRouteAssertions(projectId, annotationId)}`, getRequestOptions);

    addAnnotationAssertion = (projectId: string, annotationId: string, postRequestOptions: PostRequestOptions<Assertion>) =>
        this._sterlingApiClient.postAsync<Assertion>(`${this._rootRouteAssertions(projectId, annotationId)}`, postRequestOptions);

    // _rootRouteTags
    getAssignedTags = (projectId: string, versionId: string, annotationId: string, requestOptions?: GetRequestOptions<Array<Tag>>) =>
        this._sterlingApiClient.getAsync<Array<Tag>>(`${this._rootRouteTags(projectId, versionId, annotationId)}`, requestOptions);

    addTag = (projectId: string, versionId: string, annotationId: string, postRequestOptions?: PostRequestOptions<AddAnnotationTagBody>) =>
        this._sterlingApiClient.postAsync<AddAnnotationTagBody>(`${this._rootRouteTags(projectId, versionId, annotationId)}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Tag assigned to annotations.'],
        });

    removeTag = (projectId: string, versionId: string, annotationId: string, tagId: string, requestOptions?: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteTags(projectId, versionId, annotationId)}/${tagId}`, {
            ...requestOptions,
            toastSuccessMessages: ['Tag unpinned from annotation.'],
        });

    // _rootRouteKeywords
    getKeywords = (projectId: string, annotationId: string, requestOptions?: GetRequestOptions<Array<Keyword>>) =>
        this._sterlingApiClient.getAsync<Array<Keyword>>(`${this._rootRouteKeywords(projectId, annotationId)}`, requestOptions);

    addKeyword = (projectId: string, annotationId: string, postRequestOptions?: PostRequestOptions<AddAnnotationKeywordBody>) =>
        this._sterlingApiClient.postAsync<AddAnnotationKeywordBody>(`${this._rootRouteKeywords(projectId, annotationId)}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Keyword added to annotation.'],
        });

    removeKeyword = (projectId: string, annotationId: string, tagId: string, requestOptions?: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteKeywords(projectId, annotationId)}/${tagId}`, {
            ...requestOptions,
            toastSuccessMessages: ['Keyword removed from annotation.'],
        });

    removeAllKeywords = (projectId: string, annotationId: string, requestOptions?: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteKeywords(projectId, annotationId)}`, {
            ...requestOptions,
            toastSuccessMessages: ['Keywords removed from annotation.'],
        });

    // _rootRouteComments
    getComments = (projectId: string, annotationId: string, requestOptions?: GetRequestOptions<Array<AnnotationComment>>) =>
        this._sterlingApiClient.getAsync<Array<AnnotationComment>>(`${this._rootRouteComments(projectId, annotationId)}`, requestOptions);

    addComment = (projectId: string, annotationId: string, postRequestOptions: PostRequestOptions<AddCommentBody>) =>
        this._sterlingApiClient.postAsync<AddCommentBody>(`${this._rootRouteComments(projectId, annotationId)}`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Comment added.'],
        });

    resolveComment = (projectId: string, annotationId: string, commentId: string, postRequestOptions?: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteComments(projectId, annotationId)}/${commentId}/resolve`, {
            ...postRequestOptions,
            toastSuccessMessages: ['Comment resolved.'],
        });

    deleteComment = (projectId: string, annotationId: string, commentId: string, deleteRequestOptions?: DeleteRequestOptions) =>
        this._sterlingApiClient.deleteAsync(`${this._rootRouteComments(projectId, annotationId)}/${commentId}`, {
            ...deleteRequestOptions,
            toastSuccessMessages: ['Comment deleted.'],
        });

    // _rootRouteAutoAnnotationsProcess
    markAutoAnnotationsProcessErrorAsReceived = (projectId: string, versionId: string, postRequestOptions?: PostRequestOptions) =>
        this._sterlingApiClient.postAsync(`${this._rootRouteAutoAnnotationsProcess(projectId, versionId)}/markErrorAsReceived`, postRequestOptions);
}
