import React, { memo, useContext, useCallback, useMemo, useState } from 'react';
import { Box, Divider, IconButton, List, ListItem, ListItemButton, Typography } from '@mui/material';
import { Annotation, AnnotationNotification } from 'ApiClients/SterlingApiClients/Types';
import { AnnotationNotificationsDictionary } from 'Views/Project/ProjectWorkspace/Types';
import AnnotationStatusComponent from 'Views/Common/AnnotationStatus/AnnotationStatus.Component';
import { AnnotationNumberComponent, AnnotationNumberStates } from '../AnnotationsList.Component';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { ChatRight, FlagFill, IconType, Link45deg, Person, Quote, Tag, TrashBin } from 'UI/Icon';
import Tooltip from 'UI/Tooltip';
import { palette } from 'UI/Provider/VerifiTheme';
import { AnnotationStatusDictionary } from 'Views/Common/Types';
import _ from 'lodash';

type AnnotationComponentProps = {
    annotation: Annotation;
    selected: boolean;
    setSelectedAnnotationId: (annotationId: string) => void;
    openSuppInfoSummaryModal: (annotationId: string) => void;
    openTagAssignmentModal: (annotationId: string) => void;
    openAssertionModal: (annotationId: string) => void;
    openDeleteModal: (annotationId: string) => void;
    openAssignmentModal: (annotationId: string) => void;
    openCommentDrawer: (annotationId: string) => void;
};

type AnnotationUtil = {
    icon: IconType;
    onClick: (annotationId: string) => void;
    tooltip: string | ((annotation: Annotation) => string | JSX.Element);
    isActive?: (annotation: Annotation) => boolean;
    color?: string | null;
};

const notificationStyles = {
    [AnnotationNotification.NewAnnotation]: { backgroundColor: palette.success.light },
    [AnnotationNotification.UpdatedAnnotation]: { backgroundColor: palette.attention.medium },
};

function AnnotationComponent(props: AnnotationComponentProps) {
    const { isEditor } = useContext(ProjectAuthContext);
    const {
        annotation,
        selected,
        setSelectedAnnotationId,
        openAssertionModal,
        openAssignmentModal,
        openDeleteModal,
        openSuppInfoSummaryModal,
        openTagAssignmentModal,
        openCommentDrawer,
    } = props;

    const [isHovered, setIsHovered] = useState(false);

    const handleSetSelectedAnnotationId = useCallback(() => {
        setSelectedAnnotationId(annotation.id);
    }, [setSelectedAnnotationId, annotation.id]);

    const tagsTooltip = useCallback((annotation: Annotation): JSX.Element => {
        const { tags } = annotation;

        if (tags.length > 0) {
            return (
                <Box>
                    <Typography variant='tooltip'>Assigned tags</Typography>
                    <Divider />
                    <List>
                        {tags.map((tag) => (
                            <React.Fragment key={tag.id}>
                                <ListItem>
                                    <Typography variant='tooltip'>{tag.name}</Typography>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            );
        } else {
            return <span>Add tag</span>;
        }
    }, []);

    const annotationUtils = useMemo(() => {
        let utils: AnnotationUtil[] = [
            {
                icon: Link45deg,
                onClick: openSuppInfoSummaryModal,
                tooltip: 'Linked information',
                isActive: (a) => a.isAnySupportingInformationLinked,
            },
        ];

        if (isEditor) {
            utils.push({
                icon: Tag,
                onClick: openTagAssignmentModal,
                tooltip: (a) => tagsTooltip(a),
                isActive: (a) => a.tags.length > 0,
            });
        }

        utils.push({
            icon: Quote,
            onClick: openAssertionModal,
            tooltip: 'Assertion',
            isActive: (a) => a.assertion !== null,
        });

        utils.push({
            icon: ChatRight,
            onClick: openCommentDrawer,
            tooltip: 'Comments',
            isActive: () => false,
            color: annotation.needsReview ? palette.attention.high : null,
        });

        if (isEditor) {
            utils.push({ icon: TrashBin, onClick: openDeleteModal, tooltip: 'Delete Annotation' });
        }

        return utils;
    }, [
        isEditor,
        openSuppInfoSummaryModal,
        openTagAssignmentModal,
        openAssertionModal,
        openDeleteModal,
        tagsTooltip,
        openCommentDrawer,
        annotation.needsReview,
    ]);

    const iconState = AnnotationStatusDictionary[annotation.status];

    return (
        <ListItemButton
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '0.5rem 1rem',
                boxSizing: 'border-box',
                gap: 1,
                '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: (theme) => theme.palette.blue.light,
                    borderLeft: (theme) => `0.25rem solid ${theme.palette.blue.dark}`,
                    paddingLeft: '0.75rem',
                },
            }}
            onClick={handleSetSelectedAnnotationId}
            selected={selected}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} data-testid='annotation-header'>
                    <AnnotationNumberComponent
                        orderNumber={annotation?.orderNumber ?? 1}
                        selected={selected}
                        state={isHovered ? AnnotationNumberStates.HOVER : selected ? AnnotationNumberStates.PRESSED : AnnotationNumberStates.PRIMARY}
                    />
                    <iconState.icon width={16} height={16} color={iconState.color} />
                    {annotation.needsReview && <FlagFill color={palette.attention.high} />}
                    {annotation.notificationType !== 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                width: 'auto',
                                height: '1rem',
                                alignItems: 'center',
                                padding: '0 0.25rem',
                                borderRadius: '0.25rem',
                                border: (theme) => `1px solid ${theme.palette.stroke.main}`,
                                ...notificationStyles[annotation.notificationType],
                            }}
                        >
                            <Typography
                                variant='tooltip'
                                sx={{
                                    fontWeight: 400,
                                    color: palette.white.main,
                                }}
                            >
                                {AnnotationNotificationsDictionary[annotation.notificationType].name}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <AnnotationStatusComponent statusNameEnabled={false} status={annotation.status} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={() => {
                        openAssignmentModal(annotation.id);
                    }}
                >
                    <IconButton>
                        <Person color={palette.text.main} />
                    </IconButton>
                    <Typography variant='caption'>{annotation.assignedMember ? annotation.assignedMember.fullName : 'Unassigned'}</Typography>
                </Box>

                <Box sx={{ display: 'flex', marginRight: '-0.25rem' }}>
                    {annotationUtils.map((util, idxU) => {
                        const tooltip = typeof util.tooltip == 'string' ? util.tooltip : util.tooltip(annotation);
                        return (
                            <Tooltip title={tooltip} key={`annotation-utils-${idxU}`}>
                                <IconButton
                                    size='small'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        util.onClick(annotation.id);
                                    }}
                                    sx={{
                                        color: util.isActive && util.isActive(annotation) ? palette.blue.dark : palette.stroke.main,
                                        ...(util.color && { color: util.color }),
                                    }}
                                >
                                    <util.icon width={16} height={16} />
                                </IconButton>
                            </Tooltip>
                        );
                    })}
                </Box>
            </Box>
        </ListItemButton>
    );
}

export default memo(AnnotationComponent, (prevProps, nextProps) => {
    const isAnnotationEqual = _.isEqual(prevProps.annotation, nextProps.annotation);
    const isSelectedEqual = prevProps.selected === nextProps.selected;

    return isAnnotationEqual && isSelectedEqual;
});
