import { Routes, Route, Outlet } from 'react-router-dom';

import NavBarComponent, { NavBarContents, NavBarHeight } from './NavBar/NavBar.Component';
import LoginModule from '../Views/Login/Login.Module';

import HomePageModule from '../Views/HomePage/HomePage.Module';
import ProjectWorkspaceVersionWrapper from 'Views/Project/ProjectWorkspace/ProjectWorkspace.VersionWrapper';
import ProjectsApiClient from '../ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import ProjectDocumentsApiClient from '../ApiClients/SterlingApiClients/ProjectDocumentsApiClient/ProjectDocuments.ApiClient';

import { AuthContextProvider } from './../Contexts/Auth.Context';
import AnnotationsApiClient from '../ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import SupportingInformationApiClient from 'ApiClients/SterlingApiClients/SupportingInformationApiClient/SupportingInformation.ApiClient';
import ProjectDashboardVersionWrapper from 'Views/Project/ProjectDashboard/ProjectDashboard.VersionWrapper';
import ReportsApiClient from 'ApiClients/SterlingApiClients/ReportsApiClient/Reports.ApiClient';
import SterlingApiClient from 'ApiClients/Sterling.ApiClient';
import NotificationsApiClient from 'ApiClients/SterlingApiClients/NotificationsApiClient/Notifications.ApiClient';
import { useMemo, useRef } from 'react';
import useAuth from 'Hooks/useAuth';
import ProjectCreatorModule from 'Views/Project/ProjectCreator/ProjectCreator.Module';
import UsersApiClient from 'ApiClients/SterlingApiClients/UsersApiClient/Users.ApiClient';
import AuthApiClient from 'ApiClients/Auth.ApiClient';
import AuditLogsApiClient from 'ApiClients/SterlingApiClients/AuditLogsApiClient/AuditLogs.ApiClient';
import ToastsComponent from './Toasts/Toasts.Component';
import useToasts from 'Hooks/useToasts';
import NotificationsConnector from './Notifications/NotificationsConnector';
import { ThemeProvider } from 'UI';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import { default as ProjectVersionsListModule } from 'Views/Project/VersionsList/VersionsList.Module';
import { default as NewProjectVersionModule } from 'Views/Project/NewVersionForm/NewVersionForm.Module';
import { AppRoutes } from './RoutesPaths';
import ProjectVersionsApiClient from 'ApiClients/SterlingApiClients/ProjectVersionsApiClient/ProjectVersions.ApiClient';
import FilesApiClient from 'ApiClients/SterlingApiClients/FilesApiClient/Files.ApiClient';
import LoginRedirectModule from './LoginRedirect/LoginRedirect.Module';
import BlankPageComponent from './BlankPage/BlankPage.Component';

function App() {
    const { addToast } = useToasts();
    const authApiClient = useMemo(() => new AuthApiClient(), []);
    const authContextProviderValue = useAuth();
    const sterlingApiClient = useMemo(() => new SterlingApiClient(authApiClient, addToast), [authApiClient, addToast]);
    const errorHandlerRegistration = useMemo(() => sterlingApiClient.errorHandlerRegistration, [sterlingApiClient]);
    const projectsApiClient = useMemo(() => new ProjectsApiClient(sterlingApiClient), [sterlingApiClient]);
    const projectVersionsApiClient = useMemo(() => new ProjectVersionsApiClient(sterlingApiClient), [sterlingApiClient]);
    const projectDocumentsApiClient = useMemo(() => new ProjectDocumentsApiClient(sterlingApiClient), [sterlingApiClient]);
    const annotationsApiClient = useMemo(() => new AnnotationsApiClient(sterlingApiClient), [sterlingApiClient]);
    const supportingInformationApiClient = useMemo(() => new SupportingInformationApiClient(sterlingApiClient), [sterlingApiClient]);
    const reportsApiClient = useMemo(() => new ReportsApiClient(sterlingApiClient), [sterlingApiClient]);
    const notificationsApiClient = useMemo(() => new NotificationsApiClient(sterlingApiClient), [sterlingApiClient]);
    const usersApiClient = useMemo(() => new UsersApiClient(sterlingApiClient), [sterlingApiClient]);
    const auditLogsApiClient = useMemo(() => new AuditLogsApiClient(sterlingApiClient), [sterlingApiClient]);
    const filesApiClient = useMemo(() => new FilesApiClient(sterlingApiClient), [sterlingApiClient]);
    const notificationsConnector = useMemo(
        () => new NotificationsConnector(authApiClient, authContextProviderValue.isAuthenticated),
        [authApiClient, authContextProviderValue.isAuthenticated]
    );

    const navBarContents: NavBarContents = {
        left: useRef<HTMLDivElement>(null),
        center: useRef<HTMLDivElement>(null),
        right: useRef<HTMLDivElement>(null),
    };

    return (
        <AuthContextProvider authContextProviderValue={authContextProviderValue}>
            <>
                <ThemeProvider>
                    <CssBaseline />
                    <ToastsComponent />
                    <Box sx={{ height: '100vh' }}>
                        <NavBarComponent navBarContents={navBarContents} logout={authApiClient.logout} />
                        <Box sx={{ height: NavBarHeight }} />
                        <Box sx={{ overflow: 'auto', height: `calc(100% - ${NavBarHeight})`, minWidth: '80rem', minHeight: '33.75rem' }}>
                            {authContextProviderValue.isAuthenticated ? (
                                <Routes>
                                    <Route
                                        path={AppRoutes.homePage.pathTemplate}
                                        element={
                                            <HomePageModule
                                                navBarContents={navBarContents}
                                                projectsApiClient={projectsApiClient}
                                                usersApiClient={usersApiClient}
                                            />
                                        }
                                    />
                                    <Route path={AppRoutes.project.pathTemplate} element={<Outlet />}>
                                        <Route
                                            path={AppRoutes.project.creator.pathTemplate}
                                            element={<ProjectCreatorModule projectsApiClient={projectsApiClient} />}
                                        />
                                    </Route>
                                    <Route path={AppRoutes.projectInstance.pathTemplate} element={<Outlet />}>
                                        <Route path={AppRoutes.projectInstance.projectVersion.pathTemplate} element={<Outlet />}>
                                            <Route
                                                path={AppRoutes.projectInstance.projectVersion.list.pathTemplate}
                                                element={
                                                    <ProjectVersionsListModule
                                                        projectsApiClient={projectsApiClient}
                                                        projectVersionsApiClient={projectVersionsApiClient}
                                                    />
                                                }
                                            />
                                            <Route
                                                path={AppRoutes.projectInstance.projectVersion.creator.pathTemplate}
                                                element={
                                                    <NewProjectVersionModule
                                                        mode='create'
                                                        navBarContents={navBarContents}
                                                        projectsApiClient={projectsApiClient}
                                                        projectDocumentsApiClient={projectDocumentsApiClient}
                                                        projectVersionsApiClient={projectVersionsApiClient}
                                                        notificationsConnector={notificationsConnector}
                                                        annotationsApiClient={annotationsApiClient}
                                                        filesApiClient={filesApiClient}
                                                    />
                                                }
                                            />
                                            <Route
                                                path={AppRoutes.projectInstance.projectVersion.draft.pathTemplate}
                                                element={
                                                    <NewProjectVersionModule
                                                        mode='edit'
                                                        navBarContents={navBarContents}
                                                        projectsApiClient={projectsApiClient}
                                                        projectDocumentsApiClient={projectDocumentsApiClient}
                                                        projectVersionsApiClient={projectVersionsApiClient}
                                                        notificationsConnector={notificationsConnector}
                                                        annotationsApiClient={annotationsApiClient}
                                                        filesApiClient={filesApiClient}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route path={AppRoutes.projectInstance.projectVersionInstance.pathTemplate} element={<Outlet />}>
                                            <Route
                                                path={AppRoutes.projectInstance.projectVersionInstance.workspace.pathTemplate}
                                                element={
                                                    <ProjectWorkspaceVersionWrapper
                                                        navBarContents={navBarContents}
                                                        projectsApiClient={projectsApiClient}
                                                        projectDocumentsApiClient={projectDocumentsApiClient}
                                                        annotationsApiClient={annotationsApiClient}
                                                        supportingInformationApiClient={supportingInformationApiClient}
                                                        notificationsApiClient={notificationsApiClient}
                                                        usersApiClient={usersApiClient}
                                                        auditLogsApiClient={auditLogsApiClient}
                                                        notificationsConnector={notificationsConnector}
                                                        errorHandlerRegistration={errorHandlerRegistration}
                                                    />
                                                }
                                            />
                                            <Route
                                                path={AppRoutes.projectInstance.projectVersionInstance.dashboard.pathTemplate}
                                                element={
                                                    authContextProviderValue.checkRole('project_creator') ? (
                                                        <ProjectDashboardVersionWrapper
                                                            navBarContents={navBarContents}
                                                            reportsApiClient={reportsApiClient}
                                                            projectsApiClient={projectsApiClient}
                                                            auditLogsApiClient={auditLogsApiClient}
                                                        />
                                                    ) : (
                                                        <div />
                                                    )
                                                }
                                            />
                                        </Route>
                                    </Route>
                                    <Route
                                        path={AppRoutes.loginRedirect.pathTemplate}
                                        element={<LoginRedirectModule authApiClient={authApiClient} setAuth={authContextProviderValue.setAuth} />}
                                    />
                                    <Route path={'*'} element={<BlankPageComponent />} />
                                </Routes>
                            ) : (
                                <Routes>
                                    <Route
                                        path={AppRoutes.loginRedirect.pathTemplate}
                                        element={<LoginRedirectModule authApiClient={authApiClient} setAuth={authContextProviderValue.setAuth} />}
                                    />
                                    <Route path={'*'} element={<LoginModule authApiClient={authApiClient} setAuth={authContextProviderValue.setAuth} />} />
                                </Routes>
                            )}
                        </Box>
                    </Box>
                </ThemeProvider>
            </>
        </AuthContextProvider>
    );
}

export default App;
