import ProjectsApiClient from 'ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import ProjectCreatorComponent from './ProjectCreator.Component';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { AppRoutes, SearchRoutes } from 'App/RoutesPaths';
import { ProjectConfigParam, ProjectDocumentType, TransactionType } from 'ApiClients/SterlingApiClients/Types';
import { ActiveProjectVersionId } from 'App/RoutesPaths/AppRoutes';

type ProjectCreatorModuleProps = {
    projectsApiClient: ProjectsApiClient;
};

function ProjectCreatorModule(props: ProjectCreatorModuleProps) {
    const { projectsApiClient: projectsApi } = props;
    const [uploading, setUploading] = useState<boolean>(false);

    const navigate = useNavigate();
    const goToProjectWorkspace = (projectId: string) =>
        navigate(
            `${AppRoutes.projectInstance.projectVersionInstance.workspace.specificPath({ projectId, versionId: ActiveProjectVersionId })}${
                SearchRoutes.goToEditProjectMembers.hash
            }`
        );

    const addProject = (
        name: string,
        file: File,
        transactionType: TransactionType,
        configParams: Array<ProjectConfigParam>,
        documentType: ProjectDocumentType
    ) =>
        projectsApi.addProject({
            body: { name, verificationDocument: file, transactionType, configParams, documentType },
            setFetching: setUploading,
            onSuccess: (projectId) => goToProjectWorkspace(projectId),
        });

    return <ProjectCreatorComponent addProject={addProject} uploading={uploading} />;
}

export default ProjectCreatorModule;
