import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { ProjectMember, ProjectMemberRole } from 'ApiClients/SterlingApiClients/Types';
import { AuthContext } from 'Contexts/Auth.Context';
import { ProjectAuthContextProviderValue } from 'Contexts/ProjectAuth.Context';
import { useContext, useEffect, useState } from 'react';

type ProjectAuthProps = {
    getMyProjectMemberInstance: (projectId: string, flowMethods?: FlowMethods<ProjectMember>) => Promise<ProjectMember>;
    projectId: string | undefined;
};

const useProjectAuth = (props: ProjectAuthProps): ProjectAuthContextProviderValue => {
    const { isAuthenticated } = useContext(AuthContext);
    const [projectMember, setProjectMember] = useState<ProjectMember | null>(null);
    const { projectId, getMyProjectMemberInstance } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (isAuthenticated && projectId) {
            getMyProjectMemberInstance(projectId, { setFetching: setIsLoading, onSuccess: setProjectMember });
        }
        // eslint-disable-next-line
    }, [projectId, isAuthenticated]);

    return {
        projectId,
        projectMember,
        isEditor: projectMember?.role === ProjectMemberRole.Editor,
        isLoading,
    };
};

export default useProjectAuth;
