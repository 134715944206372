import { useEffect, useRef } from 'react';
import { BoundingBoxSection } from 'ApiClients/SterlingApiClients/Types';
import HighlightButtonComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightButton/HighlightButton.Component';
import { NewHighlightToolbarOptions, ContentType } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

export type NewAnnotationPanelWrapperComponentProps = {
    newHighlightToolbarOptions: NewHighlightToolbarOptions;
    addAnnotation: (contentType: ContentType, boundingBoxSections: BoundingBoxSection[], statement: string) => void;
};

function NewAnnotationPanelWrapperComponent(props: NewAnnotationPanelWrapperComponentProps) {
    const { newHighlightToolbarOptions, addAnnotation } = props;

    const { setHighlightToolbarRef } = newHighlightToolbarOptions;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    let panel: JSX.Element = (
        <HighlightButtonComponent
            rootRef={ref}
            content='CREATE ANNOTATION'
            onClick={async () => {
                const newHighlight = await newHighlightToolbarOptions.getNewHighlight();
                addAnnotation && addAnnotation(newHighlight.contentType, newHighlight.boundingBoxSections, newHighlight.statement);
                newHighlightToolbarOptions.clearSelection();
            }}
        />
    );
    return <div ref={ref}>{panel}</div>;
}

export default NewAnnotationPanelWrapperComponent;
