import React, { memo, useCallback, useMemo, useState } from 'react';
import { Box, IconButton, ListItemButton } from '@mui/material';
import { IconType, Magic, TrashBin } from 'UI/Icon';
import { palette } from 'UI/Provider/VerifiTheme';
import { Tooltip } from 'UI';
import { AnnotationNumberComponent, AnnotationNumberStates } from '../AnnotationsList.Component';
import _ from 'lodash';
import { AutoAnnotation } from 'ApiClients/SterlingApiClients/Types';

type AutoAnnotationComponentProps = {
    autoAnnotation: AutoAnnotation;
    selected: boolean;
    setSelectedAutoAnnotationId: (autoAnnotationId: string) => void;
    rejectAutoAnnotation: (autoAnnotationId: string) => void;
};

type AnnotationUtil = {
    icon: IconType;
    onClick: (id: string) => void;
    tooltip: ((annotation: AutoAnnotation) => string | JSX.Element) | string;
    isActive?: (annotation: AutoAnnotation) => boolean;
};

function AutoAnnotationComponent(props: AutoAnnotationComponentProps) {
    const { autoAnnotation, selected, setSelectedAutoAnnotationId, rejectAutoAnnotation } = props;
    const [isHovered, setIsHovered] = useState(false);

    const handleSetSelectedAutoAnnotationId = useCallback(() => {
        setSelectedAutoAnnotationId(autoAnnotation.id);
    }, [setSelectedAutoAnnotationId, autoAnnotation.id]);

    const iconOnClick = (event: React.MouseEvent<HTMLButtonElement>, id: string, func: (id: string) => void) => {
        func(id);
        event.stopPropagation();
    };

    const autoAnnotationUtils: AnnotationUtil[] = useMemo(
        () => [
            {
                icon: TrashBin,
                onClick: rejectAutoAnnotation,
                tooltip: 'Reject proposal',
            },
        ],
        [rejectAutoAnnotation]
    );

    return (
        <ListItemButton
            id={`annotations-list-element-${autoAnnotation.id}`}
            onClick={handleSetSelectedAutoAnnotationId}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                padding: '1rem 1rem',
                '&:hover': {
                    backgroundColor: 'primary.lighter',
                },
                gap: 1,
                '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: (theme) => theme.palette.blue.light,
                    borderLeft: (theme) => `0.25rem solid ${theme.palette.blue.dark}`,
                    paddingLeft: '0.75rem',
                },
            }}
            selected={selected}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} data-testid='annotation-header'>
                <AnnotationNumberComponent
                    orderNumber={autoAnnotation.orderNumber}
                    selected={selected}
                    state={isHovered ? AnnotationNumberStates.HOVER : selected ? AnnotationNumberStates.PRESSED : AnnotationNumberStates.PRIMARY}
                />
                <Magic color={palette.text.main} />
            </Box>

            <Box sx={{ display: 'flex', marginRight: '-0.25rem' }}>
                {autoAnnotationUtils.map((util, idxU) => {
                    const tooltip = typeof util.tooltip == 'string' ? util.tooltip : util.tooltip(autoAnnotation);
                    return (
                        <Tooltip key={`al-annotation-top-content-actions-icon-${idxU}`} title={tooltip}>
                            <IconButton
                                size='small'
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => iconOnClick(e, autoAnnotation.id, util.onClick)}
                                sx={{
                                    color: util.isActive && util.isActive(autoAnnotation) ? palette.blue.dark : palette.stroke.main,
                                }}
                            >
                                <util.icon />
                            </IconButton>
                        </Tooltip>
                    );
                })}
            </Box>
        </ListItemButton>
    );
}

export default memo(AutoAnnotationComponent, (prevProps, nextProps) => {
    if (!_.isEqual(prevProps.autoAnnotation, nextProps.autoAnnotation) || prevProps.selected !== nextProps.selected) return false;
    else return true;
});
