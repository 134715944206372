import { useEffect, useRef, useState } from 'react';
import { ModifiedHighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import AnnotationTransferPanelComponent from '../Common/AnnotationTransferPanel/AnnotationTransferPanel.Component';
import { CreateAnnotationFromModifiedTransferPropositionBody } from 'ApiClients/SterlingApiClients/Types';
import { getSelectionType } from 'Views/Project/ProjectWorkspace/Types';

export type ModyfingPropositionTransferPanelWrapperComponentProps = {
    modifiedHighlightToolbarOptions: ModifiedHighlightToolbarOptions;
    create: (setFetching: (isFetching: boolean) => void, body: CreateAnnotationFromModifiedTransferPropositionBody) => void;
    reject: (setFetching: (isFetching: boolean) => void) => void;
    onlyReject: boolean;
};

function ModyfingPropositionTransferPanelWrapperComponent(props: ModyfingPropositionTransferPanelWrapperComponentProps) {
    const { modifiedHighlightToolbarOptions, create, reject, onlyReject } = props;

    const { setHighlightToolbarRef: setRef, getModifiedHighlight, clearSelection, modifyHighlight } = modifiedHighlightToolbarOptions;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setRef(ref);
    }, [setRef]);

    const [fetching, setFetching] = useState<boolean>(false);

    return (
        <div ref={ref}>
            <AnnotationTransferPanelComponent
                create={async () => {
                    const highlight = await getModifiedHighlight();
                    create(setFetching, {
                        statement: highlight.statement,
                        selectionType: getSelectionType(highlight.contentType),
                        boundingBoxSections: highlight.boundingBoxSections,
                    });
                    clearSelection();
                }}
                modify={() => modifyHighlight()}
                reject={async () => reject(setFetching)}
                onlyReject={onlyReject}
                fetching={fetching}
            />
        </div>
    );
}

export default ModyfingPropositionTransferPanelWrapperComponent;
