import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material';
import RestoreModalComponent, { RestoreModalState } from './Modals/RestoreModal/RestoreModal.Component';
import { BackButton, Button, Icon, Typography } from 'UI';
import { ProjectSimple, ProjectVersionListRecord, ProjectVersionStatus } from 'ApiClients/SterlingApiClients/Types';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { AppRoutes } from 'App/RoutesPaths';
import { ActiveProjectVersionId } from 'App/RoutesPaths/AppRoutes';
import VersionsTablesComponent from './VersionsTables/VersionsTables.Component';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';

export type VersionsListComponentProps = {
    project: ProjectSimple;
    isFetching: boolean;
    versions: Array<ProjectVersionListRecord>;
    restoreVersion: (versionId: string, flowMethods: FlowMethods) => void;
    deleteDraftVersion: (versionId: string) => void;
};

function VersionsListComponent(props: VersionsListComponentProps) {
    const { project, versions, isFetching, restoreVersion, deleteDraftVersion } = props;
    const [restoreModalState, setRestoreModalState] = useState<RestoreModalState>({ isOpen: false, version: null });
    const navigate = useNavigate();

    const openRestoreModal = useCallback((version: ProjectVersionListRecord) => setRestoreModalState({ isOpen: true, version }), [setRestoreModalState]);
    const goToProjectVersionWorkspace = useCallback(
        (version: ProjectVersionListRecord) =>
            navigate(
                AppRoutes.projectInstance.projectVersionInstance.workspace.specificPath({
                    projectId: project.id,
                    versionId: version.id,
                })
            ),
        [navigate, project.id]
    );
    const goToProjectVersionWorkspaceActive = useCallback(
        () =>
            navigate(
                AppRoutes.projectInstance.projectVersionInstance.workspace.specificPath({
                    projectId: project.id,
                    versionId: ActiveProjectVersionId,
                })
            ),
        [navigate, project.id]
    );
    const goToDraft = useCallback(
        (version: ProjectVersionListRecord) =>
            navigate(
                AppRoutes.projectInstance.projectVersion.draft.specificPath({
                    projectId: project.id,
                    draftId: version.id,
                })
            ),
        [navigate, project.id]
    );

    return (
        <Box
            sx={{
                minHeight: '20rem',
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: (theme) => theme.palette.body.main,
            }}
        >
            <RestoreModalComponent
                state={restoreModalState}
                closeModal={() => setRestoreModalState({ isOpen: false, version: null })}
                restore={(version) => restoreVersion(version.id, { onSuccess: () => setRestoreModalState({ isOpen: false, version: null }) })}
            />
            <Box>
                <BackButton sx={{ marginTop: '1rem', height: '1.5rem' }} onClick={goToProjectVersionWorkspaceActive} data-testid='back-button'>
                    <Typography variant='link12'>Back to Project Workspace</Typography>
                </BackButton>
                <Card
                    variant='outlined'
                    sx={{
                        minWidth: '80rem',
                        height: 'calc(100% - 2.5rem - 1rem - 2rem)', // 2.5rem - back button, 1rem - mt, 2rem - mb
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '1.5rem',
                        marginTop: '1rem',
                        overflow: 'scroll',
                    }}
                    data-testid='project-card'
                >
                    <CardHeader
                        title={'Document version list'}
                        titleTypographyProps={{ variant: 'title', fontSize: 24, fontWeight: 700 }}
                        sx={{ justifyContent: 'space-between' }}
                        data-testid='card-header'
                        action={
                            <Button
                                variant='outlined'
                                startIcon={<Icon.CloudUpload width={16} height={16} />}
                                onClick={() => navigate(AppRoutes.projectInstance.projectVersion.creator.specificPath({ projectId: project.id }))}
                                disabled={versions.some((version) => version.status === ProjectVersionStatus.Draft)}
                            >
                                Upload New Version
                            </Button>
                        }
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: 0 }} data-testid='card-content'>
                        <Divider />
                        <VersionsTablesComponent
                            versions={versions}
                            isFetching={isFetching}
                            restore={openRestoreModal}
                            goToProjectVersionWorkspaceActive={goToProjectVersionWorkspaceActive}
                            goToProjectVersionWorkspace={goToProjectVersionWorkspace}
                            editDraftVersion={goToDraft}
                            deleteDraft={(version) => deleteDraftVersion(version.id)}
                        />
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
}

export default VersionsListComponent;
