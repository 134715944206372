import { AutoAnnotationsProcessStatus, ProjectDocumentProcessingStatus, ProjectVersionSimple, User } from '../Types';

export type ProjectSimple = {
    id: string;
    name: string;
    workingVersion: ProjectVersionSimple;
};

export type ProjectListEntry = {
    id: string;
    name: string;
    creationDate: Date;
    createdBy: User | null;
    annotationsStatistics: ProjectAnnotationsStatistics | null;
    lastModifiedDate: Date;
    isEditor: boolean;
    documentType: ProjectDocumentType;
    verificationDocumentProcessingStatus: ProjectDocumentProcessingStatus;
    anySupportingDocumentsProcessingErrors: boolean;
    autoAnnotationsProcessStatus: AutoAnnotationsProcessStatus | null;
    isProjectLeader: boolean;
};

export type ProjectAnnotationsStatistics = {
    annotateStatus: AnnotationStatisticsStatus;
    verifyStatus: AnnotationStatisticsStatus;
    approveStatus: AnnotationStatisticsStatus;
    verifiedStatus: AnnotationStatisticsStatus;
    all: AnnotationStatisticsStatus;
};

export type AnnotationStatisticsStatus = {
    count: number;
    needsReviewCount: number;
};

export enum ProjectDocumentType {
    Other = 0,
    RnsAnnouncement = 1,
    OfferDocument = 2,
    SchemeDocument = 3,
    ResponseDocument = 4,
    Circular = 5,
    Presentation = 6,
    PressRelease = 7,
    Prospectus = 8,
}

export type ProjectMember = {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    role: ProjectMemberRole;
    email: string;
    userId: string;
};

export type ProjectMemberExtended = ProjectMember & {
    isProjectLeader: boolean;
};

export type AddProjectBody = {
    name: string;
    verificationDocument: File | null;
    transactionType: TransactionType;
    configParams: Array<ProjectConfigParam>;
    documentType: ProjectDocumentType;
};

export type ProjectConfigParam = {
    type: ProjectConfigParamEnum;
    value: string;
};

export enum ProjectConfigParamEnum {
    Target = 1,
    Bidco = 2,
    Bidco_Parent = 3,
    Bidco_ParentAdditional = 4,
}

export enum TransactionType {
    Other = 0,
    TakeOver = 1,
}

export type ChangeProjectNameBody = {
    name: string;
};

export type AddMemberBody = {
    userId: string;
    role: ProjectMemberRole;
};

export type ChangeMemberRoleBody = {
    role: ProjectMemberRole;
};

export enum ProjectMemberRole {
    Editor = 1,
    Contributor = 2,
}

export type ProjectTag = {
    id: string;
    name: string;
    numberOfAnnotations: number;
};

export type AddTagBody = {
    tagName: string;
};
