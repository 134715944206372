import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useEffect, useState } from 'react';
import { AnnotationAttributeType, getHighlightId, getOriginalHighlightId, getParentHighlightId } from './Drawing/ViewerAnnotations/Attributes';
import {
    AnnotationModifyingProp,
    AnnotationModifyingState,
    AvailableToolMode,
    InitAnnotationModifyingProp,
    MultiSelectProp,
} from '../../PdfViewerWithToolbar.Component';
import Log from '../../Logger';

const useAnnotationModifying = (
    webViewerInstance: WebViewerInstance | null,
    setToolMode: (viewerMode: Core.Tools.ToolNames, mode: AvailableToolMode) => void,
    selectedHighlightId: string | null,
    multiSelectProp: MultiSelectProp,
    initModifyAnnotation?: InitAnnotationModifyingProp
): AnnotationModifyingProp => {
    const [annotationModifyingState, setAnnotationModifyingState] = useState<AnnotationModifyingState>({
        active: false,
        originalHighlightId: null,
        originalHighlightColor: null,
        originalHighlightBorderColor: null,
    });

    useEffect(() => {
        if (webViewerInstance && annotationModifyingState.active && !multiSelectProp.next) {
            const annotationManager = webViewerInstance.Core.annotationManager;
            const annots = annotationManager
                .getAnnotationsList()
                .filter(
                    (a) =>
                        getHighlightId(a) === annotationModifyingState.originalHighlightId ||
                        getOriginalHighlightId(a) === annotationModifyingState.originalHighlightId
                );
            Log('HOOK', ['useAnnotationModifying', 'deleteAnnotations'], [annots]);
            annotationManager.deleteAnnotations(annots);
            setToolMode(webViewerInstance.Core.Tools.ToolNames.HIGHLIGHT, 'AnnotationCreateTextHighlight');
        }
        // eslint-disable-next-line
    }, [webViewerInstance, annotationModifyingState, setToolMode]);

    useEffect(() => {
        if (webViewerInstance && selectedHighlightId && initModifyAnnotation?.id) {
            const highlightId = initModifyAnnotation.id;
            const allAnnotations = webViewerInstance.Core.annotationManager.getAnnotationsList();
            const annot = allAnnotations.filter(
                (a) => getHighlightId(a) === highlightId || getOriginalHighlightId(a) === highlightId
            )[0] as Core.Annotations.TextHighlightAnnotation;
            const border = allAnnotations.filter(
                (a) => getParentHighlightId(a) === highlightId && AnnotationAttributeType.get(a) === 'border'
            )[0] as Core.Annotations.LineAnnotation;
            setAnnotationModifyingState({
                active: true,
                originalHighlightId: highlightId,
                originalHighlightColor: annot.StrokeColor,
                originalHighlightBorderColor: border?.StrokeColor || null,
            });
            initModifyAnnotation.callBack();
        }
    }, [webViewerInstance, selectedHighlightId, initModifyAnnotation]);

    return {
        annotationModifyingState,
        setAnnotationModifyingState,
    };
};

export default useAnnotationModifying;
