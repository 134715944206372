import { CompareResult } from 'Views/Common/PdfViewerMulti/Hooks/useCompare';
import { useState } from 'react';

export type CompareState = {
    comparing: boolean;
    setComparing: (value: boolean) => void;
    docsCompareResult: CompareResult | null;
    setDocsCompareResult: (value: CompareResult | null) => void;
};

const useCompareState = (): CompareState => {
    const [comparing, setComparing] = useState<boolean>(false);
    const [docsCompareResult, setDocsCompareResult] = useState<CompareResult | null>(null);

    return {
        comparing,
        setComparing,
        docsCompareResult,
        setDocsCompareResult,
    };
};

export default useCompareState;
