import { SelectionType } from 'ApiClients/SterlingApiClients/Types';
import { ContentType } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

const SelectionTypeDict: { [key in SelectionType]: ContentType } = {
    0: 'text',
    1: 'shape',
};

export const getSelectionType = (contentType: ContentType) =>
    Number.parseInt(Object.keys(SelectionTypeDict).find((key) => SelectionTypeDict[key as any as SelectionType] === contentType) || '0');

export const getContentType = (selectionType: SelectionType) => SelectionTypeDict[selectionType];
