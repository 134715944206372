import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { useLayoutEffect, useRef, useState } from 'react';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { AnnotationStatus, VerificationNote } from 'ApiClients/SterlingApiClients/Types';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
import { AnnotationStatusDictionary } from 'Views/Common/Types';
import { Button, Dialog, Tabs, Typography } from 'UI';
import { Box } from '@mui/material';
import DocumentsTableComponent from './DocumentsTable/DocumentsTable.Component';
import AnnotationsTableComponent from './AnnotationsTable/AnnotationsTable.Component';

export type VerificationNoteModalComponentProps = {
    isOpen: boolean;
    closeModal: () => void;
    getVerificationNote: (flowMethods: FlowMethods<VerificationNote>) => void;
    projectId: string;
    projectVersionIdUrlParam: string;
};

type TabsType = 'annotations-table' | 'documents-table';

function VerificationNoteModalComponent(props: VerificationNoteModalComponentProps) {
    const { isOpen, closeModal, projectId, projectVersionIdUrlParam } = props;
    const [documentDownloading, setDocumentDownloading] = useState<boolean>(false);

    const { data: verificationNote, fetching } = usePromiseWithFlowMethods<{}, VerificationNote | null>({
        method: (_input, flowMethods) => props.getVerificationNote(flowMethods),
        initialData: null,
        initFetch: { input: { isOpen } },
    });

    const viewer = useRef<HTMLDivElement>(null);
    const [webViewerInstance, setWebViewerInstance] = useState<WebViewerInstance | null>(null);

    useLayoutEffect(() => {
        if (!webViewerInstance && viewer && viewer.current && verificationNote) {
            WebViewer(
                {
                    path: '/webviewer',
                    disabledElements: ['toolsHeader', 'header', 'pageNavOverlay', 'searchPanel', 'contextMenuPopup'],
                    fullAPI: true,
                    licenseKey: 'Insert commercial license key here after purchase',
                },
                viewer.current!
            ).then((instance) => {
                setWebViewerInstance(instance);
            });
        }
    }, [viewer, verificationNote, webViewerInstance]);

    const generateReport = () => {
        setDocumentDownloading(true);
        webViewerInstance!.Core.documentViewer
            .loadDocument('/VerificationNoteReportTemplate.docx', {
                officeOptions: {
                    doTemplatePrep: true,
                },
            })
            .then(() => {
                const doc = webViewerInstance!.Core.documentViewer.getDocument();

                doc.getTemplateKeys().then((keys) => {
                    const stringData = JSON.stringify(verificationNote, (key: string, value: any) => {
                        if (key === 'status') {
                            const item = AnnotationStatusDictionary[value as unknown as AnnotationStatus];
                            return item.name;
                        }
                        if (typeof value === 'number') {
                            return value.toString();
                        } else if (value === null) {
                            return '';
                        }

                        return value;
                    });

                    const templateValues = stringData as unknown as Core.TemplateDataKeyValues;

                    doc.applyTemplateValues(templateValues).then(() => {
                        doc.getFileData({ downloadType: 'pdf' })
                            .then((buffer) => saveByteArray(`VerificationNote-${verificationNote!.documentTitle}`, buffer))
                            .finally(() => {
                                setDocumentDownloading(false);
                            });
                    });
                });
            });
    };

    const saveByteArray = (reportName: string, data: any) => {
        var blob = new Blob([data], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    const [tab, setTab] = useState<TabsType>('annotations-table');

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth={'xl'} fullWidth={true}>
            <Dialog.Title>Generate Report</Dialog.Title>
            <Dialog.Content>
                <Box mb={4}>
                    <Tabs value={tab} aria-label='tabs' onChange={(value) => setTab(value as TabsType)}>
                        <Tabs.Tab hasBorder value='annotations-table' data-cy='tab-annotations-table'>
                            Table of annotations
                        </Tabs.Tab>
                        <Tabs.Tab hasBorder value='documents-table' data-cy='tab-documents-table'>
                            Verification Bundle Index
                        </Tabs.Tab>
                    </Tabs>
                    <Tabs.Panel isVisible={tab === 'annotations-table'} height={400}>
                        <Typography mb={2}>{verificationNote?.documentTitle}</Typography>
                        <AnnotationsTableComponent
                            projectId={projectId}
                            projectVersionIdUrlParam={projectVersionIdUrlParam}
                            annotations={verificationNote?.annotations || []}
                            isFetching={fetching}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel isVisible={tab === 'documents-table'} height={400}>
                        <Typography mb={2}>{verificationNote?.documentTitle}</Typography>
                        <DocumentsTableComponent
                            projectId={projectId}
                            projectVersionIdUrlParam={projectVersionIdUrlParam}
                            supportingDocuments={verificationNote?.supportingDocuments || []}
                            isFetching={fetching}
                        />
                    </Tabs.Panel>
                </Box>
                <Dialog.Actions>
                    <Button
                        color='secondary'
                        sx={{
                            maxWidth: 160,
                        }}
                        fullWidth
                        type='button'
                        onClick={closeModal}
                        data-cy='close-verification-note'
                    >
                        Close
                    </Button>
                    <Button
                        data-cy='generate-verification-note'
                        isLoading={documentDownloading}
                        disabled={!webViewerInstance || documentDownloading}
                        color='primary'
                        sx={{
                            maxWidth: 160,
                        }}
                        fullWidth
                        type='button'
                        onClick={generateReport}
                    >
                        Download
                    </Button>
                </Dialog.Actions>
                <div style={{ display: 'none' }} ref={viewer}></div>
            </Dialog.Content>
        </Dialog>
    );
}

export default VerificationNoteModalComponent;
