import { useCallback, useEffect, useMemo, useState } from 'react';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { CreatingDraftConfirmationModalProps } from './Modals/CreatingDraftConfirmationModal/CreatingDraftConfirmationModal.Component';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'App/RoutesPaths';
import { ProcessingLayoutProps } from './ProcessingLayout/ProcessingLayout.Component';
import {
    TransferringAnnotationsResultModalProps,
    TransferringAnnotationsResultModalState,
} from './Modals/TransferringAnnotationsResultModal/TransferringAnnotationsResultModal.Component';
import { AnnotationTransferPropositionStatus, AnnotationsTransferProposition } from 'ApiClients/SterlingApiClients/Types';
import { FormMode } from 'Views/Project/NewVersionForm/NewVersionForm.Module';
import { DocumentInfo } from '../../DocumentUpload/useDocumentUploadState';
import { PdfViewerOtherProps } from '../Hooks/usePdfViewer';

export type DocumentsPreviewState = {
    mode: FormMode;
    pdfViewerOtherProps: PdfViewerOtherProps;
    openCreatingDraftConfirmationModal: () => void;
    creatingDraftConfirmationModalProps: CreatingDraftConfirmationModalProps;
    openTransferringAnnotationsResultModal: () => void;
    transferringAnnotationsResultModalProps: TransferringAnnotationsResultModalProps;
    setTransferringProgress: (progress: number) => void;
    processingLayoutState: ProcessingLayoutProps;
    openProcessingLayout: (title: string, progress: number | null) => void;
    closeProcessingLayout: () => void;
    nextStep: () => void;
};

export const IndexingDocumentTitle = 'Indexing document';
export const TransferingAnnotationsTitle = 'Transferring annotations';

const useDocumentsPreviewState = (
    mode: FormMode,
    projectId: string,
    nextStep: () => void,
    documentInfoActive: DocumentInfo,
    documentInfoDraft: DocumentInfo | null,
    documentInfoDraftOriginal: DocumentInfo | null,
    documentDraftIsProcessed: boolean,
    annotationsTransfer: AnnotationsTransferProposition | null,
    createDraftInput: (file: File, flowMethods: FlowMethods<string>) => void
): DocumentsPreviewState => {
    const navigate = useNavigate();

    const pdfViewerOtherProps: PdfViewerOtherProps = useMemo(() => {
        return {
            displayHighlightIcons: false,
            displayHighlightNavigationIcons: false,
            isCreatingHighlightEnabled: false,
            highlightsProps: {
                highlights: [],
                highlightToolbars: {
                    highlightToolbar: {
                        render: () => <div />,
                    },
                    newHighlightToolbar: {
                        render: () => <div />,
                    },
                },
                highlightLeftTopInformation: () => [],
                selectedHighlightId: null,
                setSelectedHighlightId: () => {},
                selectedHighlightChangedEventName: '',
                clearSelectedHighlight: () => {},
            },
        };
    }, []);

    // Modal CreatingDraftConfirmationModal
    const [creatingDraftConfirmationModalIsOpen, setCreatingDraftConfirmationModalIsOpen] = useState(false);
    const closeCreatingDraftConfirmationModal = useCallback(() => setCreatingDraftConfirmationModalIsOpen(false), []);
    const openCreatingDraftConfirmationModal = useCallback(() => setCreatingDraftConfirmationModalIsOpen(true), []);

    // Modal TransferringAnnotationsResultModal
    const [transferringAnnotationsResultModalState, setTransferringAnnotationsResultModalState] = useState<TransferringAnnotationsResultModalState>({
        isOpen: false,
    });
    const closeTransferringAnnotationsResultModal = useCallback(() => setTransferringAnnotationsResultModalState((state) => ({ ...state, isOpen: false })), []);
    const openTransferringAnnotationsResultModal = useCallback(() => setTransferringAnnotationsResultModalState({ isOpen: true }), []);

    const transferringAnnotationsResultModalSubmit = useCallback(() => {
        closeTransferringAnnotationsResultModal();
        nextStep();
    }, [closeTransferringAnnotationsResultModal, nextStep]);

    // ProcessingLayout
    const initProcessingLayoutState: ProcessingLayoutProps = useMemo(() => {
        if (mode === 'edit') {
            if (documentInfoDraft && !documentDraftIsProcessed) return { isOpen: true, title: IndexingDocumentTitle, progress: null };

            if (documentDraftIsProcessed && annotationsTransfer && annotationsTransfer.totalAnnotationsToTransfer !== annotationsTransfer.propositions.length)
                return {
                    isOpen: true,
                    title: TransferingAnnotationsTitle,
                    progress: (annotationsTransfer.propositions.length / annotationsTransfer.totalAnnotationsToTransfer) * 100,
                };
        }

        return { isOpen: false, title: null, progress: null };
        // eslint-disable-next-line
    }, []);

    const [processingLayoutState, setProcessingLayoutState] = useState<ProcessingLayoutProps>(initProcessingLayoutState);
    const closeProcessingLayout = useCallback(() => setProcessingLayoutState({ isOpen: false, title: null, progress: null }), [setProcessingLayoutState]);
    const openProcessingLayout = useCallback(
        (title: string, progress: number | null) => setProcessingLayoutState({ isOpen: true, title, progress }),
        [setProcessingLayoutState]
    );
    const setTransferringProgress = useCallback(
        (progress: number) => setProcessingLayoutState((state) => ({ ...state, progress })),
        [setProcessingLayoutState]
    );
    useEffect(() => {
        if (mode === 'edit') {
            if (documentInfoDraft && !documentDraftIsProcessed) {
                openProcessingLayout(IndexingDocumentTitle, null);
            } else if (annotationsTransfer) {
                const allTransferred = annotationsTransfer.totalAnnotationsToTransfer === annotationsTransfer.propositions.length;
                if (!allTransferred) {
                    openProcessingLayout(
                        TransferingAnnotationsTitle,
                        (annotationsTransfer.propositions.length / annotationsTransfer.totalAnnotationsToTransfer) * 100
                    );
                }
            }
        }
    }, [
        mode,
        openProcessingLayout,
        closeProcessingLayout,
        openTransferringAnnotationsResultModal,
        documentInfoDraft,
        documentDraftIsProcessed,
        annotationsTransfer,
    ]);

    // Create draft
    const navigateToDraft = useCallback(
        (draftId: string) => navigate(AppRoutes.projectInstance.projectVersion.draft.specificPath({ projectId: projectId, draftId })),
        [navigate, projectId]
    );
    const { wrappedMethod: _createDraft, fetching: creatingDraft } = usePromiseWithFlowMethods<{ file: File }, string>({
        method: (input, flowMethods) =>
            createDraftInput(input.file, {
                ...flowMethods,
                onSuccess(draftId) {
                    flowMethods.onSuccess?.(draftId);
                    closeCreatingDraftConfirmationModal();
                    navigateToDraft(draftId);
                },
            }),
        initialData: '',
    });
    const createDraft = useCallback(
        () => documentInfoDraftOriginal && _createDraft({ file: documentInfoDraftOriginal.file }),
        [_createDraft, documentInfoDraftOriginal]
    );

    return {
        mode,
        pdfViewerOtherProps,
        openCreatingDraftConfirmationModal,
        openTransferringAnnotationsResultModal,
        nextStep,
        creatingDraftConfirmationModalProps: {
            isOpen: creatingDraftConfirmationModalIsOpen,
            closeModal: closeCreatingDraftConfirmationModal,
            docNameActive: documentInfoActive.name,
            docNameDraft: documentInfoDraft?.name || 'Empty',
            confirm: createDraft,
            creatingDraft,
        },
        transferringAnnotationsResultModalProps: {
            ...transferringAnnotationsResultModalState,
            totalStats: annotationsTransfer?.totalAnnotationsToTransfer || 0,
            statusStats: {
                [AnnotationTransferPropositionStatus.Found]:
                    annotationsTransfer?.propositions.filter((p) => p.status === AnnotationTransferPropositionStatus.Found).length || 0,
                [AnnotationTransferPropositionStatus.FoundWithModifications]:
                    annotationsTransfer?.propositions.filter((p) => p.status === AnnotationTransferPropositionStatus.FoundWithModifications).length || 0,
                [AnnotationTransferPropositionStatus.Removed]:
                    annotationsTransfer?.propositions.filter((p) => p.status === AnnotationTransferPropositionStatus.Removed).length || 0,
            },
            closeModal: closeTransferringAnnotationsResultModal,
            next: transferringAnnotationsResultModalSubmit,
        },
        processingLayoutState,
        openProcessingLayout,
        closeProcessingLayout,
        setTransferringProgress,
    };
};

export default useDocumentsPreviewState;
