import {
    Annotation,
    BoundingBoxSection,
    DocumentInfo,
    FileInfo,
    SupportingInformation,
    SupportingInformationProposition,
    SupportingInformationSource,
} from 'ApiClients/SterlingApiClients/Types';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { SupportingHighlightSelectedEventName } from 'Views/Project/ProjectWorkspace/ProjectWorkspace.Component';
import {
    SupportingInformationContext,
    SupportingInformationPropositionContext,
    getContentType,
    SupportingHighlightType,
} from 'Views/Project/ProjectWorkspace/Types';
import SupportingInformationPanelWrapperComponent from './SupportingInformationPanelWrapper/SupportingInformationPanelWrapper.Component';
import NewSupportingInformationPanelWrapperComponent from './NewSupportingInformationPanelWrapper/NewSupportingInformationPanelWrapper.Component';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { IconType } from 'UI/Icon';
import { Icon } from 'UI';
import useNavigation from './Hooks/useNavigation';
import InformationDataToolbar from './InformationDataToolbar/InformationDataToolbar';
import { Box } from '@mui/material';
import PdfViewerWithToolbarComponent from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Component';
import { ContentType, Highlight, HighlightColor } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import useWebViewerInstance from 'Views/Common/PdfViewerWithToolbar/Hooks/useWebViewerInstance';

type SupportingDocumentComponentProps = {
    documentInfo: DocumentInfo;
    docFileInfo: FileInfo | null;
    onDocumentLoad: () => void;
    selectedAnnotation: Annotation | null;
    supportingInformationProps: SupportingInformationProps;
    supportingInformationPropositionProps: SupportingInformationPropositionProps;
    highlightProps: HighlightProps;
    openSupportingInformationPropositions: () => void;
    openSuppInfoSummaryModal: (annotationId: string) => void;
    closeDocument: () => void;
};

type SupportingInformationProps = {
    supportingInformationContext: SupportingInformationContext;
    getSupportingInformation: (annotationId: string) => void;
    addSupportingInformation: (
        annotationId: string,
        supportingDocumentId: string,
        contentType: ContentType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string
    ) => void;
    deleteSupportingInformation: (annotationId: string, supportingInformationId: string, setFetching: (isFetching: boolean) => void) => void;
    switchSupportingInformation: (supportingInformation: SupportingInformation) => void;
};

type SupportingInformationPropositionProps = {
    supportingInformationPropositionContext: SupportingInformationPropositionContext;
    rejectProposition: (annotationId: string, propositionId: string) => void;
    linkPropositionToAnnotation: (annotationId: string, propositionId: string, setFetching: (isFetching: boolean) => void) => void;
    restoreProposition: (annotationId: string, propositionId: string, setFetching: (isFetching: boolean) => void) => void;
};

type HighlightProps = {
    selectedSupportingHighlightId: string | null;
    setSelectedSupportingHighlightId: (id: string | null, skipJump: boolean) => void;
    initialHighlightId: string | null;
    clearInitialHighlightId: () => void;
};

export type SupportingHighlightCustomData = {
    type: SupportingHighlightType;
    source: SupportingInformationSource | null;
    propositionIsRejected?: boolean;
};

const RejectedPropositionColors: { default: HighlightColor; selected: HighlightColor } = {
    default: { R: 207, G: 207, B: 207, A: 0.2 },
    selected: { R: 207, G: 207, B: 207, A: 0.4 },
};

function SupportingDocumentComponent(props: SupportingDocumentComponentProps) {
    const { isEditor } = useContext(ProjectAuthContext);
    const { documentInfo, docFileInfo, onDocumentLoad, selectedAnnotation, openSupportingInformationPropositions, openSuppInfoSummaryModal, closeDocument } =
        props;
    const { supportingInformationContext, getSupportingInformation, addSupportingInformation, deleteSupportingInformation, switchSupportingInformation } =
        props.supportingInformationProps;
    const {
        supportingInformationPropositionContext: suppInfoProp,
        rejectProposition,
        linkPropositionToAnnotation,
        restoreProposition,
    } = props.supportingInformationPropositionProps;
    const { selectedSupportingHighlightId, setSelectedSupportingHighlightId, initialHighlightId, clearInitialHighlightId } = props.highlightProps;

    useEffect(() => {
        if (!supportingInformationContext && selectedAnnotation) {
            getSupportingInformation(selectedAnnotation.id);
        }
    }, [supportingInformationContext, getSupportingInformation, selectedAnnotation]);

    const supportingInformationPropositions = useMemo(() => {
        return suppInfoProp && suppInfoProp.supportingInformationPropositions
            ? suppInfoProp.supportingInformationPropositions
                  .slice(0, suppInfoProp.countToDisplay)
                  .filter((el) => el.documentId === documentInfo.id)
                  .filter((el) => !el.propositionUsed)
            : ([] as Array<SupportingInformationProposition>);
    }, [suppInfoProp, documentInfo]);

    const suppInfoPropHighlights = useMemo(
        () =>
            supportingInformationPropositions.map((el): Highlight<SupportingHighlightCustomData> => {
                return {
                    id: el.id,
                    orderNumber: el.propositionNumber,
                    type: 'proposition',
                    boundingBoxSections: el.content.boundingBoxSections,
                    contentType: getContentType(el.content.selectionType),
                    source: null,
                    color: el.isRejected
                        ? el.id === selectedSupportingHighlightId
                            ? RejectedPropositionColors.selected
                            : RejectedPropositionColors.default
                        : undefined,
                    propositionIsRejected: el.isRejected,
                };
            }),
        [supportingInformationPropositions, selectedSupportingHighlightId]
    );

    const suppInfoHighlights = useMemo(
        () =>
            supportingInformationContext && supportingInformationContext.supportingInformation
                ? supportingInformationContext.supportingInformation
                      .filter((el) => el.documentId === documentInfo.id)
                      .map((el): Highlight<SupportingHighlightCustomData> => {
                          return {
                              id: el.id,
                              orderNumber: el.orderNumber,
                              type: 'linked',
                              boundingBoxSections: el.content.boundingBoxSections,
                              contentType: getContentType(el.content.selectionType),
                              source: el.source,
                          };
                      })
                : ([] as Array<Highlight<SupportingHighlightCustomData>>),
        [supportingInformationContext, documentInfo]
    );

    const highlights = useMemo(() => [...suppInfoPropHighlights, ...suppInfoHighlights], [suppInfoPropHighlights, suppInfoHighlights]);

    // Supporting Information Navigation
    const annotationSupportingInformation = supportingInformationContext?.supportingInformation;
    const supportingInformationNavRef = useNavigation({
        selectedHighlightId: selectedSupportingHighlightId,
        highlights: annotationSupportingInformation?.map((el) => el.id) || [],
        switchHighlight: (index: number) => annotationSupportingInformation && switchSupportingInformation(annotationSupportingInformation[index]),
    });

    // Propositions Navigation
    const propositionsNavRef = useNavigation({
        selectedHighlightId: selectedSupportingHighlightId,
        highlights: supportingInformationPropositions.map((el) => el.id) || [],
        switchHighlight: (index: number) =>
            supportingInformationPropositions && setSelectedSupportingHighlightId(supportingInformationPropositions[index].id, false),
    });

    const selectedAnnotationRef = useRef(selectedAnnotation || null);
    selectedAnnotationRef.current = selectedAnnotation;

    const selectedSupportingHighlight = highlights.find((h) => h.id === selectedSupportingHighlightId) || null;
    const selectedSupportingHighlightRef = useRef(selectedSupportingHighlight);
    selectedSupportingHighlightRef.current = selectedSupportingHighlight;

    const highlightToolbarsDeps: React.DependencyList = useMemo(() => {
        const anySelectedAnnotation = selectedAnnotation ? true : false;
        return [selectedSupportingHighlightId, selectedAnnotation, anySelectedAnnotation, highlights];
    }, [selectedSupportingHighlightId, selectedAnnotation, highlights]);

    const { webViewerInstance, viewer } = useWebViewerInstance();

    const title: string = useMemo(() => {
        const docName = documentInfo.originalName || documentInfo.name;
        return docName.length > 100 ? docName.substring(0, 100) + '...' : docName;
    }, [documentInfo]);
    return (
        <Box sx={{ height: '100%', position: 'relative' }}>
            <InformationDataToolbar
                selectedAnnotation={selectedAnnotation}
                supportingInformationContext={
                    (supportingInformationContext && {
                        isFetching: supportingInformationContext.isFetching,
                        supportingInformation: supportingInformationContext?.supportingInformation?.filter((el) => el.documentId === documentInfo.id) || [],
                    }) ||
                    null
                }
                supportingInformationPropositionContext={
                    (suppInfoProp && {
                        ...suppInfoProp,
                        supportingInformationPropositions,
                    }) ||
                    null
                }
                openSupportingInformationPropositions={openSupportingInformationPropositions}
                openSuppInfoSummaryModal={openSuppInfoSummaryModal}
                setSelectedSupportingHighlightId={setSelectedSupportingHighlightId}
            />
            <PdfViewerWithToolbarComponent<SupportingHighlightCustomData>
                webViewerInstance={webViewerInstance}
                viewerRef={viewer}
                title={title}
                documentInfo={documentInfo}
                fileSource={'docFile'}
                docFileInfo={docFileInfo}
                onDocumentLoad={onDocumentLoad}
                closeIconOnClick={closeDocument}
                displayHighlightIcons={true}
                isCreatingHighlightEnabled={true}
                highlightsProps={{
                    highlights,
                    highlightToolbars: {
                        newHighlightToolbar: {
                            render: (options) => (
                                <NewSupportingInformationPanelWrapperComponent
                                    newHighlightToolbarOptions={options}
                                    addSupportingInformation={addSupportingInformation}
                                    documentInfo={documentInfo}
                                    selectedAnnotationRef={selectedAnnotationRef}
                                />
                            ),
                        },
                        highlightToolbar: {
                            render: (options) => (
                                <SupportingInformationPanelWrapperComponent
                                    setRef={options.setHighlightToolbarRef}
                                    selectedHighlightRef={selectedSupportingHighlightRef}
                                    selectedAnnotationRef={selectedAnnotationRef}
                                    supportingInformationNavRef={supportingInformationNavRef}
                                    deleteSupportingInformation={deleteSupportingInformation}
                                    propositionsNavRef={propositionsNavRef}
                                    rejectProposition={rejectProposition}
                                    linkPropositionToAnnotation={linkPropositionToAnnotation}
                                    restoreProposition={restoreProposition}
                                />
                            ),
                        },
                        deps: highlightToolbarsDeps,
                    },
                    highlightLeftTopInformation: (highlight) => {
                        let icon: IconType | null = null;

                        switch (highlight.type) {
                            case 'linked':
                                icon = Icon.Link45deg;
                                break;
                            case 'proposition':
                                icon = Icon.Flash;
                                break;
                        }

                        let leftTopInformation = [{ iconContent: icon }, { textContent: highlight.orderNumber.toString() }];
                        if (highlight.source && highlight.source === 1 && isEditor) {
                            leftTopInformation.push({ iconContent: Icon.Flash });
                        }

                        return leftTopInformation;
                    },
                    selectedHighlightId: selectedSupportingHighlightId,
                    setSelectedHighlightId: (id) => setSelectedSupportingHighlightId(id, true),
                    selectedHighlightChangedEventName: SupportingHighlightSelectedEventName,
                    clearSelectedHighlight: () => setSelectedSupportingHighlightId(null, true),
                    initialChosenHighlight: {
                        id: initialHighlightId,
                        callback: clearInitialHighlightId,
                    },
                }}
            />
        </Box>
    );
}

export default SupportingDocumentComponent;
