import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import useStateById from 'Hooks/useStateById';
import { AnnotationCommentsContext } from 'Views/Project/ProjectWorkspace/Types';

const useAnnotationsComments = (projectId: string, annotationApi: AnnotationsApiClient, refetchAnnotations: () => void) => {
    const [commentsByAnnotationId, setCommentsByAnnotationId] = useStateById<AnnotationCommentsContext>();

    const getComments = (annotationId: string) => {
        setCommentsByAnnotationId.Function(annotationId, (prev) => ({
            isFetching: true,
            comments: prev?.comments ? prev.comments : [],
        }));

        annotationApi.getComments(projectId, annotationId, {
            onSuccess: (data) => {
                setCommentsByAnnotationId.Value(annotationId, {
                    isFetching: false,
                    comments: data,
                });
            },
        });
    };

    const addComment = (annotationId: string, comment: string) => {
        annotationApi.addComment(projectId, annotationId, {
            body: { comment },
            onSuccess: () => {
                refetchAnnotations();
                getComments(annotationId);
            },
        });
    };

    const resolveComment = (annotationId: string, commentId: string) => {
        annotationApi.resolveComment(projectId, annotationId, commentId, {
            onSuccess: () => {
                refetchAnnotations();
                getComments(annotationId);
            },
        });
    };

    const deleteComment = (annotationId: string, commentId: string) => {
        annotationApi.deleteComment(projectId, annotationId, commentId, {
            onSuccess: () => {
                refetchAnnotations();
                getComments(annotationId);
            },
        });
    };

    return {
        commentsByAnnotationId,
        getComments,
        addComment,
        resolveComment,
        deleteComment,
    };
};

export default useAnnotationsComments;
