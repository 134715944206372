import ProjectDashboardModule, { ProjectDashboardModuleProps } from './ProjectDashboard.Module';
import ProjectVersionWrapper from '../Common/ProjectVersion.Wrapper';

export type ProjectDashboardVersionWrapperProps = Omit<ProjectDashboardModuleProps, 'projectWrapperProps'>;

function ProjectDashboardVersionWrapper(props: ProjectDashboardVersionWrapperProps) {
    return (
        <ProjectVersionWrapper
            projectsApiClient={props.projectsApiClient}
            renderModule={(projectWrapperProps) => <ProjectDashboardModule projectWrapperProps={projectWrapperProps} {...props} />}
        />
    );
}

export default ProjectDashboardVersionWrapper;
