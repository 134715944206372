import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { AnnotationAttributeType } from './Attributes';
import { getAnnotations, getAnnotationsToRemove } from './Annotations';
import { getAnnotationsLeftTopInformation, getAnnotationsLeftTopInformationToRemove } from './HighlightLeftTopInformation';
import { getAdditionalHtmlElements } from './AdditionalHtmlElements';
import { EmotionCache } from '@emotion/react';
import { HighlightLeftTopInformation } from 'Views/Common/PdfViewerWithToolbar/HighlightLeftTopInformation/HighlightLeftTopInformation.Component';
import { Highlight, AdditionalHtmlElement } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import Log from 'Views/Common/PdfViewerWithToolbar/Logger';

const drawViewerAnnotations = async <THighlightCustomData,>(
    instance: WebViewerInstance,
    highlights: Array<Highlight<THighlightCustomData>>,
    selectedHighlightId: string | null,
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>,
    emotionCache: EmotionCache,
    additionalHtmlElements?: Array<AdditionalHtmlElement<THighlightCustomData>>
) => {
    const { annotationManager } = instance.Core;
    const annotations: Array<Core.Annotations.Annotation> = annotationManager.getAnnotationsList();

    const annotationsLeftTopInformationToRemove = getAnnotationsLeftTopInformationToRemove(instance);
    const annotationsAdditionalHtmlElements = annotations.filter((a) => AnnotationAttributeType.get(a) === 'additionalHtmlElement');
    const annotationsBordersToRemove = annotations.filter((a) => AnnotationAttributeType.get(a) === 'border');
    const annotationsToRemove = getAnnotationsToRemove(instance, highlights);
    const toRemove = [...annotationsLeftTopInformationToRemove, ...annotationsToRemove, ...annotationsAdditionalHtmlElements, ...annotationsBordersToRemove];

    const annotationsLeftTopInformation = getAnnotationsLeftTopInformation(instance, highlights, selectedHighlightId, leftTopInformation, emotionCache);
    const annotationAdditionalHtmlElements = getAdditionalHtmlElements(instance, highlights, selectedHighlightId, additionalHtmlElements || [], emotionCache);
    const viewerAnnotations = getAnnotations(instance, highlights, selectedHighlightId);
    const toAdd = [...annotationAdditionalHtmlElements, ...viewerAnnotations, ...annotationsLeftTopInformation];

    annotationManager.addAnnotations(toAdd);

    let pagesTmp: Array<number> = [];
    highlights.forEach((h) => {
        const highlightPages = [...new Set(h.boundingBoxSections.map((h) => h.pageNumber))];
        pagesTmp = [...pagesTmp, ...highlightPages];
    });
    const pages = [...new Set(pagesTmp)];

    for (const p of pages) {
        await annotationManager.drawAnnotations({ pageNumber: p, majorRedraw: true });
    }

    if (toRemove.length > 0) {
        Log('ACTION', ['drawHighlights', 'deleteAnnotations'], [toRemove]);
        annotationManager.deleteAnnotations(toRemove);
    }
};

export default drawViewerAnnotations;
