import { createRef, useLayoutEffect, useRef, useState } from 'react';
import { Box, CircularProgress, SxProps, Theme } from '@mui/material';
import { Tooltip, Typography } from 'UI';
import { IconType } from 'UI/Icon';

export type HighlightToolbarComponentProps = {
    utils: Array<Util>;
};

export type Util = {
    icon: IconType;
    onClick: () => void;
    tooltip: string;
    description?: string;
    utilIconBoxSx?: SxProps<Theme>;
    disabled?: boolean;
    loading?: boolean;
};

function HighlightToolbarComponent(props: HighlightToolbarComponentProps) {
    const { utils } = props;

    const [utilsRefs, setUtilsRefs] = useState<Array<React.RefObject<HTMLDivElement>>>([]);
    useLayoutEffect(() => {
        const utilsRefs: Array<React.RefObject<HTMLDivElement>> = [];
        utils.forEach(() => {
            const ref = createRef<HTMLDivElement>();
            utilsRefs.push(ref);
        });
        setUtilsRefs(utilsRefs);
    }, [utils]);

    const rootRef = useRef<HTMLDivElement>(null);

    return (
        <Box
            ref={rootRef}
            sx={{
                display: 'flex',
                width: 'fit-content',
                height: '2rem',
                borderRadius: '0.25rem',
                borderStyle: 'solid',
                borderWidth: '0.125rem',
                borderColor: (theme) => theme.palette.blue.dark,
                backgroundColor: (theme) => theme.palette.white.main,
                paddingLeft: '0.5rem',
                paddingTop: '0.375rem',
                paddingBottom: '0.375rem',
                paddingRight: '0.5rem',
            }}
        >
            {utils.map((util, idx) => (
                <Tooltip
                    key={`highlight-toolbar-util-${idx}`}
                    title={util.tooltip}
                    placement='bottom'
                    tooltipref={utilsRefs[idx]}
                    PopperProps={{ container: rootRef.current }}
                >
                    <Box
                        onClick={() => {
                            // Tooltips on document don't work properly by default (specially for switch between linked information).
                            // It is necessary to first hide tooltip
                            if (utilsRefs[idx].current !== null) utilsRefs[idx].current!.style.display = 'none';
                            util.onClick();
                        }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                            cursor: 'pointer',
                            ...((util.disabled || util.loading) && {
                                cursor: 'default',
                                pointerEvents: 'none',
                            }),
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '1rem',
                                height: '1rem',
                                color: (theme) => theme.palette.blue.dark,
                                ...util.utilIconBoxSx,
                                ...(util.disabled && {
                                    color: (theme) => theme.palette.text.disabled,
                                }),
                            }}
                        >
                            {util.loading ? <CircularProgress size={16} /> : <util.icon width={16} height={16} />}
                        </Box>
                        {util.description && (
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '0.75rem',
                                        fontWeight: 600,
                                        marginLeft: '0.5rem',
                                        ...(util.disabled && {
                                            color: (theme) => theme.palette.text.disabled,
                                        }),
                                    }}
                                >
                                    {util.description}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Tooltip>
            ))}
        </Box>
    );
}

export default HighlightToolbarComponent;
