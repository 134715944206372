import { useMemo } from 'react';
import _ from 'lodash';
import { Box, Collapse, List, ListItem, Typography, useTheme } from '@mui/material';
import { Annotation, AutoAnnotation } from 'ApiClients/SterlingApiClients/Types';
import { AnnotationsScope } from '../../../../Types';
import { palette } from 'UI/Provider/VerifiTheme';
import { SelectedAnnotationType } from 'Views/Project/ProjectWorkspace/Types/ProjectWorkspaceComponent.Types';
import { Icon, Tooltip } from 'UI';
import { HeadingWithAnnotations } from '../Annotations.Component';
import AnnotationWrapper from './AnnotationWrapper/AnnotationWrapper.Component';
import OrderByHighlightPosition from 'Views/Common/PdfViewerWithToolbar/Functions/OrderByHighlightPosition';

type AnnotationsListComponentProps = AnnotationsScope & {
    showAutoAnnotations: boolean;
    headings: Array<HeadingWithAnnotations>;
    collapsedHeadings: Array<string>;
    collapseExpandHeading: (id: string) => void;
};

export type AnnotationInfo = {
    id: string;
    orderNumber: number;
    type: SelectedAnnotationType;
    page: number;
    coordinateY: number;
    coordinateX: number;
};

export const HeadingListExpandedEventName = 'HeadingListExpandedEventName';

function AnnotationsListComponent(props: AnnotationsListComponentProps) {
    const theme = useTheme();

    const {
        annotations,
        selectedAnnotation,
        setSelectedAnnotationId,
        autoAnnotationsScope,
        showAutoAnnotations,
        headings,
        modals,
        openCommentDrawer,
        collapsedHeadings,
        collapseExpandHeading,
    } = props;
    const { autoAnnotations, setSelectedAutoAnnotationId, selectedAutoAnnotationId, rejectAutoAnnotation, autoAnnotationsProcess } = autoAnnotationsScope;
    const isErrorReceived = useMemo(() => (autoAnnotationsProcess && autoAnnotationsProcess.isErrorReceived) || false, [autoAnnotationsProcess]);

    const { allAnnotations, annotationsById, autoAnnotationsById } = useMemo(() => {
        let allAnnotations: Array<AnnotationInfo> = [];

        let annotationsById: { [key: string]: Annotation } = {};
        annotations.forEach((a) => {
            annotationsById[a.id] = a;
            allAnnotations.push({
                id: a.id,
                orderNumber: a.orderNumber,
                type: 'annotation',
                page: a.content.boundingBoxSections[0].pageNumber,
                coordinateY: a.content.boundingBoxSections[0].boundingBoxes[0].topLeft.y,
                coordinateX: a.content.boundingBoxSections[0].boundingBoxes[0].topLeft.x,
            });
        });

        let autoAnnotationsById: { [key: string]: AutoAnnotation } = {};
        autoAnnotations.forEach((a) => {
            autoAnnotationsById[a.id] = a;
            allAnnotations.push({
                id: a.id,
                orderNumber: a.orderNumber,
                type: 'autoAnnotation',
                page: a.content.boundingBoxSections[0].pageNumber,
                coordinateY: a.content.boundingBoxSections[0].boundingBoxes[0].topLeft.y,
                coordinateX: a.content.boundingBoxSections[0].boundingBoxes[0].topLeft.x,
            });
        });

        return {
            allAnnotations: allAnnotations.sort(OrderByHighlightPosition),
            annotationsById,
            autoAnnotationsById,
        };
    }, [annotations, autoAnnotations]);

    const headingsWithAnnotations: Array<HeadingWithAnnotations> = useMemo(() => {
        let headingsWithAnnots: Array<HeadingWithAnnotations> = _.cloneDeep(headings);
        let currentHeadingIdx = 0;
        let currentHeading = headingsWithAnnots[currentHeadingIdx];

        allAnnotations.forEach((a) => {
            for (let i = currentHeadingIdx; i < headingsWithAnnots.length; i++) {
                const isLastHeading = currentHeadingIdx === headingsWithAnnots.length - 1;

                if (isLastHeading) {
                    currentHeading.annotations.push(a);
                    break;
                } else {
                    let nextHeading = headingsWithAnnots[currentHeadingIdx + 1];

                    if (a.page < nextHeading.page || (a.page === nextHeading.page && a.coordinateY < nextHeading.coordinateY)) {
                        currentHeading.annotations.push(a);
                        break;
                    } else {
                        currentHeadingIdx++;
                        currentHeading = headingsWithAnnots[currentHeadingIdx];
                    }
                }
            }
        });

        return headingsWithAnnots;
    }, [headings, allAnnotations]);

    const headingsToRender = useMemo(() => {
        const result: Array<JSX.Element> = [];

        headingsWithAnnotations.forEach((h) => {
            const collapsed = collapsedHeadings.includes(h.id);
            if (headingsWithAnnotations.length > 1) {
                // display heading only if there are more than one (default) heading
                result.push(
                    <ListItem
                        key={`heading-${h.id}`}
                        sx={{
                            height: '2rem',
                            width: '100%',
                            backgroundColor: (theme) => theme.palette.body.main,
                            boxShadow: '0px 1px 1px 0px #8f9bb326 inset, 0px -1px 1px 0px #8f9bb326 inset',
                            display: 'flex',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '0.75rem',
                                color: (theme) => theme.palette.text.medium,
                            }}
                        >
                            {h.name?.length > 40 ? h.name?.substring(0, 40) + '...' : h.name}
                        </Typography>
                        {h.areAnyAutoAnnotatingSectionsErrors && !isErrorReceived && (
                            <Box sx={{ marginLeft: '0.5rem', color: (theme) => theme.palette.attention.medium }}>
                                <Tooltip
                                    tooltipsx={{ color: theme.palette.attention.medium }}
                                    title='Auto Annotations process for this section has been ended with errors.'
                                >
                                    <Icon.ExclamationCircle />
                                </Tooltip>
                            </Box>
                        )}
                        <Box
                            onClick={() => collapseExpandHeading(h.id)}
                            sx={{
                                width: '1rem',
                                marginLeft: 'auto',
                                marginRight: '0rem',
                                cursor: 'pointer',
                                color: (theme) => theme.palette.icons.secondary,
                                ...(!collapsed && { transform: 'rotate(90deg);' }),
                                transition: 'transform 300ms',
                            }}
                        >
                            <Icon.ArrowChevronCompactRight />
                        </Box>
                    </ListItem>
                );
            }

            result.push(
                <Collapse
                    key={`heading-annotations-${h.id}`}
                    in={!collapsed}
                    timeout='auto'
                    onEntered={() => {
                        const event = new CustomEvent<{ id: string }>(HeadingListExpandedEventName, { detail: { id: h.id } });
                        document.dispatchEvent(event);
                    }}
                >
                    <List component='div' disablePadding>
                        {h.annotations.map((annot) => {
                            const isSelectedAnnotation = selectedAnnotation?.id === annot.id;
                            const isSelectedAutoAnnotation = selectedAutoAnnotationId === annot.id;

                            return (
                                <AnnotationWrapper
                                    key={`annotation-${annot.id}`}
                                    annot={annot}
                                    annotationsById={annotationsById}
                                    isSelectedAnnotation={isSelectedAnnotation}
                                    setSelectedAnnotationId={setSelectedAnnotationId}
                                    autoAnnotationsById={autoAnnotationsById}
                                    isSelectedAutoAnnotation={isSelectedAutoAnnotation}
                                    setSelectedAutoAnnotationId={setSelectedAutoAnnotationId}
                                    showAutoAnnotations={showAutoAnnotations}
                                    rejectAutoAnnotation={rejectAutoAnnotation}
                                    modals={modals}
                                    openCommentDrawer={openCommentDrawer}
                                    heading={{
                                        headingId: h.id,
                                        headingCollapsed: collapsed,
                                        expandHeading: () => collapseExpandHeading(h.id),
                                    }}
                                />
                            );
                        })}
                    </List>
                </Collapse>
            );
        });

        return result;
    }, [
        selectedAnnotation,
        selectedAutoAnnotationId,
        headingsWithAnnotations,
        collapseExpandHeading,
        collapsedHeadings,
        showAutoAnnotations,
        modals,
        openCommentDrawer,
        rejectAutoAnnotation,
        setSelectedAnnotationId,
        setSelectedAutoAnnotationId,
        annotationsById,
        autoAnnotationsById,
        theme.palette.attention.medium,
        isErrorReceived,
    ]);

    return <List sx={{ width: '100%', paddingTop: 0, paddingBottom: 0, bgcolor: (theme) => theme.palette.white.main }}>{headingsToRender}</List>;
}

export enum AnnotationNumberStates {
    PRIMARY = 'primary',
    HOVER = 'hover',
    PRESSED = 'pressed',
}

const annotationNumberColors = {
    [AnnotationNumberStates.PRIMARY]: { bgColor: palette.white.main, borderColor: palette.stroke.main },
    [AnnotationNumberStates.HOVER]: { bgColor: palette.blue.light, borderColor: palette.stroke.main },
    [AnnotationNumberStates.PRESSED]: { bgColor: palette.blue.light, borderColor: palette.blue.dark },
};
export function AnnotationNumberComponent(props: { orderNumber: number; selected: boolean; state: AnnotationNumberStates }) {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '1.5rem',
                height: '1rem',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: '0.25rem',
                backgroundColor: annotationNumberColors[props.state].bgColor,
                border: `1px solid ${annotationNumberColors[props.state].borderColor}`,
            }}
        >
            <Typography
                variant='buttonSmall'
                sx={{
                    fontSize: 10,
                }}
            >
                {props.orderNumber}
            </Typography>
        </Box>
    );
}

export default AnnotationsListComponent;
