import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { AnnotationStatus } from 'ApiClients/SterlingApiClients/Types';

const useAnnotationsStatus = (projectId: string, projectVersionId: string, annotationApi: AnnotationsApiClient, refetchAnnotations: () => void) => {
    const acceptAnnotationStage = (id: string, currentStatus: AnnotationStatus, flowMethods?: FlowMethods) =>
        annotationApi.acceptAnnotationStage(projectId, projectVersionId, id, {
            body: { currentStatus },
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods?.onSuccess?.(data);
                refetchAnnotations();
            },
        });

    return {
        acceptAnnotationStage,
    };
};

export default useAnnotationsStatus;
