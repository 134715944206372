import { SupportingInformationContext } from 'Views/Project/ProjectWorkspace/Types';
import { useEffect } from 'react';
import { DocumentInfo } from 'ApiClients/SterlingApiClients/Types';
import { Button, Chip, Dialog, Icon, TableList, Typography } from 'UI';
import { Box, CircularProgress, IconButton } from '@mui/material';

export type SuppInfoSummaryModalComponentProps = {
    isOpen: boolean;
    supportingInformationContext: SupportingInformationContext | null;
    getSupportingInformation: () => void;
    documentsById: { [key: string]: DocumentInfo };
    goToSupportingInformation: (id: string, documentId: string) => void;
    closeModal: () => void;
};

function SuppInfoSummaryModalComponent(props: SuppInfoSummaryModalComponentProps) {
    const {
        isOpen,
        supportingInformationContext: suppInfo,
        getSupportingInformation: getSuppInfo,
        documentsById,
        goToSupportingInformation,
        closeModal,
    } = props;

    useEffect(() => {
        getSuppInfo();
        // eslint-disable-next-line
    }, []);

    const isFetching = suppInfo?.isFetching ?? false;
    const hasSupportingInformation = suppInfo?.supportingInformation && suppInfo.supportingInformation.length > 0;
    const sortedSupportingInformation = (suppInfo?.supportingInformation ?? []).sort((a, b) => a.orderNumber - b.orderNumber);

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='xs'>
            <Dialog.Title>Linked Information</Dialog.Title>
            <Dialog.Content>
                <Box
                    sx={{
                        marginBottom: 4,
                    }}
                    position='relative'
                >
                    <TableList.Table
                        {...((isFetching || !hasSupportingInformation) && {
                            style: {
                                pointerEvents: 'none',
                            },
                        })}
                        maxHeight={256}
                    >
                        <TableList.Header>
                            <TableList.TR isHeader>
                                <TableList.TH size={66}>Link</TableList.TH>
                                <TableList.TH>Document</TableList.TH>
                                <TableList.TH size='sm'>Page</TableList.TH>
                                <TableList.TH size='sm' />
                            </TableList.TR>
                        </TableList.Header>
                        <TableList.Body data-cy='supporting-information-table-body'>
                            {hasSupportingInformation &&
                                sortedSupportingInformation.map((info) => {
                                    return (
                                        <TableList.TR key={info.id} data-cy='supporting-information-table-row' sx={{ alignItems: 'initial' }}>
                                            <TableList.TD size={66}>
                                                <Chip
                                                    color='info'
                                                    size='small'
                                                    sx={{ minWidth: '2rem' }}
                                                    icon={<Icon.Link45deg fontSize={10} />}
                                                    label={info.orderNumber}
                                                />
                                            </TableList.TD>
                                            <TableList.TD sx={{ whiteSpace: 'normal' }}>{`SUPPORTING DOCUMENT > ${
                                                documentsById[info.documentId].originalName || documentsById[info.documentId].name
                                            }`}</TableList.TD>
                                            <TableList.TD size='sm' textAlign='center'>
                                                {Math.min(...info.content.boundingBoxSections.map((b) => b.pageNumber)).toString()}
                                            </TableList.TD>
                                            <TableList.TD size='sm' textAlign='center'>
                                                <IconButton
                                                    data-cy='go-to-supporting-information'
                                                    size='small'
                                                    sx={{
                                                        padding: 0,
                                                    }}
                                                    onClick={() => {
                                                        goToSupportingInformation(info.id, info.documentId);
                                                        closeModal();
                                                    }}
                                                >
                                                    <Icon.ArrowUpRightSquare />
                                                </IconButton>
                                            </TableList.TD>
                                        </TableList.TR>
                                    );
                                })}
                            {!isFetching && !hasSupportingInformation && (
                                <TableList.TR>
                                    <TableList.TD textAlign='center'>
                                        <Typography
                                            variant='inputLabel'
                                            sx={{ height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        >
                                            No linked information.
                                        </Typography>
                                    </TableList.TD>
                                </TableList.TR>
                            )}
                            {isFetching && (
                                <TableList.TR>
                                    <TableList.TD textAlign='center'>
                                        <CircularProgress size={20} />
                                    </TableList.TD>
                                </TableList.TR>
                            )}
                        </TableList.Body>
                    </TableList.Table>
                </Box>

                <Dialog.Actions>
                    <Button
                        color='secondary'
                        sx={{
                            maxWidth: 160,
                        }}
                        fullWidth
                        type='button'
                        onClick={closeModal}
                        data-cy='close-supporting-information'
                    >
                        Close
                    </Button>
                </Dialog.Actions>
            </Dialog.Content>
        </Dialog>
    );
}

export default SuppInfoSummaryModalComponent;
