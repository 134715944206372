import { Box } from '@mui/material';
import { Icon } from 'UI';
import PdfViewerWithToolbarComponent, { PdfViewerWithToolbarComponentProps } from '../PdfViewerWithToolbar/PdfViewerWithToolbar.Component';
import useCompare, { CompareProps } from './Hooks/useCompare';
import { useMemo } from 'react';
import useViewersSync from './Hooks/useViewersSync/useViewersSync';

export type PdfViewerMultiComponentProps<THighlightCustomDataLeft, THighlightCustomDataRight> = {
    viewerPropsLeft: PdfViewerWithToolbarComponentProps<THighlightCustomDataLeft>;
    viewerPropsRight: PdfViewerWithToolbarComponentProps<THighlightCustomDataRight>;
    compareProps?: CompareProps;
    siblingSync?: boolean;
};

function PdfViewerMultiComponent<THighlightCustomDataLeft = {}, THighlightCustomDataRight = {}>(
    props: PdfViewerMultiComponentProps<THighlightCustomDataLeft, THighlightCustomDataRight>
) {
    const { viewerPropsLeft, viewerPropsRight, siblingSync } = props;
    const compareProps = useMemo(
        () => props.compareProps || { compareEnabled: false, docIdLeft: '', docIdRight: '', displayCompareResult: false },
        [props.compareProps]
    );

    const webViewerInstanceLeft = viewerPropsLeft.webViewerInstance;
    const webViewerInstanceRight = viewerPropsRight.webViewerInstance;

    const { startCompareRef, setDocLeftRenderedId, setDocRightRenderedId } = useCompare(webViewerInstanceLeft, webViewerInstanceRight, compareProps);

    const { syncing, setSyncing } = useViewersSync(
        webViewerInstanceLeft,
        webViewerInstanceRight,
        viewerPropsLeft.highlightsProps.selectedHighlightId,
        viewerPropsRight.highlightsProps.selectedHighlightId,
        siblingSync
    );

    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center' }}>
            <PdfViewerWithToolbarComponent<THighlightCustomDataLeft>
                {...viewerPropsLeft}
                syncingProps={{ syncing: syncing?.primary || null, setSyncing: () => setSyncing('left') }}
                onDocumentLoad={() => {
                    viewerPropsLeft.onDocumentLoad?.();
                    startCompareRef.current();
                }}
                onFinishedRendering={(documentInfo) => {
                    setDocLeftRenderedId(documentInfo.id);
                }}
            />
            <Box sx={{ width: '2.5rem', minWidth: '2.5rem', display: 'grid', rowGap: 4, justifyContent: 'center' }}>
                <Icon.ArrowLongRight />
                <Icon.ArrowLongRight />
                <Icon.ArrowLongRight />
            </Box>
            <PdfViewerWithToolbarComponent<THighlightCustomDataRight>
                {...viewerPropsRight}
                syncingProps={{ syncing: syncing?.primary || null, setSyncing: () => setSyncing('right') }}
                onDocumentLoad={() => {
                    viewerPropsRight.onDocumentLoad?.();
                    startCompareRef.current();
                }}
                onFinishedRendering={(documentInfo) => {
                    setDocRightRenderedId(documentInfo.id);
                }}
            />
        </Box>
    );
}

export default PdfViewerMultiComponent;
