import { BoundingBoxSection, Content, ProjectMember } from '../Types';

export type Annotation = {
    id: string;
    orderNumber: number;
    status: AnnotationStatus;
    content: Content;
    assignedMember: ProjectMember | null;
    isAnySupportingInformationLinked: boolean;
    assertion: string | null;
    statement: string;
    notificationType: AnnotationNotification;
    tags: Array<Tag>;
    needsReview: boolean;
    nextStatus: AnnotationStatus | null;
    sourceId: string;
};

export enum AnnotationNotification {
    None = 0,
    NewAnnotation = 1,
    UpdatedAnnotation = 2,
}

export enum AnnotationStatus {
    Annotate = 0,
    Verify = 1,
    Approve = 2,
    Verified = 3,
}

export enum CommentReason {
    AddingComment = 0,
    SettingNeedsReview = 1,
}

export type Assertion = {
    text: string | null;
};

export type AddAnnotationBody = {
    selectionType: number;
    boundingBoxSections: Array<BoundingBoxSection>;
    statement: string;
};

export type AcceptAnnotationStageBody = {
    currentStatus: AnnotationStatus;
};

export type CreateAnnotationFromModifiedAutoAnnotationBody = {
    selectionType: number;
    boundingBoxSections: Array<BoundingBoxSection>;
    statement: string;
};

export type AddAnnotationTagBody = {
    tagId: string;
};

export type Tag = {
    id: string;
    name: string;
};

export type AutoAnnotation = {
    id: string;
    orderNumber: number;
    content: Content;
    statement: string;
    score: number;
    description: string;
    statementType: string;
};

export type Keyword = {
    id: string;
    content: string;
};

export type AddAnnotationKeywordBody = {
    keyword: string;
};

export type SetNeedsReviewBody = {
    status: AnnotationStatus;
    comment: string;
};

export type AddCommentBody = {
    comment: string;
};

export type AnnotationComment = {
    author: ProjectMember;
    content: string;
    creationDate: Date;
    id: string;
    isResolved: boolean;
    reason: CommentReason;
};
