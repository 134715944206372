import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import ProjectsApiClient from 'ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import { ProjectMemberExtended, ProjectMemberRole } from 'ApiClients/SterlingApiClients/Types';

const useMembers = (projectsApiClient: ProjectsApiClient) => {
    const getProjectMembers = (projectId: string, flowMethods?: FlowMethods<Array<ProjectMemberExtended>>) => projectsApiClient.getMembers(projectId, flowMethods);
    const addMemberToProject = (projectId: string, userId: string, role: ProjectMemberRole, flowMethods?: FlowMethods) =>
        projectsApiClient.addMember(projectId, {
            ...flowMethods,
            body: {
                userId,
                role,
            },
        });
    const changeMemberProjectRole = (projectId: string, memberId: string, role: ProjectMemberRole, flowMethods?: FlowMethods) =>
        projectsApiClient.changeMemberRole(projectId, memberId, { ...flowMethods, body: { role } });
    const removeMemberFromProject = (projectId: string, memberId: string, flowMethods?: FlowMethods) =>
        projectsApiClient.removeMember(projectId, memberId, flowMethods);

    return {
        getProjectMembers,
        addMemberToProject,
        changeMemberProjectRole,
        removeMemberFromProject,
    };
};

export default useMembers;
