import { Icon } from 'UI';
import HighlightToolbarComponent, { Util } from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightToolbar/HighlightToolbar.Component';

export type AnnotationTransferPanelComponentProps = {
    create: () => void;
    modify: () => void;
    reject: () => void;
    onlyReject: boolean;
    fetching: boolean;
};

function AnnotationTransferPanelComponent(props: AnnotationTransferPanelComponentProps) {
    const { create, modify, reject, onlyReject, fetching } = props;

    let utils: Array<Util> = [
        {
            icon: Icon.CloseCircle,
            onClick: () => reject(),
            description: 'Reject',
            tooltip: 'Reject',
            utilIconBoxSx: { color: (theme) => theme.palette.attention.high },
            loading: fetching,
        },
    ];

    if (!onlyReject) {
        utils.push({
            icon: Icon.PencilSquare,
            onClick: () => modify(),
            description: 'Modify',
            tooltip: 'Modify',
            utilIconBoxSx: { color: (theme) => theme.palette.text.primary },
            loading: fetching,
        });
        utils.push({
            icon: Icon.Approve,
            onClick: () => create(),
            description: 'Accept',
            tooltip: 'Accept',
            utilIconBoxSx: { color: (theme) => theme.palette.success.main },
            loading: fetching,
        });
    }

    return <HighlightToolbarComponent utils={utils} />;
}

export default AnnotationTransferPanelComponent;
