import { Content } from '../Types';

export type Documents = {
    verificationDocument: VerificationDocument | null;
    supportingDocuments: DocumentsContainer | null;
    anySupportingDocumentsProcessingErrors: boolean;
};

export type VerificationDocument = DocumentInfo & {
    isAnnotationModificationAllowed: boolean;
    headings: Array<DocumentHeading>;
    autoAnnotationsProcess: AutoAnnotationsProcess | null;
};

export type DocumentInfo = {
    id: string;
    name: string;
    processingStatus: ProjectDocumentProcessingStatus;
    originalName: string | null;
    fileId: string;
};

export enum ProjectDocumentProcessingStatus {
    ToBeProcessed = 1,
    Processing = 2,
    Processed = 3,
    Failed = 4,
}

export type DocumentsContainer = {
    name: string;
    documents: Array<DocumentInfo>;
    directories: Array<DocumentsContainer>;
    path: string;
};

export type UploadSupportingDocumentBody = {
    name: string;
    document: File | null;
};

export type DocumentHeading = {
    id: string;
    statement: string;
    content: Content;
    anyAutoAnnotationsSectionProcessFailed: boolean;
};

export type AutoAnnotationsProcess = {
    status: AutoAnnotationsProcessStatus;
    progress: number;
    anySectionProcessFailed: boolean;
    isErrorReceived: boolean;
};

export enum AutoAnnotationsProcessStatus {
    ToBeProcessed = 1,
    Processing = 2,
    Processed = 3,
    ProcessedWithErrors = 4,
    Failed = 5,
}

export enum AutoAnnotationsSectionProcessStatus {
    ToBeProcessed = 1,
    Processing = 2,
    Processed = 3,
    Failed = 4,
}
