import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { Tag } from 'ApiClients/SterlingApiClients/Types';

const useAnnotationsTags = (projectId: string, projectVersionId: string, annotationApi: AnnotationsApiClient, refetchAnnotations: () => void) => {
    const getAnnotationAssignedTags = (annotationId: string, flowMethods?: FlowMethods<Array<Tag>>) =>
        annotationApi.getAssignedTags(projectId!, projectVersionId, annotationId, flowMethods);

    const addTagToAnnotation = (annotationId: string, tagId: string, flowMethods?: FlowMethods) =>
        annotationApi.addTag(projectId!, projectVersionId, annotationId, {
            ...flowMethods,
            body: { tagId: tagId },
            onSuccess() {
                if (flowMethods && flowMethods.onSuccess) {
                    flowMethods.onSuccess({});
                }
                refetchAnnotations();
            },
        });

    const deleteAssignedTagFromAnnotation = (annotationId: string, tagId: string, flowMethods?: FlowMethods) =>
        annotationApi.removeTag(projectId!, projectVersionId, annotationId, tagId, {
            ...flowMethods,
            onSuccess() {
                if (flowMethods && flowMethods.onSuccess) {
                    flowMethods.onSuccess({});
                }
                refetchAnnotations();
            },
        });

    return {
        getAnnotationAssignedTags,
        addTagToAnnotation,
        deleteAssignedTagFromAnnotation,
    };
};

export default useAnnotationsTags;
