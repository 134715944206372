import { useEffect, useRef, useState } from 'react';
import { CreateAnnotationFromModifiedTransferPropositionBody } from 'ApiClients/SterlingApiClients/Types';
import HighlightButtonComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightButton/HighlightButton.Component';
import { NewHighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { getSelectionType } from 'Views/Project/ProjectWorkspace/Types';

export type CustomTransferPanelWrapperComponentProps = {
    newHighlightToolbarOptions: NewHighlightToolbarOptions;
    transferAnnotation: (setFetching: (isFetching: boolean) => void, body: CreateAnnotationFromModifiedTransferPropositionBody) => void;
};

function CustomTransferPanelWrapperComponent(props: CustomTransferPanelWrapperComponentProps) {
    const { newHighlightToolbarOptions, transferAnnotation } = props;

    const { setHighlightToolbarRef } = newHighlightToolbarOptions;

    const [transferring, setTransferring] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    let panel: JSX.Element = (
        <HighlightButtonComponent
            rootRef={ref}
            content='TRANSFER ANNOTATION'
            loading={transferring}
            onClick={async () => {
                const newHighlight = await newHighlightToolbarOptions.getNewHighlight();
                transferAnnotation &&
                    transferAnnotation(setTransferring, {
                        statement: newHighlight.statement,
                        selectionType: getSelectionType(newHighlight.contentType),
                        boundingBoxSections: newHighlight.boundingBoxSections,
                    });
                newHighlightToolbarOptions.clearSelection();
            }}
        />
    );
    return <div ref={ref}>{panel}</div>;
}

export default CustomTransferPanelWrapperComponent;
