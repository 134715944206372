import { AppRoutes } from ".";
import UrlParams from "./UrlParams";

const SearchRoutes = {
    goToLinkedInformation: {
        hash: '#goToLinkedInformation',
        getPath: (annotationId: string, documentId: string, supportingInformationId: string) =>
            `${UrlParams.annotationId.specificParam(annotationId)}&${UrlParams.documentId.specificParam(
                documentId
            )}&${UrlParams.supportingInformationId.specificParam(supportingInformationId)}`,
        getFullLink: (projectId: string, versionId: string, annotationId: string, documentId: string, supportingInformationId: string) =>
            `${AppRoutes.projectInstance.projectVersionInstance.workspace.specificPath({ projectId, versionId })}?${SearchRoutes.goToLinkedInformation.getPath(
                annotationId,
                documentId,
                supportingInformationId
            )}${SearchRoutes.goToLinkedInformation.hash}`,
    },

    goToAnnotation: {
        hash: '#goToAnnotaion',
        getPath: (annotationId: string) => `${UrlParams.annotationId.specificParam(annotationId)}`,
        getFullLink: (projectId: string, versionId: string, annotationId: string) =>
            `${AppRoutes.projectInstance.projectVersionInstance.workspace.specificPath({ projectId, versionId })}?${SearchRoutes.goToAnnotation.getPath(annotationId)}${SearchRoutes.goToAnnotation.hash
            }`,
    },

    goToEditProjectMembers: {
        hash: '#editProjectMembers',
        getFullLink: (projectId: string, versionId: string) =>
            `${AppRoutes.projectInstance.projectVersionInstance.workspace.specificPath({ projectId, versionId })}${SearchRoutes.goToEditProjectMembers.hash}`,
    },

    goToSupportingDocument: {
        hash: '#goToSupportingDocument',
        getPath: (documentId: string) => `${UrlParams.documentId.specificParam(documentId)}`,
        getFullLink: (projectId: string, versionId: string, documentId: string) =>
            `${AppRoutes.projectInstance.projectVersionInstance.workspace.specificPath({ projectId, versionId })}?${SearchRoutes.goToSupportingDocument.getPath(
                documentId
            )}${SearchRoutes.goToSupportingDocument.hash}`,
    },
};

export default SearchRoutes