import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { AnnotationStatus } from 'ApiClients/SterlingApiClients/Types';

const useAnnotationsNeedsReview = (
    projectId: string,
    projectVersionId: string,
    annotationApi: AnnotationsApiClient,
    refetchAnnotations: () => void,
    getComments: (annotationId: string) => void
) => {
    const setNeedsReview = (annotationId: string, status: AnnotationStatus, comment: string, flowMethods: FlowMethods) =>
        annotationApi.setNeedsReview(projectId!, projectVersionId, annotationId, {
            ...flowMethods,
            body: { status, comment },
            onSuccess: (data) => {
                flowMethods.onSuccess && flowMethods.onSuccess(data);
                refetchAnnotations();
                getComments(annotationId);
            },
        });

    return { setNeedsReview };
};

export default useAnnotationsNeedsReview;
