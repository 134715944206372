import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { FormMode } from '../../NewVersionForm.Module';
import { DocumentInfo } from '../DocumentUpload/useDocumentUploadState';
import useDocumentsPreviewState, { DocumentsPreviewState } from './DocumentsPreview/useDocumentsPreviewState';
import {
    Annotation,
    AnnotationsTransferProposition,
    CreateAnnotationFromModifiedTransferPropositionBody,
    ProjectSimple,
} from 'ApiClients/SterlingApiClients/Types';
import { PdfViewerWithToolbarComponentProps } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Component';
import useCompareState, { CompareState } from './Hooks/useCompareState';
import useAnnotationsTransferState, { AnnotationsTransferState } from './AnnotationsTransfer/useAnnotationsTransferState';
import { ProjectVersionForm } from '../../NewVersionForm.Component';
import { useEffect, useMemo, useState } from 'react';
import { DocumentFileConfiguration } from 'App/Consts';
import usePdfViewer from './Hooks/usePdfViewer';

export type DocsViewerStepsWrapperState = {
    activeStep: number;
    viewerPropsActive: PdfViewerWithToolbarComponentProps | null;
    viewerPropsDraft: PdfViewerWithToolbarComponentProps | null;
    compareState: CompareState;
    documentsPreviewState: DocumentsPreviewState;
    annotationsTransferState: AnnotationsTransferState;
};

const AllowedFileTypesConfig = DocumentFileConfiguration.allowedFileTypes;

const useDocsViewerStepsWrapperState = (
    // Common
    render: boolean,
    mode: FormMode,
    activeStep: number,
    nextStep: () => void,
    project: ProjectSimple,
    activeVersion: ProjectVersionForm,
    draftVersion: ProjectVersionForm | null,
    documentInfoDraftInput: DocumentInfo | null,
    annotationsTransfer: AnnotationsTransferProposition | null,
    ///// DocumentsPreview
    createDraftInput: (file: File, flowMethods: FlowMethods<string>) => void,
    convertDocxToPdf: (file: File, flowMethods: FlowMethods<File>) => void,
    ///// TrasnferAnnotations
    getAnnotationsTransfer: (draftId: string, flowMethods?: FlowMethods<AnnotationsTransferProposition | null>) => void,
    getAnnotations: (versionId: string, flowMethods?: FlowMethods<Array<Annotation>>) => void,
    acceptDraftProjectVersionInput: (draftId: string, flowMethods?: FlowMethods) => void,
    createAnnotationFromTransferPropositionInput: (draftId: string, annotationId: string, flowMethods?: FlowMethods) => void,
    createAnnotationFromModifiedTransferPropositionInput: (
        draftId: string,
        annotationId: string,
        body: CreateAnnotationFromModifiedTransferPropositionBody,
        flowMethods?: FlowMethods
    ) => void,
    rejectAnnotationTransferPropositionInput: (draftId: string, annotationId: string, displayToastSuccessMessages: boolean, flowMethods?: FlowMethods) => void,
    deleteAnnotationCreatedFromTransferPropositionInput: (
        draftId: string,
        annotationId: string,
        displayToastSuccessMessages: boolean,
        flowMethods?: FlowMethods
    ) => void,
    navigateToDocumentVersionList: () => void
): DocsViewerStepsWrapperState => {
    const [documentInfoDraft, setDocumentInfoDraft] = useState<DocumentInfo | null>(null);
    useEffect(() => {
        if (documentInfoDraftInput) {
            if (documentInfoDraftInput.file.type === AllowedFileTypesConfig.pdf.key) {
                setDocumentInfoDraft(documentInfoDraftInput);
            } else if (documentInfoDraftInput.file.type === AllowedFileTypesConfig.word.key) {
                convertDocxToPdf(documentInfoDraftInput.file, {
                    onSuccess: (file) => {
                        setDocumentInfoDraft({ ...documentInfoDraftInput, file });
                    },
                });
            }
        }
    }, [convertDocxToPdf, setDocumentInfoDraft, documentInfoDraftInput]);

    const enableWebViewerInstance = useMemo(
        () => (activeVersion.document !== null && documentInfoDraft !== null && render) || false,
        [activeVersion.document, documentInfoDraft, render]
    );
    const pdfViewerBasePropsActive = usePdfViewer(activeVersion.document, enableWebViewerInstance);
    const pdfViewerBasePropsDraft = usePdfViewer(documentInfoDraft, enableWebViewerInstance);
    const compareState = useCompareState();

    const documentsPreviewState = useDocumentsPreviewState(
        mode,
        project.id,
        nextStep,
        activeVersion.document,
        documentInfoDraft,
        documentInfoDraftInput,
        draftVersion?.documentIsProcessed || false,
        annotationsTransfer,
        createDraftInput
    );

    const annotationsTransferState = useAnnotationsTransferState(
        project,
        activeVersion,
        draftVersion,
        annotationsTransfer,
        getAnnotationsTransfer,
        getAnnotations,
        acceptDraftProjectVersionInput,
        createAnnotationFromTransferPropositionInput,
        createAnnotationFromModifiedTransferPropositionInput,
        rejectAnnotationTransferPropositionInput,
        deleteAnnotationCreatedFromTransferPropositionInput,
        navigateToDocumentVersionList
    );

    let pdfViewerOtherPropsActive = null;
    let pdfViewerOtherPropsDraft = null;

    switch (activeStep) {
        case 0:
        case 1:
            pdfViewerOtherPropsActive = documentsPreviewState.pdfViewerOtherProps;
            pdfViewerOtherPropsDraft = documentsPreviewState.pdfViewerOtherProps;
            break;
        case 2:
            pdfViewerOtherPropsActive = annotationsTransferState.pdfViewerOtherPropsActive;
            pdfViewerOtherPropsDraft = annotationsTransferState.pdfViewerOtherPropsDraft;
            break;
    }

    const viewerPropsActive: PdfViewerWithToolbarComponentProps | null =
        pdfViewerBasePropsActive && pdfViewerOtherPropsActive ? { ...pdfViewerBasePropsActive, ...pdfViewerOtherPropsActive } : null;
    const viewerPropsDraft: PdfViewerWithToolbarComponentProps | null =
        pdfViewerBasePropsDraft && pdfViewerOtherPropsDraft ? { ...pdfViewerBasePropsDraft, ...pdfViewerOtherPropsDraft } : null;

    return {
        activeStep,
        viewerPropsActive,
        viewerPropsDraft,
        compareState,
        documentsPreviewState,
        annotationsTransferState,
    };
};

export default useDocsViewerStepsWrapperState;
