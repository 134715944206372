import { EmotionCache } from '@emotion/react';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { getZoomRounded } from '../../useDrawing';
import { createRoot } from 'react-dom/client';
import { Dimensions, Position, getElementPositionBasedOn } from './Common';
import { getHighlightId, getHighlightPartIndex } from '../ViewerAnnotations/Attributes';
import HighlightPartNumberComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPartNumber/HighlightPartNumber.Component';

export const PartNumberContainerClassName = 'part-number-container';
export const PartNumberClassName = 'part-number';
export const PartNumberAnnotationIdAttribute = 'part-number-annot-id';

export const eraseHighlightPartNumber = (instance: WebViewerInstance) => {
    const toRemove = instance.UI.iframeWindow.document.getElementsByClassName(PartNumberContainerClassName);
    for (const r of toRemove) {
        r.remove();
    }
};

export const drawHighlightPartNumber = (instance: WebViewerInstance, selectedHighlightId: string | null, emotionCache: EmotionCache) => {
    if (selectedHighlightId) {
        const appRoot = instance.UI.iframeWindow.document.body.getElementsByClassName('App')[0];

        if (appRoot) {
            const container = document.createElement('div');
            container.classList.add(PartNumberContainerClassName);
            appRoot.appendChild(container);

            const core = instance.Core;
            const { documentViewer } = instance.Core;
            const zoomRounded = getZoomRounded(documentViewer.getZoomLevel());
            const annots = core.annotationManager.getAnnotationsList().filter((a) => getHighlightId(a) === selectedHighlightId);

            if (annots.length > 1) {
                annots.forEach((annot) => {
                    const width = 32;
                    const height = 16;

                    const newDiv = instance.UI.iframeWindow.document.createElement('div');
                    newDiv.classList.add(PartNumberClassName);
                    newDiv.setAttribute(PartNumberAnnotationIdAttribute, annot.Id);
                    newDiv.style.position = 'absolute';
                    newDiv.style.height = `${height}px`;
                    newDiv.style.width = `${width}px`;
                    newDiv.style.zIndex = '100';

                    const coordinates = _getPosition(instance, annot, { width, height });

                    newDiv.style.top = `${coordinates.top}px`;
                    newDiv.style.left = `${coordinates.left}px`;
                    const root = createRoot(newDiv);
                    root.render(
                        <HighlightPartNumberComponent
                            webViewerInstance={instance}
                            highlightId={selectedHighlightId}
                            partNumber={getHighlightPartIndex(annot) + 1}
                            partsCount={annots.length}
                            scale={zoomRounded}
                            emotionCache={emotionCache}
                        />
                    );
                    container.appendChild(newDiv);
                });
            }
        }
    }
};

export const recalculateHighlightPartNumberPosition = (instance: WebViewerInstance) => {
    const elements = instance.UI.iframeWindow.document.getElementsByClassName(PartNumberClassName);
    const zoom = instance.Core.documentViewer.getZoomLevel();
    const zoomRounded = getZoomRounded(zoom);

    for (const e of elements) {
        const annotId = e.getAttribute(PartNumberAnnotationIdAttribute);
        const annot = instance.Core.annotationManager.getAnnotationById(annotId!);

        const coordinates = _getPosition(instance, annot, { width: e.clientWidth, height: e.clientHeight });

        (e as HTMLDivElement).style.top = `${coordinates.top}px`;
        (e as HTMLDivElement).style.left = `${coordinates.left}px`;
        if (e.children.length > 0) {
            (e.children[0] as HTMLDivElement).style.transform = `scale(${zoomRounded})`;
        }
    }
};

const _getPosition = (instance: WebViewerInstance, annotation: Core.Annotations.Annotation, elementDimensions: Dimensions) =>
    getElementPositionBasedOn(instance, annotation, elementDimensions, _calculatePosition);

const _calculatePosition = (topLeft: Position, _bottomRight: Position, elementDimensions: Dimensions) => {
    const left = topLeft.x - elementDimensions.width;
    const top = topLeft.y;

    return {
        left,
        top,
    };
};
