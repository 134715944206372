import { useCallback, useEffect, useRef, useState } from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import SetUpViewer from './Functions/SetUpViewer';
import GetPositionDiff, { getSelectedAnnotations } from './Functions/DiffPosition';

export type SyncState = {
    removeHandlerFunctions: Array<() => void>;
};

export type Primary = 'left' | 'right';
export type Syncing = {
    primary: Primary;
    positionDiff: number;
    updatedNum: number;
};

const useViewersSync = (
    webViewerInstanceLeft: WebViewerInstance | null,
    webViewerInstanceRight: WebViewerInstance | null,
    leftHighlightSelectedId: string | null,
    rightHighlightSelectedId: string | null,
    siblingSync?: boolean
) => {
    const syncStateRef = useRef<SyncState>({
        removeHandlerFunctions: [],
    });

    const siblingSyncRef = useRef(siblingSync);
    siblingSyncRef.current = siblingSync;

    const [syncing, _setSyncing] = useState<Syncing | null>(null);
    const positionDiffRef = useRef(syncing?.positionDiff || null);
    positionDiffRef.current = syncing?.positionDiff || null;

    const setSyncing = useCallback(
        (primary: Primary) => {
            _setSyncing((prev) => {
                if (prev && prev.primary === primary) {
                    return null;
                }

                if (webViewerInstanceLeft && webViewerInstanceRight) {
                    const positionDiff = GetPositionDiff(
                        webViewerInstanceLeft,
                        webViewerInstanceRight,
                        leftHighlightSelectedId,
                        rightHighlightSelectedId,
                        siblingSync
                    );

                    return {
                        primary,
                        positionDiff,
                        updatedNum: 0,
                    };
                }

                return null;
            });
        },
        [webViewerInstanceLeft, webViewerInstanceRight, leftHighlightSelectedId, rightHighlightSelectedId, siblingSync]
    );

    useEffect(() => {
        if (webViewerInstanceLeft && webViewerInstanceRight) {
            if (syncing && syncStateRef.current.removeHandlerFunctions.length === 0)
                startSyncing(syncing.primary, syncStateRef, webViewerInstanceLeft, webViewerInstanceRight, siblingSyncRef, positionDiffRef);
            else if (!syncing) stopSyncing(syncStateRef);
        }
        // eslint-disable-next-line
    }, [syncing]);

    useEffect(() => {
        if (webViewerInstanceLeft && webViewerInstanceRight && syncing && siblingSync) {
            const positionDiff = GetPositionDiff(webViewerInstanceLeft, webViewerInstanceRight, leftHighlightSelectedId, rightHighlightSelectedId, siblingSync);
            _setSyncing((prev) => {
                if (prev) return { ...prev, positionDiff, updatedNum: prev.updatedNum + 1 };

                return null;
            });
        }
        // eslint-disable-next-line
    }, [leftHighlightSelectedId, rightHighlightSelectedId]);

    useEffect(() => {
        if (webViewerInstanceLeft && webViewerInstanceRight && syncing && syncing.updatedNum > 0) {
            const { leftAnnot, rightAnnot } = getSelectedAnnotations(
                webViewerInstanceLeft,
                webViewerInstanceRight,
                leftHighlightSelectedId,
                rightHighlightSelectedId
            );
            if (leftAnnot) {
                webViewerInstanceLeft.Core.documentViewer.getAnnotationManager().jumpToAnnotation(leftAnnot);
            }
            if (rightAnnot) {
                webViewerInstanceRight.Core.documentViewer.getAnnotationManager().jumpToAnnotation(rightAnnot);
            }
        }
        // eslint-disable-next-line
    }, [syncing?.updatedNum]);

    return {
        syncing,
        setSyncing,
    };
};

const startSyncing = (
    primary: Primary,
    syncStateRef: React.MutableRefObject<SyncState>,
    webViewerInstanceLeft: WebViewerInstance,
    webViewerInstanceRight: WebViewerInstance,
    siblingSyncRef: React.MutableRefObject<boolean | undefined>,
    positionDiffRef: React.MutableRefObject<number | null>
) => {
    const primaryInstance = primary === 'left' ? webViewerInstanceLeft : webViewerInstanceRight;
    const secondaryInstance = primary === 'left' ? webViewerInstanceRight : webViewerInstanceLeft;

    if (primaryInstance && secondaryInstance) {
        const primaryViewer = primaryInstance.Core.documentViewer;
        const secondaryViewer = secondaryInstance.Core.documentViewer;

        // Zoom
        const zoomLevel = primaryViewer.getZoom();
        secondaryViewer.zoomTo(zoomLevel);
        // Scroll
        if (siblingSyncRef.current && positionDiffRef.current) {
            const primaryScrollContainer = getScrollContainer(primaryInstance);
            const secondaryScrollContainer = getScrollContainer(secondaryInstance);

            const scrollTop = primaryScrollContainer.scrollTop + (primary === 'left' ? -positionDiffRef.current : positionDiffRef.current);

            secondaryScrollContainer.scrollTop = scrollTop;
        }

        SetUpViewer(syncStateRef, webViewerInstanceLeft, webViewerInstanceRight, positionDiffRef, 'left');
        SetUpViewer(syncStateRef, webViewerInstanceRight, webViewerInstanceLeft, positionDiffRef, 'right');
    }
};

const stopSyncing = (syncStateRef: React.MutableRefObject<SyncState>) => {
    syncStateRef.current.removeHandlerFunctions.forEach((removeHandler) => removeHandler());
    syncStateRef.current.removeHandlerFunctions = [];
};

export const getScrollContainer = (instance: WebViewerInstance) => instance.UI.iframeWindow.document.getElementsByClassName('DocumentContainer')[0];

export default useViewersSync;
