import { Highlight } from '../PdfViewerWithToolbar.Types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { IconType } from 'UI/Icon';
import { Icon, ThemeProvider, Tooltip, Typography } from 'UI';
import { palette } from 'UI/Provider/VerifiTheme';
import { WebViewerInstance } from '@pdftron/webviewer';
import { getZoomRounded } from '../Hooks/Annotations/useDrawing';
import { getHighlightId, isLastAnnotationOfHighlight } from '../Hooks/Annotations/Drawing/ViewerAnnotations/Attributes';

type HighlightLeftTopInformationComponentProps<THighlightCustomData> = {
    instance: WebViewerInstance;
    highlight: Highlight<THighlightCustomData>;
    siblings: Array<Highlight<THighlightCustomData>>;
    selectedHighlightId: string | null;
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>;
    emotionCache: EmotionCache;
    tooltipsContainer: HTMLDivElement;
};

export type HighlightLeftTopInformation<THighlightCustomData> = (highlight: Highlight<THighlightCustomData>) => Array<Info>;

type Info = {
    textContent?: string;
    iconContent?: IconType;
};

const _getScale = (instance: WebViewerInstance) => getZoomRounded(instance.Core.documentViewer.getZoomLevel());

function HighlightLeftTopInformationComponent<THighlightCustomData>(props: HighlightLeftTopInformationComponentProps<THighlightCustomData>) {
    const { instance, highlight, siblings, selectedHighlightId, leftTopInformation, emotionCache, tooltipsContainer } = props;

    const rootRef = useRef<HTMLDivElement | null>(null);
    const selected = useMemo(() => selectedHighlightId === highlight.id, [selectedHighlightId, highlight.id]);
    const information = useMemo(() => leftTopInformation(highlight), [highlight, leftTopInformation]);
    useEffect(() => {
        if (selected) {
            const annotationRoot = rootRef.current?.parentElement?.parentElement;
            if (annotationRoot) annotationRoot.style.zIndex = '100';
        }
    }, [selected]);

    const showTooltip = useMemo(() => siblings.length > 1, [siblings]);

    const getScale = useCallback(() => _getScale(instance), [instance]);
    const [scale, setScale] = useState(getScale());

    const selectHighlight = useCallback((highlightId: string) => _selectHighlight(instance, highlightId), [instance]);

    return (
        <ThemeProvider>
            <CacheProvider value={emotionCache}>
                <Tooltip
                    tooltipsx={{
                        transform: `scale(${scale}) !important`,
                        padding: '0.25rem',
                    }}
                    title={
                        showTooltip ? (
                            <HighlightLeftTopInformationTooltip
                                siblings={siblings}
                                selectedHighlightId={selectedHighlightId}
                                leftTopInformation={leftTopInformation}
                                selectHighlight={selectHighlight}
                            />
                        ) : (
                            ''
                        )
                    }
                    onOpen={() => setScale(getScale())}
                    PopperProps={{
                        container: tooltipsContainer,
                    }}
                >
                    <Box
                        ref={rootRef}
                        sx={{
                            position: 'absolute',
                            right: '0rem',
                            minWidth: '1.5rem',
                            height: '1rem',
                            fontWeight: 700,
                            fontSize: '0.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRadius: '0.25rem',
                            paddingLeft: '0.25rem',
                            paddingRight: showTooltip ? '0.5rem' : '0.25rem',
                            cursor: 'pointer',
                            transform: `scale(${scale})`,
                            transformOrigin: `top right`,
                            ...(selected
                                ? {
                                      backgroundColor: palette.blue.light,
                                      border: `0.125rem solid ${palette.blue.dark}`,
                                  }
                                : {
                                      backgroundColor: palette.white.main,
                                      border: `0.125rem solid ${palette.stroke.main}`,
                                  }),
                        }}
                        data-cy='highlight-left-top-information'
                    >
                        {showTooltip && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '0rem',
                                    right: '0rem',
                                }}
                            >
                                <Icon.ArrowUpRightSquare height={6} width={6} />
                            </Box>
                        )}
                        {information.map((info, idx) => {
                            const last: boolean = idx === information.length - 1;
                            return (
                                <Box
                                    key={`highlight-leftTopInformation-${idx}`}
                                    sx={{ marginRight: last ? '0rem' : '0.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {info.textContent && <Typography sx={{ fontSize: '0.5rem' }}>{info.textContent}</Typography>}
                                    {info.iconContent && <info.iconContent key={`highlight-leftTopInformation-${idx}`} height='0.5rem' width='0.5rem' />}
                                </Box>
                            );
                        })}
                    </Box>
                </Tooltip>
            </CacheProvider>
        </ThemeProvider>
    );
}

const _selectHighlight = (instance: WebViewerInstance, highlightId: string) => {
    const { annotationManager } = instance.Core;
    const annot = annotationManager.getAnnotationsList().find((a) => getHighlightId(a) === highlightId && isLastAnnotationOfHighlight(a) === true);
    if (annot) annotationManager.selectAnnotation(annot);
};

type HighlightLeftTopInformationTooltipComponentProps<THighlightCustomData> = {
    siblings: Array<Highlight<THighlightCustomData>>;
    selectedHighlightId: string | null;
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>;
    selectHighlight: (highlightId: string) => void;
};

function HighlightLeftTopInformationTooltip<THighlightCustomData>(props: HighlightLeftTopInformationTooltipComponentProps<THighlightCustomData>) {
    const { siblings, selectedHighlightId, leftTopInformation, selectHighlight } = props;

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: 0.25 }}>
            {
                // Reverse the highlights array to keep consistent with the left hand nav
                [...siblings].reverse().map((s) => (
                    <HighlightLeftTopInformationNode
                        key={`node-${s.id}`}
                        highlight={s}
                        selected={s.id === selectedHighlightId}
                        leftTopInformation={leftTopInformation}
                        selectHighlight={selectHighlight}
                    />
                ))
            }
        </Box>
    );
}

type HighlightLeftTopInformationNodeComponentProps<THighlightCustomData> = {
    highlight: Highlight<THighlightCustomData>;
    selected: boolean;
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>;
    selectHighlight: (highlightId: string) => void;
};

function HighlightLeftTopInformationNode<THighlightCustomData>(props: HighlightLeftTopInformationNodeComponentProps<THighlightCustomData>) {
    const { highlight, selected, leftTopInformation, selectHighlight } = props;
    const information = leftTopInformation(highlight);

    return (
        <Box
            onClick={(e) => {
                selectHighlight(highlight.id);
                e.stopPropagation();
            }}
            sx={{
                display: 'flex',
                minWidth: '1rem',
                height: '0.75rem',
                fontWeight: 700,
                fontSize: '0.3rem',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center',
                borderRadius: '0.25rem',
                paddingLeft: '0.25rem',
                paddingRight: '0.25rem',
                cursor: 'pointer',
                boxSizing: 'border-box',
                ...(selected
                    ? {
                          backgroundColor: palette.blue.light,
                          border: `0.125rem solid ${palette.blue.dark}`,
                      }
                    : {
                          backgroundColor: palette.white.main,
                          border: `0.125rem solid ${palette.stroke.main}`,
                      }),
            }}
            data-cy='highlight-left-top-information'
        >
            {information.map((info, idx) => {
                const last: boolean = idx === information.length - 1;
                return (
                    <Box
                        key={`highlight-leftTopInformation-${idx}`}
                        sx={{ marginRight: last ? '0rem' : '0.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        {info.textContent && <Typography sx={{ fontSize: '0.3rem' }}>{info.textContent}</Typography>}
                        {info.iconContent && <info.iconContent key={`highlight-leftTopInformation-${idx}`} height='0.3rem' width='0.3rem' />}
                    </Box>
                );
            })}
        </Box>
    );
}

export default HighlightLeftTopInformationComponent;
