import { useMemo } from 'react';
import { Highlight } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { getContentType } from 'Views/Project/ProjectWorkspace/Types';
import { hexToRGBA } from 'UI/functions';
import { AnnotationTransferPropositionStatus, CreateAnnotationFromModifiedTransferPropositionBody } from 'ApiClients/SterlingApiClients/Types';
import { AnnotationTransferData, StatusConfig } from '../useAnnotationsTransferState';
import { PdfViewerOtherProps } from '../../Hooks/usePdfViewer';
import CustomTransferPanelWrapperComponent from '../PdfViewerComponents/CustomTransferPanelWrapper/CustomTransferPanelWrapper.Component';
import PropositionTransferPanelWrapperComponent from '../PdfViewerComponents/PropositionTransferPanelWrapper/PropositionTransferPanelWrapper.Component';
import ModyfingPropositionTransferPanelWrapperComponent from '../PdfViewerComponents/ModyfingPropositionTransferPanelWrapper/ModyfingPropositionTransferPanelWrapper.Component';

type MethodWithSetFetching = (setFetching: (isFetching: boolean) => void) => void;

const usePdfViewerDraft = (
    selectedAnnotationIdActiveVersion: string | null,
    selectedAnnotationId: string | null,
    setSelectedAnnotationId: (annotationId: string | null) => void,
    data: Array<AnnotationTransferData>,
    onlyReject: boolean,
    createAnnotationFromTransferProposition: MethodWithSetFetching,
    createAnnotationFromModifiedTransferProposition: (
        setFetching: (isFetching: boolean) => void,
        body: CreateAnnotationFromModifiedTransferPropositionBody
    ) => void,
    reject: MethodWithSetFetching
): PdfViewerOtherProps => {
    const highlights = useMemo(
        () => [
            ...data
                .filter((el) => el.status !== AnnotationTransferPropositionStatus.Removed)
                .map((el): Highlight => {
                    const annot = el.annotationActive;
                    const draftAnnot = el.annotationDraft;
                    const proposition = el.proposition;
                    return {
                        id: annot.id,
                        orderNumber: annot.orderNumber,
                        contentType: getContentType(draftAnnot ? draftAnnot.content.selectionType : proposition.content!.selectionType),
                        boundingBoxSections: draftAnnot ? draftAnnot.content.boundingBoxSections : proposition.content!.boundingBoxSections,
                        color:
                            annot.id === selectedAnnotationId ? hexToRGBA(StatusConfig[el.status].selectedColor) : hexToRGBA(StatusConfig[el.status].baseColor),
                        siblingsId: annot.id,
                    };
                }),
        ],
        [data, selectedAnnotationId]
    );

    const highlightToolbarsDeps: React.DependencyList = useMemo(() => {
        return [highlights, selectedAnnotationId, createAnnotationFromTransferProposition, createAnnotationFromModifiedTransferProposition, reject, onlyReject];
    }, [highlights, selectedAnnotationId, createAnnotationFromTransferProposition, createAnnotationFromModifiedTransferProposition, reject, onlyReject]);

    const isCreatingHighlightEnabled: boolean = useMemo(() => {
        if (selectedAnnotationIdActiveVersion) {
            const annotation = data.find((el) => el.annotationActive.id === selectedAnnotationIdActiveVersion);
            if (annotation) {
                return annotation.status !== AnnotationTransferPropositionStatus.Found;
            }
        }

        return false;
    }, [data, selectedAnnotationIdActiveVersion]);

    return {
        displayHighlightIcons: true,
        isCreatingHighlightEnabled,
        highlightsProps: {
            highlights: highlights,
            highlightToolbars: {
                newHighlightToolbar: {
                    render: (options) => (
                        <CustomTransferPanelWrapperComponent
                            newHighlightToolbarOptions={options}
                            transferAnnotation={createAnnotationFromModifiedTransferProposition}
                        />
                    ),
                },
                highlightToolbar: {
                    render: (options) => (
                        <PropositionTransferPanelWrapperComponent
                            highlightToolbarOptions={options}
                            create={createAnnotationFromTransferProposition}
                            reject={reject}
                            onlyReject={onlyReject}
                        />
                    ),
                },
                modifiedHighlightToolbar: {
                    render: (options) => (
                        <ModyfingPropositionTransferPanelWrapperComponent
                            modifiedHighlightToolbarOptions={options}
                            create={createAnnotationFromModifiedTransferProposition}
                            reject={reject}
                            onlyReject={onlyReject}
                        />
                    ),
                },
                deps: highlightToolbarsDeps,
            },
            highlightLeftTopInformation: (highlight) => [{ textContent: highlight.orderNumber.toString() }],
            selectedHighlightId: selectedAnnotationId,
            setSelectedHighlightId: setSelectedAnnotationId,
            clearSelectedHighlight: () => setSelectedAnnotationId(null),
            focusOnSelectedHighlightAfterDraw: true,
            keepSelectedHighlightInCentre: true,
        },
    };
};

export default usePdfViewerDraft;
