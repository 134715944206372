import { AuthUser } from 'ApiClients/Auth.ApiClient';
import { UserRole } from 'ApiClients/SterlingApiClients/Types';
import { AuthContextProviderMethods, AuthContextProviderValue } from 'Contexts/Auth.Context';
import { useCallback, useState } from 'react';

const useAuth = (): AuthContextProviderValue & AuthContextProviderMethods => {
    const [authContextProviderValue, setAuthContextProviderValue] = useState<AuthContextProviderValue>({
        isAuthenticated: false,
        user: {
            id: '',
            name: '',
            email: '',
            roles: [],
        },
    });

    const setAuth = useCallback(
        (user: AuthUser, isAuthenticated: boolean) => setAuthContextProviderValue({ user, isAuthenticated }),
        [setAuthContextProviderValue]
    );

    const emailDomain: string = 'verifi.legal';
    const userName: string = authContextProviderValue.user.name;
    const userEmail: string = userName.length > 0 ? `${userName.split(' ')[0][0].toLowerCase()}${userName.split(' ')[1].toLowerCase()}@${emailDomain}` : '';

    return {
        ...authContextProviderValue,
        user: {
            ...authContextProviderValue.user,
            email: userEmail,
        },
        checkRole: (role: UserRole) => authContextProviderValue.user.roles.includes(role),
        setAuth,
    };
};

export default useAuth;
