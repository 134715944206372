import { NavBarContents } from 'App/NavBar/NavBar.Component';
import NewVersionFormComponent from './NewVersionForm.Component';
import ProjectsApiClient from 'ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import { useParams } from 'react-router-dom';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import {
    Annotation,
    AnnotationsTransferProposition,
    CreateAnnotationFromModifiedTransferPropositionBody,
    ProjectSimple,
} from 'ApiClients/SterlingApiClients/Types';
import LoadingPageComponent from 'App/LoadingPage/LoadingPage.Component';
import useProjectVersion from './Hooks/useProjectVersion';
import ProjectDocumentsApiClient from 'ApiClients/SterlingApiClients/ProjectDocumentsApiClient/ProjectDocuments.ApiClient';
import ProjectVersionsApiClient from 'ApiClients/SterlingApiClients/ProjectVersionsApiClient/ProjectVersions.ApiClient';
import { useCallback, useEffect, useMemo } from 'react';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import NotificationsConnector from 'App/Notifications/NotificationsConnector';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import FilesApiClient from 'ApiClients/SterlingApiClients/FilesApiClient/Files.ApiClient';

export type NewVersionFormModuleProps = {
    mode: FormMode;
    navBarContents: NavBarContents;
    projectsApiClient: ProjectsApiClient;
    projectDocumentsApiClient: ProjectDocumentsApiClient;
    projectVersionsApiClient: ProjectVersionsApiClient;
    notificationsConnector: NotificationsConnector;
    annotationsApiClient: AnnotationsApiClient;
    filesApiClient: FilesApiClient;
};

export type FormMode = 'create' | 'edit';

function NewVersionFormModule(props: NewVersionFormModuleProps) {
    const {
        mode,
        navBarContents,
        projectsApiClient,
        projectDocumentsApiClient,
        projectVersionsApiClient,
        notificationsConnector,
        annotationsApiClient,
        filesApiClient,
    } = props;
    const { projectId, draftId } = useParams();

    const { data: project } = usePromiseWithFlowMethods<{}, ProjectSimple | null>({
        method: (_input, flowMethods) => projectsApiClient.getProject(projectId!, flowMethods),
        initialData: null,
        initFetch: { input: {} },
    });

    const disableFetchActiveVersion = useMemo(() => project === null, [project]);
    const disableFetchDraftVersion = useMemo(() => mode !== 'edit' || disableFetchActiveVersion, [mode, disableFetchActiveVersion]);

    const { version: activeVersion } = useProjectVersion(
        projectDocumentsApiClient,
        projectVersionsApiClient,
        project?.id || '',
        project?.workingVersion.id || '',
        disableFetchActiveVersion
    );
    const { version: draftVersion, setDocumentIsProcessed: setDraftDocumentIsProcessed } = useProjectVersion(
        projectDocumentsApiClient,
        projectVersionsApiClient,
        project?.id || '',
        draftId!,
        disableFetchDraftVersion
    );

    const createDraft = useCallback(
        (file: File, flowMethods: FlowMethods<string>) =>
            projectVersionsApiClient.createDraftProjectVersion(projectId!, {
                ...flowMethods,
                body: { verificationDocument: file },
            }),
        [projectId, projectVersionsApiClient]
    );

    const { data: annotationsTransfer, wrappedMethod: _getAnnotationsTransfer } = usePromiseWithFlowMethods<
        { projectId: string; draftId: string },
        AnnotationsTransferProposition | null
    >({
        method: (input, flowMethods) => projectVersionsApiClient.getAnnotationsTransferProposition(input.projectId, input.draftId, flowMethods),
        initialData: null,
    });
    const getAnnotationsTransfer = useCallback(
        (draftId: string, flowMethods?: FlowMethods<AnnotationsTransferProposition | null>) =>
            _getAnnotationsTransfer({ projectId: projectId!, draftId }, flowMethods),
        [_getAnnotationsTransfer, projectId]
    );
    useEffect(() => {
        if (draftVersion && draftVersion.documentIsProcessed) {
            getAnnotationsTransfer(draftVersion.id);
        }
    }, [draftVersion, getAnnotationsTransfer]);

    const displayComponent = useMemo(() => {
        if (project && activeVersion) return true;
        else return false;
    }, [project, activeVersion]);

    const getAnnotations = useCallback(
        (versionId: string, flowMethods?: FlowMethods<Array<Annotation>>) => annotationsApiClient.getAnnotations(projectId!, versionId, flowMethods),
        [projectId, annotationsApiClient]
    );

    const acceptDraftProjectVersionInput = useCallback(
        (draftId: string, flowMethods?: FlowMethods) => projectVersionsApiClient.acceptDraftProjectVersion(projectId!, draftId, flowMethods),
        [projectId, projectVersionsApiClient]
    );

    const createAnnotationFromTransferPropositionInput = useCallback(
        (draftId: string, annotationId: string, flowMethods?: FlowMethods) =>
            projectVersionsApiClient.createAnnotationFromTransferProposition(projectId!, draftId, annotationId, flowMethods),
        [projectId, projectVersionsApiClient]
    );

    const createAnnotationFromModifiedTransferPropositionInput = useCallback(
        (draftId: string, annotationId: string, body: CreateAnnotationFromModifiedTransferPropositionBody, flowMethods?: FlowMethods) =>
            projectVersionsApiClient.createAnnotationFromModifiedTransferProposition(projectId!, draftId, annotationId, {
                ...flowMethods,
                body,
            }),
        [projectId, projectVersionsApiClient]
    );

    const rejectAnnotationTransferPropositionInput = useCallback(
        (draftId: string, annotationId: string, displayToastSuccessMessages: boolean, flowMethods?: FlowMethods) =>
            projectVersionsApiClient.rejectAnnotationTransferProposition(projectId!, draftId, annotationId, displayToastSuccessMessages, flowMethods),
        [projectId, projectVersionsApiClient]
    );

    const deleteAnnotationCreatedFromTransferPropositionInput = useCallback(
        (draftId: string, annotationId: string, displayToastSuccessMessages: boolean, flowMethods?: FlowMethods) =>
            projectVersionsApiClient.deleteAnnotationCreatedFromTransferProposition(
                projectId!,
                draftId,
                annotationId,
                displayToastSuccessMessages,
                flowMethods
            ),
        [projectId, projectVersionsApiClient]
    );

    const convertDocxToPdf = useCallback(
        (file: File, flowMethods: FlowMethods<File>) =>
            filesApiClient.convertDocxToPdf(file.name, {
                ...flowMethods,
                body: { file },
                bodyAsDataForm: true,
            }),
        [filesApiClient]
    );

    return displayComponent ? (
        <NewVersionFormComponent
            notificationsConnector={notificationsConnector}
            mode={mode}
            navBarContents={navBarContents}
            project={project!}
            activeVersion={activeVersion!}
            draftVersion={draftVersion}
            createDraft={createDraft}
            setDraftDocumentIsProcessed={setDraftDocumentIsProcessed}
            getAnnotationsTransfer={getAnnotationsTransfer}
            getAnnotations={getAnnotations}
            annotationsTransfer={annotationsTransfer}
            acceptDraftProjectVersionInput={acceptDraftProjectVersionInput}
            createAnnotationFromTransferPropositionInput={createAnnotationFromTransferPropositionInput}
            createAnnotationFromModifiedTransferPropositionInput={createAnnotationFromModifiedTransferPropositionInput}
            rejectAnnotationTransferPropositionInput={rejectAnnotationTransferPropositionInput}
            deleteAnnotationCreatedFromTransferPropositionInput={deleteAnnotationCreatedFromTransferPropositionInput}
            convertDocxToPdf={convertDocxToPdf}
        />
    ) : (
        <LoadingPageComponent />
    );
}

export default NewVersionFormModule;
