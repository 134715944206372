import NotificationsConnector from './NotificationsConnector';
import { Message } from './NotificationsConnectorTypes';

type MethodNames = {
    hub: string;
    subscribe: string;
    unsubscribe: string;
};

export default abstract class NotificationsSubscriber<TMessageData> {
    private _connector: NotificationsConnector;
    private _methodNames: MethodNames;
    private _args: Array<any>;
    private _eventsHandler: (message: Message<TMessageData>) => void;

    constructor(
        notificationsConnector: NotificationsConnector,
        methodNames: MethodNames,
        args: Array<any>,
        eventsHandler: (message: Message<TMessageData>) => void
    ) {
        this._connector = notificationsConnector;
        this._methodNames = methodNames;
        this._args = args;
        this._eventsHandler = eventsHandler;
        this._connector.connection!.on(this._methodNames.hub, (message: Message<TMessageData>) => this._eventsHandler(message));
        this._connector.onReconnect = () => this.subscribe();
    }

    public subscribe = () => {
        if (this._connector.isConnected()) {
            this._subscribe();
        } else {
            const maxNumberOfRetries = 5;
            let numberOfRetries = 1;
            let timeout = setInterval(() => {
                if (numberOfRetries >= maxNumberOfRetries) {
                    clearInterval(timeout);
                } else if (this._connector.isConnected()) {
                    this._subscribe();
                    clearInterval(timeout);
                }

                numberOfRetries++;
            }, 1000);
        }
    };

    public unSubscribe = () => {
        if (this._connector.isConnected()) {
            this._connector.connection!.send(this._methodNames.unsubscribe, ...this._args);
            this._clearEvents();
        }
    };

    private _clearEvents = () => {
        this._eventsHandler = () => {};
    };

    private _subscribe() {
        this._connector.connection!.send(this._methodNames.subscribe, ...this._args);
    }
}
