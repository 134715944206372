import { useEffect, useRef } from 'react';
import EditPanelComponent from './EditPanel/EditPanel.Component';
import { AnnotationHighlightCustomData } from '../VerificationDocument.Component';
import AutoAnnotationPanelComponent from '../Common/AutoAnnotationPanel/AutoAnnotationPanel.Component';
import { AnnotationStatus } from 'ApiClients/SterlingApiClients/Types';
import { Highlight } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

export type AnnotationPanelWrapperComponentProps = {
    getSelectedAnnotationId: () => string;
    highlight: Highlight<AnnotationHighlightCustomData>;
    setRef: (ref: React.RefObject<HTMLDivElement>) => void;
    supportingInformationPropositionsOpen: boolean;
    openDeleteModal: (id: string) => void;
    openAssignmentModal: (id: string) => void;
    verifyAnnotation: (id: string) => void;
    openAssertionModal: (id: string) => void;
    isEditor: boolean;
    openTagAssignmentModal: (id: string) => void;
    isVerificationDocumentProcessed: boolean;
    autoAnnotationProps: AutoAnnotationProps;
    showHideAnnotationElements: (id: string, show: boolean) => void;
    isAnnotateStatus: boolean;
    openSuppInfoSummaryModal: (id: string) => void;
    acceptAnnotationStage: (id: string, currentStatus: AnnotationStatus) => void;
    annotationStatus: AnnotationStatus | null;
    nextAnnotationStatus: AnnotationStatus | null;
    openNeedsReviewModal: (id: string) => void;
    needsReview: boolean;
};

type AutoAnnotationProps = {
    createAnnotation: (id: string) => void;
    modifyAnnotation: () => void;
    rejectAnnotation: (id: string) => void;
};

function AnnotationPanelWrapperComponent(props: AnnotationPanelWrapperComponentProps) {
    const {
        getSelectedAnnotationId,
        highlight,
        setRef,
        supportingInformationPropositionsOpen,
        openDeleteModal,
        openAssignmentModal,
        openNeedsReviewModal,
        verifyAnnotation,
        openAssertionModal,
        openTagAssignmentModal,
        isEditor,
        isVerificationDocumentProcessed,
        autoAnnotationProps,
        showHideAnnotationElements,
        isAnnotateStatus,
        openSuppInfoSummaryModal,
        acceptAnnotationStage,
        annotationStatus,
        nextAnnotationStatus,
        needsReview,
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setRef(ref);
    }, [setRef]);

    return (
        <div ref={ref}>
            {highlight && (
                <>
                    {highlight.type === 'annotation' && (
                        <EditPanelComponent
                            openDeleteModal={() => openDeleteModal(getSelectedAnnotationId())}
                            openAssignmentModal={() => openAssignmentModal(getSelectedAnnotationId())}
                            openTagAssignmentModal={() => openTagAssignmentModal(getSelectedAnnotationId())}
                            openNeedsReviewModal={() => openNeedsReviewModal(getSelectedAnnotationId())}
                            supportingInformationPropositionsOpen={supportingInformationPropositionsOpen}
                            verifyAnnotation={() => verifyAnnotation(getSelectedAnnotationId())}
                            openAssertionModal={() => openAssertionModal(getSelectedAnnotationId())}
                            isEditor={isEditor}
                            isVerificationDocumentProcessed={isVerificationDocumentProcessed}
                            showHideAnnotationElements={(show) => showHideAnnotationElements(getSelectedAnnotationId(), show)}
                            isAnnotateStatus={isAnnotateStatus}
                            openSuppInfoSummaryModal={() => openSuppInfoSummaryModal(getSelectedAnnotationId())}
                            acceptAnnotationStage={() => nextAnnotationStatus && acceptAnnotationStage(getSelectedAnnotationId(), annotationStatus!)}
                            nextAnnotationStatus={nextAnnotationStatus}
                            needsReview={needsReview}
                        />
                    )}
                    {highlight.type === 'autoAnnotation' && (
                        <AutoAnnotationPanelComponent
                            createAnnotation={() => autoAnnotationProps.createAnnotation(highlight.id)}
                            modifyAnnotation={autoAnnotationProps.modifyAnnotation}
                            rejectAnnotation={() => autoAnnotationProps.rejectAnnotation(highlight.id)}
                            showHideAnnotationElements={(show) => showHideAnnotationElements(highlight.id, show)}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default AnnotationPanelWrapperComponent;
