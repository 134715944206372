import { useState, useMemo, useCallback, useEffect } from 'react';
import { ProjectConfigParam, ProjectConfigParamEnum, ProjectDocumentType, TransactionType } from 'ApiClients/SterlingApiClients/Types';
import { Box, Card, CardHeader, CardContent, CardActions, Container, Divider } from '@mui/material';
import CreatorProjectDetails, { TransactionTypeOptions } from './CreatorProjectDetails/CreatorProjectDetails.Component';
import CreatorPartiesInvolved from './CreatorPartiesInvolved/CreatorPartiesInvolved.Component';
import { BackButton, Button, Stepper } from 'UI';
import { palette } from 'UI/Provider/VerifiTheme';

export type ProjectCreatorComponentProps = {
    addProject: (
        name: string,
        file: File,
        transactionType: TransactionType,
        configParams: Array<ProjectConfigParam>,
        documentType: ProjectDocumentType
    ) => void;
    uploading: boolean;
};

const buttonWidth = { width: '10rem' };

const DefaultTransactionType = TransactionType.TakeOver;

function ProjectCreatorComponent(props: ProjectCreatorComponentProps) {
    const { addProject, uploading } = props;
    const [activeStep, setActiveStep] = useState(0);
    const [projectName, setProjectName] = useState<string>('');
    const [projectType, setProjectType] = useState<TransactionType>(DefaultTransactionType);
    const [documentType, setDocumentType] = useState<ProjectDocumentType>(TransactionTypeOptions[DefaultTransactionType].defaultDocType);
    useEffect(() => {
        const defaultDocType = TransactionTypeOptions[projectType].defaultDocType;
        if (documentType !== defaultDocType) {
            setDocumentType(defaultDocType);
        }
        // eslint-disable-next-line
    }, [projectType]);
    const [file, setFile] = useState<File | null>(null);
    const [targetCompanyName, setTargetCompanyName] = useState<string>('');
    const [acquiringCompanyName, setAcquiringCompanyName] = useState<string>('');
    const [ultimateParentNames, setUltimateParentNames] = useState<string[]>(['']);

    const isFormIncompleteStepFirst = !projectName || documentType === null || !file;
    const isFormIncompleteStepSecond = !(targetCompanyName.trim()) || !(acquiringCompanyName.trim());
    const isStepFirst = activeStep === 0;
    const shouldStepSecondBeAvailable = useMemo(() => {
        return projectType === TransactionType.TakeOver;
    }, [projectType]);
    const cancelEnabled = !!isStepFirst;

    const handleAddProject = useCallback(() => {
        const projectData = {
            projectName,
            projectType,
            documentType,
            file,
            targetCompanyName,
            acquiringCompanyName,
            ultimateParent: ultimateParentNames[0],
            additionalParents: ultimateParentNames.slice(1),
        };

        const configParams = [];
        if (projectData.targetCompanyName) {
            configParams.push({
                type: ProjectConfigParamEnum.Target,
                value: projectData.targetCompanyName,
            });
        }
        if (projectData.acquiringCompanyName) {
            configParams.push({
                type: ProjectConfigParamEnum.Bidco,
                value: projectData.acquiringCompanyName,
            });
        }
        if (projectData.ultimateParent) {
            configParams.push({
                type: ProjectConfigParamEnum.Bidco_Parent,
                value: projectData.ultimateParent,
            });
        }
        projectData.additionalParents.forEach((parent) => {
            configParams.push({
                type: ProjectConfigParamEnum.Bidco_ParentAdditional,
                value: parent,
            });
        });

        addProject(projectName, file!, projectType, configParams, documentType!);
    }, [addProject, projectName, projectType, documentType, file, targetCompanyName, acquiringCompanyName, ultimateParentNames]);

    const prevStep = useCallback(() => {
        setActiveStep((prev) => prev - 1);
    }, []);

    const nextStep = useCallback(() => {
        setActiveStep((prev) => prev + 1);
    }, []);

    const actionButton = useMemo(
        () => (
            <Box data-testid='action-buttons'>
                <Button variant='text' sx={{ marginRight: '10px' }} disabled={cancelEnabled} onClick={prevStep}>
                    Cancel
                </Button>

                {isStepFirst && shouldStepSecondBeAvailable ? (
                    <Button variant='contained' color='primary' sx={buttonWidth} disabled={isFormIncompleteStepFirst} onClick={nextStep}>
                        Next
                    </Button>
                ) : (
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={(shouldStepSecondBeAvailable && isFormIncompleteStepSecond) || (!shouldStepSecondBeAvailable && isFormIncompleteStepFirst)}
                        onClick={handleAddProject}
                        sx={buttonWidth}
                        isLoading={uploading}
                    >
                        Save
                    </Button>
                )}
            </Box>
        ),
        [
            isStepFirst,
            isFormIncompleteStepFirst,
            isFormIncompleteStepSecond,
            uploading,
            cancelEnabled,
            handleAddProject,
            nextStep,
            prevStep,
            shouldStepSecondBeAvailable,
        ]
    );

    const steps = [{ title: 'Project Details', subtitle: 'and verification document upload' }];
    if (shouldStepSecondBeAvailable) {
        steps.push({ title: 'Parties Involved', subtitle: 'and supporting documents upload' });
    }

    return (
        <Container
            maxWidth={false}
            sx={{
                bgcolor: palette.body.main,
                pt: '1rem',
                position: 'relative',
            }}
            data-testid='project-creator-container'
        >
            {!isStepFirst && (
                <BackButton
                    sx={{ position: 'absolute', zIndex: 1000 }}
                    onClick={() => setActiveStep((prev) => prev - 1)}
                    text='Project Details'
                    data-testid='back-button'
                />
            )}
            <Stepper steps={steps} activeStep={activeStep} />
            <Card
                variant='outlined'
                sx={{ width: '100%', mx: 'auto', display: 'flex', flexDirection: 'column', gap: '1.5rem', padding: '1.5rem 2rem' }}
                data-testid='project-card'
            >
                <CardHeader
                    title={`Create a new project:  ${isStepFirst ? 'Project Details' : 'Parties Involved'}`}
                    titleTypographyProps={{ variant: 'h6', color: 'textPrimary', fontWeight: 500 }}
                    action={actionButton}
                    sx={{ padding: '0', gap: '1.5rem' }}
                    data-testid='card-header'
                />
                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', padding: 0 }} data-testid='card-content'>
                    <Divider />
                    {isStepFirst ? (
                        <CreatorProjectDetails
                            projectName={projectName}
                            setProjectName={setProjectName}
                            documentType={documentType}
                            setDocumentType={setDocumentType}
                            file={file}
                            setFile={setFile}
                            projectType={projectType}
                            setProjectType={setProjectType}
                        />
                    ) : (
                        <CreatorPartiesInvolved
                            targetCompanyName={targetCompanyName}
                            setTargetCompanyName={setTargetCompanyName}
                            acquiringCompanyName={acquiringCompanyName}
                            setAcquiringCompanyName={setAcquiringCompanyName}
                            ultimateParentNames={ultimateParentNames}
                            setUltimateParentNames={setUltimateParentNames}
                            {...props}
                        />
                    )}
                    <Divider />
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end', padding: '0' }} data-testid='card-actions'>
                    {actionButton}
                </CardActions>
            </Card>
        </Container>
    );
}

export default ProjectCreatorComponent;
