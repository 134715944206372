import SterlingApiClient, { GetRequestOptions, PostRequestOptions } from '../../Sterling.ApiClient';
import { Documents, UploadSupportingDocumentBody } from '../Types';

export default class ProjectDocumentsApiClient {
    _sterlingApiClient: SterlingApiClient;

    _rootRoute = (projectId: string, versionId: string) => `projects/${projectId}/versions/${versionId}/documents`;

    constructor(sterlingApiClient: SterlingApiClient) {
        this._sterlingApiClient = sterlingApiClient;
    }

    getProjectDocumentsList = (projectId: string, versionId: string, getRequestOptions?: GetRequestOptions<Documents>) =>
        this._sterlingApiClient.getAsync<Documents>(`${this._rootRoute(projectId, versionId)}`, getRequestOptions);

    getDocument = async (projectId: string, versionId: string, documentId: string, documentName: string) =>
        this._sterlingApiClient.getFileAsync(`${this._rootRoute(projectId, versionId)}/${documentId}`, documentId, documentName);

    uploadSupportingDocument = async (projectId: string, versionId: string, postRequestOptions: PostRequestOptions<UploadSupportingDocumentBody, string>) =>
        this._sterlingApiClient.postAsync<UploadSupportingDocumentBody, string>(`${this._rootRoute(projectId, versionId)}`, {
            ...postRequestOptions,
            bodyAsDataForm: true,
            toastSuccessMessages: ['Document uploaded.'],
        });
}
