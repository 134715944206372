import { Icon } from 'UI';
import { useState } from 'react';
import { Navigation } from '../../Hooks/useNavigation';
import HighlightToolbarComponent, { Util } from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightToolbar/HighlightToolbar.Component';

type PropositionPanelComponentProps = {
    navigation: Navigation;
    isRejected: boolean;
    rejectProposition: () => void;
    linkProposition: (setFetching: (isFetching: boolean) => void) => void;
    restoreProposition: (setFetching: (isFetching: boolean) => void) => void;
};

function PropositionPanelComponent(props: PropositionPanelComponentProps) {
    const { navigation, isRejected, rejectProposition, linkProposition, restoreProposition } = props;

    const [linkingInformation, setLinkingInformation] = useState<boolean>(false);
    const [restoringProposition, setRestoringProposition] = useState<boolean>(false);

    const isFetching = linkingInformation || restoringProposition;

    const utils: Array<Util> = [
        {
            icon: Icon.ArrowLeftCircle,
            onClick: () => navigation.previous(),
            tooltip: 'Previous Proposition',
            disabled: !navigation.available || isFetching,
        },
        {
            icon: Icon.ArrowRightCircle,
            onClick: () => navigation.next(),
            tooltip: 'Next Proposition',
            disabled: !navigation.available || isFetching,
        },
    ];

    if (isRejected) {
        utils.push({
            icon: Icon.ArrowCounterClockWise,
            onClick: () => restoreProposition(setRestoringProposition),
            tooltip: 'Restore Proposition',
            description: 'Restore',
            disabled: isFetching && !restoringProposition,
            loading: restoringProposition,
        });
    } else {
        utils.push({
            icon: Icon.CloseCircle,
            onClick: () => rejectProposition(),
            tooltip: 'Reject Proposition',
            description: 'Reject',
            disabled: isFetching,
            utilIconBoxSx: { color: (theme) => theme.palette.attention.high },
        });
        utils.push({
            icon: Icon.Approve,
            onClick: () => linkProposition(setLinkingInformation),
            tooltip: 'Link to Annotation',
            description: 'Link',
            disabled: isFetching && !linkingInformation,
            loading: linkingInformation,
            utilIconBoxSx: { color: (theme) => theme.palette.success.main },
        });
    }

    return (
        <div style={{ display: 'flex' }}>
            <HighlightToolbarComponent utils={utils} />
        </div>
    );
}

export default PropositionPanelComponent;
