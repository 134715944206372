import { Icon } from 'UI';
import { useState } from 'react';
import { Box } from '@mui/material';
import HighlightToolbarComponent, { Util } from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightToolbar/HighlightToolbar.Component';

export type AutoAnnotationPanelComponentProps = {
    createAnnotation: () => void;
    modifyAnnotation: () => void;
    rejectAnnotation: () => void;
    showHideAnnotationElements: (show: boolean) => void;
};

function AutoAnnotationPanelComponent(props: AutoAnnotationPanelComponentProps) {
    const { createAnnotation, modifyAnnotation, rejectAnnotation, showHideAnnotationElements } = props;

    let utils: Array<Util> = [
        {
            icon: Icon.CloseCircle,
            onClick: () => rejectAnnotation(),
            description: 'Reject',
            tooltip: 'Reject proposal',
            utilIconBoxSx: { color: (theme) => theme.palette.attention.high },
        },
        {
            icon: Icon.PencilSquare,
            onClick: () => modifyAnnotation(),
            description: 'Modify',
            tooltip: 'Modify proposal',
            utilIconBoxSx: { color: (theme) => theme.palette.text.primary },
        },
        {
            icon: Icon.Approve,
            onClick: () => createAnnotation(),
            description: 'Accept',
            tooltip: 'Accept proposal',
            utilIconBoxSx: { color: (theme) => theme.palette.success.main },
        },
    ];

    const [expanded, setExpanded] = useState<boolean>(true);
    const showHideAnnotationElementsHandle = () => {
        showHideAnnotationElements(!expanded);
        setExpanded(!expanded);
    };

    return (
        <div style={{ display: 'flex' }}>
            <HighlightToolbarComponent
                utils={[
                    {
                        icon: Icon.FileEarmarkBinary,
                        onClick: showHideAnnotationElementsHandle,
                        tooltip: expanded ? 'Hide number' : 'Show number',
                    },
                ]}
            />
            <Box sx={{ width: '0.25rem' }} />
            <HighlightToolbarComponent utils={utils} />
        </div>
    );
}

export default AutoAnnotationPanelComponent;
