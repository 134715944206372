import { WebViewerInstance } from '@pdftron/webviewer';
import { FileInfo } from 'ApiClients/SterlingApiClients/Types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DocumentInfo, FileSource } from '../PdfViewerWithToolbar.Types';

const useDocument = (
    webViewerInstance: WebViewerInstance | null,
    fileSource: FileSource,
    documentInfo: DocumentInfo,
    getDocument?: (docId: string, docName: string) => Promise<FileInfo | null>,
    docFileInfo?: FileInfo | null,
    onDocumentLoad?: () => void,
    onFinishedRendering?: (documentInfo: DocumentInfo) => void
) => {
    const [documentLoading, setDocumentLoading] = useState<boolean>(false);
    const documentLoadingRef = useRef(documentLoading);
    documentLoadingRef.current = documentLoading;

    const documentInfoRef = useRef(documentInfo);
    documentInfoRef.current = documentInfo;

    // Dummy method to keep same type as getDocument. For 'load' method
    const getDocumentTmp = useCallback(
        async (_docId: string, _docName: string) => {
            return docFileInfo || null;
        },
        [docFileInfo]
    );

    const load = useCallback(
        (getDoc: (docId: string, docName: string) => Promise<FileInfo | null>) => {
            if (webViewerInstance && documentInfo) {
                webViewerInstance.Core.documentViewer.closeDocument();
                webViewerInstance.UI.openElements(['loadingModal']);
                setDocumentLoading(true);
                getDoc(documentInfo.fileId, documentInfo.name).then((fileInfo) => {
                    if (fileInfo && documentInfo.fileId === fileInfo.id)
                        webViewerInstance.Core.documentViewer.loadDocument(fileInfo.file).then(() => {
                            onDocumentLoad && onDocumentLoad();
                            webViewerInstance.UI.closeElements(['loadingModal']);
                        });
                });
            }
        },
        // eslint-disable-next-line
        [webViewerInstance, documentInfo.id, setDocumentLoading]
    );

    useEffect(() => {
        if (fileSource === 'getDocument') {
            load(getDocument!);
        }
        // eslint-disable-next-line
    }, [webViewerInstance, documentInfo.id, getDocument, load]);

    useEffect(() => {
        if (fileSource === 'docFile') {
            load(getDocumentTmp);
        }
        // eslint-disable-next-line
    }, [webViewerInstance, documentInfo.id, getDocumentTmp, load]);

    useEffect(() => {
        if (webViewerInstance) {
            webViewerInstance.Core.documentViewer.addEventListener('finishedRendering', async () => {
                if (documentLoadingRef.current) setDocumentLoading(false);

                onFinishedRendering?.(documentInfoRef.current);
            });
        }
        // eslint-disable-next-line
    }, [webViewerInstance]);

    return {
        documentLoading,
    };
};

export default useDocument;
