import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { Box } from '@mui/material';
import { AnnotationStatus, IndexReportElement, ProjectIndexReport, ProjectMember, VerificationNote } from 'ApiClients/SterlingApiClients/Types';
import { NavBarContents } from 'App/NavBar/NavBar.Component';
import { AppRoutes } from 'App/RoutesPaths';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { BackButton, Button, Icon, Typography } from 'UI';
import { ItemsStats } from './Types';
import BoardComponent from './Board/Board.Component';
import IndexTableComponent from './IndexTable/IndexTable.Component';
import VerificationNoteModalComponent from './VerificationNoteModal/VerificationNoteModal.Component';
import TitleWithIconComponent from 'Views/Common/NavBar/TitleWithIcon/TitleWithIcon.Component';

type ProjectDashboardComponentProps = {
    projectId: string;
    projectVersionIdUrlParam: string;
    navBarContents: NavBarContents;
    report: ProjectIndexReport | null;
    members: Array<ProjectMember>;
    getVerificationNote: (flowMethods: FlowMethods<VerificationNote>) => void;
    projectName: string;
};

function ProjectDashboardComponent(props: ProjectDashboardComponentProps) {
    const { projectId, projectVersionIdUrlParam, report, members, projectName, getVerificationNote, navBarContents } = props;
    const projectIndexes = report?.projectIndexes || [];
    const projectStatistics = report?.projectStatistics || null;
    const [selectedStatus, setSelectedStatus] = useState<AnnotationStatus | null>(null);
    const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
    const [verificationNoteModalState, setVerificationNoteModalState] = useState<{ isOpen: boolean }>({ isOpen: false });

    const navigate = useNavigate();

    let membersStatsById: ItemsStats = {};
    members.forEach((m) => (membersStatsById[m.id] = { name: m.fullName, count: 0 }));

    const reportFilteredByStatus = selectedStatus !== null ? projectIndexes.filter((el) => el.annotation.status === selectedStatus) : projectIndexes;

    reportFilteredByStatus.sort((a: IndexReportElement, b: IndexReportElement) => a.annotation.orderNumber - b.annotation.orderNumber);
    if (members.length > 0) {
        reportFilteredByStatus.forEach((el) => {
            const memberId = el.assignedMember?.id;

            if (memberId && membersStatsById[memberId]) membersStatsById[memberId].count += 1;
        });
    }

    const reportFilteredByStatusAndMember =
        selectedMemberId !== null ? reportFilteredByStatus.filter((el) => el.assignedMember?.id === selectedMemberId) : reportFilteredByStatus;

    return (
        <Box sx={{ width: '100%', paddingLeft: '5rem', paddingRight: '5rem', marginTop: '2rem' }}>
            {navBarContents.left?.current && (
                <>{createPortal(<TitleWithIconComponent title='Dashboard' icon={Icon.Activity} />, navBarContents.left.current)}</>
            )}
            <Typography variant='h5'>{projectName}</Typography>
            <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', marginTop: '2rem' }}>
                <BackButton onClick={() => navigate(AppRoutes.homePage.specificPath({}))} text='Back to project list' />
                <Link to={AppRoutes.projectInstance.projectVersionInstance.workspace.specificPath({ projectId, versionId: projectVersionIdUrlParam })}>
                    <Button variant='outlined'>PROJECT WORKSPACE</Button>
                </Link>
                <Button onClick={() => setVerificationNoteModalState({ isOpen: true })}>GENERATE REPORT</Button>
            </Box>
            <BoardComponent
                projectStatistics={projectStatistics}
                membersStatsById={membersStatsById}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                selectedMemberId={selectedMemberId}
                setSelectedMemberId={setSelectedMemberId}
            />
            <IndexTableComponent report={reportFilteredByStatusAndMember} projectId={projectId} projectVersionIdUrlParam={projectVersionIdUrlParam} />
            {verificationNoteModalState.isOpen && (
                <VerificationNoteModalComponent
                    {...verificationNoteModalState}
                    closeModal={() => setVerificationNoteModalState({ isOpen: false })}
                    getVerificationNote={getVerificationNote}
                    projectId={projectId}
                    projectVersionIdUrlParam={projectVersionIdUrlParam}
                />
            )}
        </Box>
    );
}

export default ProjectDashboardComponent;
