import { useCallback, useEffect, useState } from 'react';
import { SupportingInformationPropositionContext } from '../Types';
import { Annotation, DocumentInfo, Keyword } from 'ApiClients/SterlingApiClients/Types';
import HeadComponent from './Head/Head.Component';
import PropositionsListComponent from './PropositionsList/PropositionsList.Component';
import { Box, CircularProgress, Divider } from '@mui/material';
import KeywordsModalComponent from './KeywordsModal/KeywordsModal.Component';
import { AnnotationKeywordsProps } from '../Types/ProjectWorkspaceComponent.Types';
import ToolbarComponent, { ToolbarHeight } from './Toolbar/Toolbar.Component';
import { Typography } from 'UI';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';

type SupportingInformationPropositionsComponentProps = {
    selectedAnnotation: Annotation;
    selectPreviousAnnotation: () => void;
    selectNextAnnotation: () => void;
    supportingInformationPropositionContext: SupportingInformationPropositionContext;
    increaseCountPropositionsToDisplay: (annotationId: string) => void;
    getSupportingInformationPropositions: (annotationId: string) => void;
    showSupportingInformationPropositionOnDocument: (id: string, documentId: string) => void;
    linkPropositionToAnnotation: (annotationId: string, propositionId: string, setFetching: (isFetching: boolean) => void) => void;
    unlinkPropositionFromAnnotation: (annotationId: string, supportingInformationId: string, setFetching: (isFetching: boolean) => void) => void;
    rejectProposition: (annotationId: string, propositionId: string) => void;
    restoreProposition: (annotationId: string, propositionId: string, setFetching: (isFetching: boolean) => void) => void;
    documentsById: { [key: string]: DocumentInfo };
    closeSidebar: () => void;
    annotationKeywordsProps: AnnotationKeywordsProps;
};

function SupportingInformationPropositionsComponent(props: SupportingInformationPropositionsComponentProps) {
    const {
        selectedAnnotation,
        selectPreviousAnnotation,
        selectNextAnnotation,
        supportingInformationPropositionContext: suppInfoPropContext,
        increaseCountPropositionsToDisplay,
        getSupportingInformationPropositions,
        showSupportingInformationPropositionOnDocument,
        linkPropositionToAnnotation,
        unlinkPropositionFromAnnotation,
        rejectProposition,
        restoreProposition,
        documentsById,
        closeSidebar,
        annotationKeywordsProps,
    } = props;
    const [keywordsModalOpen, setKeywordsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!suppInfoPropContext) {
            getSupportingInformationPropositions(selectedAnnotation.id);
        }
    }, [selectedAnnotation, suppInfoPropContext, getSupportingInformationPropositions]);

    const suppInfoProp = suppInfoPropContext?.supportingInformationPropositions || [];
    const isFetching = suppInfoPropContext?.isFetching || false;
    const countPropositionsToDisplay = suppInfoPropContext?.countToDisplay;

    const {
        data: keywords,
        wrappedMethod: getKeywords,
        fetching,
    } = usePromiseWithFlowMethods<{ annotationId: string }, Array<Keyword>>({
        method: (input, flowMethods) => props.annotationKeywordsProps.getKeywords(input.annotationId, flowMethods),
        initialData: [{ id: 'fake', content: 'fake' }],
    });
    useEffect(() => {
        getKeywords({ annotationId: selectedAnnotation.id });
    }, [selectedAnnotation, getKeywords]);
    const refetchKeywords = useCallback(() => getKeywords({ annotationId: selectedAnnotation.id }), [selectedAnnotation, getKeywords]);

    return (
        <Box sx={{ height: '100%', border: (theme) => `1px solid ${theme.palette.stroke.main}`, borderRadius: '0.25rem' }}>
            {keywordsModalOpen && (
                <KeywordsModalComponent
                    isOpen={keywordsModalOpen}
                    keywords={keywords}
                    refetchKeywords={refetchKeywords}
                    fetching={fetching}
                    annotationId={selectedAnnotation.id}
                    annotationStatement={selectedAnnotation.statement}
                    closeModal={() => setKeywordsModalOpen(false)}
                    {...annotationKeywordsProps}
                    getSupportingInformationPropositions={getSupportingInformationPropositions}
                />
            )}
            <ToolbarComponent closeSidebar={closeSidebar} />
            <Box
                sx={{
                    height: `calc(100% - ${ToolbarHeight})`,
                }}
            >
                <HeadComponent
                    isFetching={isFetching}
                    selectNextAnnotation={selectNextAnnotation}
                    selectPreviousAnnotation={selectPreviousAnnotation}
                    selectedAnnotation={selectedAnnotation}
                    suppInfoPropLength={suppInfoProp.length}
                    countSuppInfoPropLengthToDisplay={countPropositionsToDisplay}
                    openKeywordsModal={() => setKeywordsModalOpen(true)}
                    keywords={keywords}
                />
                <Divider />
                {isFetching && suppInfoProp.length === 0 && (
                    <Box sx={{ display: 'flex', marginTop: '4rem', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
                {!isFetching && suppInfoProp.length === 0 && (
                    <Box sx={{ marginTop: '2rem', justifyContent: 'center' }}>
                        <Typography variant='title' sx={{ textAlign: 'center', marginBottom: '1rem' }}>
                            NO DATA
                        </Typography>
                        <Typography sx={{ textAlign: 'center' }}>Modify annotation area</Typography>
                    </Box>
                )}
                {suppInfoProp.length > 0 && (
                    <PropositionsListComponent
                        isFetching={isFetching}
                        selectedAnnotation={selectedAnnotation}
                        documentsById={documentsById}
                        suppInfoProp={suppInfoProp}
                        showSupportingInformationPropositionOnDocument={showSupportingInformationPropositionOnDocument}
                        linkPropositionToAnnotation={linkPropositionToAnnotation}
                        unlinkPropositionFromAnnotation={unlinkPropositionFromAnnotation}
                        rejectProposition={rejectProposition}
                        restoreProposition={restoreProposition}
                        countPropositionsToDisplay={countPropositionsToDisplay}
                        increaseCountPropositionsToDisplay={increaseCountPropositionsToDisplay}
                    />
                )}
            </Box>
        </Box>
    );
}

export default SupportingInformationPropositionsComponent;
