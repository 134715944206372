import { EmotionCache } from '@emotion/react';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { AnnotationAttributeIsHtmlAnnotation, AnnotationAttributeParentHighlightId, AnnotationAttributeType, getParentHighlightId } from './Attributes';
import { createRoot } from 'react-dom/client';
import HighlightLeftTopInformationComponent, {
    HighlightLeftTopInformation,
} from 'Views/Common/PdfViewerWithToolbar/HighlightLeftTopInformation/HighlightLeftTopInformation.Component';
import { Highlight } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

const Width = 24;
const Height = 16;
export const TooltipsContainerClassName = 'highlight-left-top-information-tooltips-container';

export const getAnnotationsLeftTopInformationToRemove = (instance: WebViewerInstance) => {
    const toRemove = instance.UI.iframeWindow.document.getElementsByClassName(TooltipsContainerClassName);
    for (const r of toRemove) {
        r.remove();
    }

    const { annotationManager } = instance.Core;
    const annotations: Array<Core.Annotations.Annotation> = annotationManager.getAnnotationsList();

    return annotations.filter(
        (a) => AnnotationAttributeType.get(a) === 'highlightLeftTopInformation' || AnnotationAttributeType.get(a) === 'highlightLeftTopInformationBackground'
    );
};

const getSiblings = <THighlightCustomData,>(
    highlights: Array<Highlight<THighlightCustomData>>,
    highlight: Highlight<THighlightCustomData>
): Array<Highlight<THighlightCustomData>> => {
    const highlightX = highlight.boundingBoxSections[0].boundingBoxes[0].topLeft.x;
    const highlightY = highlight.boundingBoxSections[0].boundingBoxes[0].topLeft.y;
    const siblings = highlights.filter((h) => {
        const hX = h.boundingBoxSections[0].boundingBoxes[0].topLeft.x;
        const hY = h.boundingBoxSections[0].boundingBoxes[0].topLeft.y;

        const x = hX - Width / 2 < highlightX && highlightX < hX + Width / 2;
        const y = hY - Height / 2 < highlightY && highlightY < hY + Height / 2;

        return x && y;
    });

    return siblings;
};

export const getAnnotationsLeftTopInformation = <THighlightCustomData,>(
    instance: WebViewerInstance,
    highlights: Array<Highlight<THighlightCustomData>>,
    selectedHighlightId: string | null,
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>,
    emotionCache: EmotionCache
) => {
    const { Annotations } = instance.Core;
    let annotationsLeftTopInformation: Array<Core.Annotations.Annotation> = [];

    let highlightsByPage: { [key: number]: Array<Highlight<THighlightCustomData>> } = {};
    highlights.forEach((h) => {
        if (!highlightsByPage[h.boundingBoxSections[0].pageNumber]) highlightsByPage[h.boundingBoxSections[0].pageNumber] = [];

        highlightsByPage[h.boundingBoxSections[0].pageNumber].push(h);
    });

    const appRoot = instance.UI.iframeWindow.document.body.getElementsByClassName('App')[0];
    const tooltipsContainer = document.createElement('div');
    tooltipsContainer.classList.add(TooltipsContainerClassName);
    appRoot.appendChild(tooltipsContainer);

    highlights.forEach((h) => {
        const siblings = getSiblings(highlightsByPage[h.boundingBoxSections[0].pageNumber], h);

        const annotationLeftTopInformation = new Annotations.HTMLAnnotation();
        const firstBoundingBoxSection = h.boundingBoxSections[0];
        annotationLeftTopInformation.NoMove = true;
        annotationLeftTopInformation.NoResize = true;
        annotationLeftTopInformation.PageNumber = firstBoundingBoxSection.pageNumber;
        annotationLeftTopInformation.X = firstBoundingBoxSection.boundingBoxes[0].topLeft.x - Width;
        annotationLeftTopInformation.Y = firstBoundingBoxSection.boundingBoxes[0].topLeft.y - Height;
        annotationLeftTopInformation.Width = Width;
        annotationLeftTopInformation.Height = Height;
        annotationLeftTopInformation.createInnerElement = () => {
            const newDiv = document.createElement('div');
            const root = createRoot(newDiv);
            root.render(
                <HighlightLeftTopInformationComponent<THighlightCustomData>
                    instance={instance}
                    highlight={h}
                    siblings={siblings}
                    selectedHighlightId={selectedHighlightId}
                    leftTopInformation={leftTopInformation}
                    emotionCache={emotionCache}
                    tooltipsContainer={tooltipsContainer}
                />
            );
            return newDiv;
        };
        AnnotationAttributeType.set(annotationLeftTopInformation, 'highlightLeftTopInformation');
        AnnotationAttributeIsHtmlAnnotation.set(annotationLeftTopInformation, 'true');
        AnnotationAttributeParentHighlightId.set(annotationLeftTopInformation, h.id);
        // This is needed to have a background for the left top information because HTML Annotation is not clickable
        annotationsLeftTopInformation.push(GetBackgroundForHtmlAnnotation(instance, annotationLeftTopInformation));
        annotationsLeftTopInformation.push(annotationLeftTopInformation);
    });
    return annotationsLeftTopInformation;
};

const GetBackgroundForHtmlAnnotation = (instance: WebViewerInstance, htmlAnnot: Core.Annotations.HTMLAnnotation) => {
    const { Annotations } = instance.Core;
    const annot = new Annotations.RectangleAnnotation();
    annot.NoMove = true;
    annot.NoResize = true;
    annot.PageNumber = htmlAnnot.PageNumber;
    annot.X = htmlAnnot.X;
    annot.Y = htmlAnnot.Y;
    annot.Height = htmlAnnot.Height;
    annot.Width = htmlAnnot.Width;
    annot.FillColor = new Annotations.Color(255, 255, 255, 1);
    annot.StrokeColor = new Annotations.Color(255, 255, 255, 1);
    AnnotationAttributeType.set(annot, 'highlightLeftTopInformationBackground');
    AnnotationAttributeParentHighlightId.set(annot, getParentHighlightId(htmlAnnot));

    return annot;
};
