import { useEffect, useRef, useState } from 'react';
import { HighlightToolbarOptions } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import AnnotationTransferPanelComponent from '../Common/AnnotationTransferPanel/AnnotationTransferPanel.Component';

export type PropositionTransferPanelWrapperComponentProps = {
    highlightToolbarOptions: HighlightToolbarOptions;
    create: (setFetching: (isFetching: boolean) => void) => void;
    reject: (setFetching: (isFetching: boolean) => void) => void;
    onlyReject: boolean;
};

function PropositionTransferPanelWrapperComponent(props: PropositionTransferPanelWrapperComponentProps) {
    const { highlightToolbarOptions, create, reject, onlyReject } = props;

    const { setHighlightToolbarRef, modifyHighlight } = highlightToolbarOptions;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    const [fetching, setFetching] = useState<boolean>(false);

    return (
        <div ref={ref}>
            <AnnotationTransferPanelComponent
                create={() => create(setFetching)}
                modify={() => modifyHighlight()}
                reject={() => reject(setFetching)}
                onlyReject={onlyReject}
                fetching={fetching}
            />
        </div>
    );
}

export default PropositionTransferPanelWrapperComponent;
