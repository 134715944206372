import { useState } from 'react';
import AnnotationsComponent from './Annotations/Annotations.Component';
import DocumentsComponent, { DocumentsComponentProps } from './Documents/Documents.Component';
import { AnnotationsScope } from '../../Types';
import { Box } from '@mui/material';
import { Typography } from 'UI';
import { DocumentHeading } from 'ApiClients/SterlingApiClients/Types';

type TabsComponentProps = {
    annotationsScope: AnnotationsScope;
    supportingDocuments: DocumentsComponentProps;
    verificationDocumentHeadings: Array<DocumentHeading>;
};

type Tabs = 'annotations' | 'documents';

export const TabsHeight = '2.5rem';

function TabsComponent(props: TabsComponentProps) {
    const { supportingDocuments, verificationDocumentHeadings } = props;

    const [selectedTab, setSelectedTab] = useState<Tabs>('annotations');

    const tabs: Array<{ title: string; type: Tabs }> = [
        { title: 'Annotations', type: 'annotations' },
        { title: 'Documents', type: 'documents' },
    ];

    return (
        <Box sx={{ height: '100%' }}>
            <Box
                sx={{
                    height: TabsHeight,
                    display: 'flex',
                    width: '100%',
                }}
            >
                {tabs.map((tab, idx) => (
                    <Box
                        key={`tabs-tab-${idx}`}
                        onClick={() => setSelectedTab(tab.type)}
                        sx={{
                            width: `${100 / tabs.length}%`,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            cursor: 'pointer',
                            ...(!(selectedTab === tab.type) && { backgroundColor: (theme) => theme.palette.blue.light }),
                        }}
                    >
                        <Typography
                            variant='subtitle2'
                            sx={{
                                ...(!(selectedTab === tab.type) && { color: (theme) => theme.palette.blue.medium }),
                                ...(selectedTab === tab.type && { fontWeight: 600 }),
                            }}
                        >
                            {tab.title}
                        </Typography>
                    </Box>
                ))}
            </Box>
            {selectedTab === 'annotations' && (
                <AnnotationsComponent annotationsScope={props.annotationsScope} verificationDocumentHeadings={verificationDocumentHeadings} />
            )}
            {selectedTab === 'documents' && <DocumentsComponent {...supportingDocuments} />}
        </Box>
    );
}

export default TabsComponent;
