import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import { Assertion } from 'ApiClients/SterlingApiClients/Types';

const useAnnotationsAssertions = (projectId: string, annotationApi: AnnotationsApiClient, refetchAnnotations: () => void) => {
    const getAssertion = (annotationId: string, flowMethods?: FlowMethods<Assertion>) =>
        annotationApi.getAnnotationAssertion(projectId!, annotationId, { ...flowMethods });
    const saveAssertion = (annotationId: string, assertion: Assertion, flowMethods?: FlowMethods) =>
        annotationApi.addAnnotationAssertion(projectId!, annotationId, {
            body: assertion,
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods?.onSuccess?.(data);
                refetchAnnotations();
            },
        });

    return {
        getAssertion,
        saveAssertion,
    };
};

export default useAnnotationsAssertions;
