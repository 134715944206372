type InputType = {
    page: number,
    coordinateY: number,
    coordinateX: number,
}

const OrderByHighlightPosition = (a: InputType, b: InputType) => {
    if (a.page !== b.page) return a.page - b.page;
    else if (a.coordinateY !== b.coordinateY) return a.coordinateY - b.coordinateY
    else return a.coordinateX - b.coordinateX
}

export default OrderByHighlightPosition