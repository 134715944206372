import './PdfViewerWithToolbar.Styles.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import createCache from '@emotion/cache';
import useDocument from './Hooks/useDocument';
import useAnnotations from './Hooks/useAnnotations';
import useToolbars from './Hooks/useToolbars';
import { DocumentProps, HighlightsProps } from './PdfViewerWithToolbar.Types';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { Box } from '@mui/material';
import useHeadings from './Hooks/useHeadings';
import ToolbarComponent, { ToolbarHeight } from './Toolbar/Toolbar.Component';
import SearchPopupComponent from './SearchPopup/SearchPopup.Component';
import HeadingsListComponent from './HeadingsList/HeadingsList.Component';
import useHighlightNavigation from './Hooks/useHighlightNavigation';
import HighlightNavigationComponent from './HighlightNavigation/HighlightNavigation.Component';

export type PdfViewerWithToolbarComponentProps<THighlightCustomData = {}> = DocumentProps & {
    viewerRef: React.RefObject<HTMLDivElement>;
    webViewerInstance: WebViewerInstance | null;
    title: string | JSX.Element;
    closeIconOnClick?: () => void;
    highlightsProps: HighlightsProps<THighlightCustomData>;
    displayHighlightIcons: boolean;
    displayHighlightNavigationIcons?: boolean;
    isCreatingHighlightEnabled: boolean;
    syncingProps?: SyncingProps;
};

export type SyncingProps = {
    syncing: string | null;
    setSyncing: () => void;
};

export type MultiSelectProp = {
    enabled: boolean;
    setEnabled: (enabled: boolean) => void;
    active: boolean;
    setActive: (active: boolean) => void;
    next: boolean;
    setNext: (next: boolean) => void;
};

export type AnnotationModifyingProp = {
    annotationModifyingState: AnnotationModifyingState;
    setAnnotationModifyingState: (annotationModifyingState: AnnotationModifyingState) => void;
};

export type InitAnnotationModifyingProp = {
    id: string | null;
    callBack: () => void;
};

export type AnnotationModifyingState = {
    active: boolean;
    originalHighlightId: string | null;
    originalHighlightColor: Core.Annotations.Color | null;
    originalHighlightBorderColor: Core.Annotations.Color | null;
};

export type AvailableToolMode = 'AnnotationCreateTextHighlight' | 'TextSelect' | 'AnnotationCreateRectangle';

function PdfViewerWithToolbarComponent<THighlightCustomData = {}>(props: PdfViewerWithToolbarComponentProps<THighlightCustomData>) {
    const {
        viewerRef,
        webViewerInstance,
        documentInfo,
        headings,
        getDocument,
        onDocumentLoad,
        fileSource,
        docFileInfo,
        highlightsProps,
        syncingProps,
        isCreatingHighlightEnabled,
        displayHighlightNavigationIcons: displayHighlightNavigationIconsProps,
        onFinishedRendering,
    } = props;
    const {
        highlights,
        selectedHighlightId,
        setSelectedHighlightId,
        selectedHighlightChangedEventName,
        highlightLeftTopInformation,
        highlightToolbars,
        clearSelectedHighlight,
        initialChosenHighlight,
        initModifyAnnotation,
        additionalHtmlElements,
        focusOnSelectedHighlightAfterDraw,
        keepSelectedHighlightInCentre,
    } = highlightsProps;

    const emotionCache = createCache({
        key: 'pdfviewer',
        container: webViewerInstance?.UI.iframeWindow.document.head,
        prepend: true,
    });

    const [searchPopupOpen, _setSearchPopupOpen] = useState<boolean>(false);
    const setSearchPopupOpen = useCallback(() => _setSearchPopupOpen((prev) => !prev), []);

    const [currentToolMode, setCurrentToolMode] = useState<AvailableToolMode>('TextSelect');
    const setToolMode = useCallback(
        (viewerMode: Core.Tools.ToolNames, mode: AvailableToolMode) => {
            if (webViewerInstance) {
                webViewerInstance.UI.setToolMode(viewerMode);
                setCurrentToolMode(mode);
            }
        },
        [webViewerInstance]
    );
    useEffect(() => {
        if (webViewerInstance && currentToolMode !== 'TextSelect' && !isCreatingHighlightEnabled)
            setToolMode(webViewerInstance.Core.Tools.ToolNames.TEXT_SELECT, 'TextSelect');
    }, [webViewerInstance, currentToolMode, setToolMode, isCreatingHighlightEnabled]);

    const { documentLoading } = useDocument(webViewerInstance, fileSource, documentInfo, getDocument, docFileInfo, onDocumentLoad, onFinishedRendering);
    const { newAnnotationId, multiSelectProp, annotationModifyingProp, viewerAnnotationsUpdated } = useAnnotations<THighlightCustomData>(
        webViewerInstance,
        documentLoading,
        highlights,
        selectedHighlightId,
        setSelectedHighlightId,
        highlightLeftTopInformation,
        clearSelectedHighlight,
        setToolMode,
        emotionCache,
        selectedHighlightChangedEventName,
        initialChosenHighlight,
        initModifyAnnotation,
        additionalHtmlElements,
        focusOnSelectedHighlightAfterDraw,
        keepSelectedHighlightInCentre
    );

    useToolbars(webViewerInstance, highlightToolbars, newAnnotationId, selectedHighlightId, emotionCache, annotationModifyingProp, viewerAnnotationsUpdated);

    const { currentHeading, jumpToHeading } = useHeadings(webViewerInstance, documentLoading, headings);

    const highlightNavigationUtilsProps = useHighlightNavigation(webViewerInstance, highlights);

    const displayHighlightNavigationIcons = useMemo(
        () => (displayHighlightNavigationIconsProps === undefined ? true : displayHighlightNavigationIconsProps),
        [displayHighlightNavigationIconsProps]
    );

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                position: 'relative',
                border: (theme) => `1px solid ${theme.palette.stroke.main}`,
                borderRadius: '0.25rem',
            }}
        >
            {displayHighlightNavigationIcons && <HighlightNavigationComponent {...highlightNavigationUtilsProps} />}
            {headings && headings.length > 1 && currentHeading && (
                <HeadingsListComponent headings={headings} currentHeading={currentHeading} jumpToHeading={jumpToHeading} />
            )}
            <ToolbarComponent
                webViewerInstance={webViewerInstance}
                documentLoading={documentLoading}
                title={props.title}
                currentToolMode={currentToolMode}
                setToolMode={setToolMode}
                openSearchPopup={() => setSearchPopupOpen()}
                closeIconOnClick={props.closeIconOnClick}
                displayHighlightIcons={props.displayHighlightIcons}
                isCreatingHighlightEnabled={isCreatingHighlightEnabled}
                multiSelectProp={multiSelectProp}
                annotationModifyingProp={annotationModifyingProp}
                syncingProps={syncingProps}
                displayHighlightNavigationIcons={displayHighlightNavigationIcons}
                highlightNavigationUtilsProps={highlightNavigationUtilsProps}
            />
            {searchPopupOpen && webViewerInstance && webViewerInstance.Core.documentViewer.getDocument() && (
                <SearchPopupComponent webViewerInstance={webViewerInstance} closePopup={() => setSearchPopupOpen()} />
            )}
            <Box
                sx={{
                    height: `calc(100% - ${ToolbarHeight})`,
                }}
            >
                <div style={{ height: '100%' }} ref={viewerRef}></div>
            </Box>
        </Box>
    );
}

export default PdfViewerWithToolbarComponent;
