import { AutoAnnotationsSectionProcessStatus, AutoAnnotationsProcessStatus, ProjectDocumentProcessingStatus } from 'ApiClients/SterlingApiClients/Types';

export enum ProjectMessageType {
    VerificationDocumentProcessingStatusUpdate = 1,
    SupportingDocumentProcessingStatusUpdate = 2,
    AutoAnnotationGenerated = 3,
    AutoAnnotationGenerationProgress = 4,
    AnnotationsTransferred = 5,
    AnnotationsTransferringProgress = 6,
    AutoAnnotationsSectionProcessStatusChanged = 7,
}

export type ProjectNotificationsMessageData =
    | VerificationDocumentProcessingStatusUpdateMessageData
    | SupportingDocumentProcessingStatusUpdateMessageData
    | AutoAnnotationGeneratedMessageData
    | AutoAnnotationsGenerationProgressMessageData
    | AutoAnnotationsSectionProcessStatusChangedMessageData
    | AnnotationsTransferredNotificationMessageData
    | AnnotationsTransferringProgressNotificationMessageData;

export type ProjectNotificationsHandlers = {
    verificationDocumentProcessingStatusUpdate?: (data: VerificationDocumentProcessingStatusUpdateMessageData) => void;
    supportingDocumentProcessingStatusUpdate?: (data: SupportingDocumentProcessingStatusUpdateMessageData) => void;
    autoAnnotationGenerated?: (data: AutoAnnotationGeneratedMessageData) => void;
    autoAnnotationGenerationProgress?: (data: AutoAnnotationsGenerationProgressMessageData) => void;
    autoAnnotationsSectionProcessStatusChanged?: (data: AutoAnnotationsSectionProcessStatusChangedMessageData) => void;
    annotationsTransferred?: (data: AnnotationsTransferredNotificationMessageData) => void;
    annotationsTransferringProgress?: (data: AnnotationsTransferringProgressNotificationMessageData) => void;
};

export type VerificationDocumentProcessingStatusUpdateMessageData = {
    verificationDocumentId: string;
    processingStatus: ProjectDocumentProcessingStatus;
    message: string;
};

export type SupportingDocumentProcessingStatusUpdateMessageData = {
    supportingDocumentId: string;
    processingStatus: ProjectDocumentProcessingStatus;
    message: string;
};

export type AutoAnnotationGeneratedMessageData = {
    processStatus: AutoAnnotationsProcessStatus;
    message: string;
};

export type AutoAnnotationsGenerationProgressMessageData = {
    progress: number;
};

export type AutoAnnotationsSectionProcessStatusChangedMessageData = {
    processStatus: AutoAnnotationsSectionProcessStatus;
    progress: number | null;
    headingId: string | null;
    message: string | null;
};

export type AnnotationsTransferredNotificationMessageData = string;

export type AnnotationsTransferringProgressNotificationMessageData = {
    numberOfAnnotationsTransferred: number;
    totalNumberOfAnnotationsToTransfer: number;
};
