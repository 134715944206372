import useToasts, { ToastEnum } from 'Hooks/useToasts';
import { Box } from '@mui/material';
import ToastComponent from './Toast/Toast.Component';

function ToastsComponent() {
    const { toasts, removeToast } = useToasts();

    return (
        <Box
            sx={{
                width: 'fit-content',
                position: 'absolute',
                left: 0,
                right: 0,
                margin: '0 auto',
                marginTop: '0.75rem',
                zIndex: 9990,
            }}
        >
            {toasts.map((t, key) => {
                return (
                    <Box
                        key={`toast-${t.id}`}
                        sx={{
                            marginTop: '0.25rem',
                        }}
                        data-testid={`toast-${key}`}
                    >
                        <ToastComponent {...t} removeToast={() => removeToast(t.id)} autoClose={t.type === ToastEnum.ERROR ? false : t.autoClose} />
                    </Box>
                );
            })}
        </Box>
    );
}

export default ToastsComponent;
