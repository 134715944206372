import { ThemeOptions } from '@mui/material';
import colors from '../tokens/Palette.module.scss';

// Default Props Docs:
// https://mui.com/material-ui/customization/theme-components/#theme-default-props
// Theming Docs:
// https://mui.com/material-ui/customization/default-theme/

export const palette = {
    white: {
        main: colors['color-whiteColor-main'],
    },
    blue: {
        medium: colors['color-blue-medium'],
        dark: colors['color-blue-dark'],
        light: colors['color-blue-light'],
        contrast: colors['color-blue-contrast'],
    },
    stroke: {
        main: colors['color-stroke-main'],
    },
    body: {
        main: colors['color-body-main'],
    },
    text: {
        main: colors['color-text-main'],
        medium: colors['color-text-medium'],
        disabled: colors['color-text-disabled'],
    },
    attention: {
        main: colors['color-attention-high'],
        high: colors['color-attention-high'],
        medium: colors['color-attention-medium'],
        low: colors['color-attention-low'],
    },
    link: {
        main: colors['color-link-main'],
    },
    success: {
        main: colors['color-success-main'],
        light: colors['color-success-main'],
        dark: colors['color-success-main'],
        contrastText: colors['color-white-main'],
    },
    highlight: {
        1: colors['color-highlight-1'],
        4: colors['color-highlight-4'],
        review: colors['color-highlight-review'],
    },
    overlay: {
        main: colors['color-overlay-main'],
    },
    member: {
        purple: colors['color-member-purple'],
        brown: colors['color-member-brown'],
    },
    accent: {
        medium: colors['color-accent-medium'],
        extraLight: colors['color-accent-extra-light'],
        extraDark: colors['color-accent-extra-dark'],
        dark: colors['color-accent-dark'],
    },
    neutrals: {
        extraDark: colors['color-neutrals-extra-dark'],
        medium: colors['color-neutrals-medium'],
        dark: colors['color-neutrals-dark'],
    },
    danger: {
        light: colors['color-danger-light'],
        extraLight: colors['color-danger-extra-light'],
        dark: colors['color-danger-dark'],
    },
    warningToast: {
        light: colors['color-warning-light'],
        extraLight: colors['color-warning-extra-light'],
        extraDark: colors['color-warning-extra-dark'],
        medium: colors['color-warning-medium'],
    },
    icons: {
        secondary: colors['color-icons-secondary'],
    },
} as const;
const fontFamily = '"Roboto","Helvetica","Arial",sans-serif';

export const typography = {
    h1: {
        fontFamily,
        fontSize: 96,
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '120%',
        letterSpacing: '-1.44px',
    },
    h2: {
        fontFamily,
        fontSize: 72,
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '120%',
        letterSpacing: '-0.36px',
    },
    h3: {
        fontFamily,
        fontSize: 60,
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '120%',
        letterSpacing: '-0.3px',
    },
    h4: {
        fontFamily,
        fontSize: 48,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
    },
    h5: {
        fontFamily,
        fontSize: 34,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: '0.085px',
    },
    h6: {
        fontFamily,
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
    },
    title: {
        fontFamily,
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: '0.03px',
    },
    avatarLabel: {
        fontFamily,
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: '0.028px',
    },
    subtitle1: {
        fontFamily,
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: '0.024px',
    },
    body1: {
        fontFamily,
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: '0.024px',
    },
    subtitle2: {
        fontFamily,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: '0.014px',
    },
    body2: {
        fontFamily,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: '0.021px',
    },
    link14: {
        fontFamily,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: '0.021px',
        color: palette.link.main,
        cursor: 'pointer',
    },
    button: {
        fontFamily,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: '0.056px',
        textTransform: 'uppercase',
    },
    caption: {
        fontFamily,
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: '0.048px',
    },
    buttonSmall: {
        fontFamily,
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: '0.12px',
        textTransform: 'uppercase',
    },
    badge: {
        fontFamily,
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: '0.12px',
        textTransform: 'uppercase',
    },
    overline: {
        fontFamily,
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: '0.12px',
        textTransform: 'uppercase',
    },
    link12: {
        fontFamily,
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: '0.18px',
        color: palette.link.main,
        cursor: 'pointer',
    },
    link11: {
        fontFamily,
        fontSize: 11,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        color: palette.link.main,
        cursor: 'pointer',
    },

    note: {
        fontFamily,
        fontSize: 11,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
    },
    tooltip: {
        fontFamily,
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
    },
    inputLabel: {
        fontFamily,
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: '0.018px',
    },
} as const;

const theme: ThemeOptions = {
    typography,
    palette: {
        mode: 'light',
        primary: {
            main: palette.blue.dark,
            light: palette.blue.light,
            dark: palette.blue.dark,
            contrastText: palette.blue.contrast,
        },
        secondary: {
            main: '#ed6f22',
        },
        error: {
            main: palette.attention.high,
            light: palette.attention.low,
            dark: palette.attention.high,
            contrastText: palette.blue.contrast,
        },
        info: {
            main: palette.attention.low,
            contrastText: palette.blue.contrast,
        },
        warning: {
            main: palette.attention.medium,
            contrastText: palette.blue.contrast,
        },
        ...palette,
        text: {
            primary: palette.text.main,
            secondary: palette.text.medium,
            disabled: palette.text.disabled,
            main: palette.text.main,
            medium: palette.text.medium,
        },
    },
    shape: {
        borderRadius: 4,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontFamily,
                    '& ::-webkit-scrollbar': {
                        width: 8,
                    },
                    '& ::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                        borderRadius: 4,
                        backgroundColor: 'rgba(143, 155, 179, 0.35)',
                        minHeight: 60,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    ...typography.inputLabel,
                    fontWeight: 500,
                    '&.MuiChip-deletable': {
                        gap: 4,
                        justifyContent: 'flex-start',
                    },
                    '&.MuiChip-deletable .MuiChip-label': {
                        paddingLeft: 0,
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    },
                },
                sizeMedium: {
                    minWidth: 96,
                    height: 24,
                },
                sizeSmall: {
                    minWidth: 72,
                    height: 16,
                    ...typography.tooltip,
                },
                outlined: {
                    borderWidth: 1,
                    '&:hover': {
                        borderWidth: 1,
                    },
                    '&.Mui-disabled': {
                        borderWidth: 1,
                    },
                },
                iconMedium: {
                    marginRight: 0,
                },
                deleteIcon: {
                    display: 'flex',
                    order: -1,
                    marginRight: 0,
                    marginLeft: 12,
                    '&.MuiChip-deleteIconSmall': {
                        marginLeft: 4,
                    },
                    color: 'unset',
                    cursor: 'pointer',
                    fontSize: 16,
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.7)',
                    },
                },
                deleteIconSmall: {
                    width: 12,
                    height: 12,
                },
                icon: {
                    marginLeft: 4,
                    marginRight: 0,
                },
                iconSmall: {
                    width: 12,
                    height: 12,
                },
                label: {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    ...typography.avatarLabel,
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    ...typography.badge,
                },

                badge: {
                    ...typography.tooltip,
                    width: 16,
                    height: 16,
                    minHeight: 'auto',
                    minWidth: 'auto',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    ...typography.link14,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeSmall: {
                    fontSize: 14,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    ...typography.tooltip,
                    background: palette.white.main,
                    color: palette.text.main,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: palette.stroke.main,
                    boxShadow: '0 2px 4px rgba(63, 68, 68, 0.15)',
                },
                arrow: {
                    color: palette.white.main,

                    '&:before': {
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: palette.stroke.main,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 12,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '120%',
                    letterSpacing: '0.18px',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    title: 'p',
                    inputLabel: 'p',
                    note: 'p',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    borderRadius: 4,
                    borderWidth: 2,
                },
                sizeLarge: {
                    lineHeight: '16px',
                    padding: '8px 16px',
                },
                outlinedPrimary: {
                    borderColor: palette.blue.dark,
                },

                outlined: {
                    '&:hover': {
                        borderWidth: 2,
                    },

                    '&.Mui-disabled': {
                        color: palette.blue.medium,
                        borderColor: palette.blue.medium,
                        borderWidth: 2,
                    },
                },
                contained: {
                    '&.Mui-disabled': {
                        color: palette.white.main,
                        backgroundColor: palette.blue.medium,
                    },
                },
                outlinedSizeLarge: {
                    padding: '6px 16px',
                },
                outlinedSizeMedium: {
                    padding: '6px 16px',
                },
                outlinedSizeSmall: {
                    padding: '3px 12px',
                    lineHeight: '14px',
                },
                sizeMedium: {
                    lineHeight: '16px',
                    padding: '8px 16px',
                },
                sizeSmall: {
                    fontSize: 12,
                    lineHeight: '16px',
                    padding: '4px 12px',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    borderRadius: '0 0 4px 4px',
                    boxShadow: '0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12)',
                },
                noOptions: {
                    ...typography.subtitle2,
                    fontWeight: 400,
                    paddingTop: 8,
                    paddingBottom: 8,
                    color: palette.text.main,
                    '&:hover': {
                        backgroundColor: palette.blue.light,
                    },
                    '&:focus': {
                        backgroundColor: palette.blue.light,
                    },
                },
                listbox: {
                    paddingTop: 8,
                    paddingBottom: 8,
                    '& .MuiAutocomplete-option.Mui-focused': {
                        backgroundColor: palette.blue.light,
                    },
                    '& .MuiAutocomplete-option': {
                        paddingTop: 4,
                        paddingBottom: 4,
                    },
                },
                option: {
                    ...typography.subtitle2,
                    fontWeight: 400,

                    '&:hover': {
                        backgroundColor: palette.blue.light,
                    },
                    '& .Mui-focused': {
                        backgroundColor: palette.blue.light,
                    },
                    minHeight: 24,
                },
            },
            defaultProps: {
                disablePortal: true,
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    ...typography.subtitle2,
                    color: palette.white.main,
                    textTransform: 'unset',
                    border: 'none',
                    minWidth: 92,
                    padding: '2px 6px',
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    '&:hover': {
                        backgroundColor: 'rgba(144, 202, 249, 0.16)',
                    },
                    '&.Mui-selected': {
                        backgroundColor: palette.white.main,
                        color: palette.text.main,
                        borderRadius: 4,
                    },
                },
                sizeLarge: {
                    height: 32,
                    padding: '6px 14px',
                },
                sizeMedium: {
                    height: 32,
                    padding: '6px 14px',
                },
                sizeSmall: {
                    height: 20,
                    padding: '4px 14px',
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                grouped: {
                    borderRadius: '4px !important',
                    '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                        borderRadius: 4,
                    },
                },
                root: {
                    padding: 2,
                    minWidth: 192,
                    background: palette.blue.medium,
                    justifyContent: 'space-between',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        ...typography.body1,
                        color: palette.stroke.main,
                    },
                },
            },
        },

        MuiMenu: {
            styleOverrides: {
                list: {
                    borderRadius: '0 0 4px 4px',
                    paddingTop: 4,
                    paddingBottom: 4,
                },
            },
        },

        MuiMenuItem: {
            styleOverrides: {
                root: {
                    ...typography.subtitle2,
                    fontWeight: 400,
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                    minHeight: 24,

                    '&:hover': {
                        backgroundColor: palette.blue.light,
                    },

                    '&:focus': {
                        backgroundColor: palette.blue.light,
                    },
                },
            },
        },

        MuiDialog: {
            styleOverrides: {
                root: {},
                paperWidthXs: {
                    maxWidth: 480,
                },
                paper: {
                    width: '100%',
                    overflowY: 'visible',
                },
            },
        },

        MuiRadio: {
            styleOverrides: {
                root: {
                    color: palette.blue.medium,
                    '&.Mui-checked': {
                        color: palette.success.main,
                    },
                },
            },
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: palette.blue.medium,
                    '&.Mui-checked': {
                        color: palette.success.main,
                    },
                },
            },
        },

        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: palette.text.main,
                    '&.Mui-focused': {
                        color: palette.text.main,
                    },
                },
                asterisk: {
                    color: palette.attention.high,
                },
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    minWidth: 198,
                    ...typography.body1,
                    color: palette.text.main,
                },
            },
        },
    },
};

export default theme;
