import { Box } from '@mui/material';
import ProcessingLayoutComponent from './ProcessingLayout/ProcessingLayout.Component';
import CreatingDraftConfirmationModalComponent from './Modals/CreatingDraftConfirmationModal/CreatingDraftConfirmationModal.Component';
import TransferringAnnotationsResultModalComponent from './Modals/TransferringAnnotationsResultModal/TransferringAnnotationsResultModal.Component';
import { Button, Icon, Typography } from 'UI';
import { useContext, useMemo } from 'react';
import { DocumentsPreviewState } from './useDocumentsPreviewState';
import { CompareState } from '../Hooks/useCompareState';
import ComparingInfoComponent from '../Common/ComparingInfo/ComparingInfo.Component';
import { FullscreenModeContext } from 'Contexts/FullscreenMode.Context';
import { FullscreenButton } from 'Views/Project/NewVersionForm/NewVersionForm.Component';

export type DocumentsPreviewComponentProps = DocumentsPreviewState & {
    compareState: CompareState;
};

export const DocumentsPreviewHeadHeight = '2rem';
export const DocumentsPreviewHeadHeightFullscreen = '4rem';
export const DocumentsPreviewMarginBottom = '1.5rem';
export const DocumentsPreviewMarginBottomFullscreen = '0rem';
const Title = 'Preview document changes';

function DocumentsPreviewComponent(props: DocumentsPreviewComponentProps) {
    const {
        mode,
        nextStep,
        openCreatingDraftConfirmationModal,
        processingLayoutState,
        creatingDraftConfirmationModalProps,
        transferringAnnotationsResultModalProps,
        compareState,
    } = props;
    const { docsCompareResult } = compareState;

    const submit: { text: string; action: () => void } = useMemo(() => {
        if (mode === 'create') {
            return {
                text: 'Continue',
                action: openCreatingDraftConfirmationModal,
            };
        } else {
            return {
                text: 'Next',
                action: nextStep,
            };
        }
    }, [mode, openCreatingDraftConfirmationModal, nextStep]);

    const { isFullscreen, toggleFullscreen } = useContext(FullscreenModeContext);

    return (
        <>
            <ProcessingLayoutComponent {...processingLayoutState} />
            <CreatingDraftConfirmationModalComponent {...creatingDraftConfirmationModalProps} />
            <TransferringAnnotationsResultModalComponent {...transferringAnnotationsResultModalProps} />
            {isFullscreen ? (
                <Box
                    sx={{
                        height: DocumentsPreviewHeadHeightFullscreen,
                        marginBottom: DocumentsPreviewMarginBottomFullscreen,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ width: '20%' }}>
                        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, whiteSpace: 'nowrap' }}>{Title}</Typography>
                    </Box>
                    <Box sx={{ width: '60%' }}>
                        <ComparingInfoComponent {...compareState} />
                    </Box>
                    <Box
                        sx={{
                            width: '20%',
                            marginLeft: 'auto',
                            marginRight: '0rem',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <SubmitButton text={submit.text} action={submit.action} disabled={!docsCompareResult && mode === 'create'} />
                        <FullscreenButton isFullscreen={isFullscreen} toggleFullscreen={toggleFullscreen} />
                    </Box>
                </Box>
            ) : (
                <Box sx={{ height: DocumentsPreviewHeadHeight, marginBottom: DocumentsPreviewMarginBottom, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '20%' }}>
                        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, whiteSpace: 'nowrap' }}>{Title}</Typography>
                    </Box>
                    <Box sx={{ width: '60%', marginTop: '1.5rem' }}>
                        <ComparingInfoComponent {...compareState} />
                    </Box>
                    <Box sx={{ width: '20%', marginLeft: 'auto', marginRight: '0rem', display: 'flex' }}>
                        <SubmitButton text={submit.text} action={submit.action} disabled={!docsCompareResult && mode === 'create'} />
                    </Box>
                </Box>
            )}
        </>
    );
}

type SubmitButtonProps = {
    text: string;
    action: () => void;
    disabled: boolean;
};

function SubmitButton(props: SubmitButtonProps) {
    const { text, action, disabled } = props;

    return (
        <Button color='primary' sx={{ gap: 1, marginLeft: 'auto', marginRight: '0rem' }} onClick={action} disabled={disabled}>
            {text}
            <Icon.ArrowSimpleRight />
        </Button>
    );
}

export default DocumentsPreviewComponent;
