import {
    Annotation,
    AnnotationNotification,
    AnnotationStatus,
    AutoAnnotation,
    AutoAnnotationsProcess,
    BoundingBoxSection,
    ProjectDocumentProcessingStatus,
} from 'ApiClients/SterlingApiClients/Types';
import { AnnotationFilters } from '../Hooks/useAnnotationsFilters';
import { AnnotationComment } from 'ApiClients/SterlingApiClients/Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { ContentType } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

export type AnnotationsScope = {
    annotations: Array<Annotation>;
    annotationFilters: AnnotationFilters;
    addAnnotation: (contentType: ContentType, boundingBoxSections: Array<BoundingBoxSection>, statement: string) => void;
    verifyAnnotation: (id: string) => void;
    selectedAnnotation: Annotation | null;
    setSelectedAnnotationId: (id: string | null, options?: { skipJump?: boolean; callback?: () => void }) => void;
    modals: AnnotationModals;
    autoAnnotationsScope: AutoAnnotationsScope;
    modifyAnnotation: {
        id: string | null;
        setId: (id: string) => void;
        clear: () => void;
    };
    acceptAnnotationStage: (annotationId: string, currentStatus: AnnotationStatus, flowMethods?: FlowMethods) => void;
    openCommentDrawer: (annotationId: string) => void;
    reorderAnnotationsNumbers: (flowMethods: FlowMethods) => void;
    isAnnotationModificationAllowed: boolean;
};

export type AnnotationModals = {
    openDeleteModal: (id: string) => void;
    openAssignmentModal: (id: string) => void;
    openAssertionModal: (id: string) => void;
    openSuppInfoSummaryModal: (id: string) => void;
    openTagAssignmentModal: (id: string) => void;
    openAnnotationFiltersModal: () => void;
    openNeedsReviewModal: (id: string) => void;
};

export const AnnotationNotificationsDictionary: { [key in AnnotationNotification]: AnnotationNotificationInfo } = {
    0: { name: 'None' },
    1: { name: 'New' },
    2: { name: 'Updated' },
};

export type AnnotationNotificationInfo = { name: string };

export type AutoAnnotationsScope = {
    autoAnnotationsProcess: AutoAnnotationsProcess | null;
    markAutoAnnotationsProcessErrorAsReceived: () => void;
    autoAnnotations: Array<AutoAnnotation>;
    selectedAutoAnnotationId: string | null;
    setSelectedAutoAnnotationId: (id: string | null, options?: { skipJump?: boolean; callback?: () => void }) => void;
    createAnnotationFromAutoAnnotation: (autoAnnotationId: string) => void;
    createAnnotationFromModifiedAutoAnnotation: (
        autoAnnotationId: string,
        contentType: ContentType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string
    ) => void;
    rejectAutoAnnotation: (autoAnnotationId: string) => void;
    verificationDocumentProcessingStatus: ProjectDocumentProcessingStatus;
};

export type AnnotationCommentsContext = {
    isFetching: boolean;
    comments: Array<AnnotationComment> | null;
};

export type AnnotationCommentsByAnnotationId = {
    [key: string]: AnnotationCommentsContext;
};
