import { CacheProvider, EmotionCache } from '@emotion/react';
import { Box } from '@mui/material';
import { WebViewerInstance } from '@pdftron/webviewer';
import { Icon, ThemeProvider, Typography } from 'UI';
import { palette } from 'UI/Provider/VerifiTheme';
import { getHighlightId, getHighlightPartIndex } from '../Hooks/Annotations/Drawing/ViewerAnnotations/Attributes';

type HighlightPartNumberComponentProps = {
    webViewerInstance: WebViewerInstance;
    highlightId: string;
    partNumber: number;
    partsCount: number;
    scale: number;
    emotionCache: EmotionCache;
};

function HighlightPartNumberComponent(props: HighlightPartNumberComponentProps) {
    const { webViewerInstance, highlightId, partNumber, partsCount, scale, emotionCache } = props;

    const prevPart = partNumber === 1 ? partsCount : partNumber - 1;
    const nextPart = partNumber === partsCount ? 1 : partNumber + 1;

    const iconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, partNumber: number) => {
        const annot = webViewerInstance.Core.annotationManager
            .getAnnotationsList()
            .find((a) => getHighlightId(a) === highlightId && getHighlightPartIndex(a) === partNumber - 1);
        if (annot) webViewerInstance.Core.annotationManager.jumpToAnnotation(annot);
        e.stopPropagation();
    };

    return (
        <ThemeProvider>
            <CacheProvider value={emotionCache}>
                <Box
                    sx={{
                        transform: `scale(${scale})`,
                        transformOrigin: `top right`,
                        position: 'absolute',
                        right: '0rem',
                        background: palette.blue.medium,
                        borderRadius: '0.25rem',
                        paddingLeft: '0.25rem',
                        paddingRight: '0.25rem',
                        paddingTop: '0.125rem',
                        paddingBottom: '0.125rem',
                        color: '#fff',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ cursor: 'pointer', display: 'flex', marginRight: '0.125rem' }} onClick={(e) => iconClick(e, prevPart)}>
                        <Icon.ArrowSimpleLeft height={5} width={5} />
                    </Box>
                    <Typography sx={{ fontStyle: 'normal', fontWeight: 700, fontSize: '0.4rem' }}>
                        {partNumber} of {partsCount}
                    </Typography>

                    <Box sx={{ cursor: 'pointer', display: 'flex', marginLeft: '0.125rem' }} onClick={(e) => iconClick(e, nextPart)}>
                        <Icon.ArrowSimpleRight height={5} width={5} />
                    </Box>
                </Box>
            </CacheProvider>
        </ThemeProvider>
    );
}

export default HighlightPartNumberComponent;
