import { useState } from 'react';

type ModalState<TState> = TState & {
    isOpen: boolean;
};

type ModalContextWithInput<TState, TOpenInput = string> = ModalContext<TState> & {
    openModal: (input: TOpenInput) => void;
};

type ModalContextWithoutInput<TState> = ModalContext<TState> & {
    openModal: () => void;
};

type ModalContext<TState> = {
    state: ModalState<TState>;
    closeModal: () => void;
};

type Modals = {
    assignmentModal: ModalContextWithInput<DefaultAnnotationModalState>;
    deleteModal: ModalContextWithInput<DefaultAnnotationModalState>;
    assertionModal: ModalContextWithInput<DefaultAnnotationModalState>;
    suppInfoSummaryModal: ModalContextWithInput<DefaultAnnotationModalState>;
    projectMembersModal: ModalContextWithoutInput<{}>;
    projectNameModal: ModalContextWithoutInput<{}>;
    tagManagementModal: ModalContextWithoutInput<{}>;
    tagAssignmentModal: ModalContextWithInput<DefaultAnnotationModalState>;
    annotationFiltersModal: ModalContextWithoutInput<{}>;
    needsReviewModal: ModalContextWithInput<DefaultAnnotationModalState>;
};

type DefaultAnnotationModalState = {
    annotationId: string | null;
};

const useProjectWorkspaceModals = () => {
    const modals: Modals = {
        assignmentModal: useDefaultAnnotationModal(),
        deleteModal: useDefaultAnnotationModal(),
        assertionModal: useDefaultAnnotationModal(),
        suppInfoSummaryModal: useDefaultAnnotationModal(),
        projectMembersModal: useDefaultProjectModal(),
        projectNameModal: useDefaultProjectModal(),
        tagManagementModal: useDefaultProjectModal(),
        tagAssignmentModal: useDefaultAnnotationModal(),
        annotationFiltersModal: useDefaultProjectModal(),
        needsReviewModal: useDefaultAnnotationModal(),
    };

    return modals;
};

const useDefaultAnnotationModal = () => {
    const [modalState, setModalState] = useState<ModalState<DefaultAnnotationModalState>>({
        isOpen: false,
        annotationId: null,
    });
    const openModal = (annotationId: string) => setModalState({ isOpen: true, annotationId });

    return {
        state: modalState,
        openModal,
        closeModal: () => setModalState({ isOpen: false, annotationId: null }),
    };
};

const useDefaultProjectModal = () => {
    const [modalState, setModalState] = useState<ModalState<{}>>({
        isOpen: false,
    });
    const openModal = () => setModalState({ isOpen: true });

    return {
        state: modalState,
        openModal,
        closeModal: () => setModalState({ isOpen: false }),
    };
};

export default useProjectWorkspaceModals;
