import { Box } from '@mui/material';
import { Typography } from 'UI';
import { IconType } from 'UI/Icon';

export type TitleWithIconComponentProps = {
    title: string;
    icon: IconType;
};

function TitleWithIconComponent(props: TitleWithIconComponentProps) {
    const { title, icon: Icon } = props;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '2rem' }}>
            <Icon height={24} width={24} />
            <Typography variant='title' color={(th) => th.palette.white} sx={{ display: 'inline', ml: '8px' }}>
                {title}
            </Typography>
        </Box>
    );
}

export default TitleWithIconComponent;
