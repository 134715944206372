import {
    AutoAnnotationsProcessStatus,
    ProjectListEntry as Project,
    ProjectDocumentProcessingStatus,
    ProjectMemberExtended,
    ProjectMemberRole,
    User,
} from 'ApiClients/SterlingApiClients/Types';
import { useState } from 'react';
import ArchiveModalComponent, { ArchiveModalComponentProps } from './ArchiveModal/ArchiveModal.Component';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import ProjectMembersModal, { ProjectMembersModalProps } from 'Views/Common/ProjectMembersModal/ProjectMembersModal.Component';
import ProjectNameModalComponent, { ProjectNameModalComponentProps } from 'Views/Common/ProjectNameModal/ProjectNameModal.Component';
import { NavBarContents } from 'App/NavBar/NavBar.Component';
import { createPortal } from 'react-dom';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { Button, Icon, TableList, Tooltip, Typography } from 'UI';
import AnnotationsStatusCellComponent from './ProjectListCells/AnnotationsStatusCell.Component';
import ActionsCellComponent from './ProjectListCells/ActionsCell.Component';
import { ProjectDocumentTypeDictionary } from 'Views/Common/Types';
import { ActiveProjectVersionId } from 'App/RoutesPaths/AppRoutes';
import TitleWithIconComponent from 'Views/Common/NavBar/TitleWithIcon/TitleWithIcon.Component';
import { ErrorsDescription } from 'App/Consts';
import ExclamationCircleWrapperComponent from './ExclamationCircleWrapper/ExclamationCircleWrapper.Component';

type HomePageComponentProps = {
    projects: Array<Project>;
    isFetching: boolean;
    navBarContents: NavBarContents;
    deleteProject: (id: string, flowMethods: FlowMethods) => void;
    goToProjectWorkspace: (projectId: string, projectVersionId: string) => void;
    goToProjectDashboard: (projectId: string, projectVersionId: string) => void;
    goToProjectCreator: () => void;
    membersProps: MembersProps;
    usersProps: UsersProps;
    changeProjectName: (projectId: string, projectName: string, flowMethods: FlowMethods) => void;
};

export type UsersProps = {
    getUsers: (flowMethods: FlowMethods<Array<User>>) => void;
};

type MembersProps = {
    getProjectMembers: (projectId: string, flowMethods: FlowMethods<Array<ProjectMemberExtended>>) => void;
    addMemberToProject: (projectId: string, userId: string, role: ProjectMemberRole, flowMethods: FlowMethods) => void;
    changeMemberProjectRole: (projectId: string, memberId: string, role: ProjectMemberRole, flowMethods: FlowMethods) => void;
    removeMemberFromProject: (projectId: string, memberId: string, flowMethods: FlowMethods) => void;
};

const columnsSizes = {
    projectName: '23%',
    documentType: '12%',
    totalAnnotations: '15%',
    flaggedAnnotations: '5%',
    stats: '5%',
    lastModified: '10%',
    projectLead: '15%',
    actions: '15%',
};

function HomePageComponent(props: HomePageComponentProps) {
    const theme = useTheme();

    const [projectMembersModalIsOpen, setProjectMembersModalIsOpen] = useState<boolean>(false);
    const [projectNameModalIsOpen, setProjectNameModalIsOpen] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const { getProjectMembers, addMemberToProject, changeMemberProjectRole, removeMemberFromProject } = props.membersProps;
    const { getUsers } = props.usersProps;
    const { projects, isFetching, changeProjectName, navBarContents } = props;
    const hasProjects = projects.length > 0;

    const [archiveModalState, setArchiveModalState] = useState<{ isOpen: boolean; projectId: string }>({
        isOpen: false,
        projectId: '',
    });

    const archiveModalProps: ArchiveModalComponentProps = {
        isOpen: archiveModalState.isOpen,
        archiveProject: () =>
            props.deleteProject(archiveModalState.projectId, {
                onSuccess: () => {
                    setArchiveModalState({ isOpen: false, projectId: '' });
                },
            }),
        closeModal: () => setArchiveModalState({ isOpen: false, projectId: '' }),
    };

    const projectMembersModal: ProjectMembersModalProps = {
        isOpen: projectMembersModalIsOpen,
        closeModal: () => setProjectMembersModalIsOpen(false),
        projectId: selectedProject?.id!,
        getUsers,
        getMembers: getProjectMembers,
        addMember: addMemberToProject,
        changeMemberRole: changeMemberProjectRole,
        removeMember: removeMemberFromProject,
    };
    const projectNameModal: ProjectNameModalComponentProps = {
        isOpen: projectNameModalIsOpen,
        closeModal: () => setProjectNameModalIsOpen(false),
        projectId: selectedProject?.id || '',
        projectName: selectedProject?.name || '',
        changeProjectName,
    };

    const editProjectName = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, project: Project) => {
        event.stopPropagation();
        if (project.isEditor) {
            setSelectedProject(project);
            setProjectNameModalIsOpen(true);
        }
    };

    const editProjectMembers = (project: Project) => {
        if (project.isEditor) {
            setSelectedProject(project);
            setProjectMembersModalIsOpen(true);
        }
    };

    const columns: Array<{
        name: string | JSX.Element;
        width: string;
        justifyContent: 'left' | 'center' | 'right';
        value: (p: Project) => string | JSX.Element;
    }> = [
        {
            name: 'Project Name',
            width: columnsSizes.projectName,
            justifyContent: 'left',
            value: (p: Project) => (
                <Box sx={{ whiteSpace: 'normal', display: 'flex', alignItems: 'center' }}>
                    <Icon.PencilSquare
                        style={{ marginRight: '0.5rem', minWidth: 'fit-content', width: '1rem', height: '1rem' }}
                        onClick={(e) => editProjectName(e, p)}
                    />
                    {p.name}
                    <ExclamationCircleWrapperComponent
                        display={p.verificationDocumentProcessingStatus === ProjectDocumentProcessingStatus.Failed}
                        title={ErrorsDescription.verificationDocumentProcessingStatusFailed}
                        color={theme.palette.attention.high}
                    />
                    <ExclamationCircleWrapperComponent
                        display={p.anySupportingDocumentsProcessingErrors}
                        title={ErrorsDescription.anySupportingDocumentsProcessingErrors}
                        color={theme.palette.attention.medium}
                    />
                    <ExclamationCircleWrapperComponent
                        display={
                            (p.autoAnnotationsProcessStatus &&
                                (p.autoAnnotationsProcessStatus === AutoAnnotationsProcessStatus.Failed ||
                                    p.autoAnnotationsProcessStatus === AutoAnnotationsProcessStatus.ProcessedWithErrors)) ||
                            false
                        }
                        title={
                            p.autoAnnotationsProcessStatus === AutoAnnotationsProcessStatus.Failed
                                ? ErrorsDescription.autoAnnotationsProcessStatusFailed
                                : ErrorsDescription.autoAnnotationsProcessStatusProcessedWithErrors
                        }
                        color={theme.palette.attention.medium}
                    />
                </Box>
            ),
        },
        {
            name: 'Document Type',
            width: columnsSizes.documentType,
            justifyContent: 'left',
            value: (p) => ProjectDocumentTypeDictionary[p.documentType].name,
        },
        {
            name: 'Total Annotations',
            width: columnsSizes.totalAnnotations,
            justifyContent: 'center',
            value: (p) => (p.isEditor ? p.annotationsStatistics!.all.count.toString() : 'n/a'),
        },
        {
            name: (
                <Box sx={{ color: (theme) => theme.palette.attention.high }}>
                    <Icon.FlagFill />
                </Box>
            ),
            width: columnsSizes.flaggedAnnotations,
            justifyContent: 'center',
            value: (p) => (p.isEditor ? p.annotationsStatistics!.all.needsReviewCount.toString() : 'n/a'),
        },
        {
            name: '',
            width: columnsSizes.stats,
            justifyContent: 'left',
            value: (p: Project) => (p.isEditor ? <AnnotationsStatusCellComponent statistics={p.annotationsStatistics!} /> : 'n/a'),
        },
        {
            name: 'Last Modified',
            width: columnsSizes.lastModified,
            justifyContent: 'center',
            value: (p: Project) => new Date(p.lastModifiedDate).toLocaleString().split(',')[0],
        },
        {
            name: 'Project Lead',
            width: columnsSizes.projectLead,
            justifyContent: 'left',
            value: (p: Project) => p.createdBy?.fullName || 'N/A',
        },
        {
            name: 'Actions',
            width: columnsSizes.actions,
            justifyContent: 'right',
            value: (p: Project) => (
                <ActionsCellComponent
                    isProjectLeader={p.isProjectLeader}
                    projectId={p.id}
                    editProjectMembers={() => editProjectMembers(p)}
                    archiveProject={() => setArchiveModalState({ isOpen: true, projectId: p.id })}
                />
            ),
        },
    ];

    return (
        <Box sx={{ paddingTop: '2.5rem', paddingLeft: '12.5rem', paddingRight: '12.5rem', minWidth: '64rem', height: 'calc(100% - 2.5rem);' }}>
            {archiveModalProps.isOpen && <ArchiveModalComponent {...archiveModalProps} />}
            {projectMembersModal.isOpen && <ProjectMembersModal {...projectMembersModal} />}
            {projectNameModal.isOpen && <ProjectNameModalComponent {...projectNameModal} />}
            {navBarContents.left?.current && (
                <>{createPortal(<TitleWithIconComponent title='Projects List' icon={Icon.Folder} />, navBarContents.left.current)}</>
            )}
            <Box
                sx={{
                    marginBottom: 4,
                }}
            >
                <Button color='primary' sx={{ maxWidth: '13rem' }} fullWidth type='button' onClick={props.goToProjectCreator} data-cy='create-new-project'>
                    Create New Project
                </Button>
            </Box>
            <Box
                sx={{
                    height: 'calc(100% - 4rem);',
                    minWidth: '64rem',
                }}
                position='relative'
            >
                <TableList.Table
                    {...((isFetching || !hasProjects) && {
                        style: {
                            pointerEvents: 'none',
                        },
                    })}
                    minHeight={450}
                    height={'100%'}
                >
                    <TableList.Header>
                        <TableList.TR isHeader>
                            {columns.map((c, idx) => (
                                <TableList.TH
                                    key={`projects-table-head-row-${idx}`}
                                    sx={{
                                        width: `${c.width} !important`,
                                        minWidth: `${c.width} !important`,
                                        display: 'flex',
                                        justifyContent: c.justifyContent,
                                    }}
                                >
                                    {c.name}
                                </TableList.TH>
                            ))}
                        </TableList.TR>
                    </TableList.Header>
                    <TableList.Body data-cy='projects-table-body' sx={{ height: '100%' }}>
                        {!isFetching &&
                            hasProjects &&
                            projects
                                .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
                                .map((p, idx) => {
                                    return (
                                        <TableList.TR
                                            key={p.id}
                                            data-cy='projects-table-row'
                                            sx={{
                                                backgroundColor: (theme) => (idx % 2 === 0 ? theme.palette.blue.light : 'inherit'),
                                                minHeight: '3rem',
                                                cursor: 'pointer',
                                                borderStyle: 'solid',
                                                borderWidth: 1,
                                                borderColor: (theme) => theme.palette.stroke.main,
                                            }}
                                            onClick={() => props.goToProjectWorkspace(p.id, ActiveProjectVersionId)}
                                        >
                                            {columns.map((c, idx) => (
                                                <TableList.TD
                                                    key={`projects-table-row-${idx}`}
                                                    sx={{
                                                        width: `${c.width} !important`,
                                                        minWidth: `${c.width} !important`,
                                                        display: 'flex',
                                                        justifyContent: c.justifyContent,
                                                    }}
                                                >
                                                    {c.value(p)}
                                                </TableList.TD>
                                            ))}
                                        </TableList.TR>
                                    );
                                })}
                        {!isFetching && !hasProjects && (
                            <TableList.TR>
                                <TableList.TD textAlign='center'>
                                    <Typography variant='inputLabel' sx={{ height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        No projects.
                                    </Typography>
                                </TableList.TD>
                            </TableList.TR>
                        )}
                        {isFetching && (
                            <TableList.TR>
                                <TableList.TD textAlign='center'>
                                    <CircularProgress size={20} />
                                </TableList.TD>
                            </TableList.TR>
                        )}
                    </TableList.Body>
                </TableList.Table>
            </Box>
        </Box>
    );
}

export default HomePageComponent;
