import WebViewer, { WebViewerOptions } from "@pdftron/webviewer";
import { RgbaColor } from "UI/functions/hexToRgba";

const CustomWebViewerInstance = (viewerElement: HTMLElement, options?: Partial<WebViewerOptions>) => WebViewer(
    {
        path: '/webviewer',
        disabledElements: [
            'toolsHeader',
            'header',
            'pageNavOverlay',
            'searchPanel',
            'contextMenuPopup',
            'toolsOverlay',
            'textPopup',
            'leftPanel',
            'OpenFileModal',
            'saveModal',
            'richTextPopup',
            'viewControlsOverlay',
            'menuOverlay',
            'zoomOverlay',
            'pageManipulationOverlay',
            'thumbnailsControlManipulatePopupSmall',
            'thumbnailsControlRotatePopup',
            'thumbnailsControlManipulatePopup',
            'signatureModal',
            'errorModal',
            'passwordModal',
            'progressModal',
            'filterModal',
            'linkModal',
            'signatureValidationModal',
            'formFieldEditPopup',
            'scaleModal',
            'printModal',
            'Model3DModal',
            'ColorPickerModal',
            'pageRedactionModal',
            'formFieldEditPopup',
        ],
        fullAPI: true,
        licenseKey: 'Insert commercial license key here after purchase',
        ...options
    },
    viewerElement
)

export const TextSelectionColor: RgbaColor = { R: 0, G: 141, B: 255, A: 0.3 }

export default CustomWebViewerInstance;