import { WebViewerInstance } from '@pdftron/webviewer';
import { useEffect, useRef, useState } from 'react';
import { HighlightColors } from '../PdfViewerWithToolbar.Types';
import CustomWebViewerInstance from '../CustomWebViewerInstance';

const useWebViewerInstance = (enable: boolean = true) => {
    const viewer = useRef<HTMLDivElement>(null);
    const [webViewerInstance, setWebViewerInstance] = useState<WebViewerInstance | null>(null);

    useEffect(() => {
        if (!webViewerInstance && enable) {
            CustomWebViewerInstance(viewer.current!).then((instance) => {
                const { Core, UI } = instance;
                const { documentViewer, Annotations } = Core;

                const parentTags = Array.from(document.head.children).filter(
                    (tag) =>
                        (tag.nodeName === 'STYLE' || (tag.nodeName === 'LINK' && tag.getAttribute('rel') === 'stylesheet')) &&
                        tag.getAttribute('data-emotion') === null
                );
                parentTags.forEach((t) => {
                    const nodeNew = t.cloneNode(true);
                    UI.iframeWindow.document.head.appendChild(nodeNew);
                });

                const sT = HighlightColors.simpleText;
                documentViewer.setTextHighlightColor(`rgba(${sT.R}, ${sT.G}, ${sT.B}, ${sT.A})`);
                Annotations.SelectionModel.defaultSelectionOutlineColor = new Annotations.Color(0, 0, 0, 0);

                documentViewer.addEventListener('documentLoaded', async () => {
                    UI.setFitMode(UI.FitMode.FitWidth);
                });

                UI.setToolMode(Core.Tools.ToolNames.TEXT_SELECT);

                UI.hotkeys.off();

                setWebViewerInstance(instance);
            });
        }
    }, [viewer, webViewerInstance, enable]);

    return { webViewerInstance, viewer };
};

export default useWebViewerInstance;
