import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectSimple } from 'ApiClients/SterlingApiClients/Types';
import { ActiveProjectVersionId } from 'App/RoutesPaths/AppRoutes';
import ProjectsApiClient from 'ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import LoadingPageComponent from 'App/LoadingPage/LoadingPage.Component';

type ProjectVersionWrapperProps = {
    projectsApiClient: ProjectsApiClient;
    renderModule: (projectWrapperProps: ProjectWrapperProps) => JSX.Element;
};

export type ProjectWrapperProps = {
    project: ProjectSimple;
    refreshProject: () => void;
};

function ProjectVersionWrapper(props: ProjectVersionWrapperProps) {
    const { projectsApiClient, renderModule } = props;
    const { projectId, versionId } = useParams();

    const [project, setProject] = useState<ProjectSimple | null>(null);

    const getProject = useCallback(() => {
        if (versionId === ActiveProjectVersionId) {
            projectsApiClient.getProject(projectId!, { onSuccess: setProject });
        } else {
            projectsApiClient.getProjectWithConcreteVersion(projectId!, versionId!, { onSuccess: setProject });
        }
    }, [projectsApiClient, projectId, versionId]);

    useEffect(() => {
        getProject();
    }, [getProject]);

    if (project) {
        return renderModule({ project, refreshProject: getProject });
    } else {
        return <LoadingPageComponent />;
    }
}

export default ProjectVersionWrapper;
