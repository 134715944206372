import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { stateSelector, actions } from './../Redux/Slices/ToastsSlice';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';

export type Toast = {
    type: ToastTypes;
    content: string[];
    errorId?: string;
    variant?: ToastVariant;
    autoClose?: boolean;
};

export type ToastWithId = Toast & {
    id: string;
};

export enum ToastEnum {
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    NOTIFICATION_PRIMARY = 'notificationPrimary',
    NOTIFICATION_SECONDARY = 'notificationSecondary',
}

export enum ToastVariantEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export type ToastVariant = ToastVariantEnum.PRIMARY | ToastVariantEnum.SECONDARY;

export type ToastTypes =
    | ToastEnum.SUCCESS
    | ToastEnum.INFO
    | ToastEnum.WARNING
    | ToastEnum.ERROR
    | ToastEnum.PRIMARY
    | ToastEnum.SECONDARY
    | ToastEnum.NOTIFICATION_PRIMARY
    | ToastEnum.NOTIFICATION_SECONDARY;

const useToasts = (): { toasts: Array<ToastWithId>; addToast: (toast: Toast) => void; removeToast: (id: string) => void } => {
    const toasts = useSelector(stateSelector);
    const dispatch = useDispatch();
    const { addToast: addToastAction, removeToast: removeToastAction } = actions;
    const addToast = useCallback((toast: Toast) => dispatch(addToastAction(toast)), [dispatch, addToastAction]);
    const removeToast = useCallback((id: string) => dispatch(removeToastAction(id)), [dispatch, removeToastAction]);

    return { toasts, addToast, removeToast };
};

export const useToastModalClose = (closeModalInput: () => void) => {
    const [anythingChanged, setAnythingChanged] = useState<boolean>(false);
    const { addToast } = useToasts();

    const closeModal = () => {
        if (anythingChanged) addToast({ type: ToastEnum.SUCCESS, content: ['Changes saved.'] });
        closeModalInput();
    };

    const flowMethodsMiddleware = (flowMethods: FlowMethods) => ({
        ...flowMethods,
        onSuccess: (data: any) => {
            flowMethods.onSuccess && flowMethods.onSuccess(data);
            if (!anythingChanged) setAnythingChanged(true);
        },
    });

    return {
        closeModal,
        flowMethodsMiddleware,
    };
};

export default useToasts;
