import { AnnotationSelectedEventName } from 'Views/Project/ProjectWorkspace/ProjectWorkspace.Component';
import { AnnotationsScope, getContentType } from 'Views/Project/ProjectWorkspace/Types';
import { AnnotationStatus, DocumentHeading, DocumentInfo, FileInfo, ProjectDocumentProcessingStatus } from 'ApiClients/SterlingApiClients/Types';
import { useContext, useMemo, useRef } from 'react';
import AnnotationPanelWrapperComponent from './AnnotationPanelWrapper/AnnotationPanelWrapper.Component';
import NewAnnotationPanelWrapperComponent from './NewAnnotationPanelWrapper/NewAnnotationPanelWrapper.Component';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SearchRoutes, UrlParams } from 'App/RoutesPaths';
import { SelectedAnnotationType } from 'Views/Project/ProjectWorkspace/Types/ProjectWorkspaceComponent.Types';
import AnnotationModifyingPanelWrapperComponent from './AnnotationModifyingPanelWrapper/AnnotationModifyingPanelWrapper.Component';
import AutoAnnotationLabelComponent from './AutoAnnotationLabel/AutoAnnotationLabel.Component';
import { Button, Icon, Typography } from 'UI';
import { Box } from '@mui/material';
import useWebViewerInstance from 'Views/Common/PdfViewerWithToolbar/Hooks/useWebViewerInstance';
import PdfViewerWithToolbarComponent from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Component';
import { HighlightColor, InitialChosenHighlight, Highlight, HighlightColors, Heading } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

export type VerificationDocumentComponentProps = {
    documentInfo: DocumentInfo;
    headings: Array<DocumentHeading>;
    getDocument: (docId: string, docName: string) => Promise<FileInfo | null>;
    onDocumentLoad: () => void;
    supportingInformationPropositionsOpen: boolean;
    annotationsScope: AnnotationsScope;
    closeIconOnClick?: () => void;
    goToProjectVersionsList: () => void;
};

export type AnnotationHighlightCustomData = {
    type: SelectedAnnotationType;
    autoAnnotationScope?: AutoAnnotationScope;
};

type AutoAnnotationScope = {
    statementType: string;
    description: string;
};

const AnnotateColor: HighlightColor = { R: 255, G: 255, B: 0, A: 0.2 };
const VerifyColor: HighlightColor = { R: 255, G: 255, B: 0, A: 0.2 };
const ApproveColor: HighlightColor = { R: 255, G: 255, B: 0, A: 0.2 };
const VerifiedColor: HighlightColor = { R: 0, G: 255, B: 0, A: 0.2 };

const AnnotationStatusColors: { [key in AnnotationStatus]: HighlightColor } = {
    0: AnnotateColor,
    1: VerifyColor,
    2: ApproveColor,
    3: VerifiedColor,
};

const AutoAnnotationColors: { default: HighlightColor; selected: HighlightColor; border: HighlightColor } = {
    default: { R: 207, G: 207, B: 207, A: 0.2 },
    selected: { R: 79, G: 117, B: 255, A: 0.4 },
    border: { R: 0, G: 32, B: 112, A: 0.4 },
};

const DisplayHighlightedAutoAnnotationInfo = process.env.REACT_APP_DISPLAY_HIGHLIGHTED_AUTOANNOTATION_INFO === 'true';

function VerificationDocumentComponent(props: VerificationDocumentComponentProps) {
    const { isEditor } = useContext(ProjectAuthContext);
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const { hash } = useLocation();
    const initAnnotId: string | null = urlSearchParams.get(UrlParams.annotationId.paramName);

    let initialChosenHighlight: InitialChosenHighlight | undefined;
    if (initAnnotId && (hash === SearchRoutes.goToAnnotation.hash || hash === SearchRoutes.goToLinkedInformation.hash)) {
        const clearInitAnnotId = () => {
            urlSearchParams.delete(UrlParams.annotationId.paramName);
            setUrlSearchParams(urlSearchParams);
        };
        initialChosenHighlight = {
            id: initAnnotId,
            callback: clearInitAnnotId,
        };
    }

    const { supportingInformationPropositionsOpen, onDocumentLoad, goToProjectVersionsList } = props;
    const {
        annotations,
        selectedAnnotation,
        setSelectedAnnotationId,
        addAnnotation,
        modals: annotationModals,
        verifyAnnotation,
        autoAnnotationsScope,
        modifyAnnotation,
        acceptAnnotationStage,
        isAnnotationModificationAllowed,
    } = props.annotationsScope;

    const {
        autoAnnotations,
        selectedAutoAnnotationId,
        setSelectedAutoAnnotationId,
        createAnnotationFromAutoAnnotation,
        createAnnotationFromModifiedAutoAnnotation,
        rejectAutoAnnotation,
    } = autoAnnotationsScope;

    const highlights = useMemo(
        () => [
            ...annotations.map((el): Highlight<AnnotationHighlightCustomData> => {
                return {
                    id: el.id,
                    orderNumber: el.orderNumber,
                    contentType: getContentType(el.content.selectionType),
                    boundingBoxSections: el.content.boundingBoxSections,
                    color: el.id === selectedAnnotation?.id ? HighlightColors.selected : AnnotationStatusColors[el.status],
                    type: 'annotation',
                };
            }),
            ...autoAnnotations.map((el): Highlight<AnnotationHighlightCustomData> => {
                return {
                    id: el.id,
                    orderNumber: el.orderNumber,
                    contentType: getContentType(el.content.selectionType),
                    boundingBoxSections: el.content.boundingBoxSections,
                    color: el.id === selectedAutoAnnotationId ? AutoAnnotationColors.selected : AutoAnnotationColors.default,
                    borderColor: AutoAnnotationColors.border,
                    type: 'autoAnnotation',
                    autoAnnotationScope: {
                        statementType: el.statementType,
                        description: el.description,
                    },
                };
            }),
        ],
        [annotations, autoAnnotations, selectedAnnotation, selectedAutoAnnotationId]
    );

    const highlightToolbarsDeps: React.DependencyList = useMemo(() => {
        return [
            isEditor,
            supportingInformationPropositionsOpen,
            highlights,
            selectedAutoAnnotationId,
            selectedAnnotation,
            props.documentInfo?.processingStatus,
        ];
    }, [isEditor, supportingInformationPropositionsOpen, highlights, selectedAutoAnnotationId, selectedAnnotation, props.documentInfo?.processingStatus]);

    const highlightsRef = useRef(highlights);
    highlightsRef.current = highlights;

    const headings: Array<Heading> = useMemo(
        () => props.headings.map((h) => ({ id: h.id, title: h.statement, boundingBoxSections: h.content.boundingBoxSections })),
        [props.headings]
    );

    const title: JSX.Element = useMemo(() => {
        return (
            <Box sx={{ marginLeft: '1rem' }}>
                <Button
                    size='small'
                    variant='text'
                    sx={{
                        textTransform: 'none',
                        minWidth: 0,
                    }}
                    onClick={() => goToProjectVersionsList()}
                >
                    <Box display='inline-flex' alignItems='center' gap={1} sx={{ color: (theme) => theme.palette.link.main }}>
                        <Icon.FileEarmarkText />
                        <Typography
                            sx={{
                                fontSize: '14px !important',
                                fontWeight: 600,
                                color: (theme) => theme.palette.link.main,
                            }}
                        >
                            Manage document versions
                        </Typography>
                    </Box>
                </Button>
            </Box>
        );
    }, [goToProjectVersionsList]);

    const { webViewerInstance, viewer } = useWebViewerInstance();
    return (
        <PdfViewerWithToolbarComponent<AnnotationHighlightCustomData>
            webViewerInstance={webViewerInstance}
            viewerRef={viewer}
            title={title}
            documentInfo={props.documentInfo}
            headings={headings}
            fileSource={'getDocument'}
            getDocument={props.getDocument}
            onDocumentLoad={onDocumentLoad}
            closeIconOnClick={props.closeIconOnClick}
            displayHighlightIcons={true}
            isCreatingHighlightEnabled={isAnnotationModificationAllowed}
            highlightsProps={{
                highlights,
                highlightToolbars: {
                    newHighlightToolbar: {
                        render: (options) => <NewAnnotationPanelWrapperComponent newHighlightToolbarOptions={options} addAnnotation={addAnnotation} />,
                    },
                    highlightToolbar: {
                        render: (options) => (
                            <AnnotationPanelWrapperComponent
                                isEditor={isEditor}
                                getSelectedAnnotationId={() => options.getSelectedHighlightId()}
                                highlight={highlights.find((h) => h.id === (selectedAnnotation?.id || selectedAutoAnnotationId))!}
                                setRef={options.setHighlightToolbarRef}
                                supportingInformationPropositionsOpen={supportingInformationPropositionsOpen}
                                openAssertionModal={annotationModals.openAssertionModal}
                                openAssignmentModal={annotationModals.openAssignmentModal}
                                openDeleteModal={annotationModals.openDeleteModal}
                                openNeedsReviewModal={annotationModals.openNeedsReviewModal}
                                verifyAnnotation={verifyAnnotation}
                                openTagAssignmentModal={annotationModals.openTagAssignmentModal}
                                isVerificationDocumentProcessed={props.documentInfo?.processingStatus === ProjectDocumentProcessingStatus.Processed}
                                autoAnnotationProps={{
                                    createAnnotation: createAnnotationFromAutoAnnotation,
                                    modifyAnnotation: () => options.modifyHighlight(),
                                    rejectAnnotation: rejectAutoAnnotation,
                                }}
                                showHideAnnotationElements={options.showHideAnnotationElements}
                                isAnnotateStatus={selectedAnnotation?.status === AnnotationStatus.Annotate}
                                openSuppInfoSummaryModal={annotationModals.openSuppInfoSummaryModal}
                                acceptAnnotationStage={acceptAnnotationStage}
                                nextAnnotationStatus={selectedAnnotation?.nextStatus || null}
                                annotationStatus={selectedAnnotation ? selectedAnnotation.status : null}
                                needsReview={selectedAnnotation?.needsReview || false}
                            />
                        ),
                    },
                    modifiedHighlightToolbar: {
                        render: (options) => (
                            <AnnotationModifyingPanelWrapperComponent
                                newHighlightToolbarOptions={options}
                                autoAnnotationProps={{
                                    createAnnotation: createAnnotationFromModifiedAutoAnnotation,
                                    rejectAnnotation: rejectAutoAnnotation,
                                }}
                                showHideAnnotationElements={options.showHideAnnotationElements}
                            />
                        ),
                    },
                    deps: highlightToolbarsDeps,
                },
                highlightLeftTopInformation: (highlight) => [{ textContent: highlight.orderNumber.toString() }],
                additionalHtmlElements: [
                    {
                        display: (options) =>
                            DisplayHighlightedAutoAnnotationInfo &&
                            options.highlight.type === 'autoAnnotation' &&
                            options.highlight.id === options.selectedHighlightId,
                        component: (options) =>
                            (options.highlight.autoAnnotationScope && <AutoAnnotationLabelComponent {...options.highlight.autoAnnotationScope} />) || <div />,
                        info: (options) => {
                            const section = options.highlight.boundingBoxSections[0];
                            return {
                                pageNumber: section.pageNumber,
                                height: 20,
                                width: 50,
                                x: section.boundingBoxes[0].topLeft.x + 5,
                                y: section.boundingBoxes[0].topLeft.y - 20,
                                position: 'bottom left',
                            };
                        },
                    },
                ],
                selectedHighlightId: selectedAnnotation?.id || selectedAutoAnnotationId,
                setSelectedHighlightId: (id) => {
                    const h = highlightsRef.current.find((h) => h.id === id);
                    if (h) {
                        if (h.type === 'annotation') setSelectedAnnotationId(id, { skipJump: true });
                        else if (h.type === 'autoAnnotation') setSelectedAutoAnnotationId(id, { skipJump: true });
                    } else {
                        setSelectedAnnotationId(null, { skipJump: true });
                    }
                },
                selectedHighlightChangedEventName: AnnotationSelectedEventName,
                clearSelectedHighlight: () => {
                    setSelectedAnnotationId(null);
                },
                initialChosenHighlight,
                initModifyAnnotation: {
                    id: modifyAnnotation.id,
                    callBack: () => modifyAnnotation.clear(),
                },
            }}
        />
    );
}

export default VerificationDocumentComponent;
