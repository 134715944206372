import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import ProjectsApiClient from 'ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';

const useProject = (projectsApiClient: ProjectsApiClient, refreshProject: () => void) => {
    const changeProjectName = (projectId: string, projectName: string, flowMethods?: FlowMethods) =>
        projectsApiClient.changeProjectName(projectId, {
            ...flowMethods,
            onSuccess: (data) => {
                flowMethods?.onSuccess && flowMethods.onSuccess(data);
                refreshProject();
            },
            body: { name: projectName },
        });

    return {
        changeProjectName,
    };
};

export default useProject;
