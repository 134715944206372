import { WebViewerInstance } from '@pdftron/webviewer';
import { useEffect } from 'react';
import { MultiSelectProp } from '../../PdfViewerWithToolbar.Component';
import { isNewHighlight } from './Drawing/ViewerAnnotations/Attributes';
import Log from '../../Logger';

const useOnEndCreatingAnnotation = (webViewerInstance: WebViewerInstance | null, newAnnotationId: string | null, multiSelectProp: MultiSelectProp) => {
    useEffect(() => {
        if (webViewerInstance) {
            const annotationManager = webViewerInstance.Core.annotationManager;
            if (!multiSelectProp.active && !newAnnotationId) {
                const annotsToRemove = annotationManager.getAnnotationsList().filter((a) => isNewHighlight(a) && a.Id !== newAnnotationId);
                if (annotsToRemove.length > 0) {
                    Log('HOOK', ['useOnEndCreatingAnnotation', 'deleteAnnotations'], [annotsToRemove]);
                    annotationManager.deleteAnnotations(annotsToRemove);
                }
            }

            if (newAnnotationId) {
                const annot = webViewerInstance.Core.annotationManager.getAnnotationById(newAnnotationId);
                webViewerInstance.Core.annotationManager.selectAnnotation(annot);
                if (!multiSelectProp.enabled) multiSelectProp.setEnabled(true);
            } else {
                if (multiSelectProp.enabled) multiSelectProp.setEnabled(false);
            }
        }
        // eslint-disable-next-line
    }, [webViewerInstance, newAnnotationId, multiSelectProp.active]);
};

export default useOnEndCreatingAnnotation;
