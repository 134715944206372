import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useEffect } from 'react';
import { AdditionalHtmlElement, Highlight, HighlightColor, InitialChosenHighlight } from '../../PdfViewerWithToolbar.Types';
import { HighlightLeftTopInformation } from '../../HighlightLeftTopInformation/HighlightLeftTopInformation.Component';
import { getHighlightId, isFirstAnnotationOfHighlight } from './Drawing/ViewerAnnotations/Attributes';
import { EmotionCache } from '@emotion/react';
import drawHtmlElements from './Drawing/HtmlElements/Index';
import drawViewerAnnotations from './Drawing/ViewerAnnotations/Index';
import { recalculateHighlightPartNumberPosition } from './Drawing/HtmlElements/HighlightPartNumber';

const useDrawing = <THighlightCustomData,>(
    webViewerInstance: WebViewerInstance | null,
    documentLoading: boolean,
    highlights: Array<Highlight<THighlightCustomData>>,
    selectedHighlightId: string | null,
    highlightLeftTopInformation: HighlightLeftTopInformation<THighlightCustomData>,
    emotionCache: EmotionCache,
    initialChosenHighlight?: InitialChosenHighlight,
    additionalHtmlElements?: Array<AdditionalHtmlElement<THighlightCustomData>>,
    focusOnSelectedHighlightAfterDraw?: boolean
) => {
    useEffect(() => {
        if (webViewerInstance && webViewerInstance.Core.documentViewer.getDocument() && !documentLoading) {
            drawHighlights(
                webViewerInstance,
                highlights,
                selectedHighlightId,
                highlightLeftTopInformation,
                emotionCache,
                additionalHtmlElements,
                focusOnSelectedHighlightAfterDraw
            ).then(() => {
                if (initialChosenHighlight?.id) {
                    const annotationManager = webViewerInstance.Core.annotationManager;
                    const annot = annotationManager
                        .getAnnotationsList()
                        .filter((a) => getHighlightId(a) === initialChosenHighlight.id && isFirstAnnotationOfHighlight(a))[0];
                    annotationManager.jumpToAnnotation(annot);
                    annotationManager.selectAnnotation(annot);
                    initialChosenHighlight.callback();
                }
            });
        }
        // eslint-disable-next-line
    }, [webViewerInstance, documentLoading, highlights, selectedHighlightId]);
};

export const HtmlElementsResizing = (webViewerInstance: WebViewerInstance) => {
    recalculateHighlightPartNumberPosition(webViewerInstance);
};

export const drawHighlights = async <THighlightCustomData,>(
    instance: WebViewerInstance,
    highlights: Array<Highlight<THighlightCustomData>>,
    selectedHighlightId: string | null,
    leftTopInformation: HighlightLeftTopInformation<THighlightCustomData>,
    emotionCache: EmotionCache,
    additionalHtmlElements?: Array<AdditionalHtmlElement<THighlightCustomData>>,
    focusOnSelectedHighlightAfterDraw?: boolean
) => {
    // Reverse the highlights array to draw the first highlight on top of the others
    const _highlights = [...highlights].reverse();

    await drawViewerAnnotations(instance, _highlights, selectedHighlightId, leftTopInformation, emotionCache, additionalHtmlElements);
    drawHtmlElements(instance, selectedHighlightId, emotionCache);

    if (selectedHighlightId && focusOnSelectedHighlightAfterDraw) {
        const annotationManager = instance.Core.annotationManager;
        const annot = annotationManager.getAnnotationsList().find((a) => getHighlightId(a) === selectedHighlightId && isFirstAnnotationOfHighlight(a));
        if (annot) annotationManager.jumpToAnnotation(annot);
    }
};

export const getZoomRounded = (zoom: number) => Math.round(zoom / 0.01) * 0.01;

export const highlightColorToAnnotationsColor = (Annotations: typeof Core.Annotations, c: HighlightColor) => new Annotations.Color(c.R, c.G, c.B, c.A);

export default useDrawing;
