import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { User } from 'ApiClients/SterlingApiClients/Types';
import UsersApiClient from 'ApiClients/SterlingApiClients/UsersApiClient/Users.ApiClient';

const useUsers = (usersApiClient: UsersApiClient) => {
    const getUsers = (flowMethods: FlowMethods<Array<User>>) => usersApiClient.getUsers(flowMethods);

    return {
        getUsers,
    };
};

export default useUsers;
