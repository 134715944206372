import React, { useCallback, useEffect, useRef } from 'react';
import AnnotationComponent from '../Annotation/Annotation.Component';
import AutoAnnotationComponent from '../AutoAnnotation/AutoAnnotation.Component';
import { Divider, ListItem } from '@mui/material';
import { Annotation, AutoAnnotation } from 'ApiClients/SterlingApiClients/Types';
import { AnnotationInfo, HeadingListExpandedEventName } from '../AnnotationsList.Component';
import { AnnotationModals } from 'Views/Project/ProjectWorkspace/Types';

type AnnotationProps = {
    annot: AnnotationInfo;
    // Annotation
    annotationsById: { [key: string]: Annotation };
    isSelectedAnnotation: boolean;
    setSelectedAnnotationId: (id: string | null, options?: { skipJump?: boolean; callback?: () => void }) => void;
    // AutoAnnotation
    autoAnnotationsById: { [key: string]: AutoAnnotation };
    isSelectedAutoAnnotation: boolean;
    setSelectedAutoAnnotationId: (id: string | null, options?: { skipJump?: boolean; callback?: () => void }) => void;
    showAutoAnnotations: boolean;
    rejectAutoAnnotation: (autoAnnotationId: string) => void;
    // Modals
    modals: AnnotationModals;
    // Drawers
    openCommentDrawer: (annotationId: string) => void;
    // Heading
    heading: HeadingProps;
};

type HeadingProps = {
    headingId: string;
    headingCollapsed: boolean;
    expandHeading: () => void;
};

function AnnotationWrapper(props: AnnotationProps) {
    const {
        annot,
        annotationsById,
        isSelectedAnnotation,
        setSelectedAnnotationId,
        autoAnnotationsById,
        isSelectedAutoAnnotation,
        setSelectedAutoAnnotationId,
        showAutoAnnotations,
        rejectAutoAnnotation,
        heading: { headingId, headingCollapsed, expandHeading },
    } = props;

    const rootRef = useRef<HTMLLIElement>(null);
    const jumpToAnnotation = useCallback(() => {
        const element = rootRef.current;
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [rootRef]);
    useEffect(() => {
        if (isSelectedAnnotation || isSelectedAutoAnnotation) {
            if (headingCollapsed) {
                const eventListener = ((e: CustomEvent<{ id: string }>) => {
                    if (e.detail.id === headingId) jumpToAnnotation();
                }) as EventListener;
                document.addEventListener(HeadingListExpandedEventName, eventListener, { once: true });
                expandHeading();
            } else {
                jumpToAnnotation();
            }
        }
        // eslint-disable-next-line
    }, [isSelectedAnnotation, isSelectedAutoAnnotation, jumpToAnnotation]);

    if (!showAutoAnnotations && annot.type === 'autoAnnotation') return null;

    return (
        <React.Fragment key={`annotations-list-element-${annot.id}`}>
            <ListItem ref={rootRef} disableGutters disablePadding>
                {annot.type === 'annotation' ? (
                    <AnnotationComponent
                        annotation={annotationsById[annot.id]}
                        selected={isSelectedAnnotation}
                        setSelectedAnnotationId={setSelectedAnnotationId}
                        openAssertionModal={props.modals.openAssertionModal}
                        openAssignmentModal={props.modals.openAssignmentModal}
                        openDeleteModal={props.modals.openDeleteModal}
                        openSuppInfoSummaryModal={props.modals.openSuppInfoSummaryModal}
                        openTagAssignmentModal={props.modals.openTagAssignmentModal}
                        openCommentDrawer={props.openCommentDrawer}
                    />
                ) : (
                    <AutoAnnotationComponent
                        autoAnnotation={autoAnnotationsById[annot.id]}
                        selected={isSelectedAutoAnnotation}
                        setSelectedAutoAnnotationId={setSelectedAutoAnnotationId}
                        rejectAutoAnnotation={rejectAutoAnnotation}
                    />
                )}
            </ListItem>
            <Divider sx={{ borderColor: (theme) => theme.palette.stroke.main }} />
        </React.Fragment>
    );
}

export default AnnotationWrapper;
