import Sidebar from './Sidebar/Sidebar.Component';
import VerificationDocumentComponent from './DocumentViewers/VerificationDocument/VerificationDocument.Component';
import { useCallback, useContext, useMemo, useState } from 'react';
import { AnnotationsScope } from './Types';
import SupportingInformationPropositionsComponent from './SupportingInformationPropositions/SupportingInformationPropositions.Component';
import SupportingDocumentComponent from './DocumentViewers/SupportingDocument/SupportingDocument.Component';
import {
    AnnotationsProps,
    AssertionsProps,
    DocumentsProps,
    MembersProps,
    SupportingInformationProps,
    SupportingInformationPropositionsProps,
    UsersProps,
    ProjectProps,
    TagsProps,
    SelectedAnnotationType,
    CommentsProps,
    ReadOnlyModalProps,
} from './Types/ProjectWorkspaceComponent.Types';
import useStateCallback from 'Hooks/useStateCallback';
import useProjectWorkspaceModals from './Modals/useProjectWorkspaceModals';
import ProjectWorkspaceModalsComponent from './Modals/ProjectWorkspaceModals.Component';
import { BoundingBoxSection, ProjectDocumentProcessingStatus, ProjectVersionStatus, SupportingInformation } from 'ApiClients/SterlingApiClients/Types';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';
import { useParams } from 'react-router';
import { NavBarContents } from 'App/NavBar/NavBar.Component';
import { createPortal } from 'react-dom';
import SettingsComponent from './Settings/Settings.Component';
import { useNavigate } from 'react-router-dom';
import useProjectSearchParams from './Hooks/Component/useProjectSearchParams';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { Icon, Typography } from 'UI';
import { Box } from '@mui/material';
import { Panel, PanelGroup, PanelResizeHandle, PanelResizeHandleProps } from 'react-resizable-panels';
import CommentDrawer, { CommentDrawerState } from './Drawers/CommentDrawer/CommentDrawer.Component';
import { AppRoutes } from 'App/RoutesPaths';
import { ContentType } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import TitleWithIconComponent from 'Views/Common/NavBar/TitleWithIcon/TitleWithIcon.Component';

type ProjectWorkspaceComponentProps = {
    navBarContents: NavBarContents;
    projectProps: ProjectProps;
    membersProps: MembersProps;
    usersProps: UsersProps;
    documentsProps: DocumentsProps;
    annotationsProps: AnnotationsProps;
    supportingInformationProps: SupportingInformationProps;
    supportingInformationPropositionsProps: SupportingInformationPropositionsProps;
    assertionsProps: AssertionsProps;
    commentsProps: CommentsProps;
    tagsProps: TagsProps;
    createAuditLog: (actionType: string, content: string) => void;
    readOnlyModalProps: ReadOnlyModalProps;
};

const PaddingTop = 1.5;
const MinHeight = 20;
const Height = `calc(100% - ${PaddingTop}rem)`;
const ContentMinHeight = MinHeight - PaddingTop;
export const StylesConsts = { PaddingTop, MinHeight, Height, ContentMinHeight };

function ProjectWorkspaceComponent(props: ProjectWorkspaceComponentProps) {
    const { isEditor } = useContext(ProjectAuthContext);
    const urlParams = useParams();
    const navigate = useNavigate();

    // PROPS
    const navBarContents = props.navBarContents;
    const { project, changeProjectName } = props.projectProps;
    const { getProjectMembers, addMemberToProject, changeMemberProjectRole, removeMemberFromProject, assignMemberToAnnotation } = props.membersProps;
    const { getUsers } = props.usersProps;
    const { addTagToProject, getProjectTags, removeTagFromProject } = props.tagsProps;
    const {
        documents,
        getDocument,
        verificationDocumentInfo,
        supportingDocumentInfo,
        supportingDocumentFileInfo,
        uploadSupportingDocument,
        anySupportingDocumentsProcessingErrors,
    } = props.documentsProps;
    const {
        annotations,
        addAnnotation,
        deleteAnnotation,
        markAnnotationNotificationAsRead,
        getAnnotationAssignedTags,
        addTagToAnnotation,
        deleteAssignedTagFromAnnotation,
        annotationFilters,
        autoAnnotationsProps,
        annotationKeywordsProps,
        acceptAnnotationStage,
        getAvailablePreviousStatuses,
        setNeedsReview,
        reorderAnnotationsNumbers,
    } = props.annotationsProps;

    const { commentsByAnnotationId, getComments, addComment, resolveComment, deleteComment } = props.commentsProps;

    const {
        autoAnnotations,
        rejectAutoAnnotation,
        createAnnotationFromAutoAnnotation,
        createAnnotationFromModifiedAutoAnnotation,
        markAutoAnnotationsProcessErrorAsReceived,
    } = autoAnnotationsProps;
    const { supportingInformationByAnnotationId, addSupportingInformation, deleteSupportingInformation, getSupportingInformation } =
        props.supportingInformationProps;
    const {
        supportingInformationPropositionByAnnotationId,
        increaseCountPropositionsToDisplay,
        getSupportingInformationPropositions,
        linkPropositionToAnnotation,
        rejectProposition,
        restoreProposition,
    } = props.supportingInformationPropositionsProps;
    const { getAssertion, saveAssertion } = props.assertionsProps;
    const { createAuditLog, readOnlyModalProps } = props;

    // >>>> MODALS
    const {
        assignmentModal,
        assertionModal,
        deleteModal,
        suppInfoSummaryModal,
        projectMembersModal,
        projectNameModal,
        tagManagementModal,
        tagAssignmentModal,
        annotationFiltersModal,
        needsReviewModal,
    } = useProjectWorkspaceModals();
    // SUPPORTING INFORMATION PROPOSITIONS SIDEBAR
    // SupportingInformationPropositions and SupportingDocument can't be open in the same time
    // use openSupportingInformationPropositions and closeSupportingInformationPropositions instead of _setSupportingInformationPropositionsOpen
    const [supportingInformationPropositionsOpen, _setSupportingInformationPropositionsOpen] = useState<boolean>(false);
    const openSupportingInformationPropositions = () => {
        props.documentsProps.switchSupportingDocument(null);
        _setSupportingInformationPropositionsOpen(true);
    };
    const closeSupportingInformationPropositions = () => _setSupportingInformationPropositionsOpen(false);

    // SUPPORTING INFORMATION
    const [selectedSupportingHighlightId, _setSelectedSupportingHighlightId] = useStateCallback<string | null>(null);
    const setSelectedSupportingHighlightId = useCallback(
        (id: string | null, skipJump?: boolean) => {
            _setSelectedSupportingHighlightId(id, () => {
                if (id && !skipJump) {
                    const event = new CustomEvent<{ id: string }>(SupportingHighlightSelectedEventName, { detail: { id } });
                    document.dispatchEvent(event);
                }
            });
        },
        [_setSelectedSupportingHighlightId]
    );

    const [initialHighlightId, setInitialHighlightId] = useState<string | null>(null);

    const switchSupportingInformation = (supportingInformation: SupportingInformation) => {
        if (supportingDocumentInfo?.id !== supportingInformation.documentId) {
            switchSuppDoc(supportingInformation.documentId, () => setInitialHighlightId(supportingInformation.id));
        }
        setSelectedSupportingHighlightId(supportingInformation.id);
    };

    // DOCUMENTS
    const switchSuppDoc = useCallback(
        (id: string | null, callback?: () => void) => {
            if (id !== supportingDocumentInfo?.id) {
                setSelectedSupportingHighlightId(null, true);
            }
            props.documentsProps.switchSupportingDocument(id, callback);
            // SupportingInformationPropositions and SupportingDocument can't be open in the same time
            if (id && supportingInformationPropositionsOpen) {
                closeSupportingInformationPropositions();
            }
        },
        [props.documentsProps, setSelectedSupportingHighlightId, supportingDocumentInfo?.id, supportingInformationPropositionsOpen]
    );

    // COMMENTS CONTEXT
    const [commentDrawerState, setCommentDrawerState] = useState<CommentDrawerState>({ isOpen: false, annotationId: null });

    const openCommentDrawer = useCallback((annotationId: string) => {
        setCommentDrawerState({ isOpen: true, annotationId });
    }, []);

    const closeCommentDrawer = useCallback(() => {
        setCommentDrawerState((prev: CommentDrawerState) => ({ ...prev, isOpen: false }));
    }, []);

    // ANNOTATIONS CONTEXT
    const [__selectedAnnotationId, __setSelectedAnnotationId] = useStateCallback<{ id: string; type: SelectedAnnotationType } | null>(null);
    const _setSelectedAnnotationId = useCallback(
        (id: string | null, type: SelectedAnnotationType, options?: { skipJump?: boolean; callback?: () => void }) => {
            if (id && (annotations.find((a) => a.id === id)?.notificationType || 0) !== 0) markAnnotationNotificationAsRead(id);
            if (!id) closeSupportingInformationPropositions();
            __setSelectedAnnotationId(
                (prev) => {
                    if (prev?.id !== id) {
                        setSelectedSupportingHighlightId(null, true);
                    } else if (id) {
                        const event = new CustomEvent<{ id: string }>(AnnotationSelectedEventName, { detail: { id } });
                        document.dispatchEvent(event);
                    }
                    return id ? { id, type } : null;
                },
                () => {
                    if (id && !options?.skipJump) {
                        const event = new CustomEvent<{ id: string }>(AnnotationSelectedEventName, { detail: { id } });
                        document.dispatchEvent(event);
                    }
                    options && options.callback && options.callback();
                }
            );
        },
        [__setSelectedAnnotationId, annotations, markAnnotationNotificationAsRead, setSelectedSupportingHighlightId]
    );
    const setSelectedAnnotationId = useCallback(
        (id: string | null, options?: { skipJump?: boolean; callback?: () => void }) => _setSelectedAnnotationId(id, 'annotation', options),
        [_setSelectedAnnotationId]
    );
    const selectedAnnotationId = (__selectedAnnotationId?.type === 'annotation' && __selectedAnnotationId.id) || null;

    const setSelectedAutoAnnotationId = useCallback(
        (id: string | null, options?: { skipJump?: boolean; callback?: () => void }) => _setSelectedAnnotationId(id, 'autoAnnotation', options),
        [_setSelectedAnnotationId]
    );
    const selectedAutoAnnotationId = (__selectedAnnotationId?.type === 'autoAnnotation' && __selectedAnnotationId.id) || null;
    const [autoAnnotationIdToModify, setAutoAnnotationToModify] = useState<string | null>(null);

    const annotationsScope: AnnotationsScope = {
        annotations,
        annotationFilters,
        addAnnotation,
        acceptAnnotationStage,
        verifyAnnotation: (id: string) => {
            getSupportingInformationPropositions(id, { onInit: openSupportingInformationPropositions });
        },
        selectedAnnotation: annotations.find((a) => a.id === selectedAnnotationId) || null,
        setSelectedAnnotationId,
        openCommentDrawer,
        reorderAnnotationsNumbers,
        modals: {
            openAssertionModal: assertionModal.openModal,
            openAssignmentModal: assignmentModal.openModal,
            openDeleteModal: deleteModal.openModal,
            openSuppInfoSummaryModal: suppInfoSummaryModal.openModal,
            openTagAssignmentModal: tagAssignmentModal.openModal,
            openAnnotationFiltersModal: annotationFiltersModal.openModal,
            openNeedsReviewModal: needsReviewModal.openModal,
        },
        modifyAnnotation: {
            id: autoAnnotationIdToModify,
            setId: (id: string) => {
                setSelectedAutoAnnotationId(id, {
                    callback() {
                        setAutoAnnotationToModify(id);
                    },
                });
            },
            clear: () => setAutoAnnotationToModify(null),
        },
        autoAnnotationsScope: {
            autoAnnotationsProcess: verificationDocumentInfo?.autoAnnotationsProcess || null,
            autoAnnotations,
            selectedAutoAnnotationId,
            setSelectedAutoAnnotationId,
            createAnnotationFromAutoAnnotation: (autoAnnotationId: string) =>
                createAnnotationFromAutoAnnotation(autoAnnotationId, { onSuccess: () => __setSelectedAnnotationId(null) }),
            createAnnotationFromModifiedAutoAnnotation: (
                autoAnnotationId: string,
                contentType: ContentType,
                boundingBoxSections: Array<BoundingBoxSection>,
                statement: string
            ) =>
                createAnnotationFromModifiedAutoAnnotation(autoAnnotationId, contentType, boundingBoxSections, statement, {
                    onSuccess: () => __setSelectedAnnotationId(null),
                }),
            rejectAutoAnnotation: (autoAnnotationId: string) => rejectAutoAnnotation(autoAnnotationId, { onSuccess: () => __setSelectedAnnotationId(null) }),
            verificationDocumentProcessingStatus: verificationDocumentInfo?.processingStatus || ProjectDocumentProcessingStatus.ToBeProcessed,
            markAutoAnnotationsProcessErrorAsReceived,
        },
        isAnnotationModificationAllowed: verificationDocumentInfo?.isAnnotationModificationAllowed || false,
    };
    const selectedAnnotation = annotations.find((a) => a.id === selectedAnnotationId) || null;
    const selectedAnnotationIndex = (selectedAnnotation && annotations.indexOf(selectedAnnotation)) || 0;
    const prevAnnotationIndex = selectedAnnotationIndex - 1 < 0 ? annotations.length - 1 : selectedAnnotationIndex - 1;
    const nextAnnotationIndex = selectedAnnotationIndex + 1 > annotations.length - 1 ? 0 : selectedAnnotationIndex + 1;

    const goToSupportingInformation = useCallback(
        (id: string, documentId: string) => {
            const switchSuppDocAndSelectHighlight = () => {
                if (supportingDocumentInfo?.id !== documentId) {
                    switchSuppDoc(documentId, () => {
                        setInitialHighlightId(id);
                        setSelectedSupportingHighlightId(id, true);
                    });
                } else {
                    setSelectedSupportingHighlightId(id);
                }
            };

            if (suppInfoSummaryModal.state.annotationId! !== selectedAnnotationId) {
                setSelectedAnnotationId(suppInfoSummaryModal.state.annotationId!, {
                    callback: () => switchSuppDocAndSelectHighlight(),
                });
            } else {
                switchSuppDocAndSelectHighlight();
            }
        },
        [
            setInitialHighlightId,
            setSelectedSupportingHighlightId,
            setSelectedAnnotationId,
            selectedAnnotationId,
            suppInfoSummaryModal.state.annotationId,
            supportingDocumentInfo?.id,
            switchSuppDoc,
        ]
    );

    useProjectSearchParams(projectMembersModal.openModal, goToSupportingInformation, switchSuppDoc, annotations);

    const goToProjectVersionsList = useCallback(() => {
        navigate(AppRoutes.projectInstance.projectVersion.list.specificPath({ projectId: project.id }));
    }, [navigate, project]);

    const projectNameDescription: string | null = useMemo(() => {
        const version = project.workingVersion;
        if (version.status === ProjectVersionStatus.Archived) return 'Archived';
        if (version.status === ProjectVersionStatus.Draft) return 'Draft';
        if (version.status === ProjectVersionStatus.Active && version.isLocked) return 'Read Only';

        return null;
    }, [project]);

    return (
        <Box sx={{ minHeight: `${StylesConsts.MinHeight}rem`, height: '100%', display: 'flex' }}>
            {navBarContents.left?.current && (
                <div>
                    {createPortal(
                        <Box sx={{ display: 'flex' }}>
                            <TitleWithIconComponent title={project.name} icon={Icon.Folder} />
                            <Typography variant='title' color={(th) => th.palette.blue.medium} sx={{ display: 'inline', ml: '8px' }}>
                                {projectNameDescription && `(${projectNameDescription} Version)`}
                            </Typography>
                        </Box>,
                        navBarContents.left.current
                    )}
                </div>
            )}
            {isEditor && navBarContents.right?.current && (
                <div>
                    {createPortal(
                        <SettingsComponent
                            openProjectNameModal={projectNameModal.openModal}
                            openProjectMembersModal={projectMembersModal.openModal}
                            openTagManagementModal={tagManagementModal.openModal}
                            goToProjectDashboard={() =>
                                navigate(
                                    AppRoutes.projectInstance.projectVersionInstance.dashboard.specificPath({
                                        projectId: urlParams.projectId!,
                                        versionId: urlParams.versionId!,
                                    })
                                )
                            }
                        />,
                        navBarContents.right.current
                    )}
                </div>
            )}
            <Box // Background
                sx={{
                    width: '100%',
                    height: `${StylesConsts.Height}rem`,
                    background: (theme) => theme.palette.body.main,
                    position: 'fixed',
                    zIndex: -1,
                }}
            />
            <CommentDrawer
                annotationCommentsContext={commentsByAnnotationId[commentDrawerState.annotationId ?? '']}
                getComments={() => getComments(commentDrawerState.annotationId ?? '')}
                addComment={addComment}
                resolveComment={resolveComment}
                deleteComment={deleteComment}
                closeCommentDrawer={closeCommentDrawer}
                commentSelected={commentDrawerState.annotationId === annotationsScope.selectedAnnotation?.id}
                commentDrawerState={commentDrawerState}
            />
            <ProjectWorkspaceModalsComponent
                suppInfoSummaryModal={{
                    isOpen: suppInfoSummaryModal.state.isOpen,
                    supportingInformationContext: supportingInformationByAnnotationId[suppInfoSummaryModal.state.annotationId!],
                    getSupportingInformation: () => getSupportingInformation(suppInfoSummaryModal.state.annotationId!),
                    documentsById: documents.supportingDocumentsById,
                    goToSupportingInformation: goToSupportingInformation,
                    closeModal: suppInfoSummaryModal.closeModal,
                }}
                assertionModal={{
                    isOpen: assertionModal.state.isOpen,
                    annotation: annotations.find((annotation) => annotation.id === assertionModal.state.annotationId)!,
                    getAssertion,
                    saveAssertion,
                    closeModal: assertionModal.closeModal,
                }}
                assignmentModal={{
                    isOpen: assignmentModal.state.isOpen,
                    annotation: annotations.find((annotation) => annotation.id === assignmentModal.state.annotationId)!,
                    assignMember: assignMemberToAnnotation,
                    closeModal: assignmentModal.closeModal,
                    getMembers: (flowMethods) => getProjectMembers(project!.id, flowMethods),
                }}
                deleteModal={{
                    isOpen: deleteModal.state.isOpen,
                    deleteAnnotation: (flowMethods: FlowMethods) => deleteAnnotation(deleteModal.state.annotationId!, flowMethods),
                    closeModal: deleteModal.closeModal,
                }}
                projectMembersModal={{
                    isOpen: projectMembersModal.state.isOpen,
                    closeModal: projectMembersModal.closeModal,
                    projectId: project.id,
                    getUsers,
                    getMembers: getProjectMembers,
                    addMember: addMemberToProject,
                    changeMemberRole: changeMemberProjectRole,
                    removeMember: removeMemberFromProject,
                }}
                projectNameModal={{
                    isOpen: projectNameModal.state.isOpen,
                    closeModal: projectNameModal.closeModal,
                    projectId: project.id,
                    projectName: project.name,
                    changeProjectName,
                }}
                tagManagementModal={{
                    isOpen: tagManagementModal.state.isOpen,
                    addTag: addTagToProject,
                    getTags: getProjectTags,
                    removeTag: removeTagFromProject,
                    closeModal: tagManagementModal.closeModal,
                }}
                tagAssignmentModal={{
                    isOpen: tagAssignmentModal.state.isOpen,
                    closeModal: tagAssignmentModal.closeModal,
                    getTags: (flowMethods) => getProjectTags(flowMethods),
                    addTag: addTagToAnnotation,
                    deleteAssignedTag: deleteAssignedTagFromAnnotation,
                    getAssignedTags: getAnnotationAssignedTags,
                    annotationId: tagAssignmentModal.state.annotationId!,
                }}
                annotationFiltersModal={{
                    isOpen: annotationFiltersModal.state.isOpen,
                    closeModal: annotationFiltersModal.closeModal,
                    annotationFilters,
                    getMembers: (flowMethods) => getProjectMembers(project.id!, flowMethods),
                    getTags: (flowMethods) => getProjectTags(flowMethods),
                }}
                needsReviewModal={{
                    annotation: annotations.find((annotation) => annotation.id === needsReviewModal.state.annotationId)!,
                    isOpen: needsReviewModal.state.isOpen,
                    closeModal: needsReviewModal.closeModal,
                    getAvailableStatuses: getAvailablePreviousStatuses,
                    setNeedsReview: setNeedsReview,
                }}
                readOnlyModalProps={{
                    isOpen: readOnlyModalProps.isOpen,
                    closeModal: readOnlyModalProps.closeModal,
                    version: project.workingVersion,
                }}
            />
            <Sidebar
                supportingDocuments={{
                    documents: documents.supportingDocumentsTree,
                    selectedDocumentId: supportingDocumentInfo?.id || null,
                    swichDocument: (id: string) => switchSuppDoc(id),
                    uploadSupportingDocument,
                    anySupportingDocumentsProcessingErrors,
                }}
                annotationsScope={annotationsScope}
                verificationDocumentHeadings={verificationDocumentInfo?.headings || []}
                closeSupportingInformationPropositions={closeSupportingInformationPropositions}
            />
            <Box
                sx={{
                    display: 'inline-block',
                    minHeight: `${StylesConsts.ContentMinHeight}rem`,
                    height: 'calc(100% - 1.5rem)', // - marginTop
                    minWidth: '54rem',
                    width: '100%',
                    marginTop: '1.5rem',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                }}
            >
                <PanelGroup direction='vertical'>
                    <Panel>
                        {verificationDocumentInfo && (
                            <VerificationDocumentComponent
                                documentInfo={verificationDocumentInfo}
                                headings={verificationDocumentInfo.headings}
                                getDocument={getDocument}
                                onDocumentLoad={() =>
                                    createAuditLog('OpenVerificationDocument', JSON.stringify({ projectId: project.id, verificationDocumentInfo }))
                                }
                                annotationsScope={annotationsScope}
                                supportingInformationPropositionsOpen={supportingInformationPropositionsOpen}
                                goToProjectVersionsList={goToProjectVersionsList}
                            />
                        )}
                    </Panel>
                    {(supportingDocumentInfo !== null || (supportingInformationPropositionsOpen && selectedAnnotation !== null)) && (
                        <>
                            <CustomPanelResizeHandle />
                            <Panel>
                                {selectedAnnotation && supportingInformationPropositionsOpen && (
                                    <SupportingInformationPropositionsComponent
                                        selectedAnnotation={selectedAnnotation}
                                        selectPreviousAnnotation={() => setSelectedAnnotationId(annotations[prevAnnotationIndex].id)}
                                        selectNextAnnotation={() => setSelectedAnnotationId(annotations[nextAnnotationIndex].id)}
                                        supportingInformationPropositionContext={supportingInformationPropositionByAnnotationId[selectedAnnotation.id]}
                                        increaseCountPropositionsToDisplay={increaseCountPropositionsToDisplay}
                                        getSupportingInformationPropositions={getSupportingInformationPropositions}
                                        closeSidebar={closeSupportingInformationPropositions}
                                        showSupportingInformationPropositionOnDocument={(id: string, documentId: string) => {
                                            switchSuppDoc(documentId, () => setInitialHighlightId(id));
                                            setSelectedSupportingHighlightId(id);
                                        }}
                                        linkPropositionToAnnotation={linkPropositionToAnnotation}
                                        unlinkPropositionFromAnnotation={deleteSupportingInformation}
                                        documentsById={documents.supportingDocumentsById}
                                        rejectProposition={rejectProposition}
                                        restoreProposition={restoreProposition}
                                        annotationKeywordsProps={annotationKeywordsProps}
                                    />
                                )}
                                {supportingDocumentInfo && !supportingInformationPropositionsOpen && (
                                    <SupportingDocumentComponent
                                        documentInfo={supportingDocumentInfo}
                                        docFileInfo={supportingDocumentFileInfo}
                                        onDocumentLoad={() =>
                                            createAuditLog('OpenSupportingDocument', JSON.stringify({ projectId: project.id, supportingDocumentInfo }))
                                        }
                                        selectedAnnotation={selectedAnnotation}
                                        supportingInformationProps={{
                                            supportingInformationContext: supportingInformationByAnnotationId[selectedAnnotation?.id || ''],
                                            getSupportingInformation,
                                            addSupportingInformation,
                                            deleteSupportingInformation,
                                            switchSupportingInformation,
                                        }}
                                        supportingInformationPropositionProps={{
                                            supportingInformationPropositionContext:
                                                supportingInformationPropositionByAnnotationId[selectedAnnotation?.id || ''],
                                            rejectProposition,
                                            linkPropositionToAnnotation,
                                            restoreProposition,
                                        }}
                                        highlightProps={{
                                            selectedSupportingHighlightId,
                                            setSelectedSupportingHighlightId: (id: string | null, skipJump: boolean) =>
                                                setSelectedSupportingHighlightId(id, skipJump),
                                            initialHighlightId,
                                            clearInitialHighlightId: () => setInitialHighlightId(null),
                                        }}
                                        openSupportingInformationPropositions={openSupportingInformationPropositions}
                                        openSuppInfoSummaryModal={suppInfoSummaryModal.openModal}
                                        closeDocument={() => switchSuppDoc(null)}
                                    />
                                )}
                            </Panel>
                        </>
                    )}
                </PanelGroup>
            </Box>
        </Box>
    );
}

function CustomPanelResizeHandle(props: PanelResizeHandleProps) {
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <PanelResizeHandle {...props} style={{ width: '3rem' }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Icon.ArrowsCollapse />
                </Box>
            </PanelResizeHandle>
        </Box>
    );
}

export const AnnotationSelectedEventName = 'annotationSelected';
export const SupportingHighlightSelectedEventName = 'supportingHighlightSelected';

export default ProjectWorkspaceComponent;
