import NotificationsConnector from 'App/Notifications/NotificationsConnector';
import ProjectNotificationsSubscriber from 'App/Notifications/ProjectNotificationsSubscriber/ProjectNotificationsSubscriber';
import { ProjectNotificationsHandlers } from 'App/Notifications/ProjectNotificationsSubscriber/ProjectNotificationsSubscriber.Types';
import { useEffect, useMemo } from 'react';

const useProjectNotifications = (notificationsConnector: NotificationsConnector, projectId: string, notificationsHandlers: ProjectNotificationsHandlers) => {
    const projectNotificationsSubscriber = useMemo(
        () => new ProjectNotificationsSubscriber(notificationsConnector, projectId, notificationsHandlers),
        // eslint-disable-next-line
        [notificationsConnector]
    );

    useEffect(() => {
        projectNotificationsSubscriber.subscribe();
        return () => projectNotificationsSubscriber.unSubscribe();
        // eslint-disable-next-line
    }, []);
};

export default useProjectNotifications;
