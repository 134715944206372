import NotificationsApiClient from 'ApiClients/SterlingApiClients/NotificationsApiClient/Notifications.ApiClient';
import SupportingInformationApiClient from 'ApiClients/SterlingApiClients/SupportingInformationApiClient/SupportingInformation.ApiClient';
import AnnotationsApiClient from 'ApiClients/SterlingApiClients/AnnotationsApiClient/Annotations.ApiClient';
import ProjectDocumentsApiClient from 'ApiClients/SterlingApiClients/ProjectDocumentsApiClient/ProjectDocuments.ApiClient';
import ProjectsApiClient from '../../../ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import ProjectWorkspaceComponent from './ProjectWorkspace.Component';
import useProjectAuth from 'Hooks/useProjectAuth';
import { ProjectAuthContextProvider } from 'Contexts/ProjectAuth.Context';
import UsersApiClient from 'ApiClients/SterlingApiClients/UsersApiClient/Users.ApiClient';
import { NavBarContents } from 'App/NavBar/NavBar.Component';
import AuditLogsApiClient from 'ApiClients/SterlingApiClients/AuditLogsApiClient/AuditLogs.ApiClient';

import useToasts, { ToastEnum } from 'Hooks/useToasts';
import NotificationsConnector from 'App/Notifications/NotificationsConnector';
import {
    useAnnotations,
    useAnnotationsAssertions,
    useAnnotationsStatus,
    useAnnotationsTags,
    useAuditLog,
    useDocuments,
    useMembers,
    useProject,
    useProjectNotifications,
    useSupportingInformation,
    useTags,
    useUsers,
} from './Hooks/Module';
import useAnnotationsKeywords from './Hooks/Module/Annotations/useAnnotationsKeywords';
import useAnnotationsComments from './Hooks/Module/Annotations/useAnnotationsComments';
import useAnnotationsNeedsReview from './Hooks/Module/Annotations/useAnnotationsNeedsReview';
import { ProjectWrapperProps } from '../Common/ProjectVersion.Wrapper';
import { ErrorHandlerRegistration } from 'ApiClients/Sterling.ApiClient';
import useErrorHandler from './Hooks/Module/useErrorHandler';
import { AutoAnnotationsSectionProcessStatus, AutoAnnotationsProcessStatus, ProjectDocumentProcessingStatus } from 'ApiClients/SterlingApiClients/Types';
import { useRef } from 'react';

export type ProjectWorkspaceModuleProps = {
    projectWrapperProps: ProjectWrapperProps;
    navBarContents: NavBarContents;
    projectsApiClient: ProjectsApiClient;
    projectDocumentsApiClient: ProjectDocumentsApiClient;
    annotationsApiClient: AnnotationsApiClient;
    supportingInformationApiClient: SupportingInformationApiClient;
    notificationsApiClient: NotificationsApiClient;
    usersApiClient: UsersApiClient;
    auditLogsApiClient: AuditLogsApiClient;
    notificationsConnector: NotificationsConnector;
    errorHandlerRegistration: ErrorHandlerRegistration;
};

function ProjectWorkspaceModule(props: ProjectWorkspaceModuleProps) {
    const { addToast } = useToasts();

    const {
        projectWrapperProps,
        navBarContents,
        projectsApiClient,
        projectDocumentsApiClient,
        annotationsApiClient,
        supportingInformationApiClient,
        notificationsApiClient,
        usersApiClient,
        auditLogsApiClient,
        notificationsConnector,
        errorHandlerRegistration,
    } = props;

    const { project, refreshProject } = projectWrapperProps;

    const projectAuthContextProviderValue = useProjectAuth({ getMyProjectMemberInstance: projectsApiClient.getMyProjectMemberInstance, projectId: project.id });

    const { createAuditLog } = useAuditLog(project.id, auditLogsApiClient);

    const { changeProjectName } = useProject(projectsApiClient!, refreshProject);

    const { getProjectMembers, addMemberToProject, changeMemberProjectRole, removeMemberFromProject } = useMembers(projectsApiClient);

    const { getUsers } = useUsers(usersApiClient);

    const {
        verificationDocumentInfo,
        supDocInfo,
        supDocFileInfo,
        suppDocsById,
        supportingDocumentsTreeExtended,
        switchSupportingDocument,
        uploadSupportingDocument,
        getDocument,
        setVerificationDocumentProcessingStatus,
        handleAutoAnnotationGenerated,
        setAutoAnnotationsProcessProgress,
        setAutoAnnotationsProcessIsErrorReceived,
        handleAutoAnnotationsSectionProcessStatusChanged,
        setSupportingDocumentProcessingStatus,
        refetchDocuments,
        anySupportingDocumentsProcessingErrors,
    } = useDocuments(project.id, project.workingVersion.id, projectDocumentsApiClient);

    const { annots, autoAnnots } = useAnnotations(
        project.id,
        project.workingVersion.id,
        annotationsApiClient,
        notificationsApiClient,
        verificationDocumentInfo,
        createAuditLog,
        setAutoAnnotationsProcessIsErrorReceived
    );

    const { commentsByAnnotationId, addComment, getComments, resolveComment, deleteComment } = useAnnotationsComments(
        project.id,
        annotationsApiClient,
        annots.refetchAnnotations
    );
    const { setNeedsReview } = useAnnotationsNeedsReview(project.id, project.workingVersion.id, annotationsApiClient, annots.refetchAnnotations, getComments);
    const {
        annotations,
        annotationFilters,
        addAnnotation,
        deleteAnnotation,
        assignMemberToAnnotation,
        refetchAnnotations,
        markAnnotationNotificationAsRead,
        getAvailablePreviousStatuses,
        reorderAnnotationsNumbers,
    } = annots;

    const {
        autoAnnotations,
        createAnnotationFromAutoAnnotation,
        createAnnotationFromModifiedAutoAnnotation,
        rejectAutoAnnotation,
        refetchAutoAnnotations,
        markAutoAnnotationsProcessErrorAsReceived,
    } = autoAnnots;

    const { getAnnotationAssignedTags, addTagToAnnotation, deleteAssignedTagFromAnnotation } = useAnnotationsTags(
        project.id,
        project.workingVersion.id,
        annotationsApiClient,
        refetchAnnotations
    );
    const { getAnnotationKeywords, addAnnotationKeyword, removeAnnotationKeyword, removeAllAnnotationKeywords } = useAnnotationsKeywords(
        project.id,
        annotationsApiClient
    );

    const { getAssertion, saveAssertion } = useAnnotationsAssertions(project.id, annotationsApiClient, refetchAnnotations);

    const { acceptAnnotationStage } = useAnnotationsStatus(project.id, project.workingVersion.id, annotationsApiClient, refetchAnnotations);

    const { suppInfo, suppInfoPropositions } = useSupportingInformation(
        project.id,
        project.workingVersion.id,
        supportingInformationApiClient,
        refetchAnnotations
    );
    const { getSupportingInformation, supportingInformationByAnnotationId, addSupportingInformation, deleteSupportingInformation } = suppInfo;
    const {
        getSupportingInformationPropositions,
        supportingInformationPropositionByAnnotationId,
        linkPropositionToAnnotation,
        rejectProposition,
        restoreProposition,
        increaseCountPropositionsToDisplay,
    } = suppInfoPropositions;

    const { getProjectTags, addTagToProject, removeTagFromProject } = useTags(project.id, project.workingVersion.id, projectsApiClient, refetchAnnotations);

    const supportingDocumentsRef = useRef(suppDocsById);
    supportingDocumentsRef.current = suppDocsById;
    useProjectNotifications(notificationsConnector, project.id, {
        verificationDocumentProcessingStatusUpdate: (msg) => {
            const status = msg.processingStatus;
            switch (status) {
                case ProjectDocumentProcessingStatus.Processing:
                    setVerificationDocumentProcessingStatus(status);
                    addToast({ type: ToastEnum.SUCCESS, content: [msg.message] });
                    break;
                case ProjectDocumentProcessingStatus.Processed:
                    refetchDocuments();
                    addToast({ type: ToastEnum.SUCCESS, content: [msg.message] });
                    break;
                case ProjectDocumentProcessingStatus.Failed:
                    setVerificationDocumentProcessingStatus(status);
                    addToast({ type: ToastEnum.ERROR, content: [msg.message] });
                    break;
            }
        },
        supportingDocumentProcessingStatusUpdate: (msg) => {
            if (supportingDocumentsRef.current[msg.supportingDocumentId]) {
                setSupportingDocumentProcessingStatus(msg.supportingDocumentId, msg.processingStatus);

                switch (msg.processingStatus) {
                    case ProjectDocumentProcessingStatus.Processing:
                    case ProjectDocumentProcessingStatus.Processed:
                        addToast({ type: ToastEnum.SUCCESS, content: [msg.message] });
                        break;
                    case ProjectDocumentProcessingStatus.Failed:
                        addToast({ type: ToastEnum.ERROR, content: [msg.message] });
                        break;
                }
            }
        },
        autoAnnotationGenerated: (msg) => {
            handleAutoAnnotationGenerated(msg);
            refetchAutoAnnotations();
            let toastType: ToastEnum = ToastEnum.SUCCESS;
            switch (msg.processStatus) {
                case AutoAnnotationsProcessStatus.ProcessedWithErrors:
                    toastType = ToastEnum.WARNING;
                    break;
                case AutoAnnotationsProcessStatus.Failed:
                    toastType = ToastEnum.ERROR;
                    break;
            }
            addToast({ type: toastType, content: [msg.message] });
        },
        autoAnnotationGenerationProgress: (msg) => setAutoAnnotationsProcessProgress(msg.progress),
        autoAnnotationsSectionProcessStatusChanged: (msg) => {
            if (msg.processStatus === AutoAnnotationsSectionProcessStatus.Failed) {
                addToast({ type: ToastEnum.WARNING, content: [msg.message!] });
            }

            handleAutoAnnotationsSectionProcessStatusChanged(msg);
        },
    });

    const { isReadOnlyModalOpen, closeReadOnlyModal } = useErrorHandler(errorHandlerRegistration);

    return (
        <ProjectAuthContextProvider projectAuthContextProviderValue={projectAuthContextProviderValue}>
            <ProjectWorkspaceComponent
                navBarContents={navBarContents}
                projectProps={{ project, changeProjectName }}
                membersProps={{
                    getProjectMembers,
                    addMemberToProject,
                    changeMemberProjectRole,
                    removeMemberFromProject,
                    assignMemberToAnnotation,
                }}
                usersProps={{
                    getUsers,
                }}
                documentsProps={{
                    documents: {
                        verificationDocument: verificationDocumentInfo,
                        supportingDocumentsTree: supportingDocumentsTreeExtended,
                        supportingDocumentsById: suppDocsById,
                    },
                    getDocument,
                    verificationDocumentInfo: verificationDocumentInfo,
                    supportingDocumentInfo: supDocInfo,
                    supportingDocumentFileInfo: supDocFileInfo,
                    switchSupportingDocument,
                    uploadSupportingDocument,
                    anySupportingDocumentsProcessingErrors,
                }}
                annotationsProps={{
                    annotations: annotations || [],
                    addAnnotation,
                    deleteAnnotation,
                    markAnnotationNotificationAsRead,
                    getAnnotationAssignedTags,
                    addTagToAnnotation,
                    deleteAssignedTagFromAnnotation,
                    getAvailablePreviousStatuses,
                    reorderAnnotationsNumbers,
                    annotationFilters,
                    autoAnnotationsProps: {
                        autoAnnotationsProcess: verificationDocumentInfo?.autoAnnotationsProcess || null,
                        autoAnnotations,
                        refetchAutoAnnotations,
                        createAnnotationFromAutoAnnotation,
                        createAnnotationFromModifiedAutoAnnotation,
                        rejectAutoAnnotation,
                        markAutoAnnotationsProcessErrorAsReceived,
                    },
                    annotationKeywordsProps: {
                        getKeywords: getAnnotationKeywords,
                        addKeyword: addAnnotationKeyword,
                        removeKeyword: removeAnnotationKeyword,
                        removeAllKeywords: removeAllAnnotationKeywords,
                    },
                    acceptAnnotationStage,
                    setNeedsReview,
                }}
                supportingInformationProps={{
                    supportingInformationByAnnotationId,
                    getSupportingInformation,
                    addSupportingInformation,
                    deleteSupportingInformation,
                }}
                supportingInformationPropositionsProps={{
                    supportingInformationPropositionByAnnotationId,
                    increaseCountPropositionsToDisplay,
                    getSupportingInformationPropositions,
                    linkPropositionToAnnotation,
                    rejectProposition,
                    restoreProposition,
                }}
                assertionsProps={{
                    getAssertion,
                    saveAssertion,
                }}
                commentsProps={{
                    commentsByAnnotationId,
                    addComment,
                    getComments,
                    resolveComment,
                    deleteComment,
                }}
                tagsProps={{
                    addTagToProject,
                    getProjectTags,
                    removeTagFromProject,
                }}
                createAuditLog={createAuditLog}
                readOnlyModalProps={{
                    isOpen: isReadOnlyModalOpen,
                    closeModal: closeReadOnlyModal,
                }}
            />
        </ProjectAuthContextProvider>
    );
}

export default ProjectWorkspaceModule;
