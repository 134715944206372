import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import ProjectsApiClient from 'ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import { ProjectTag } from 'ApiClients/SterlingApiClients/Types';

const useTags = (projectId: string, projectVersionId: string, projectsApi: ProjectsApiClient, refetchAnnotations: () => void) => {
    const addTagToProject = (tag: string, flowMethods?: FlowMethods) =>
        projectsApi.addTag(projectId!, projectVersionId, { ...flowMethods, body: { tagName: tag } });

    const getProjectTags = (flowMethods: FlowMethods<Array<ProjectTag>>) => projectsApi.getTags(projectId!, projectVersionId, flowMethods);

    const removeTagFromProject = (tagId: string, flowMethods?: FlowMethods) =>
        projectsApi.removeTag(projectId!, projectVersionId, tagId, {
            ...flowMethods,
            onSuccess() {
                if (flowMethods && flowMethods.onSuccess) {
                    flowMethods.onSuccess({});
                }
                refetchAnnotations();
            },
        });

    return {
        addTagToProject,
        getProjectTags,
        removeTagFromProject,
    };
};

export default useTags;
