import { Box } from '@mui/material';
import LoadingPageComponent from 'App/LoadingPage/LoadingPage.Component';
import PdfViewerMultiComponent from 'Views/Common/PdfViewerMulti/PdfViewerMulti.Component';
import { DocsViewerStepsWrapperState } from './useDocsViewerStepsWrapperState';
import DocumentsPreviewComponent, {
    DocumentsPreviewHeadHeight,
    DocumentsPreviewHeadHeightFullscreen,
    DocumentsPreviewMarginBottom,
    DocumentsPreviewMarginBottomFullscreen,
} from './DocumentsPreview/DocumentsPreview.Component';
import { useContext, useMemo } from 'react';
import AnnotationsTransferComponent, {
    AnnotationsTransferHeadHeight,
    AnnotationsTransferHeadHeightFullscreen,
    AnnotationsTransferMarginBottom,
} from './AnnotationsTransfer/AnnotationsTransfer.Component';
import { FullscreenModeContext } from 'Contexts/FullscreenMode.Context';

export type DocsViewerStepsWrapperComponentProps = DocsViewerStepsWrapperState;

function DocsViewerStepsWrapperComponent(props: DocsViewerStepsWrapperComponentProps) {
    const { activeStep, viewerPropsActive, viewerPropsDraft, compareState, documentsPreviewState, annotationsTransferState } = props;
    const { setComparing, setDocsCompareResult } = compareState;

    const { isFullscreen } = useContext(FullscreenModeContext);

    const { headHeight, marginBottom, topContentComponent, siblingSync } = useMemo(() => {
        let headHeight = '0rem';
        let marginBottom = '0rem';
        let topContentComponent: JSX.Element | null = null;
        let siblingSync = false;
        switch (activeStep) {
            case 1:
                headHeight = isFullscreen ? DocumentsPreviewHeadHeightFullscreen : DocumentsPreviewHeadHeight;
                marginBottom = isFullscreen ? DocumentsPreviewMarginBottomFullscreen : DocumentsPreviewMarginBottom;
                topContentComponent = <DocumentsPreviewComponent {...documentsPreviewState} compareState={compareState} />;
                break;
            case 2:
                headHeight = isFullscreen ? AnnotationsTransferHeadHeightFullscreen : AnnotationsTransferHeadHeight;
                marginBottom = AnnotationsTransferMarginBottom;
                topContentComponent = <AnnotationsTransferComponent {...annotationsTransferState} compareState={compareState} />;
                siblingSync = true;
                break;
        }

        return { headHeight, marginBottom, topContentComponent, siblingSync };
    }, [activeStep, annotationsTransferState, documentsPreviewState, compareState, isFullscreen]);

    const displayCompareResult = useMemo(() => {
        if (activeStep === 1) return true;
        if (activeStep === 2) return annotationsTransferState.showDiffs;

        return false;
    }, [activeStep, annotationsTransferState.showDiffs]);

    return (
        <Box sx={{ height: '100%' }}>
            {topContentComponent}
            <Box sx={{ height: `calc(100% - ${headHeight} - ${marginBottom})` }}>
                {viewerPropsActive && viewerPropsDraft ? (
                    <PdfViewerMultiComponent
                        viewerPropsLeft={viewerPropsActive}
                        viewerPropsRight={viewerPropsDraft}
                        siblingSync={siblingSync}
                        compareProps={{
                            compareEnabled: true,
                            docIdLeft: viewerPropsActive.documentInfo.id,
                            docIdRight: viewerPropsDraft.documentInfo.id,
                            setComparing,
                            displayCompareResult,
                            onCompare: setDocsCompareResult,
                        }}
                    />
                ) : (
                    <LoadingPageComponent />
                )}
            </Box>
        </Box>
    );
}

export default DocsViewerStepsWrapperComponent;
