import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import { palette } from 'UI/Provider/VerifiTheme';
import { Close, CloudUpload, FileTypePdf } from 'UI/Icon';
import { Button } from 'UI';
import ProgressBarComponent from './ProgressBar/ProgressBar.Component';
import { DocumentFileConfiguration } from 'App/Consts';

type DocumentUploaderProps = {
    file: File | null;
    setFile: (file: File | null) => void;
    disabled?: boolean;
    uploadLabel: string;
};

const AllowedFileTypesConfig = DocumentFileConfiguration.allowedFileTypes;
const AllowedFileTypesOnDrop = Object.keys(AllowedFileTypesConfig).map((key) => AllowedFileTypesConfig[key as keyof typeof AllowedFileTypesConfig].key);
const GetAllowedFileTypesDropzone = () => {
    let allowedFileTypesDropzone: { [key: string]: string[] } = {};
    for (const key in AllowedFileTypesConfig) {
        const type = AllowedFileTypesConfig[key as keyof typeof AllowedFileTypesConfig].key;
        const extensions = AllowedFileTypesConfig[key as keyof typeof AllowedFileTypesConfig].extensions;
        allowedFileTypesDropzone[type] = extensions;
    }
    return allowedFileTypesDropzone;
};
const AllowedFileTypesDropzone = GetAllowedFileTypesDropzone();

const isEligibleFileType = (file: File): boolean => {
    const fileType = file.type;

    return AllowedFileTypesOnDrop.includes(fileType);
};

function DocumentUploaderComponent({ file, setFile, uploadLabel, disabled }: DocumentUploaderProps) {
    const [progress, setProgress] = useState<number>(0);
    const removeFile = useCallback(() => setFile(null), [setFile]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const selectedFile = acceptedFiles[0];
            const sizeInMB: number = selectedFile.size / (1024 * 1024);
            if (isEligibleFileType(selectedFile) && sizeInMB < DocumentFileConfiguration.maxFileSizeMB) {
                const reader = new FileReader();

                reader.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentLoaded = Math.round((event.loaded / event.total) * 100);
                        setProgress(percentLoaded);
                    }
                };

                reader.onloadend = () => {
                    setFile(selectedFile);
                };

                reader.readAsArrayBuffer(selectedFile);
            }
        },
        [setFile, setProgress]
    );

    useEffect(() => {
        if (file) {
            setProgress(100);
        }
    }, [file]);

    const {
        getRootProps,
        getInputProps,
        open: openInput,
    } = useDropzone({
        onDrop,
        noClick: true,
        multiple: false,
        accept: AllowedFileTypesDropzone,
        disabled,
    });

    return (
        <Box>
            <Box
                {...getRootProps()}
                sx={{
                    overflow: 'hidden',
                    paddingTop: '2rem',
                    paddingBottom: '2rem',
                    background: palette.body.main,
                    border: `0.125rem dashed ${palette.stroke.main}`,
                    borderRadius: '0.25rem',
                    textAlign: 'center',
                }}
                data-testid='drop-area'
            >
                <Box data-testid='file-uploader-box'>
                    <CloudUpload color={palette.blue.medium} style={{ marginBottom: '1rem' }} />

                    <Typography
                        variant='title'
                        sx={{
                            marginBottom: '0.5rem',
                        }}
                    >
                        {uploadLabel}
                        <br />
                        <span style={{ fontWeight: '600' }}> (PDF, .docx) </span>
                    </Typography>
                    <Typography
                        variant='body2'
                        sx={{
                            marginBottom: '1rem',
                        }}
                    >
                        Drag and drop file here or upload from computer
                    </Typography>
                    <input data-testid='file-uploader-input' {...getInputProps({ id: 'file-input' })} disabled={disabled} />
                    <Button
                        data-testid='file-uploader-box'
                        variant='outlined'
                        sx={{ paddingLeft: '2rem', paddingRight: '2rem' }}
                        onClick={openInput}
                        disabled={disabled}
                    >
                        UPLOAD
                    </Button>
                </Box>
            </Box>
            {file && (
                <Box
                    style={{
                        padding: '1rem',
                        marginTop: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        border: `1px solid ${palette.stroke.main}`,
                        borderRadius: '5px',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex' }}>
                            <FileTypePdf height={40} width={40} />

                            <Box sx={{ marginLeft: '1rem' }}>
                                <Typography variant='subtitle1'>{file.name}</Typography>
                                <Typography color='body2'>{file.size / 1000} KB</Typography>
                            </Box>
                        </Box>
                        <Close style={{ color: palette.text.main, cursor: 'pointer' }} onClick={removeFile} />
                    </Box>
                    <ProgressBarComponent value={progress} />
                </Box>
            )}
        </Box>
    );
}

export default DocumentUploaderComponent;
