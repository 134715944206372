import { useMemo } from 'react';
import { Highlight } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';
import { getContentType } from 'Views/Project/ProjectWorkspace/Types';
import { hexToRGBA } from 'UI/functions';
import { PdfViewerOtherProps } from '../../Hooks/usePdfViewer';
import { AnnotationTransferData, StatusConfig } from '../useAnnotationsTransferState';
import ActiveAnnotationPanelWrapperComponent from '../PdfViewerComponents/ActiveAnnotationPanelWrapper/ActiveAnnotationPanelWrapper.Component';

const usePdfViewerActive = (
    selectedAnnotationId: string | null,
    setSelectedAnnotationId: (annotationId: string | null) => void,
    data: Array<AnnotationTransferData>
): PdfViewerOtherProps => {
    const highlights = useMemo(
        () => [
            ...data.map((el): Highlight => {
                const annot = el.annotationActive;
                return {
                    id: annot.id,
                    orderNumber: annot.orderNumber,
                    contentType: getContentType(annot.content.selectionType),
                    boundingBoxSections: annot.content.boundingBoxSections,
                    color: annot.id === selectedAnnotationId ? hexToRGBA(StatusConfig[el.status].selectedColor) : hexToRGBA(StatusConfig[el.status].baseColor),
                    siblingsId: annot.id,
                };
            }),
        ],
        [data, selectedAnnotationId]
    );

    const highlightToolbarsDeps: React.DependencyList = useMemo(() => {
        return [highlights, selectedAnnotationId];
    }, [highlights, selectedAnnotationId]);

    return {
        displayHighlightIcons: false,
        isCreatingHighlightEnabled: false,
        highlightsProps: {
            highlights: highlights,
            highlightToolbars: {
                highlightToolbar: {
                    render: (options) => {
                        const annotation = data.find((el) => el.annotationActive.id === selectedAnnotationId)?.annotationActive;
                        if (annotation) {
                            return (
                                <ActiveAnnotationPanelWrapperComponent
                                    highlightToolbarOptions={options}
                                    annotation={data.find((el) => el.annotationActive.id === selectedAnnotationId)?.annotationActive!}
                                />
                            );
                        } else {
                            return <div />;
                        }
                    },
                },
                newHighlightToolbar: {
                    render: () => <div />,
                },
                deps: highlightToolbarsDeps,
            },
            highlightLeftTopInformation: (highlight) => [{ textContent: highlight.orderNumber.toString() }],
            selectedHighlightId: selectedAnnotationId,
            setSelectedHighlightId: setSelectedAnnotationId,
            clearSelectedHighlight: () => setSelectedAnnotationId(null),
            focusOnSelectedHighlightAfterDraw: true,
            keepSelectedHighlightInCentre: true,
        },
    };
};

export default usePdfViewerActive;
