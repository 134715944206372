import { Box, Collapse, Divider } from '@mui/material';
import { Annotation } from 'ApiClients/SterlingApiClients/Types';
import { Icon, Typography } from 'UI';
import { SupportingInformationContext, SupportingInformationPropositionContext } from 'Views/Project/ProjectWorkspace/Types';
import { useState } from 'react';
import InformationDataComponent from './InformationData/InformationData';

export type InformationDataToolbarProps = {
    selectedAnnotation: Annotation | null;
    supportingInformationContext: SupportingInformationContext | null;
    supportingInformationPropositionContext: SupportingInformationPropositionContext | null;
    openSupportingInformationPropositions: () => void;
    openSuppInfoSummaryModal: (annotationId: string) => void;
    setSelectedSupportingHighlightId: (id: string | null, skipJump: boolean) => void;
};

function InformationDataToolbar(props: InformationDataToolbarProps) {
    const {
        selectedAnnotation,
        supportingInformationContext,
        supportingInformationPropositionContext,
        openSuppInfoSummaryModal,
        openSupportingInformationPropositions,
        setSelectedSupportingHighlightId,
    } = props;

    const [open, setOpen] = useState<boolean>(true);

    return (
        <Box
            sx={{
                position: 'absolute',
                top: '3rem',
                zIndex: 100,
                display: 'flex',
                width: 'fit-content',
            }}
        >
            <Box
                onClick={() => setOpen(!open)}
                sx={{
                    height: '4rem',
                    width: '2rem',
                    backgroundColor: (theme) => theme.palette.blue.dark,
                    color: (theme) => theme.palette.white.main,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                {open ? <Icon.ArrowSimpleLeft /> : <Icon.ArrowSimpleRight />}
            </Box>
            <Collapse in={open} orientation='horizontal'>
                <Box
                    sx={{
                        width: '35rem',
                        height: '100%',
                        backgroundColor: (theme) => theme.palette.white.main,
                        border: (theme) => `0.125rem solid ${theme.palette.stroke.main}`,
                        borderRadius: '0rem 0.25rem 0.25rem 0rem',
                        paddingLeft: '1rem',
                        display: 'flex',
                    }}
                >
                    {selectedAnnotation ? (
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                                <Typography>Annotation</Typography>
                                <Box
                                    sx={{
                                        marginLeft: '0.5rem',
                                        minWidth: '1.5rem',
                                        height: '1.5rem',
                                        fontWeight: 700,
                                        fontSize: '0.75rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '0.25rem',
                                        paddingLeft: '0.25rem',
                                        paddingRight: '0.25rem',
                                        backgroundColor: (theme) => theme.palette.blue.light,
                                        border: (theme) => `0.125rem solid ${theme.palette.blue.dark}`,
                                    }}
                                >
                                    {selectedAnnotation.orderNumber}
                                </Box>
                            </Box>
                            <Divider orientation='vertical' />
                            <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem', width: '100%' }}>
                                <Box sx={{ display: 'grid', rowGap: '0.5rem', width: '100%' }}>
                                    <InformationDataComponent
                                        title='Linked Information'
                                        highlightsContext={
                                            (supportingInformationContext && {
                                                highlights:
                                                    supportingInformationContext.supportingInformation?.map((s) => ({
                                                        id: s.id,
                                                        number: s.orderNumber,
                                                        page: s.content.boundingBoxSections[0].pageNumber,
                                                        active: true,
                                                    })) || [],
                                                isFetching: supportingInformationContext.isFetching,
                                            }) ||
                                            null
                                        }
                                        setSelectedSupportingHighlightId={setSelectedSupportingHighlightId}
                                        action={{ title: 'Linked Information List', func: () => openSuppInfoSummaryModal(selectedAnnotation.id) }}
                                    />
                                    <InformationDataComponent
                                        title='Propositions'
                                        highlightsContext={
                                            (supportingInformationPropositionContext && {
                                                highlights:
                                                    supportingInformationPropositionContext.supportingInformationPropositions?.map((p) => ({
                                                        id: p.id,
                                                        number: p.propositionNumber,
                                                        page: p.content.boundingBoxSections[0].pageNumber,
                                                        active: !p.isRejected,
                                                    })) || [],
                                                isFetching: supportingInformationPropositionContext.isFetching,
                                            }) ||
                                            null
                                        }
                                        setSelectedSupportingHighlightId={setSelectedSupportingHighlightId}
                                        action={{ title: 'Propositions List', func: () => openSupportingInformationPropositions() }}
                                        actionWhenEmpty={{ title: 'Find Supporting Information', func: () => openSupportingInformationPropositions() }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>Choose Annotation to see more data.</Typography>
                        </Box>
                    )}
                </Box>
            </Collapse>
        </Box>
    );
}

export default InformationDataToolbar;
