import PropositionPanelComponent from './PropositionPanel/PropositionPanel.Component';
import SupportingInformationPanelComponent from './SupportingInformationPanel/SupportingInformationPanel.Component';
import { Annotation } from 'ApiClients/SterlingApiClients/Types';
import { SupportingHighlightCustomData } from '../SupportingDocument.Component';
import { useEffect, useRef } from 'react';
import { Navigation } from '../Hooks/useNavigation';
import { Highlight } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

export type SupportingInformationPanelWrapperComponentProps = {
    setRef: (ref: React.RefObject<HTMLDivElement>) => void;
    selectedHighlightRef: React.RefObject<Highlight<SupportingHighlightCustomData> | null>;
    selectedAnnotationRef: React.RefObject<Annotation | null>;
    supportingInformationNavRef: React.RefObject<Navigation>;
    deleteSupportingInformation: (annotationId: string, supportingInformationId: string, setFetching: (isFetching: boolean) => void) => void;
    propositionsNavRef: React.RefObject<Navigation>;
    rejectProposition: (annotationId: string, propositionId: string) => void;
    linkPropositionToAnnotation: (annotationId: string, propositionId: string, setFetching: (isFetching: boolean) => void) => void;
    restoreProposition: (annotationId: string, propositionId: string, setFetching: (isFetching: boolean) => void) => void;
};

function SupportingInformationPanelWrapperComponent(props: SupportingInformationPanelWrapperComponentProps) {
    const {
        setRef,
        selectedHighlightRef,
        selectedAnnotationRef,
        supportingInformationNavRef,
        deleteSupportingInformation,
        propositionsNavRef,
        rejectProposition,
        linkPropositionToAnnotation,
        restoreProposition,
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setRef(ref);
    }, [setRef]);

    const selectedHighlight = selectedHighlightRef.current;
    const selectedAnnotation = selectedAnnotationRef.current;
    const supportingInformationNav = supportingInformationNavRef.current;
    const propositionsNav = propositionsNavRef.current;

    let panel: JSX.Element = <div />;
    if (selectedHighlight && selectedAnnotation && supportingInformationNav && propositionsNav) {
        switch (selectedHighlight.type) {
            case 'linked':
                panel = (
                    <SupportingInformationPanelComponent
                        navigation={supportingInformationNav}
                        deleteSupportingInformation={() => deleteSupportingInformation(selectedAnnotation.id, selectedHighlight.id, () => {})}
                    />
                );
                break;
            case 'proposition':
                panel = (
                    <PropositionPanelComponent
                        navigation={propositionsNav}
                        isRejected={selectedHighlight.propositionIsRejected || false}
                        rejectProposition={() => rejectProposition(selectedAnnotation.id, selectedHighlight.id)}
                        linkProposition={(setLinkingInformation) =>
                            linkPropositionToAnnotation(selectedAnnotation.id, selectedHighlight.id, setLinkingInformation)
                        }
                        restoreProposition={(setRestoringProposition) =>
                            restoreProposition(selectedAnnotation.id, selectedHighlight.id, setRestoringProposition)
                        }
                    />
                );
                break;
        }
    }
    return <div ref={ref}>{panel}</div>;
}

export default SupportingInformationPanelWrapperComponent;
