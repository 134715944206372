export const DocumentFileConfiguration = {
    maxFileSizeMB: 200,
    allowedFileTypes: {
        word: {
            key: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            extensions: ['.docx'],
        },
        pdf: {
            key: 'application/pdf',
            extensions: ['.pdf'],
        },
    }
}

export const ErrorsDescription = {
    verificationDocumentProcessingStatusFailed: 'Verification document processing failed.',
    anySupportingDocumentsProcessingErrors: 'Some of supporting document processing failed.',
    autoAnnotationsProcessStatusFailed: 'Auto Annotations Process failed.',
    autoAnnotationsProcessStatusProcessedWithErrors: 'Auto Annotations process processed with errors.',
}