import ProjectsApiClient from 'ApiClients/SterlingApiClients/ProjectsApiClient/Projects.ApiClient';
import ReportsApiClient from 'ApiClients/SterlingApiClients/ReportsApiClient/Reports.ApiClient';
import { Navigate, useParams } from 'react-router-dom';
import ProjectDashboardComponent from './ProjectDashboard.Component';
import useProjectAuth from 'Hooks/useProjectAuth';
import { AppRoutes } from 'App/RoutesPaths';
import { NavBarContents } from 'App/NavBar/NavBar.Component';
import AuditLogsApiClient from 'ApiClients/SterlingApiClients/AuditLogsApiClient/AuditLogs.ApiClient';
import { useEffect } from 'react';
import { Capability_ProjectCollaboration } from 'ApiClients/SterlingApiClients/AuditLogsApiClient/AuditLogs.ApiClient.Types';
import { FlowMethods } from 'ApiClients/Sterling.ApiClient';
import { ProjectIndexReport, ProjectMember, VerificationNote } from 'ApiClients/SterlingApiClients/Types';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import { ProjectWrapperProps } from '../Common/ProjectVersion.Wrapper';

export type ProjectDashboardModuleProps = {
    projectWrapperProps: ProjectWrapperProps;
    navBarContents: NavBarContents;
    reportsApiClient: ReportsApiClient;
    projectsApiClient: ProjectsApiClient;
    auditLogsApiClient: AuditLogsApiClient;
};

function ProjectDashboardModule(props: ProjectDashboardModuleProps) {
    const { versionId: projectVersionIdUrlParam } = useParams();
    const { projectWrapperProps, reportsApiClient: reportsApi, projectsApiClient: projectsApi, navBarContents, auditLogsApiClient } = props;
    const { project } = projectWrapperProps;
    const { isEditor, isLoading } = useProjectAuth({ getMyProjectMemberInstance: projectsApi.getMyProjectMemberInstance, projectId: project.id });

    const { data: report } = usePromiseWithFlowMethods<{ projectId: string; projectVersionId: string }, ProjectIndexReport | null>({
        method: (input, flowMethods) => reportsApi.getIndexReport(input.projectId, input.projectVersionId, flowMethods),
        initialData: null,
        initFetch: { input: { projectId: project.id, projectVersionId: project.workingVersion.id } },
    });

    const { data: members } = usePromiseWithFlowMethods<{ projectId: string }, Array<ProjectMember>>({
        method: (input, flowMethods) => projectsApi.getMembers(input.projectId, flowMethods),
        initialData: [],
        initFetch: { input: { projectId: project.id } },
    });

    useEffect(() => {
        auditLogsApiClient.createAuditLog({
            body: {
                content: JSON.stringify({
                    projectId: project.id,
                }),
                actionType: 'OpenDashboard',
                capability: Capability_ProjectCollaboration,
            },
        });
    }, [auditLogsApiClient, project.id]);

    const getVerificationNote = (flowMethods?: FlowMethods<VerificationNote>) =>
        reportsApi.getVerificationNote(project.id, project.workingVersion.id, flowMethods);

    return (
        <>
            {!isLoading &&
                (isEditor ? (
                    <ProjectDashboardComponent
                        navBarContents={navBarContents}
                        projectId={project.id}
                        projectVersionIdUrlParam={projectVersionIdUrlParam!}
                        report={report}
                        members={members}
                        getVerificationNote={getVerificationNote}
                        projectName={project.name}
                    />
                ) : (
                    <Navigate to={AppRoutes.homePage.specificPath({})}></Navigate>
                ))}
        </>
    );
}

export default ProjectDashboardModule;
