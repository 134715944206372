import { useEffect, useRef } from 'react';
import { Annotation, BoundingBoxSection, DocumentInfo } from 'ApiClients/SterlingApiClients/Types';
import HighlightButtonComponent from 'Views/Common/PdfViewerWithToolbar/HighlightPanelComponents/HighlightButton/HighlightButton.Component';
import { NewHighlightToolbarOptions, ContentType } from 'Views/Common/PdfViewerWithToolbar/PdfViewerWithToolbar.Types';

export type NewSupportingInformationPanelWrapperComponentProps = {
    newHighlightToolbarOptions: NewHighlightToolbarOptions;
    selectedAnnotationRef: React.MutableRefObject<Annotation | null>;
    addSupportingInformation: (
        annotationId: string,
        supportingDocumentId: string,
        contentType: ContentType,
        boundingBoxSections: Array<BoundingBoxSection>,
        statement: string
    ) => void;
    documentInfo: DocumentInfo;
};

function NewSupportingInformationPanelWrapperComponent(props: NewSupportingInformationPanelWrapperComponentProps) {
    const { newHighlightToolbarOptions, selectedAnnotationRef, addSupportingInformation, documentInfo } = props;

    const { setHighlightToolbarRef } = newHighlightToolbarOptions;
    const annot = selectedAnnotationRef.current;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setHighlightToolbarRef(ref);
    }, [setHighlightToolbarRef]);

    let panel: JSX.Element = <div />;

    if (annot) {
        panel = (
            <HighlightButtonComponent
                rootRef={ref}
                content='LINK TO  ANNOTATION'
                onClick={async () => {
                    const newHighlight = await newHighlightToolbarOptions.getNewHighlight();
                    if (annot) {
                        addSupportingInformation &&
                            addSupportingInformation(
                                annot.id,
                                documentInfo.id,
                                newHighlight.contentType,
                                newHighlight.boundingBoxSections,
                                newHighlight.statement
                            );
                    }
                    newHighlightToolbarOptions.clearSelection();
                }}
            />
        );
    }

    return <div ref={ref}>{panel}</div>;
}

export default NewSupportingInformationPanelWrapperComponent;
