import { CustomErrorHandler, ErrorHandlerRegistration } from 'ApiClients/Sterling.ApiClient';
import { useCallback, useEffect, useState } from 'react';

const useErrorHandler = (errorHandlerRegistration: ErrorHandlerRegistration) => {
    const [isReadOnlyModalOpen, setIsReadOnlyModalOpen] = useState(false);
    const closeReadOnlyModal = useCallback(() => setIsReadOnlyModalOpen(false), [setIsReadOnlyModalOpen]);
    const openReadOnlyModal = useCallback(() => setIsReadOnlyModalOpen(true), [setIsReadOnlyModalOpen]);
    const errorHandler: CustomErrorHandler = useCallback(
        (error, baseErrorHandler) => {
            if (error?.response?.status === 423) {
                openReadOnlyModal();
            } else {
                baseErrorHandler(error);
            }
        },
        [openReadOnlyModal]
    );

    useEffect(() => {
        errorHandlerRegistration.register(errorHandler);
        return () => errorHandlerRegistration.unregister();
    }, [errorHandlerRegistration, errorHandler]);

    return {
        isReadOnlyModalOpen,
        closeReadOnlyModal,
    };
};

export default useErrorHandler;
