import { useContext, useState } from 'react';
import { AppBar, Box, Menu as MuiMenu, Toolbar } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Contexts/Auth.Context';
import { AppRoutes } from 'App/RoutesPaths';
import { Button, Icon, Tooltip } from 'UI';
import Menu from 'UI/Menu';

type NavBarComponentProps = {
    navBarContents: NavBarContents;
    logout: () => void;
};

export type NavBarContents = {
    left: React.MutableRefObject<HTMLDivElement | null>;
    center: React.MutableRefObject<HTMLDivElement | null>;
    right: React.MutableRefObject<HTMLDivElement | null>;
};

export const NavBarHeight = '4rem';

function NavBarComponent(props: NavBarComponentProps) {
    const { navBarContents, logout } = props;
    const authContext = useContext(AuthContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <AppBar sx={{ backgroundColor: (theme) => theme.palette.white.main, color: (theme) => theme.palette.text.main }}>
                <Toolbar sx={{ height: NavBarHeight, margin: '0 3rem', padding: '0 !important' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title={`Version ${process.env.REACT_APP_VERSION || 'Unknown'}`}>
                            <Link style={{ display: 'flex' }} to={AppRoutes.homePage.specificPath({})}>
                                <Icon.VerifiLogo height={24} width='100%' />
                            </Link>
                        </Tooltip>
                    </Box>
                    <Box sx={{ marginLeft: '2rem', width: '100%', display: 'flex', alignItems: 'center' }}>
                        <Box>
                            <div ref={navBarContents.left} />
                        </Box>
                        <Box>
                            <div ref={navBarContents.center} />
                        </Box>
                        <Box sx={{ marginLeft: 'auto', marginRight: '0rem', display: 'flex' }}>
                            <div ref={navBarContents.right} />
                            {authContext.isAuthenticated && (
                                <Box sx={{ lineHeight: '2rem', height: '100%', marginLeft: '2rem' }}>
                                    <Button onClick={handleClick} color='secondary' shape='circle'>
                                        <Icon.PersonCircle />
                                    </Button>
                                    <MuiMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                                        <Menu.Item onClick={logout} sx={{ gap: 1, alignItems: 'center' }}>
                                            <LogoutIcon sx={{ fontSize: 14 }} />
                                            Logout
                                        </Menu.Item>
                                    </MuiMenu>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default NavBarComponent;
