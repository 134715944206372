import { Box } from '@mui/material';
import { VerifiSpinner } from 'UI';

function LoadingPageComponent() {
    return (
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <VerifiSpinner />
        </Box>
    );
}

export default LoadingPageComponent;
