import { ProjectVersionListRecord } from 'ApiClients/SterlingApiClients/Types';
import { Button, Typography } from 'UI';
import Dialog from 'UI/Dialog';

export type RestoreModalState = { isOpen: boolean; version: ProjectVersionListRecord | null };

export type RestoreModalProps = {
    closeModal: () => void;
    state: RestoreModalState;
    restore: (version: ProjectVersionListRecord) => void;
};

function RestoreModalComponent(props: RestoreModalProps) {
    const { closeModal, state, restore } = props;

    const handleSubmit = (event: React.FormEvent) => {
        restore(state.version!);
        event.preventDefault();
    };

    return (
        <Dialog open={state.isOpen} onClose={closeModal} maxWidth='xs' data-testid='restore-archived-version-modal'>
            <Dialog.Title>Restore archived version</Dialog.Title>
            <Dialog.Content>
                <form onSubmit={handleSubmit}>
                    <Typography variant='body2' sx={{ textAlign: 'center', padding: '0.5rem 2rem 0 2rem', mb: 4 }}>
                        Are you sure you want to restore the archived document <b>{state.version?.verificationDocumentName}</b> as the current working document?
                    </Typography>

                    <Dialog.Actions>
                        <Button
                            fullWidth
                            color='secondary'
                            type='button'
                            onClick={closeModal}
                            sx={{
                                maxWidth: 160,
                            }}
                        >
                            No - Cancel
                        </Button>
                        <Button
                            fullWidth
                            type='submit'
                            sx={{
                                maxWidth: 160,
                            }}
                        >
                            Yes - Restore
                        </Button>
                    </Dialog.Actions>
                </form>
            </Dialog.Content>
        </Dialog>
    );
}

export default RestoreModalComponent;
