import { Box, FormControlLabel } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import useToasts, { ToastEnum } from 'Hooks/useToasts';
import { palette } from 'UI/Provider/VerifiTheme';
import AnnotationsListComponent, { AnnotationInfo } from './AnnotationsList/AnnotationsList.Component';
import { Button, Checkbox, Chip, Icon, Tooltip, Typography } from 'UI';
import { Close, EditNote } from 'UI/Icon';
import { AnnotationsScope } from '../../../Types';
import AnnotationSwitch from 'UI/Switch/Switch.Component';
import { TabsHeight } from '../Tabs.Component';
import { AutoAnnotationsProcess, AutoAnnotationsProcessStatus, DocumentHeading, ProjectDocumentProcessingStatus } from 'ApiClients/SterlingApiClients/Types';
import usePromiseWithFlowMethods from 'Hooks/usePromiseWithFlowMethods';
import ProcessingInfoComponent from './ProcessingInfo/ProcessingInfo.Component';
import { ErrorsDescription } from 'App/Consts';
import { ProjectAuthContext } from 'Contexts/ProjectAuth.Context';

type AnnotationsComponentProps = {
    annotationsScope: AnnotationsScope;
    verificationDocumentHeadings: Array<DocumentHeading>;
};

export type HeadingWithAnnotations = {
    id: string;
    name: string;
    page: number;
    coordinateY: number;
    annotations: Array<AnnotationInfo>;
    areAnyAutoAnnotatingSectionsErrors: boolean;
};

export const UtilsHeight = '8rem';

function AnnotationsComponent(props: AnnotationsComponentProps) {
    const { annotationsScope, verificationDocumentHeadings: headings } = props;
    const [showAutoAnnotations, setShowAutoAnnotations] = useState(true);

    const headingsWithAnnotations: Array<HeadingWithAnnotations> = useMemo(() => {
        let headingsWithAnnots: Array<HeadingWithAnnotations> = headings.map((h) => ({
            id: h.id,
            name: h.statement,
            page: h.content.boundingBoxSections[0].pageNumber,
            coordinateY: h.content.boundingBoxSections[0].boundingBoxes[0].topLeft.y,
            annotations: [],
            areAnyAutoAnnotatingSectionsErrors: h.anyAutoAnnotationsSectionProcessFailed,
        }));

        if (headingsWithAnnots.length === 0) {
            headingsWithAnnots.push({
                id: 'dummy-heading',
                name: 'Document',
                page: 1,
                coordinateY: 0,
                annotations: [],
                areAnyAutoAnnotatingSectionsErrors: false,
            });
        }

        return headingsWithAnnots;
    }, [headings]);

    const [collapsedHeadings, _setCollapsedHeadings] = useState<Array<string>>([]);
    const collapseExpandHeading = (id: string) =>
        _setCollapsedHeadings((prev) => {
            if (prev.includes(id)) return prev.filter((h) => h !== id);
            else return [...prev, id];
        });
    const allHeadingsCollapsed = collapsedHeadings.length === headingsWithAnnotations.length;
    const collapseExpandAllHeadings = () =>
        _setCollapsedHeadings(() => {
            if (allHeadingsCollapsed) return [];
            else return headingsWithAnnotations.map((h) => h.id);
        });

    const { verificationDocumentProcessingStatus, autoAnnotations, autoAnnotationsProcess, markAutoAnnotationsProcessErrorAsReceived } =
        annotationsScope.autoAnnotationsScope;
    const { addToast } = useToasts();
    const filters = annotationsScope.annotationFilters;
    const utils = [{ text: 'Filters', onClick: () => annotationsScope.modals.openAnnotationFiltersModal() }];

    const handleSwitchClick = () => setShowAutoAnnotations((prev) => !prev);

    const { wrappedMethod: reorderAnnotationsNumbers, fetching: reordering } = usePromiseWithFlowMethods({
        method: (_input, flowMethods) => props.annotationsScope.reorderAnnotationsNumbers(flowMethods),
        initialData: {},
    });

    const processing = useMemo(() => {
        if (verificationDocumentProcessingStatus !== ProjectDocumentProcessingStatus.Processed) return true;

        if (autoAnnotationsProcess) {
            const status = autoAnnotationsProcess.status;

            if (status === AutoAnnotationsProcessStatus.ToBeProcessed || status === AutoAnnotationsProcessStatus.Processing) return true;
        }

        return false;
    }, [verificationDocumentProcessingStatus, autoAnnotationsProcess]);

    return (
        <Box
            sx={{
                height: `calc(100% - ${TabsHeight})`,
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
            }}
        >
            <Box
                sx={{
                    backgroundColor: (theme) => theme.palette.white.main,
                    display: 'flex',
                    padding: '1rem',
                    paddingBottom: '0.5rem',
                    height: UtilsHeight,
                    gap: 2,
                    columnGap: 1,
                    flexDirection: 'column',
                    position: 'relative',
                    flex: '1 0 3.5rem',
                }}
            >
                <FormControlLabel
                    control={<AnnotationSwitch checked={showAutoAnnotations} onChange={handleSwitchClick} />}
                    label={<Typography variant='inputLabel'>{showAutoAnnotations ? 'Show auto-annotations' : 'Hide auto-annotations'}</Typography>}
                    sx={{ display: 'flex', gap: 0.5, alignItems: 'center', margin: 0 }}
                />
                {utils.map((util, idx) => (
                    <Button
                        color='primary'
                        key={`annotations-button-${idx}`}
                        onClick={() => util.onClick()}
                        sx={{
                            width: '8rem',
                        }}
                    >
                        {util.text}
                    </Button>
                ))}

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        color='secondary'
                        key={`reorder-numbers-button`}
                        onClick={() => reorderAnnotationsNumbers({})}
                        sx={{
                            width: '11rem',
                            minWidth: '11rem',
                            height: '1.5rem',
                            fontSize: '0.75rem',
                        }}
                        disabled={processing || autoAnnotations.length > 0}
                        isLoading={reordering}
                    >
                        Reorder numbers
                    </Button>
                    <AutoAnnotationsProcessErrorInfoComponent
                        autoAnnotationsProcess={autoAnnotationsProcess}
                        markAutoAnnotationsProcessErrorAsReceived={markAutoAnnotationsProcessErrorAsReceived}
                    />
                    {headingsWithAnnotations.length > 1 && (
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                            <Box onClick={() => collapseExpandAllHeadings()} sx={{ cursor: 'pointer', color: (theme) => theme.palette.icons.secondary }}>
                                {allHeadingsCollapsed ? <Icon.ArrowChevronExpand /> : <Icon.ArrowChevronContract />}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            {processing ? (
                <ProcessingInfoComponent documentProcessingStatus={verificationDocumentProcessingStatus} autoAnnotationsProcess={autoAnnotationsProcess} />
            ) : (
                <>
                    {filters.all.filtersList.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                margin: '1rem',
                                flex: '1 1 auto',
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                                <Typography variant='subtitle2'>Filters</Typography>
                                <Button
                                    variant='text'
                                    endIcon={<Close />}
                                    onClick={() => {
                                        filters.all.clear();
                                        addToast({ type: ToastEnum.SUCCESS, content: ['Filters removed.'] });
                                    }}
                                >
                                    Clear filters
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    flexWrap: 'wrap',
                                }}
                            >
                                {filters.all.filtersList.map((f, idx) => (
                                    <Chip
                                        color='info'
                                        key={`annotations-filters-list-item-${idx}`}
                                        label={f.label}
                                        onDelete={() => {
                                            f.remove();
                                            addToast({ type: ToastEnum.SUCCESS, content: ['Filter removed.'] });
                                        }}
                                        size='small'
                                    />
                                ))}
                            </Box>
                        </Box>
                    )}
                    <Box
                        sx={{
                            height: `calc(100% - ${UtilsHeight})`,
                            position: 'relative',
                            width: '100%',
                            display: 'flex',
                            flexFlow: 'column',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            textAlign: 'center',
                            gap: 1,
                            flex: '1 1 auto',
                            overflow: 'auto',
                        }}
                    >
                        {annotationsScope.annotations.length === 0 && annotationsScope.autoAnnotationsScope.autoAnnotations.length === 0 ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%',
                                    gap: 3,
                                }}
                            >
                                <EditNote color={palette.stroke.main} />
                                <Typography variant='body1'>NO ANNOTATIONS</Typography>
                                <Typography variant='body2'>
                                    Get started by highlighting facts <br /> in the text
                                </Typography>
                            </Box>
                        ) : (
                            <AnnotationsListComponent
                                showAutoAnnotations={showAutoAnnotations}
                                headings={headingsWithAnnotations}
                                collapsedHeadings={collapsedHeadings}
                                collapseExpandHeading={collapseExpandHeading}
                                {...annotationsScope}
                            />
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
}

function AutoAnnotationsProcessErrorInfoComponent(props: {
    autoAnnotationsProcess: AutoAnnotationsProcess | null;
    markAutoAnnotationsProcessErrorAsReceived: () => void;
}) {
    const { autoAnnotationsProcess, markAutoAnnotationsProcessErrorAsReceived } = props;

    let autoAnnotationsProcessErrorInfo: string | null = useMemo(() => {
        if (autoAnnotationsProcess) {
            switch (autoAnnotationsProcess.status) {
                case AutoAnnotationsProcessStatus.Failed:
                    return ErrorsDescription.autoAnnotationsProcessStatusFailed;
                case AutoAnnotationsProcessStatus.ProcessedWithErrors:
                    return ErrorsDescription.autoAnnotationsProcessStatusProcessedWithErrors;
                default:
                    return null;
            }
        }

        return null;
    }, [autoAnnotationsProcess]);

    const [checked, setChecked] = useState(false);

    const { isEditor } = useContext(ProjectAuthContext);

    if (autoAnnotationsProcessErrorInfo && autoAnnotationsProcess && !autoAnnotationsProcess.isErrorReceived) {
        return (
            <Box sx={{ color: (theme) => theme.palette.attention.medium, display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}>
                <Tooltip
                    title={
                        <Box>
                            <Typography variant='body2' sx={{ color: (theme) => theme.palette.attention.medium, fontSize: '0.75rem', fontWeight: 600 }}>
                                {autoAnnotationsProcessErrorInfo}
                            </Typography>
                            {isEditor && (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', width: '100%' }}>
                                    <Checkbox
                                        sx={{
                                            padding: '0rem',
                                            height: '0.75rem',
                                            width: '0.75rem',
                                            color: (theme) => `${theme.palette.primary.main} !important`,
                                        }}
                                        checked={checked}
                                        onChange={(_event, checked) => setChecked(checked)}
                                    />
                                    <Typography sx={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>Never show this again</Typography>
                                    <Button
                                        variant='text'
                                        disabled={!checked}
                                        sx={{
                                            marginLeft: '0.5rem',
                                            height: '1rem',
                                            width: '3rem',
                                            minWidth: '3rem',
                                            paddingLeft: '0.25rem',
                                            paddingRight: '0.25rem',
                                            paddingTop: '0rem',
                                            paddingBottom: '0rem',
                                            fontSize: '0.75rem',
                                            fontWeight: 600,
                                        }}
                                        onClick={() => markAutoAnnotationsProcessErrorAsReceived()}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    }
                >
                    <Icon.ExclamationCircle width={24} height={24} />
                </Tooltip>
            </Box>
        );
    } else {
        return null;
    }
}

export default AnnotationsComponent;
